/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../../common/Snackbar';
import { UserInfo } from '../../Interface/UserInterface/IUser';
import ChallengeDialog from '../../Booking/ChallengeDialog';
import CloseIcon from '@mui/icons-material/Close';

interface BookingRequestDialogProps {
  open: boolean;
  onCloseDialog: () => void;
  onSendBookingRequest: (sendData: any) => void;
  data: any;
  selectedArtist?: UserInfo[] | null | undefined;
  userPage?: boolean;
  id?: number | null | undefined;
}

const BookingRequestDialog: React.FC<BookingRequestDialogProps> = ({
  open,
  onCloseDialog,
  onSendBookingRequest,
  data,
  selectedArtist,
  userPage,
  id,
}) => {
  const privateAxios = useAxiosPrivate();

  const [hireDetails, setHireDetails] = useState({
    bookingIds: 0,
    bookingId: 0,
    projectName: '',
    startDate: null,
    endDate: null,
    description: '',
  });
  const [errors, setErrors] = useState({
    projectName: '',
    startDate: '',
    endDate: '',
    description: '',
  });
  const [openToast, setOpenToast] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [openChallenge, setOpenChallenge] = useState(false);
  const [holdData, setHoldData] = useState(null);

  useEffect(() => {
    if (!open) {
      setHireDetails({
        bookingIds: 0,
        bookingId: 0,
        projectName: '',
        startDate: null,
        endDate: null,
        description: '',
      });
    }
  }, [open]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setHireDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
      ...(data && data.length > 1
        ? { bookingIds: data.map((item: any) => item.id) }
        : { bookingId: data[0]?.id ? data[0]?.id : id }),
    }));
    setErrors((preError) => ({
      ...preError,
      [name]: '',
    }));
  };

  const handleStartDateChange = (date: any) => {
    setHireDetails((prevDetails) => ({
      ...prevDetails,
      startDate: date,
    }));
    setErrors((preError) => ({
      ...preError,
      startDate: '',
    }));
  };

  const handleEndDateChange = (date: any) => {
    const { startDate } = hireDetails;

    if (startDate && date < startDate) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        endDate: 'End date must be greater than or equal to start date',
      }));
    } else {
      setHireDetails((prevDetails) => ({
        ...prevDetails,
        endDate: date,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        endDate: '',
      }));
    }
  };

  // const extractIds = () => {
  //   if (Array.isArray(data) && data.length > 0) {
  //     return data.map((item) => item.id);
  //   } else if (data && typeof data === 'object') {
  //     return [data.id];
  //   } else {
  //     return [];
  //   }
  // };

  const handleClick = () => {
    setOpenToast(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpenToast(false);
  };

  // const handleHoldRequest = (id: any) => {
  //   const { projectName, startDate, endDate, description } = hireDetails;

  //   const errors = {
  //     projectName: '',
  //     startDate: '',
  //     endDate: '',
  //     description: '',
  //   };

  //   if (!projectName) {
  //     errors.projectName = 'Project Name is required';
  //   }

  //   if (!startDate) {
  //     errors.startDate = 'Start Date is required';
  //   }

  //   if (!endDate) {
  //     errors.endDate = 'End Date is required';
  //   }

  //   if (!description) {
  //     errors.description = 'Description is required';
  //   }

  //   if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
  //     errors.endDate = 'End Date must be greater than or equal to Start Date';
  //   }

  //   if (Object.values(errors).some((field) => field !== '')) {
  //     setErrors(errors);
  //     return;
  //   }
  //   const ids = selectedArtist && selectedArtist[0]?.id;
  //   const checkData = {
  //     bookingId: id === undefined ? ids : id,
  //     startDate: hireDetails.startDate,
  //     endDate: hireDetails.endDate,
  //   };
  //   const checkhold = privateAxios.post(`dashboard/checkhold`, checkData, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });
  //   checkhold
  //     .then((response: any) => {
  //       if (response.status === 200) {
  //         console.log('resposne', response.data);
  //         if (response.data.message.codes === 'noHold') {
  //           handleHold(checkData);
  //         }
  //         if (response.data.message.codes === 'alreadyHold') {
  //           setToast({ message: response.data.message.value, type: 'success' });
  //           handleClick();
  //           setHoldData(response.data.data);
  //           setOpenChallenge(true);
  //           // onClose();
  //         }
  //         return;
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.error(error);
  //       setToast({ message: error.response.data.message.value, type: 'error' });
  //       handleClick();
  //       return;
  //     });
  // };

  const handleHold = (data: any) => {
    console.log('Holddata', data);

    const holderDetails = {
      holdingId: hireDetails.bookingId,
      projectName: hireDetails.projectName,
      startDate: hireDetails.startDate,
      endDate: hireDetails.endDate,
      description: hireDetails.description,
    };
    const holdRequest = privateAxios.post(`dashboard/hold`, holderDetails, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    holdRequest
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          // onCloseDialog();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
        // onCloseDialog();
        // return;
      });
  };

  const handleChallenge = () => {
    const { bookingIds, ...restData } = hireDetails;

    const challenge = privateAxios.post(`dashboard/challenge`, restData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    challenge
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          closeChallengeDialog();
          onCloseDialog();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
        closeChallengeDialog;
        onCloseDialog();
      });
  };

  const closeChallengeDialog = () => {
    setOpenChallenge(false);
    onCloseDialog();
  };

  const checkValues = () => {
    const { projectName, startDate, endDate, description } = hireDetails;

    const errors = {
      projectName: '',
      startDate: '',
      endDate: '',
      description: '',
    };

    if (!projectName) {
      errors.projectName = 'Project Name is required';
    }

    if (!startDate) {
      errors.startDate = 'Start Date is required';
    }

    if (!endDate) {
      errors.endDate = 'End Date is required';
    }

    if (!description) {
      errors.description = 'Description is required';
    }

    if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
      errors.endDate = 'End Date must be greater than or equal to Start Date';
    }

    if (Object.values(errors).some((field) => field !== '')) {
      setErrors(errors);
      return;
    }

    onSendBookingRequest(hireDetails);
  };

  const resetAll = () => {
    setErrors({
      projectName: '',
      startDate: '',
      endDate: '',
      description: '',
    });
    setHireDetails({
      bookingIds: 0,
      bookingId: 0,
      projectName: '',
      startDate: null,
      endDate: null,
      description: '',
    });
    onCloseDialog();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={resetAll}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '903px !important',
            height: 'auto',
            padding: '30px',
          },
        }}
        className="sendBooks">
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography style={{ color: '#2CB512', fontSize: '18px', fontWeight: 600, flex: '1' }}>
            Send booking request
          </Typography>
          <IconButton onClick={resetAll} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ color: '#73787E', fontSize: '16px', fontWeight: 400, marginTop: '10px' }}>
            Define your project name and details and add a timeline for the talent member.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography className="lable-name" sx={{ textAlign: 'left', mt: '30px' }}>
                Project Name
              </Typography>
              <TextField
                className="formFields"
                sx={{ mt: 0.5, width: '100%', maxHeight: '200px' }}
                type="text"
                name="projectName"
                placeholder="Enter Project Name Here"
                value={hireDetails.projectName}
                onChange={handleChange}
              />
              {errors.projectName && <Typography sx={{ color: 'red', mt: 1 }}>{errors.projectName}</Typography>}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                className="lable-name"
                sx={{
                  textAlign: 'left',
                  color: '#344054',
                }}>
                Start Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    label="Start Date"
                    value={hireDetails.startDate}
                    sx={{ fontFamily: "'Onest', sans-serif !important" }}
                    minDate={dayjs()}
                    views={['year', 'month', 'day']}
                    // onChange={handleStartDateChange}
                    onChange={(newEndDate) => {
                      const adjustedEndDate =
                        newEndDate !== null ? dayjs(newEndDate).hour(5).minute(30).second(0).millisecond(0) : null;
                      handleStartDateChange(adjustedEndDate);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {errors.startDate && <Typography sx={{ color: 'red', mt: 1 }}>{errors.startDate}</Typography>}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className="lable-name"
                sx={{
                  textAlign: 'left',
                  color: '#344054',
                }}>
                End Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateTimePicker']}>
                  <DateTimePicker
                    label="End Date"
                    value={hireDetails.endDate}
                    sx={{ fontFamily: "'Onest', sans-serif !important" }}
                    minDate={dayjs()}
                    views={['year', 'month', 'day']}
                    // onChange={handleEndDateChange}
                    onChange={(newEndDate) => {
                      const adjustedEndDate =
                        newEndDate !== null ? dayjs(newEndDate).hour(17).minute(30).second(0).millisecond(0) : null;
                      handleEndDateChange(adjustedEndDate);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {errors.endDate && <Typography sx={{ color: 'red', mt: 1 }}>{errors.endDate}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <Typography className="lable-name" sx={{ textAlign: 'left', color: '#344054' }}>
                Description (Required)
              </Typography>
              <TextField
                className="formFields"
                multiline
                rows={4}
                sx={{ mt: 0.5, width: '100%', maxHeight: '200px' }}
                type="text"
                name="description"
                placeholder="Type Something Here..."
                value={hireDetails.description}
                onChange={handleChange}
              />
              {errors.description && <Typography sx={{ color: 'red', mt: 1 }}>{errors.description}</Typography>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            className="common-button-white"
            type="submit"
            variant="contained"
            onClick={resetAll}
            sx={{
              width: '100%',
              maxWidth: '271px',
              height: '44px',
              marginRight: 2,
              mt: 3,
              mb: 2,
              '&:hover': {
                color: 'var(--Primary, #2CB512)',
                backgroundColor: '#fff',
              },
            }}>
            Cancel
          </Button>
          {/* {(data && data.length === 1) || userPage ? (
            <Button
              className="common-button-green"
              type="submit"
              variant="contained"
              sx={{
                width: '100%',
                maxWidth: '271px',
                height: '44px',
                marginRight: 2,
                mt: 3,
                mb: 2,
                '&:hover': {
                  background: 'var(--Primary, #2CB512)',
                },
              }}
              onClick={() => handleHoldRequest(id)}>
              Request to hold
            </Button>
          ) : (
            ''
          )} */}

          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            // onClick={() => onSendBookingRequest(hireDetails)}
            onClick={checkValues}
            sx={{
              width: '100%',
              maxWidth: '271px',
              height: '44px',
              marginRight: 2,
              mt: 3,
              mb: 2,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
              color: '#FFF',
              '&.Mui-disabled': {
                color: '#FFF',
              },
            }}>
            Request to Hire
          </Button>
        </DialogActions>
      </Dialog>

      <ChallengeDialog
        openChallenge={openChallenge}
        selectedArtist={selectedArtist}
        handleChallengeClose={closeChallengeDialog}
        requestChallenge={handleChallenge}
        // id={id}
        holdData={holdData}
      />

      <CustomSnackbar open={openToast} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

export default BookingRequestDialog;

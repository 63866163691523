/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../../../Assets/css/Skills.css';
import { Collapse } from 'react-bootstrap';
import { Box, Button, IconButton, Grid, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Info from '../../../Assets/Images/Info.png';
import SelectedInfo from '../../../Assets/Images/SelectedInfo.png';
// import Env from '../../../services/Env';
import { connect } from 'react-redux';
import CustomSnackbar from '../../../common/Snackbar';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';

interface step {
  label: string;
  key: string;
}

interface SkillsDetailsProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  stepKeys: step;
  // userAccessToken: string;
  id: number;
}

interface CheckedItems {
  [key: string]: boolean;
}

interface Skill {
  label: string;
  key: string;
  information: string;
}

interface Category {
  category: { label: string; key: string };
  skills: Skill[];
}

interface SelectedSkills {
  stepperKey: string;
  // userId: number;
  skills: {
    categoryKey: string;
    skillKey: string[];
  }[];
}

interface CategoryProps {
  category: Category;
  isOpen: Record<string, boolean>;
  handleToggle: (key: string) => void;
  checkedItems: Record<string, boolean>;
  onCheckboxChange: (categoryKey: string, skillKey: string) => void;
  selectedSkills: {
    stepperKey: string;
    // userId: number;
    skills: any[];
  };
  existingData: SelectedSkills;
  size: Record<string, boolean>;
}

interface PayloadItem {
  categoryKey: string | any;
  skillKey: string[] | any;
}

const Category: React.FC<CategoryProps> = ({
  category,
  isOpen,
  handleToggle,
  checkedItems,
  onCheckboxChange,
  selectedSkills,
  existingData,
}) => {
  const falseSlideInitial = !isOpen[category.category.key];
  const isSkillSelected = (categoryKey: string, skillKey: string) => {
    if (existingData) {
      const existingDataCheck =
        existingData.skills?.some((selectedCategory: any) => {
          return (
            selectedCategory.category.key === categoryKey &&
            selectedCategory.skills.some((selectedSkill: any) => selectedSkill.key === skillKey)
          );
        }) || false;

      if (existingDataCheck) {
        return true;
      }
    }

    return (
      selectedSkills.skills?.some((selectedCategory: any) => {
        if (selectedCategory && selectedCategory.skills && selectedCategory.category && selectedCategory.category.key) {
          return (
            selectedCategory.category.key === categoryKey &&
            selectedCategory.skills.some((selectedSkill: any) => {
              return selectedSkill.key === skillKey;
            })
          );
        }

        return false;
      }) || false
    );
  };

  return (
    <li key={category.category.key}>
      <div className="check-box">
        <div className="check-head" onClick={() => handleToggle(category.category.key)}>
          <h4>{category.category.label}</h4>
          <IconButton className="drop-icon" aria-label="angle-down">
            {falseSlideInitial ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
        <div id={category.category.key} className="toolsLevel" style={{ height: falseSlideInitial ? '' : '200px' }}>
          <Collapse in={falseSlideInitial}>
            <ul>
              {category.skills.map((skill: any) => (
                <li key={skill.key}>
                  <div
                    className={`form-group check-text ${
                      selectedSkills.skills.length === 0 || selectedSkills.skills.length > 0
                        ? checkedItems[`${category.category.key}-${skill.key}`]
                          ? 'green-box'
                          : ''
                        : isSkillSelected(category.category.key, skill.key)
                        ? 'green-box'
                        : ''
                    }`}>
                    <input
                      type="checkbox"
                      key={skill.key}
                      id={skill.key}
                      name={skill.key}
                      checked={
                        selectedSkills.skills.length === 0 || selectedSkills.skills.length > 0
                          ? checkedItems[`${category.category.key}-${skill.key}`]
                          : isSkillSelected(category.category.key, skill.key)
                      }
                      onChange={() => onCheckboxChange(category.category.key, skill.key)}
                    />
                    <label style={{ cursor: 'pointer' }} htmlFor={skill.key}>
                      {skill.label}
                    </label>
                    <img
                      src={
                        selectedSkills.skills.length === 0 || selectedSkills.skills.length > 0
                          ? checkedItems[`${category.category.key}-${skill.key}`]
                            ? SelectedInfo
                            : Info
                          : isSkillSelected(category.category.key, skill.key)
                          ? SelectedInfo
                          : Info
                      }
                      alt="Info"
                      title={skill.information}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </Collapse>
        </div>
      </div>
    </li>
  );
};

const SkillsDetails: React.FC<SkillsDetailsProps> = ({
  activeStep,
  handleNext,
  handleBack,
  stepKeys,
  // id,
  // userAccessToken,
}) => {
  const privateAxios = useAxiosPrivate();

  const [payLoad1, setPayLoad1] = useState<PayloadItem[]>([]);
  const [skills, setSkills] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({});
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  const [selectedSkills, setSelectedSkills] = useState<SelectedSkills>({
    stepperKey: stepKeys.key,
    // userId: id,
    skills: [],
  });
  const [existingData, setExistingData] = useState<any>(null);
  const [Error, setError] = useState({
    skills: '',
  });
  const [size, setSize] = useState<{ [key: string]: boolean }>({});

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getSkillsData();
    getSelectedData();
  }, []);

  useEffect(() => {
    const updatedIsOpen = { ...isOpen };
    const selectedSkillsData = skills;
    selectedSkillsData.forEach((selectedCategory: any) => {
      const key = `${selectedCategory.category.key}`;
      updatedIsOpen[key] = false;
    });
    setIsOpen(updatedIsOpen);
    setSize(updatedIsOpen);
  }, [skills]);

  const getSelectedData = () => {
    // const getData = Env.get(`user/getuserstepper?stepperKey=skills&userId=${id}`, userAccessToken);

    const getData = privateAxios.get(`user/getuserstepper?stepperKey=${stepKeys.key}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getData
      .then((response: any) => {
        if (response.status === 200) {
          setExistingData(response.data.data);
          const updatedCheckedItems = { ...checkedItems };
          const selectedSkillsData = response.data.data;

          selectedSkillsData.forEach((selectedCategory: any) => {
            selectedCategory.skills.forEach((selectedSkill: any) => {
              const key = `${selectedCategory.category.key}-${selectedSkill.key}`;
              updatedCheckedItems[key] = true;
            });
          });

          setCheckedItems(updatedCheckedItems);

          setSelectedSkills((prevData: any) => ({ ...prevData, skills: selectedSkillsData }));
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const getSkillsData = () => {
    // const skillData = Env.get(`user/getSkills`, userAccessToken);

    const skillData = privateAxios.get(`user/getSkills`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    skillData
      .then((response: any) => {
        if (response.status === 200) {
          const initialCheckedItems = response.data.data.reduce((acc: CheckedItems, category: any) => {
            category.skills.forEach((skill: any) => {
              acc[`${category.category.key}-${skill.key}`] = false;
            });
            return acc;
          }, {});

          setSkills(response.data.data);
          setCheckedItems(initialCheckedItems);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const handleSkillDetailsData = () => {
    const { skills } = selectedSkills;

    const updatedSelectedSkills = skills.map((category: any) => ({
      categoryKey: category.category.key,
      skillKey: category.skills
        .filter((skill: any) => checkedItems[`${category.category.key}-${skill.key}`])
        .map((selectedSkill: any) => selectedSkill.key),
    }));

    const skillsMap: Record<string, string[]> = {};

    [...updatedSelectedSkills, ...payLoad1].forEach((entry) => {
      const { categoryKey, skillKey } = entry;

      if (!skillsMap[categoryKey]) {
        skillsMap[categoryKey] = [];
      }

      skillsMap[categoryKey].push(...entry.skillKey.filter((key: any) => checkedItems[`${categoryKey}-${key}`]));
    });

    const combinedSkills = Object.entries(skillsMap)
      .map(([categoryKey, skillKey]) => ({
        categoryKey,
        skillKey,
      }))
      .filter((entry) => entry.skillKey.length > 0);

    if (combinedSkills.length > 0 || payLoad1.length > 0) {
      setError((pre: any) => ({
        ...pre,
        skills: '',
      }));
    }

    const changesDetected = existingData && existingData.skills !== combinedSkills;

    const errors = {
      skills: '',
    };

    if (changesDetected) {
      if (!combinedSkills || combinedSkills.length === 0) {
        errors.skills = 'Skills is required';
      }

      if (Object.values(errors).some((field) => field !== '')) {
        setError(errors);
        return;
      }
      // const skilldetails = Env.post(
      //   `user/register`,
      //   {
      //     stepperKey: 'skills',
      //     userId: id,
      //     skills: combinedSkills,
      //   },
      //   userAccessToken
      // );

      const skilldetails = privateAxios.post(
        `user/register`,
        {
          stepperKey: 'skills',
          // userId: id,
          skills: combinedSkills,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      skilldetails
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setTimeout(() => handleNext(), 2000);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
      return;
    } else if (selectedSkills.skills.length === 0) {
      if (payLoad1.length === 0) {
        errors.skills = 'Skills is required';
      }

      if (Object.values(errors).some((field) => field !== '')) {
        setError(errors);
        return;
      }
      // const skilldetails = Env.post(
      //   `user/register`,
      //   {
      //     stepperKey: stepKeys.key,
      //     userId: id,
      //     skills: payLoad1,
      //   },
      //   userAccessToken
      // );
      const skilldetails = privateAxios.post(
        `user/register`,
        {
          stepperKey: 'skills',
          // userId: id,
          skills: payLoad1,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      skilldetails
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setTimeout(() => handleNext(), 2000);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    } else {
      handleNext();
      return;
    }
  };

  const handleToggle = (key: any) => {
    setIsOpen((prevIsOpen) => ({
      ...prevIsOpen,
      [key]: !prevIsOpen[key],
    }));

    setSize((prevSize) => ({
      ...prevSize,
      [key]: !prevSize[key],
    }));
  };

  const handleCheckboxChange = (categoryKey: string, skillKey: string) => {
    Error.skills = '';
    setPayLoad1((prevPayload) => {
      const existingCategoryIndex = prevPayload.findIndex((item) => item.categoryKey === categoryKey);
      if (existingCategoryIndex !== -1) {
        const updatedPayload = [...prevPayload];
        const skillIndex = updatedPayload[existingCategoryIndex].skillKey.indexOf(skillKey);

        if (skillIndex !== -1) {
          updatedPayload[existingCategoryIndex].skillKey.splice(skillIndex, 1);

          if (updatedPayload[existingCategoryIndex].skillKey.length === 0) {
            updatedPayload.splice(existingCategoryIndex, 1);
          }
        } else {
          updatedPayload[existingCategoryIndex].skillKey.push(skillKey);
        }

        return updatedPayload;
      } else {
        return [...prevPayload, { categoryKey: categoryKey, skillKey: [skillKey] }];
      }
    });

    setCheckedItems((prevCheckedItems) => {
      const key = `${categoryKey}-${skillKey}`;
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [key]: !prevCheckedItems[key],
      };

      return updatedCheckedItems;
    });
  };

  return (
    <>
      <div id="app">
        <div style={{ paddingTop: 25 }}>
          <h1
            style={{
              fontSize: '30px',
              fontWeight: 600,
              lineHeight: '38px',
              marginBottom: '10px',
              fontFamily: 'Onest, sans-serif',
            }}>
            Skills
          </h1>
          <p
            style={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              paddingTop: '10px',
              color: '#667085',
              fontFamily: 'Onest, sans-serif',
            }}>
            Click on any roles that you can perform (at any level) professionally. Then, click to activate any skills that
            you utilize. Some skills, once clicked, will reveal sub-skills for even greater detail. 
            <br />
            Clients often search for specific skills, so make sure to be thorough. Hover over the &apos;?&apos; icon for a
            description of the skill.
          </p>
        </div>
      </div>
      <div className="box-content" style={{ fontFamily: 'Onest, sans-serif' }}>
        {Error.skills.length > 0 && Error.skills && <Typography sx={{ color: 'red', mt: 1 }}>{Error.skills}</Typography>}
        <Grid container sx={{ pl: 0 }}>
          {skills.map((category: any) => (
            <Grid item key={category.category.key} sm={4}>
              <div className="list-box">
                <ul style={{ paddingLeft: 0 }} key={category.category.key}>
                  <Category
                    category={category}
                    isOpen={isOpen}
                    handleToggle={handleToggle}
                    checkedItems={checkedItems}
                    onCheckboxChange={handleCheckboxChange}
                    selectedSkills={selectedSkills}
                    existingData={existingData}
                    size={size}
                  />
                </ul>
              </div>
            </Grid>
          ))}
        </Grid>
        {Error.skills.length > 0 && Error.skills && <Typography sx={{ color: 'red', mt: 1 }}>{Error.skills}</Typography>}
      </div>
      <Box sx={{ padding: '50px', paddingTop: '40px' }}>
        <Box sx={{ textAlign: 'center' }}>
          {activeStep === 1 ? (
            ''
          ) : (
            <Button
              className="common-button-white"
              type="submit"
              variant="contained"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                marginRight: 1,
                mt: 3,
                mb: 2,
                '&:hover': {
                  color: 'var(--Primary, #2CB512)',
                  backgroundColor: '#fff',
                },
                alignItems: 'center',
              }}>
              Back
            </Button>
          )}
          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleSkillDetailsData}
            sx={{
              marginLeft: 1,
              mt: 3,
              mb: 2,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save and Next
          </Button>
        </Box>
        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Box>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(SkillsDetails);

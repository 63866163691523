/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import '../../../Assets/css/Skills.css';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { Collapse } from 'react-bootstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import Env from '../../../services/Env';
import { connect } from 'react-redux';
import CustomSnackbar from '../../../common/Snackbar';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';

interface step {
  label: string;
  key: string;
}

interface ToolsDetailsProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  stepKeys: step;
  // userAccessToken: string;
  id: number;
}

interface CheckedItems {
  [key: string]: boolean;
}

interface Tool {
  label: string;
  key: string;
  information: string;
}

interface Category {
  category: { label: string; key: string };
  tools: Tool[];
}

interface SelectedTools {
  stepperKey: string;
  // userId: number;
  tools: {
    categoryKey: string;
    toolKey: string[];
  }[];
}

interface CategoryProps {
  category: Category;
  isOpen: Record<string, boolean>;
  handleToggle: (key: string) => void;
  checkedItems: Record<string, boolean>;
  handleCheckboxChange: (categoryKey: string, toolKey: string) => void;
  selectedTools: {
    stepperKey: string;
    // userId: number;
    tools: any[];
  };
  existingData: SelectedTools;
  size: Record<string, boolean>;
}

interface PayloadItem {
  categoryKey: string | any;
  toolKey: string[] | any;
}

const Category: React.FC<CategoryProps> = ({
  category,
  isOpen,
  handleToggle,
  checkedItems,
  handleCheckboxChange,
  selectedTools,
  existingData,
}) => {
  const falseSlideInitial = !isOpen[category.category.key];
  const isToolSelected = (categoryKey: string, toolKey: string) => {
    if (existingData) {
      const existingDataCheck =
        existingData.tools?.some((selectedCategory: any) => {
          return (
            selectedCategory.category.key === categoryKey &&
            selectedCategory.tools.some((selectedTool: any) => selectedTool.key === toolKey)
          );
        }) || false;

      if (existingDataCheck) {
        return true;
      }
    }

    return (
      selectedTools.tools.some((selectedCategory) => {
        if (selectedCategory && selectedCategory.tools && selectedCategory.category && selectedCategory.category.key) {
          return (
            selectedCategory.category.key === categoryKey &&
            selectedCategory.tools.some((selectedTool: any) => {
              return selectedTool.key === toolKey;
            })
          );
        }

        return false;
      }) || false
    );
  };

  return (
    <li key={category.category.key}>
      <div className="check-box">
        <div className="check-head" onClick={() => handleToggle(category.category.key)}>
          <h4>{category.category.label}</h4>
          <IconButton className="drop-icon" aria-label="angle-down">
            {falseSlideInitial ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
        <div id={category.category.key} className="toolsLevel" style={{ height: falseSlideInitial ? '' : '200px' }}>
          <Collapse in={falseSlideInitial}>
            <ul>
              {category.tools.map((tool: any) => (
                <li key={tool.key}>
                  <div
                    className={`form-group check-text ${
                      selectedTools.tools.length === 0 || selectedTools.tools.length > 0
                        ? checkedItems[`${category.category.key}-${tool.key}`]
                          ? 'green-box'
                          : ''
                        : isToolSelected(category.category.key, tool.key)
                        ? 'green-box'
                        : ''
                    }`}>
                    <label style={{ cursor: 'pointer' }} htmlFor={tool.key}>
                      {tool.label}
                    </label>
                    <input
                      type="checkbox"
                      key={tool.key}
                      id={tool.key}
                      name={tool.key}
                      onChange={() => handleCheckboxChange(category.category.key, tool.key)}
                      checked={
                        selectedTools.tools.length === 0 || selectedTools.tools.length > 0
                          ? checkedItems[`${category.category.key}-${tool.key}`]
                          : isToolSelected(category.category.key, tool.key)
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          </Collapse>
        </div>
      </div>
    </li>
  );
};

const ToolsDetails: React.FC<ToolsDetailsProps> = ({
  activeStep,
  handleNext,
  handleBack,
  stepKeys,
  id,
  // userAccessToken,
}) => {
  const privateAxios = useAxiosPrivate();

  const [payLoad, setPayLoad] = useState<PayloadItem[]>([]);
  const [tools, setTools] = useState([]);
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({});
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [selectedTools, setSelectedTools] = useState({
    stepperKey: stepKeys.key,
    // userId: id,
    tools: [],
  });
  const [existingData, setExistingData] = useState<any>(null);
  const [Error, setError] = useState({
    tools: '',
  });
  const [size, setSize] = useState<{ [key: string]: boolean }>({});

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getToolsData();
    getSelectedData();
  }, []);

  useEffect(() => {
    const updatedIsOpen = { ...isOpen };
    const selectedSkillsData = tools;
    selectedSkillsData.forEach((selectedCategory: any) => {
      const key = `${selectedCategory.category.key}`;
      updatedIsOpen[key] = false;
    });
    setIsOpen(updatedIsOpen);
    setSize(updatedIsOpen);
  }, [tools]);

  const getSelectedData = () => {
    // const getData = Env.get(`user/getuserstepper?stepperKey=${stepKeys.key}&userId=${id}`, userAccessToken);

    const getData = privateAxios.get(`user/getuserstepper?stepperKey=${stepKeys.key}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getData
      .then((response: any) => {
        if (response.status === 200) {
          setExistingData(response.data.data);
          const updatedCheckedItems = { ...checkedItems };
          const selectedToolsData = response.data.data;

          selectedToolsData.forEach((selectedCategory: any) => {
            selectedCategory.tools.forEach((selectedTool: any) => {
              const key = `${selectedCategory.category.key}-${selectedTool.key}`;
              updatedCheckedItems[key] = true;
            });
          });

          setCheckedItems(updatedCheckedItems);

          setSelectedTools((prevData: any) => ({ ...prevData, tools: selectedToolsData }));
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const getToolsData = () => {
    // const toolData = Env.get(`user/getTools`, userAccessToken);

    const toolData = privateAxios.get(`user/getTools`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    toolData
      .then((response: any) => {
        if (response.status === 200) {
          const initialCheckedItems = response.data.data.reduce((acc: CheckedItems, category: any) => {
            category.tools.forEach((tool: any) => {
              acc[`${category.category.key}-${tool.key}`] = false;
            });
            return acc;
          }, {});
          setTools(response.data.data);
          setCheckedItems(initialCheckedItems);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const handleToolDetailsData = () => {
    const { tools } = selectedTools;

    const updatedSelectedTools = tools.map((category: any) => ({
      categoryKey: category.category.key,
      toolKey: category.tools
        .filter((tool: any) => checkedItems[`${category.category.key}-${tool.key}`])
        .map((selectedTool: any) => selectedTool.key),
    }));

    const toolsMap: Record<string, string[]> = {};

    [...updatedSelectedTools, ...payLoad].forEach((entry) => {
      const { categoryKey, toolKey } = entry;

      if (!toolsMap[categoryKey]) {
        toolsMap[categoryKey] = [];
      }

      toolsMap[categoryKey].push(...entry.toolKey.filter((key: any) => checkedItems[`${categoryKey}-${key}`]));
    });

    const combinedTools = Object.entries(toolsMap)
      .map(([categoryKey, toolKey]) => ({
        categoryKey,
        toolKey,
      }))
      .filter((entry) => entry.toolKey.length > 0);

    if (combinedTools.length > 0 || payLoad.length > 0) {
      setError((pre: any) => ({
        ...pre,
        tools: '',
      }));
    }

    const changesDetected = existingData && existingData.tools !== combinedTools;

    const errors = {
      tools: '',
    };

    if (changesDetected) {
      if (!combinedTools || combinedTools.length === 0) {
        errors.tools = 'Tools is required';
      }

      if (Object.values(errors).some((field) => field !== '')) {
        setError(errors);
        return;
      }

      // const tooldetails = Env.post(
      //   `user/register`,
      //   {
      //     stepperKey: stepKeys.key,
      //     userId: id,
      //     tools: combinedTools,
      //   },
      //   userAccessToken
      // );
      const tooldetails = privateAxios.post(
        `user/register`,
        {
          stepperKey: stepKeys.key,
          // userId: id,
          tools: combinedTools,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      tooldetails
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setTimeout(() => handleNext(), 2000);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    } else if (selectedTools.tools.length === 0) {
      if (payLoad.length === 0) {
        errors.tools = 'Tools is required';
      }

      if (Object.values(errors).some((field) => field !== '')) {
        setError(errors);
        return;
      }

      // const tooldetails = Env.post(
      //   `user/register`,
      //   {
      //     stepperKey: stepKeys.key,
      //     userId: id,
      //     tools: payLoad,
      //   },
      //   userAccessToken
      // );

      const tooldetails = privateAxios.post(
        `user/register`,
        {
          stepperKey: stepKeys.key,
          // userId: id,
          tools: payLoad,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      tooldetails
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setTimeout(() => handleNext(), 2000);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    } else {
      handleNext();
      return;
    }
  };

  const handleToggle = (key: any) => {
    setIsOpen((prevIsOpen) => ({
      ...prevIsOpen,
      [key]: !prevIsOpen[key],
    }));

    setSize((prevSize) => ({
      ...prevSize,
      [key]: !prevSize[key],
    }));
  };

  const handleCheckboxChange = (categoryKey: string, toolKey: string) => {
    Error.tools = '';
    setPayLoad((prevPayload: any) => {
      const existingCategoryIndex = prevPayload.findIndex(
        (item: { categoryKey: string }) => item.categoryKey === categoryKey
      );
      if (existingCategoryIndex !== -1) {
        const updatedPayload = [...prevPayload];
        const toolIndex = updatedPayload[existingCategoryIndex].toolKey.indexOf(toolKey);

        if (toolIndex !== -1) {
          updatedPayload[existingCategoryIndex].toolKey.splice(toolIndex, 1);

          if (updatedPayload[existingCategoryIndex].toolKey.length === 0) {
            updatedPayload.splice(existingCategoryIndex, 1);
          }
        } else {
          updatedPayload[existingCategoryIndex].toolKey.push(toolKey);
        }

        return updatedPayload;
      } else {
        return [...prevPayload, { categoryKey: categoryKey, toolKey: [toolKey] }];
      }
    });

    setCheckedItems((prevCheckedItems) => {
      const key = `${categoryKey}-${toolKey}`;
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [key]: !prevCheckedItems[key],
      };

      return updatedCheckedItems;
    });
  };

  return (
    <>
      <div id="app">
        <div className="skillWidth">
          <h1
            style={{
              fontSize: '30px',
              fontWeight: 600,
              lineHeight: '38px',
              paddingTop: '30px',
              fontFamily: 'Onest, sans-serif',
            }}>
            Tools
          </h1>
          <p
            style={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '20px',
              paddingTop: '10px',
              color: '#667085',
              fontFamily: 'Onest, sans-serif',
            }}>
            Click on a category to reveal various tools within that category. You can select as many tools as you&apos;d
            like. Be sure to only select tools you are very comfortable and knowledgeable about. If you don&apos;t see a tool
            listed here that should be, please contact us and let us know!
          </p>

          <div className="box-content" style={{ fontFamily: 'Onest, sans-serif' }}>
            {Error.tools.length > 0 && Error.tools && <Typography sx={{ color: 'red', mt: 1 }}>{Error.tools}</Typography>}
            <Grid container sx={{ pl: 0 }}>
              {tools.map((category: any) => (
                <Grid item key={category.category.key} sm={4}>
                  <div className="list-box">
                    <ul style={{ paddingLeft: 0 }} key={category.category.key}>
                      <Category
                        category={category}
                        isOpen={isOpen}
                        handleToggle={handleToggle}
                        checkedItems={checkedItems}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedTools={selectedTools}
                        existingData={existingData}
                        size={size}
                      />
                    </ul>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
          {Error.tools.length > 0 && Error.tools && <Typography sx={{ color: 'red', mt: 1 }}>{Error.tools}</Typography>}
        </div>
      </div>
      <Box sx={{ padding: '50px', paddingTop: '40px' }}>
        <Box sx={{ textAlign: 'center' }}>
          {activeStep === 1 ? (
            ''
          ) : (
            <Button
              className="common-button-white"
              type="submit"
              variant="contained"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                marginRight: 1,
                mt: 3,
                mb: 2,
                '&:hover': {
                  color: 'var(--Primary, #2CB512)',
                  backgroundColor: '#fff',
                },
                alignItems: 'center',
              }}>
              Back
            </Button>
          )}

          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleToolDetailsData}
            disabled={activeStep === 10}
            sx={{
              marginLeft: 1,
              mt: 3,
              mb: 2,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save and Next
          </Button>
        </Box>
        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Box>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(ToolsDetails);

/* eslint-disable no-debugger */
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import useAxioPrivate from '../../../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../../../common/Snackbar';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  userId,
  isLoggedIn,
  userType,
  userAccessToken,
  userRefreshToken,
  backHandle,
  userStepStatus,
  userPM,
  // customerID,
} from '../../../../reducer/action';
import { AppDispatch } from '../../../../reducer/store';

const ConfirmationDialog = ({
  paymentId,
  message,
  openConfirmation,
  closeConfirmationDialog,
  renewal,
  paymentDelay,
  ...otherProps
}: any) => {
  const navigate = useNavigate();
  const privateAxios = useAxioPrivate();
  const dispatch = useDispatch<AppDispatch>();

  const [toast, setToast] = useState({ message: '', type: 'success' });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleExecute = () => {
    if (paymentId) {
      setLoading(true);
      const stripeCreate = privateAxios.get(
        `payment/stripe/execute?paymentId=${paymentId}&isPlan=${true}&discountEligible=${otherProps.userDisE}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      stripeCreate
        .then((response: any) => {
          if (response.data.status === true) {
            setLoading(false);
            window.open(response.data.data?.link, '_blank');

            if (otherProps.userType) {
              if (renewal) {
                dispatch(userStepStatus({ status: response.data.data.status }));
                // dispatch(userAccessToken({ accessToken: '' }));
                // dispatch(userRefreshToken({ refreshToken: '' }));
                // dispatch(isLoggedIn(false));
                // dispatch(userId(''));
                // dispatch(userType(false));
                // dispatch(userPM(false));
                // dispatch(customerID(''));
                navigate('/');
              } else {
                dispatch(userPM(response.data.data.isPayment));
                dispatch(userStepStatus({ status: response.data.data.status }));
                // navigate('/premium/creat-team');
                navigate('/premium/sign-up');
              }
            } else {
              setTimeout(() => {
                dispatch(isLoggedIn(false));
                dispatch(backHandle(false));
                dispatch(userId(''));
                dispatch(userStepStatus({ status: '' }));
                dispatch(userType(false));
                dispatch(userPM(false));
                dispatch(userAccessToken({ accessToken: '' }));
                dispatch(userRefreshToken({ refreshToken: '' }));
                localStorage.removeItem('center');
                localStorage.clear();
                navigate(`/login`);
              }, 2000);
            }
          }
        })
        .catch((error: any) => {
          setToast({ message: error.response.data?.message.value, type: 'error' });
          handleClick();
        });
    }
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress
            color="success"
            // style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          />
        </Backdrop>
      )}
      <Dialog
        open={paymentDelay}
        // onClick={closeConfirmationDialog}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '500px !important',
            height: 'auto',
            padding: '25px',
          },
        }}>
        <DialogContent sx={{ fontFamily: "'Onest', sans-serif" }}>
          <h2 style={{ marginBottom: '12px' }}>Payment Processing....</h2>
          <span>Please do not close or refresh this window until the transaction is complete</span>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openConfirmation}
        // onClick={closeConfirmationDialog}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '500px !important',
            height: 'auto',
            padding: '25px',
          },
        }}>
        <DialogContent sx={{ fontFamily: "'Onest', sans-serif" }}>{message && message}</DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button className="common-button-white" sx={{ color: '#fff' }} onClick={closeConfirmationDialog}>
            Cancel
          </Button>
          <Button className="common-button-green" sx={{ color: '#fff' }} onClick={handleExecute}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
    userStatus: state.status,
    userType: state.userType,
    userDisE: state.userDisE,
  };
}

export default connect(mapDispatchToProps)(ConfirmationDialog);

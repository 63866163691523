/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import '../../../Assets/css/Crop.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CropProps {
  imageSize: string;
  images: any;
  onCropComplete: (croppedImageData: string | null) => void;
  cropOpen: boolean;
  onCloseDialog: () => void;
}

const Crop: React.FC<CropProps> = ({ imageSize, images, onCropComplete, cropOpen, onCloseDialog }) => {
  const [image, setImage] = useState<any>(images);
  // const [cropData, setCropData] = useState<string | null>('#');
  const [cropper, setCropper] = useState<any>();

  useEffect(() => {
    if (images) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(images);
    }
  }, [images, imageSize]);

  const getCropData = () => {
    if (cropper) {
      let width = 200;
      let height = 200;

      if (imageSize === 'banner') {
        width = 1440;
        height = 186;
      }

      const croppedCanvas = cropper.getCroppedCanvas({
        width,
        height,
      });

      if (croppedCanvas) {
        // const croppedImageData = croppedCanvas.toDataURL();
        // setCropData(croppedImageData);
        croppedCanvas.toBlob((blob: any) => {
          if (blob) {
            const croppedFile: any = new File([blob], 'cropped_image.png', { type: 'image/png' });
            onCropComplete(croppedFile);
          } else {
            onCropComplete(null);
          }
        }, 'image/png');
      } else {
        onCropComplete(null);
      }
    }
  };

  const clearData = () => {
    setImage('');
    setCropper('');
    onCloseDialog();
  };

  return (
    <Dialog
      open={cropOpen}
      onClose={clearData}
      className="crop-dialog"
      sx={{
        '& .MuiDialog-paper': {
          height: 'auto',
          padding: '15px',
        },
      }}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '14px 11px' }}>
        <Typography style={{ color: '#2CB512', fontSize: '18px', fontWeight: 600, flex: '1' }}>Edit Photo</Typography>
        <IconButton onClick={clearData} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div></div>
        <Typography style={{ color: '#73787E', fontSize: '16px', fontWeight: 400 }}>Crop your image</Typography>
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%' }}>
            <br />
            <Cropper
              style={{ height: 300, width: '100%' }}
              zoomTo={0}
              // initialAspectRatio={imageSize === 'profile' ? 1 : 16 / 9}
              preview=".img-preview"
              src={image}
              viewMode={1}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance: any) => {
                setCropper(instance);
              }}
              guides={true}
              // aspectRatio={imageSize === 'profile' ? 1 : 20 / 9}
              aspectRatio={imageSize === 'profile' ? 1 : 5 / 1}
            />
          </div>
          {/* <div> */}
          {/* <div className="box" style={{ width: '100%' }}> */}
          {/* <h1 style={{ fontFamily: "'Onest', sans-serif" }}>Preview</h1> */}
          {/* <div className="img-preview" style={{ width: '400px', float: 'left', height: '300px' }} /> */}
          {/* <div className="img-preview" /> */}
          {/* </div> */}
          {/* </div> */}
          <br style={{ clear: 'both' }} />
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          className="common-button-white cropBtn"
          type="submit"
          variant="contained"
          onClick={clearData}
          sx={{
            width: '100%',
            maxWidth: '271px',
            height: '44px',
            marginRight: 2,
            mt: 3,
            mb: 2,
            '&:hover': {
              color: 'var(--Primary, #2CB512)',
              backgroundColor: '#fff',
            },
          }}>
          Cancel
        </Button>
        <Button
          className="common-button-green"
          type="submit"
          variant="contained"
          sx={{
            width: '100%',
            maxWidth: '271px',
            height: '44px',
            marginRight: 2,
            mt: 3,
            mb: 2,
            '&:hover': {
              background: 'var(--Primary, #2CB512)',
            },
          }}
          onClick={getCropData}>
          Save Photo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Crop;

import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import TimeAgo from './TimeHistory';
// import { unreadMessages } from './UnreadMessage';
// import { useFetchLatestMessage } from './FilterMessage';
import '../../../Assets/css/Conversation.css';

interface Chat {
  id: number;
  receiverId: number;
  name: string;
  profileImage: string;
  lastMessage: string;
  lastMessageCreatedAt: string;
}

interface Notification {
  senderId: number;
  receiverId: number;
  isRead: boolean;
  date: string;
}

interface ResponsiveChatProps {
  chat: Chat;
  notifications: Notification[];
  markThisUserNotificationsAsRead: any;
  selectedItem: number | null;
  onItemClick: (index: number) => void;
  index: number;
}

const RecentChat: React.FC<ResponsiveChatProps> = ({
  chat,
  notifications,
  selectedItem,
  onItemClick,
  index,
  // markThisUserNotificationsAsRead,
}) => {
  console.log('notifications', notifications);
  // console.log("Time", chat.);

  const [unreadNotifications, setUnreadNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    if (chat && notifications.length > 0) {
      const filteredNotifications = notifications.filter((notification) => notification.senderId === chat.receiverId);
      setUnreadNotifications(filteredNotifications);
    }
  }, [chat, notifications]);

  const truncateText = (text: string) => {
    return text.length > 20 ? text.substring(0, 20) + '...' : text;
  };

  const handleBadgeClick = () => {
    setUnreadNotifications([]);
  };

  return (
    <Grid
      container
      style={{
        display: 'flex',
        alignItems: 'center',
        borderBottom: selectedItem === index ? '0px' : '2px solid #d8d6e1',
        backgroundColor: selectedItem === index ? 'white' : '#F7F8F8',
        borderRadius: selectedItem === index ? '12px' : '0px',
        fontFamily: '"Onest", sans-serif !important',
      }}
      onClick={() => onItemClick(index)}>
      <Grid item xs={2}>
        <img
          src={chat.profileImage}
          style={{
            width: '50px',
            height: '50px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '50%',
          }}
          alt={chat.name}
        />
      </Grid>
      <Grid item xs={4} style={{ margin: '8px' }}>
        <Typography variant="h6" className="chat-list" sx={{ fontFamily: '"Onest", sans-serif !important' }}>
          {chat.name || ''}
        </Typography>
        <Typography className="chatName" sx={{ fontFamily: '"Onest", sans-serif !important' }}>
          {chat ? truncateText(chat.lastMessage) : ''}
        </Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="center" alignItems="center">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {unreadNotifications.length > 0 && (
            <Badge
              badgeContent={unreadNotifications.length}
              classes={{ badge: 'customBadge' }}
              style={{ marginBottom: '8px', fontFamily: '"Onest", sans-serif !important' }}
              onClick={handleBadgeClick}
            />
          )}
          {chat.lastMessage && (
            <Typography sx={{ fontSize: '13px', fontFamily: '"Onest", sans-serif !important' }}>
              {unreadNotifications.length > 0 ? (
                <TimeAgo timestamp={unreadNotifications[0].date} />
              ) : (
                <TimeAgo timestamp={chat.lastMessageCreatedAt} />
              )}
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default RecentChat;

// import Env from '../services/Env';

export function backHandle(state: any) {
  return {
    type: 'BACK_CALL',
    payload: state,
  };
}

export function isLoggedIn(state: any) {
  return {
    type: 'IS_LOGGED_IN',
    payload: state,
  };
}

export function userId(state: any) {
  return {
    type: 'ID',
    payload: state,
  };
}

export function userType(state: any) {
  return {
    type: 'USER_TYPE',
    payload: state,
  };
}

export function userPM(state: any) {
  return {
    type: 'USER_PM',
    payload: state,
  };
}

export function userAccessToken(state: any) {
  return {
    type: 'USER_ACCESS_TOKEN',
    payload: state.accessToken,
  };
}

export function userRefreshToken(state: any) {
  return {
    type: 'USER_REFRESH_TOKEN',
    payload: state.refreshToken,
  };
}

export function userEmail(state: any) {
  return {
    type: 'USER_EMAIL',
    payload: state.email,
  };
}

export function userStepStatus(state: any) {
  return {
    type: 'USER_STATUS',
    payload: state.status,
  };
}

export function userDC(state: any) {
  return {
    type: 'USER_DISCOUNT',
    payload: state,
  };
}

export function userTM(state: any) {
  return {
    type: 'USER_TM',
    payload: state,
  };
}

export function customerID(state: any) {
  return {
    type: 'CUSTOMER_ID',
    payload: state,
  };
}

export function profileHeaderPics(state: any) {
  return {
    type: 'USER_PICS',
    payload: state,
  };
}

export function userDisElgble(state: any) {
  return {
    type: 'USER_DisElg',
    payload: state,
  };
}

// export function logOut() {
//   return {
//     type: 'LOG_OUT',
//     // payload: state.status,
//   };
// }

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, TextField, Select, MenuItem, Chip, Button, Backdrop, CircularProgress } from '@mui/material';
import '../../../Assets/css/Common.css';
// import Env from '../../../services/Env';
import { connect } from 'react-redux';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../../common/Snackbar';
// import { IUserDetails } from '../../Interface/UserInterface/IUser';
import { useNavigate } from 'react-router-dom';

interface RateRangeItem {
  key: string;
  label: string;
}

// interface PersonalWorkExpProps {
//   data: IUserDetails | null;
// }

const PersonalExpression: React.FC = () => {
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();

  const [totExperience, setTotExperience] = useState<any[]>([]);
  const [selectedTotExperience, setSelectedTotExperience] = useState<string>();
  const [rateRange, setRateRange] = useState<RateRangeItem[]>([]);
  const [rate, setRate] = useState(rateRange?.length > 0 ? rateRange[0].label : '');
  const [companyInput, setCompanyInput] = useState('');
  const [rateLabel, setRateLabel] = useState('');
  const [companies, setCompanies] = useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [workExperience, setWorkExperience] = useState({
    experienceKey: '',
    rangeKey: '',
    bio: '',
    companyName: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    rateAndExperience();
    // if (data !== null) {
    //   workExpData(data);
    getUserDetails();
    setLoading(false);
    // }
  }, []);

  // const workExpData = (data: any) => {
  //   const yearInfo = `${data.experience} Year`;
  //   setWorkExperience({
  //     experienceKey: data.experience,
  //     rangeKey: data.range.key,
  //     bio: data.about,
  //     companyName: data.company,
  //   });
  //   setRateLabel(data.range.label);
  //   setSelectedTotExperience(yearInfo);
  //   setCompanies(data.company);
  //   setRate(data.range.label);
  // };

  const rateAndExperience = () => {
    const workdetails = privateAxios.get(`user/rateRange`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    workdetails
      .then((response: any) => {
        if (response.status === 200) {
          const experienceArray = Array.isArray(response.data.data.experience)
            ? response.data.data.experience
            : [response.data.data.experience];
          setRateRange(response.data.data.rateRange);
          setTotExperience(experienceArray);
        }
      })
      .catch((error: any) => {
        console.error('RESPONSE', error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleRateChangeKey = (selectedRate: any) => {
    setRate(selectedRate.label);
    setWorkExperience((prevData: any) => ({
      ...prevData,
      rangeKey: selectedRate.key,
    }));
  };

  const handleTotalExperienceChange = (event: any) => {
    setSelectedTotExperience(event.target.value);
    setWorkExperience((prevData: any) => ({
      ...prevData,
      experienceKey: event.target.value,
    }));
  };

  const handleRateChange = (event: any) => {
    setRate(event.target.value);
  };

  const handleInputChange = (event: any) => {
    setCompanyInput(event.target.value);
  };

  const handleChipDelete = (companyToDelete: any) => {
    setCompanies((prevCompanies) => prevCompanies.filter((company) => company !== companyToDelete));
    setWorkExperience((prevData: any) => ({
      ...prevData,
      companyName: prevData.companyName.filter((company: any) => company !== companyToDelete),
    }));
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelClick = () => {
    navigate('/account-dashboard');
  };

  const handleSubmitClick = () => {
    const updateWorkExperience = privateAxios.put(`user/rateexperienceinfo`, workExperience, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    updateWorkExperience
      .then((response: any) => {
        if (response.status === 200) {
          getUserDetails();
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
        }
      })
      .catch((error: any) => {
        setToast({ message: error.response.data.message, type: 'error' });
        handleClick();
      });
  };

  const handleBioChange = (e: any) => {
    setWorkExperience((prevData: any) => ({
      ...prevData,
      bio: e.target.value,
    }));
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const trimmedCompany = companyInput.trim();
      if (trimmedCompany !== '') {
        setCompanies((prevCompanies: string[]) => [...prevCompanies, trimmedCompany]);
        setCompanyInput('');
        setWorkExperience((prevData: any) => ({
          ...prevData,
          companyName: [...prevData.companyName, trimmedCompany],
        }));
      }
    }
  };

  const getUserDetails = () => {
    setLoading(true);
    const getDetails = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          setWorkExperience({
            experienceKey: data.experience.key,
            rangeKey: data.range.key,
            bio: data.about,
            companyName: data.company,
          });
          setRateLabel(data.range.label);
          setSelectedTotExperience(data.experience.key);
          setCompanies(data.company);
          setRate(data.range.label);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  return (
    <>
      <div style={{ margin: '0px', position: 'relative' }}>
        <Grid
          container
          className="button-position"
          sx={{
            position: 'absolute',
            top: -140,
            right: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}>
          <Button
            className="common-button-white"
            type="submit"
            variant="contained"
            onClick={handleCancelClick}
            sx={{
              width: '167px',
              height: '44px',
              marginRight: 1,
              '&:hover': {
                color: 'var(--Primary, #2CB512)',
                backgroundColor: '#fff',
              },
            }}>
            Cancel
          </Button>
          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleSubmitClick}
            sx={{
              width: '121px',
              height: '44px',
              marginLeft: 1,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save
          </Button>
        </Grid>
      </div>
      <Box sx={{ p: '20px', backgroundColor: '#FFFFFF', borderRadius: '10px' }}>
        {loading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            onClick={() => setLoading(false)}>
            <CircularProgress
              color="success"
              // style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            />
          </Backdrop>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography className="lable-name" sx={{ textAlign: 'left', mt: '15px' }}>
              Total Experience
            </Typography>
            <Select
              sx={{ width: '393px', fontFamily: "'Onest', sans-serif" }}
              value={selectedTotExperience || ''}
              onChange={handleTotalExperienceChange}>
              {totExperience &&
                totExperience.map((item: any, index: number) => (
                  <MenuItem sx={{ fontFamily: "'Onest', sans-serif" }} key={index} value={item.key}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className="lable-name" sx={{ textAlign: 'left', mt: '15px' }}>
              Rate Range
            </Typography>
            <Select
              sx={{ width: '393px', fontFamily: "'Onest', sans-serif" }}
              value={rate ? rate || '' : rateLabel || ''}
              onChange={handleRateChange}>
              {rateRange?.map((rate: any) => (
                <MenuItem
                  sx={{ fontFamily: "'Onest', sans-serif" }}
                  key={rate.key}
                  value={rate.label}
                  onClick={() => handleRateChangeKey(rate)}>
                  {rate.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography className="lable-name" sx={{ textAlign: 'left', mt: '30px' }}>
              Companies (Client) name that you&apos;ve Worked
              <span style={{ color: '#98A2B3' }}> [Multi-Possible]</span>
            </Typography>
            <TextField
              className="formFields"
              sx={{ mt: 0, width: '100%' }}
              type="text"
              name="companies"
              placeholder="XYZ Company"
              value={companyInput}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              required
            />
            <div style={{ marginTop: '10px' }}>
              {companies.map((company, index) => (
                <Chip
                  key={index}
                  label={company}
                  onDelete={() => handleChipDelete(company)}
                  style={{ marginRight: '8px', marginBottom: '8px' }}
                />
              ))}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography className="lable-name" sx={{ textAlign: 'left', mt: '30px' }}>
              Bio
            </Typography>
            <TextField
              className="formFields"
              multiline
              rows={4}
              sx={{ mt: 0, width: '100%', maxHeight: '200px' }}
              type="text"
              name="bio"
              placeholder="Type Here..."
              value={workExperience.bio || ''}
              onChange={(e: any) => handleBioChange(e)}
              required
            />
          </Grid>
        </Grid>
        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Box>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(PersonalExpression);

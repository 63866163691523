/* eslint-disable no-debugger */
import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginRouter from './LoginRouter';
import MasterRouter from './MasterRouter';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BookingStatus from '../Booking/BookingStatus';
import ChallengeStatus from '../Booking/ChallengeStatus';
import Renew from '../auth/Renew';
import PlanPage from '../auth/PlanPage';
import PremiumUser from '../auth/EmailConfirmation/PremiumUser';
import BasicStructure from '../auth/BasicStructure';
import ProvideInvite from '../auth/ProvideInvite';
import HoldStatus from '../Booking/HoldStatus';
// import PageNotFound from '../auth/PageNotFound';
// import { Navigate } from 'react-router-dom';

const Routers = (props: any) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get('search');

  React.useEffect(() => {
    if (searchValue) {
      localStorage.setItem('searchValue', searchValue);
    }
  });

  return (
    <Routes>
      <Route>
        <Route path="/*" element={props.isLoggedIn ? <MasterRouter /> : <LoginRouter />} />
        <Route path="/dashboard/acceptbooking" element={<BookingStatus />} />
        <Route path="/dashboard/rejectbooking" element={<BookingStatus />} />
        <Route path="/dashboard/acceptchallenge" element={<ChallengeStatus />} />
        <Route path="/dashboard/rejectchallenge" element={<ChallengeStatus />} />
        <Route path="/dashboard/acceptholding" element={<HoldStatus />} />
        <Route path="/dashboard/rejectholding" element={<HoldStatus />} />
        <Route path="/renew" element={<Renew />} />
        <Route path="/plan" element={<PlanPage />} />
        {/* <Route path="/plan/premium/email-confirmation" element={<BasicStructure />} /> */}

        <Route path="/plan/basic/invite" element={<BasicStructure />}>
          <Route index element={<ProvideInvite />} />
        </Route>
        <Route path="/plan/premium/email-confirmation" element={<BasicStructure />}>
          <Route index element={<PremiumUser />} />
        </Route>
        {/* <Route path="/not-found" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} /> */}
      </Route>
    </Routes>
  );
};

function mapDispatchToProps(state: any) {
  return {
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapDispatchToProps)(Routers);

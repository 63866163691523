const initialState = {
  isLoggedIn: false,
  isBack: false,
  userDisE: false,
};

const appReducer = (state = initialState, action: any = {}) => {
  switch (action.type) {
    case 'IS_LOGGED_IN':
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case 'ID':
      return {
        ...state,
        id: action.payload,
      };
    case 'USER_TYPE':
      return {
        ...state,
        userType: action.payload,
      };
    case 'USER_PM':
      return {
        ...state,
        userPM: action.payload,
      };
    case 'USER_ACCESS_TOKEN':
      return {
        ...state,
        userAccessToken: action.payload,
      };
    case 'USER_REFRESH_TOKEN':
      return {
        ...state,
        userRefreshToken: action.payload,
      };

    case 'BACK_CALL':
      return {
        ...state,
        isBack: action.payload,
      };

    case 'USER_EMAIL':
      return {
        ...state,
        email: action.payload,
      };

    case 'USER_STATUS':
      return {
        ...state,
        status: action.payload,
      };

    case 'USER_DISCOUNT':
      return {
        ...state,
        userDC: action.payload,
      };

    case 'CUSTOMER_ID':
      return {
        ...state,
        CID: action.payload,
      };

    case 'USER_TM':
      return {
        ...state,
        userTM: action.payload,
      };

    case 'USER_PICS':
      return {
        ...state,
        userPics: action.payload,
      };

    case 'USER_DisElg':
      return {
        ...state,
        userDisE: action.payload,
      };

    // case 'LOG_OUT':
    //   return state;
    // ...state,
    // isLoggedIn: false,
    // isBack: false,
    // id: null,
    // status: null,
    // userType: false,
    // userAccessToken: null,
    // userRefreshToken: null,
    // initialState,

    default:
      return state;
  }
};

export default appReducer;

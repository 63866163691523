import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../../common/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props: React.ComponentProps<typeof Slide>, ref: React.Ref<unknown>) {
  return <Slide direction="down" ref={ref} timeout={{ enter: 1, exit: 5 }} {...props} />;
});

const EditListDialog = ({ isNewListDialogOpen, handleCloseNewListDialog, editValue }: any) => {
  const privateAxios = useAxiosPrivate();

  const [editListValue, setEditListValue] = useState('');
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: '', type: 'success' });

  const handleEditListData = (e: any) => {
    setEditListValue(e.target.value);
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setEditListValue(editValue.label);
  }, [editValue]);

  const editList = () => {
    const data = {
      data: editListValue,
      listKey: editValue?.key,
    };

    const editListData = privateAxios.put(`dashboard/editlist`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    editListData
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          setEditListValue('');
          handleClick();
          handleCloseNewListDialog();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  return (
    <>
      <Dialog
        open={isNewListDialogOpen}
        onClose={handleCloseNewListDialog}
        fullWidth
        TransitionComponent={Transition}
        className="rosterList"
        PaperProps={{
          sx: {
            position: 'absolute',
            top: '50px',
            transitionDuration: '500ms',
          },
        }}
        transitionDuration={500}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: "'Onest', sans-serif",
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '28px',
              color: 'var(--green)',
              flex: '1',
            }}>
            Edit list
          </Typography>
          <IconButton onClick={handleCloseNewListDialog} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>Edit list</Typography>
          <TextField
            fullWidth
            value={editListValue}
            sx={{ fontFamily: "'Onest', sans-serif !important" }}
            onChange={(e) => handleEditListData(e)}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }} className="addButtons">
          <Button className="common-button-white" onClick={handleCloseNewListDialog} sx={{ width: '275px' }}>
            Cancel
          </Button>
          <Button
            className="common-button-green"
            onClick={() => {
              editList();
            }}
            disabled={editListValue === ''}
            sx={{
              width: '275px',
              color: 'var(--white)',
              '&.Mui-disabled': {
                color: '#FFF',
              },
              cursor: 'pointer',
            }}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

export default EditListDialog;

/* eslint-disable no-debugger */
import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import '../../index.css';
import '../../Assets/css/Common.css';
import { useNavigate } from 'react-router-dom';
// import Env from '../../services/Env';
import CustomSnackbar from '../../common/Snackbar';
import { useDispatch } from 'react-redux';
import {
  userId,
  isLoggedIn,
  userType,
  userAccessToken,
  userRefreshToken,
  userPM,
  userStepStatus,
} from '../../reducer/action';
// import { refreshTokenGenrate } from '../../reducer/action';
import { AppDispatch } from '../../reducer/store';
import { publicAxios } from '../../services/Interceptor';

const ProvideInvite = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [inviteCode, setInviteCode] = useState({ code: '' });
  const [toast, setToast] = useState({ message: '', type: 'success' });
  const [open, setOpen] = useState(false);
  const [Error, setError] = useState('');

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = () => {
    if (inviteCode.code === '') {
      setError('Please Enter the Code.');
      return;
    }
    // const getConfirmation = Env.post(`invite/verifyCode`, inviteCode, '');

    const getConfirmation = publicAxios.post(`invite/verifyCode`, inviteCode, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    getConfirmation
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();

          // setToast({ message: response.data.message.value, type: 'success' });
          setTimeout(() => {
            const { id, accessToken, refreshToken, isBasic, isPremium, isPayment } = response.data.data;

            dispatch(isLoggedIn(true));
            dispatch(userId(id));
            if (isPremium) {
              dispatch(userType(true));
            }
            if (isBasic) {
              dispatch(userType(false));
            }
            dispatch(userStepStatus({ status: 'Registration' }));
            dispatch(userPM(isPayment));
            dispatch(userAccessToken({ accessToken: accessToken }));
            dispatch(userRefreshToken({ refreshToken: refreshToken }));
            navigate('/basic/welcome');
          }, 2000);
        }
      })
      .catch((error: any) => {
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleInviteField = (e: any) => {
    const { name, value } = e.target;
    const codeValue = value.replace(/\D/g, '');

    setInviteCode((prevLogin) => ({
      ...prevLogin,
      [name]: codeValue,
    }));
    setError('');
  };

  return (
    <Box>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Typography
          sx={{
            textAlign: 'left',
            fontFamily: "'Onest', sans-serif",
            fontWeight: '900',
            fontSize: '32px',
          }}>
          Provide Invitation Code
        </Typography>
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: 'var(--gray-500, #667085)',
          }}>
          If you&apos;ve been invited by a premium member or by an Avalbl recruiter,  please enter that code below.
        </Typography>
        <Box
          // component="form"
          // onSubmit={(e) => {
          //   e.preventDefault();
          //   handleLogin();
          // }}
          sx={{ mt: 3 }}>
          <Typography
            className="lable-name"
            sx={{
              textAlign: 'left',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '6px',
              alignSelf: 'stretch',
            }}>
            Invitation Code
          </Typography>
          <TextField
            className="formFields"
            sx={{ width: '500px !important' }}
            name="code"
            placeholder="Enter Invitation Code"
            value={inviteCode.code}
            onChange={(e: any) => handleInviteField(e)}
            required
          />
          {Error && <Typography sx={{ color: 'red', mt: 1 }}>{Error}</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              className="common-button-green"
              type="submit"
              variant="contained"
              // onClick={() => navigate('/basic/welcome')}
              onClick={handleLogin}
              sx={{ mt: 3, mb: 2, textTransform: 'none' }}>
              Sign Up
            </Button>
          </Box>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'var(--gray-500, #667085)',
            }}>
            Already have an account? 
            <a style={{ textDecoration: 'none' }} href="/login">
              <span
                style={{
                  color: '#000',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                }}>
                Sign In
              </span>
            </a>
          </Typography>
        </Box>
      </Box>
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </Box>
  );
};

export default ProvideInvite;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import './style.css';
// import './responsive.css';
import { Backdrop, CircularProgress, Container, Grid, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
// import BannerImage from '../../Assets/Images/headerProfile.png';
// import profileImage from '../../Assets/Images/banner-04.png';
import sliderImage1 from '../../Assets/Images/sliderImage1.png';
import sliderImage2 from '../../Assets/Images/sliderImage2.png';
import sliderImage3 from '../../Assets/Images/sliderImage3.png';
// import maiIcon from '../../Assets/Images/mailIcon.png';
import mapPin from '../../Assets/Images/mapPin.png';
import Map from '../auth/Stepper/Map';
import Carousel from './Carousel';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import '../../Assets/css/Dashboard.css';
import Header from './Header';
import '../../Assets/css/Header.css';
import { IUserDetails } from '../Interface/UserInterface/IUser';
import CustomSnackbar from '../../common/Snackbar';
import '../../Assets/css/AccountDashboard.css';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reducer/store';
import { profileHeaderPics } from '../../reducer/action';

const defaultImages = [sliderImage1, sliderImage2, sliderImage3];

interface ExperienceData {
  label: string;
  key: string;
}

const UserProfile = () => {
  const privateAxios = useAxiosPrivate();
  const dispatch = useDispatch<AppDispatch>();
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const [data, setData] = useState<IUserDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  useEffect(() => {
    // const check = window.location.href.includes('profile');
    // const id = parseInt(window.location.href.split('/')[4], 10);

    // if (check) {
    getUserProfileInfo();
    // }
  }, []);

  useEffect(() => {
    const elementsWithPClass = document.querySelectorAll('#dashboard-top');
    elementsWithPClass.forEach((element) => {
      const rootDiv = element.closest('.css-1y36zv6');
      if (rootDiv) {
        rootDiv.classList.add('centerDashboardclass');
      }
    });
  }, []);

  const getUserProfileInfo = () => {
    setLoading(true);
    const getCategoryValues = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getCategoryValues
      .then((response: any) => {

        if (response.status === 200) {
          setData(response.data.data);
          dispatch(
            profileHeaderPics({
              profilePath: response.data.data?.profilePath,
              headerPath: response.data.data?.headerPath,
              availablity: response.data.data?.availability,
              firstName: response.data.data?.firstName,
              lastName: response.data.data?.lastName,
              jobTitle: response.data.data?.jobTitle,
              userName: response.data.data?.userName,
            })
          );
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getAbsoluteURL = (path: string) => {
    const externalLink = path; // Replace this with your dynamic link

    const hrefValue = externalLink.startsWith('https') ? externalLink : `http://${externalLink}`;

    return hrefValue;
  };

  // const getColorLabel = (status: string) => {
  //   switch (status) {
  //     case 'Available':
  //       return '#D5EBD1';
  //     case 'Not Available':
  //       return '#f5bac4';
  //     case 'Available Soon':
  //       return '#e7ebb5';
  //     case 'Available Part Time':
  //       return '#f5dbba';
  //     case 'Part Time':
  //       return '#f5dbba';
  //     case 'Off Market':
  //       return '#b2b9eb';
  //     default:
  //       return '';
  //   }
  // };

  // const getColor = (status: string) => {
  //   switch (status) {
  //     case 'Available':
  //       return 'var(--green)';
  //     case 'Not Available':
  //       return 'red';
  //     case 'Available Soon':
  //       return 'yellow';
  //     case 'Available Part Time':
  //       return 'orange';
  //     case 'Part Time':
  //       return 'orange';
  //     case 'Off Market':
  //       return 'blue';
  //     default:
  //       return '';
  //   }
  // };

  const labelToValues: any = {
    '1-3 yrs': [100, 0, 0, 0],
    '3-5 yrs': [100, 100, 0, 0],
    '5-10 yrs': [100, 100, 100, 0],
    '10+ yrs': [100, 100, 100, 100],
  };

  const { label = '' } = (data?.experience || {}) as ExperienceData;

  const values = labelToValues[label.toLowerCase()] || [0, 0, 0, 0];

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress
            color="success"
            // style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          />
        </Backdrop>
      )}

      <Grid sx={{ backgroundColor: '#eaecf0' }}>
        <Header />
        {data && data.portfolio && (
          <Grid>
            <Carousel images={data?.portfolio?.length === 0 ? defaultImages : data.portfolio} />
          </Grid>
        )}
        {!loading && (
          <section className="acc-info accDash">
            <Container sx={{ fontFamily: '"Onest", sans-serif !important' }} id="dashboard-top">
              <Grid container spacing={3}>
                <Grid item md={8}>
                  <div className="acc-box">
                    <div>
                      <h4 className="title-name"> About {data && `${data.firstName} ${data.lastName}`}</h4>
                    </div>
                    <Typography component={'span'} variant="body1" className="acc-text">
                      {data && data.about}
                    </Typography>
                    <div className="rate-box acc-skill">
                      <div className="aval-title">
                        <h4
                          className="title-name"
                          // style={{
                          //   backgroundColor: getColorLabel((data && data.availability) || ''),
                          //   color: getColor((data && data.availability) || ''),
                          // }}
                        >
                          Availability
                        </h4>
                        <p className="acc-text">{data && data.availability}</p>
                      </div>
                      <div className="aval-title rate-rabge-box">
                        <h4 className="title-name">Rate Range</h4>
                        <p className="acc-text"> {data && data.range.label}</p>
                      </div>
                    </div>
                    <div className="rate-box acc-skill">
                      <div className="aval-title">
                        <h4 className="title-name">Job Title</h4>
                        <p className="acc-text">{data && data.jobTitle} </p>
                      </div>
                      <Grid className="aval-title rate-rabge-box">
                        <h4 className="title-name">Total-Experience</h4>
                        {data && data.experience && (
                          <>
                            <Typography
                              component={'span'}
                              style={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#0000005c',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              {values.map((value: any, index: any) => (
                                <BorderLinearProgress
                                  key={index}
                                  variant="determinate"
                                  value={Math.min(100, value)}
                                  style={{ width: '100px', height: '12px' }}
                                />
                              ))}
                            </Typography>
                            <Typography
                              component={'span'}
                              style={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#0000005c',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              {['1-3', '3-5', '5-10', '10+'].map((rangeLabel) => (
                                <Grid key={rangeLabel} style={{ width: '100px', textAlign: 'center' }}>
                                  {rangeLabel}
                                </Grid>
                              ))}
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </div>
                    <div className="client-list list-box acc-skill">
                      <h4 className="title-name">Client List</h4>
                      <ul style={{ paddingLeft: 0 }}>
                        {(data && data.company.length === 0) || data?.company === null ? (
                          <li>Not Available</li>
                        ) : (
                          data?.company.map((company, index) => <li key={index}>{company}</li>)
                        )}
                      </ul>
                    </div>
                    <div className="skill-sec list-box acc-skill">
                      <h4 className="title-name">Skills</h4>
                      <ul style={{ paddingLeft: 0 }}>
                        {(data && data.skills.length === 0) || data?.skills === null ? (
                          <li style={{ color: '#73787e' }}>Not Available</li>
                        ) : (
                          data?.skills.map((category, index) => (
                            <li key={index}>
                              <div className="check-box">
                                <div className="check-head">
                                  <h4>{category.category.label}</h4>
                                </div>
                                <ul>
                                  {category.skills.map((skill, skillIndex) => (
                                    <li key={skillIndex}>{skill.label}</li>
                                  ))}
                                </ul>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                    <div className="Tools-sec list-box ">
                      <h4 className="title-name">Tools</h4>
                      <ul style={{ paddingLeft: 0 }}>
                        {(data && data.tools.length === 0) || data?.tools === null ? (
                          <li style={{ color: '#73787e' }}>Not Available</li>
                        ) : (
                          data?.tools.map((category, index) => (
                            <li key={index}>
                              <div className="check-box">
                                <div className="check-head">
                                  <h4>{category.category.label}</h4>
                                </div>
                                <ul>
                                  {category.tools.map((tool, toolIndex) => (
                                    <li key={toolIndex}>{tool.label}</li>
                                  ))}
                                </ul>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="acc-box right-box">
                    <div className="lang-list acc-skill">
                      <h4 className="title-name">Language</h4>
                      <ul style={{ paddingLeft: 0 }}>
                        {(data && data.language.length === 0) || data?.language === null ? (
                          <li style={{ color: '#73787e' }}>Not Available</li>
                        ) : (
                          data?.language.map((language, index) => <li key={index}>{language}</li>)
                        )}
                      </ul>
                    </div>
                    <div className="loca-cont acc-skill">
                      <h4 className="title-name">Location</h4>
                      <Grid sx={{ pt: '20px', height: '240px', width: '430px' }}>
                        {data && data.location && (
                          <Map
                            center={{
                              lat: data?.location.lat,
                              lng: data?.location.long,
                            }}
                            zoom={10}
                          />
                        )}
                      </Grid>
                      <div className="map-cont">
                        <div className="date">
                          <img src={mapPin} className="img-fluid" alt="img" />
                          <p>{data && data.location.label}</p>
                        </div>
                      </div>
                    </div>
                    <div className="port-cont acc-skill">
                      <h4 className="title-name">Portfolio Link</h4>
                      {data && data.portfolioLink ? (
                        <p className="acc-text">
                          <a href={getAbsoluteURL(data.portfolioLink)} target="_blank" rel="noopener noreferrer">
                            {data.portfolioLink}
                          </a>
                        </p>
                      ) : (
                        <span style={{ color: '#73787e' }}>Not Available</span>
                      )}
                    </div>
                    <div className="media-cont acc-skill">
                      <h4 className="title-name">Social Media</h4>
                      <ul style={{ paddingLeft: 0 }}>
                        {(data && data.socialMedia.length === 0) || data?.socialMedia === null ? (
                          <li style={{ color: '#73787e' }}>Not Available</li>
                        ) : (
                          data?.socialMedia.map((social, index) => (
                            <li key={index}>
                              <a href={getAbsoluteURL(social.link)} target="_blank" rel="noopener noreferrer">
                                <img src={social.logoPath} alt={social.label} className="img-fluid" />
                              </a>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </section>
        )}
      </Grid>
      <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleClose} toast={toast} />
    </>
  );
};

export default UserProfile;

// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from 'react';
// import { Route, Routes, Navigate } from 'react-router-dom';
// import UserRouter from './UserRouter';
// import UserFeed from '../HomeScreen/Feed/UserFeed';
// import UserProfile from '../HomeScreen/UserProfileDetail';
// import RegistrationStepper from '../auth/Stepper/Stepper';
// // import CreateTeam from '../auth/CreateTeam';
// // import AddTeam from '../auth/AddTeam';
// import { connect } from 'react-redux';
// import { isLoggedIn, userAccessToken, userRefreshToken, backHandle } from '../../reducer/action';
// import { AppDispatch } from '../../reducer/store';
// import { useDispatch } from 'react-redux';
// // import BasicStructure from '../auth/BasicStructure';
// import BasicWelcome from '../auth/BasicWelcome';
// import ProtectedRouteLayout from '../../Layout/ProtectedRouteLayout';
// import ChangePassword from '../AccountProfile/ChangePassword';
// import Dashboard from '../AccountProfile/Dashboard';
// import Editprofile from '../AccountProfile/EditProfile';
// import Booking from '../Booking/Booking';
// import { useNavigate } from 'react-router-dom';
// // import Conversations from '../HomeScreen/Conversations';
// import MyRoster from '../HomeScreen/Roster/MyRoster';
// import Chat from '../HomeScreen/Conversations/Chat';
// // import AvailabilityCalender from '../AccountProfile/AccountDetails/AvailabilityCalender';
// import useAxiosPrivate from '../../Hooks/useAxioPrivate';
// import ChoosePayment from '../auth/Payment/ChoosePayment';
// import SuccessDialog from '../auth/Payment/SuccessDialog';
// import CancelDialog from '../auth/Payment/CancelDialog';
// import CreateCard from '../auth/Payment/Stripe/CreateCard';
// // import LoginForm from '../auth/Login';
// import Renew from '../auth/Renew';
// import CardDetails from '../auth/Payment/Stripe/CardDetails';
// import PageNotFound from '../auth/PageNotFound';
// // import PageNotFound from '../auth/PageNotFound';

// interface User {
//   id: number;
//   name: string;
//   profilePic: string;
// }

// const MasterRouter = (props: any) => {
//   const dispatch = useDispatch<AppDispatch>();
//   const privateAxios = useAxiosPrivate();
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!props.userAccessToken) {
//       dispatch(backHandle(false));
//       dispatch(userAccessToken({ userAccessToken: '' }));
//       dispatch(userRefreshToken({ userRefreshToken: '' }));
//       dispatch(isLoggedIn(false));
//     }
//   }, [dispatch, navigate, props]);

//   useEffect(() => {
//     if (props.id && props.userStatus === 'Active') {
//       userInformation(props?.id);
//     }
//   }, [props]);

//   const [userInfo, setUserInfo] = useState<any>(null);

//   const userInformation = (id: number) => {
//     const getDetails = privateAxios.get(`user/getuserdetails?id=${id}`, {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//     getDetails
//       .then((response: any) => {
//         if (response.status === 200) {
//           const data = response.data.data;
//           setUserInfo(data);
//         }
//       })
//       .catch((error: any) => {
//         console.error(error);
//       });
//   };

//   const user: User = {
//     id: props.id,
//     name: userInfo?.firstName,
//     profilePic: userInfo?.profilePath,
//   };

//   return (
//     <Routes>
//       <Route path="/renew" element={<Renew />} />
//       {props.isLoggedIn && (
//         <>
//           <Route path="/login" element={props.userStatus === 'Active' && <Navigate to="/userFeed" />} />
//         </>
//       )}
//       {props.userStatus === 'Registration' && !props.userType && (
//         <>
//           <Route path="/*" element={<Navigate to="/basic/sign-up" />} />
//           <Route path="/basic/sign-up" element={<Navigate to="/" />} />
//         </>
//       )}

//       {props.userStatus === undefined ||
//         (props.userStatus === '' && (
//           <>
//             <Route
//               path="/userFeed"
//               element={
//                 props.userType ? (
//                   props.userPM ? (
//                     <Navigate to="/premium/sign-up" />
//                   ) : (
//                     <Navigate to="/premium/choose-payment" />
//                   )
//                 ) : (
//                   <Navigate to="/basic/welcome" />
//                 )
//               }
//             />
//             <Route path="/premium/choose-payment" element={<ChoosePayment />} />
//             <Route path="/basic/welcome" element={<ProtectedRouteLayout />}>
//               <Route index element={<BasicWelcome />} />
//             </Route>
//           </>
//         ))}

//       {props.userStatus === 'Active' && !props.userType ? (
//         <Route path="/premium/choose-payment" element={<ChoosePayment />} />
//       ) : (
//         <Route path="/basic/sign-up" element={<Navigate to="/" />} />
//       )}
//       {props.userStatus === undefined ||
//         (props.userStatus === '' && (
//           <>
//             <Route
//               path="/*"
//               element={
//                 props.userType ? (
//                   props.userPM || props.userDC ? (
//                     <Navigate to="/premium/sign-up" />
//                   ) : (
//                     <Navigate to="/premium/choose-payment" />
//                   )
//                 ) : (
//                   <Navigate to="/basic/welcome" />
//                 )
//               }
//             />
//             <Route path="/premium/choose-payment" element={<ChoosePayment />} />
//             <Route path="/basic/welcome" element={<ProtectedRouteLayout />}>
//               <Route index element={<BasicWelcome />} />
//             </Route>
//           </>
//         ))}
//       {/* {props.userStatus === 'Registration' && props.userType && props.userPM ? (
//         <>
//           <Route path="/premium/creat-team" element={<ProtectedRouteLayout />}>
//             <Route path="/premium/creat-team" element={<BasicStructure />}>
//               <Route index element={<CreateTeam />} />
//             </Route>
//           </Route>
//           <Route path="/premium/add-team/:id" element={<ProtectedRouteLayout />}>
//             <Route path="/premium/add-team/:id" element={<BasicStructure />}>
//               <Route index element={<AddTeam />} />
//             </Route>
//           </Route>
//         </>
//       ) : (
//         props.userStatus === 'Active' && (
//           <>
//             <Route path="/premium/creat-team" element={<Navigate to="/" />} />/
//             <Route path="/premium/add-team/:id" element={<Navigate to="/" />} />
//           </>
//         )
//       )} */}

//       {props.userStatus === 'Registration' && props.userType && (
//         <>
//           {/* <Route
//             path="/*"
//             element={
//               (props.userPM || props.userDC) && props.userTM ? (
//                 <Navigate to="/premium/sign-up" />
//               ) : !props.userTM && props.userPM ? (
//                 <Navigate to="/premium/creat-team" />
//               ) : (
//                 <Navigate to="/premium/choose-payment" />
//               )
//             }
//           /> */}
//           <Route
//             path="/*"
//             element={
//               props.userPM || props.userDC ? <Navigate to="/premium/sign-up" /> : <Navigate to="/premium/choose-payment" />
//             }
//           />
//           <Route path="/premium/choose-payment" element={<ChoosePayment />} />
//         </>
//       )}

//       {props.userStatus === 'Active' && props.userType && (
//         <>
//           <Route />
//           <Route path="/" element={<ProtectedRouteLayout />}>
//             <Route index element={<UserFeed />} />
//           </Route>
//         </>
//       )}

//       {props.userStatus === 'Active' && !props.userType && (
//         <>
//           {/* <Route path="/" element={<Navigate to="/conversations" />} /> */}
//           <Route path="/" element={<ProtectedRouteLayout />}>
//             {/* <Route index element={<Chat user={user} />} /> */}
//             <Route index element={<Dashboard />} />
//           </Route>
//         </>
//       )}

//       {/* <Route path="/basic/welcome" element={<ProtectedRouteLayout />}>
//         <Route index element={<BasicWelcome />} />
//       </Route> */}

//       {props.userStatus === 'Registration' && !props.userType ? (
//         <>
//           <Route path="/basic/sign-up" element={<ProtectedRouteLayout />}>
//             <Route index element={<RegistrationStepper />} />
//           </Route>
//           <Route path="/basic/welcome" element={<ProtectedRouteLayout />}>
//             <Route index element={<BasicWelcome />} />
//           </Route>
//         </>
//       ) : (
//         <Route path="/basic/sign-up" element={<Navigate to="/" />} />
//       )}
//       {props.userPM &&
//         (props.userStatus === 'Registration' || props.userStatus === undefined || props.userStatus === '') && (
//           <Route path="/premium/sign-up" element={<ProtectedRouteLayout />}>
//             <Route index element={<RegistrationStepper />} />
//           </Route>
//         )}

//       <Route path="/user/*" element={<UserRouter />} />
//       <Route path="/account-dashboard" element={<Dashboard />} />
//       <Route path="/change-password" element={<ChangePassword />} />
//       <Route path="/account-dashboard/edit-profile" element={<ProtectedRouteLayout />}>
//         <Route index element={<Editprofile />} />
//       </Route>
//       <Route path="/userProfile" element={<Dashboard />} />

//       <Route path="/bookings" element={<Booking />} />

//       {props.userStatus === 'Active' && props.userType ? (
//         <>
//           <Route path="/userFeed" element={<UserFeed />} />
//           <Route path="/myroster" element={<MyRoster />} />
//         </>
//       ) : (
//         props.userStatus === 'Active' &&
//         !props.userType && (
//           <>
//             <Route path="/userFeed" element={<Navigate to="/" />} />
//             <Route path="/myroster" element={<Navigate to="/" />} />
//           </>
//         )
//       )}
//       <Route path="/conversations" element={<Chat user={user} />} />
//       {/* <Route path="/availability" element={<AvailabilityCalender />} /> */}
//       <Route path="/user-details" element={<UserProfile />} />
//       <Route path="/paypal/payment/success" element={<SuccessDialog />} />
//       <Route path="/paypal/payment/cancel" element={<CancelDialog />} />
//       {!props.userPM ? (
//         <Route path="/stripe/add" element={<CreateCard />} />
//       ) : (
//         <Route path="/stripe/add" element={<Navigate to="/" />} />
//       )}
//       {!props.userPM ? (
//         <Route path="/cardDetails" element={<CardDetails />} />
//       ) : (
//         <Route path="/cardDetails" element={<Navigate to="/" />} />
//       )}

//       {props.userStatus === 'Active' && <Route path="/premium/sign-up" element={<Navigate to="/" />} />}
//       {props.userStatus === 'Active' && <Route path="/premium/choose-payment" element={<Navigate to="/" />} />}

//       <Route path="*" element={<PageNotFound />} />
//     </Routes>
//   );
// };

// function mapDispatchToProps(state: any) {
//   return {
//     id: state.id,
//     userAccessToken: state.userAccessToken,
//     userStatus: state.status,
//     userType: state.userType,
//     userPM: state.userPM,
//     userDC: state.userDC,
//     userTM: state.userTM,
//     isLoggedIn: state.isLoggedIn,
//   };
// }

// export default connect(mapDispatchToProps)(MasterRouter);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import UserRouter from './UserRouter';
import UserFeed from '../HomeScreen/Feed/UserFeed';
import UserProfile from '../HomeScreen/UserProfileDetail';
import RegistrationStepper from '../auth/Stepper/Stepper';
// import CreateTeam from '../auth/CreateTeam';
// import AddTeam from '../auth/AddTeam';
import { connect } from 'react-redux';
import { isLoggedIn, userAccessToken, userRefreshToken, backHandle } from '../../reducer/action';
import { AppDispatch } from '../../reducer/store';
import { useDispatch } from 'react-redux';
// import BasicStructure from '../auth/BasicStructure';
import BasicWelcome from '../auth/BasicWelcome';
import ProtectedRouteLayout from '../../Layout/ProtectedRouteLayout';
import ChangePassword from '../AccountProfile/ChangePassword';
import Dashboard from '../AccountProfile/Dashboard';
import Editprofile from '../AccountProfile/EditProfile';
import Booking from '../Booking/Booking';
import { useNavigate } from 'react-router-dom';
// import Conversations from '../HomeScreen/Conversations';
import MyRoster from '../HomeScreen/Roster/MyRoster';
import Chat from '../HomeScreen/Conversations/Chat';
// import AvailabilityCalender from '../AccountProfile/AccountDetails/AvailabilityCalender';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import ChoosePayment from '../auth/Payment/ChoosePayment';
import SuccessDialog from '../auth/Payment/SuccessDialog';
import CancelDialog from '../auth/Payment/CancelDialog';
import CreateCard from '../auth/Payment/Stripe/CreateCard';
// import LoginForm from '../auth/Login';
import Renew from '../auth/Renew';
import CardDetails from '../auth/Payment/Stripe/CardDetails';
import PageNotFound from '../auth/PageNotFound';
// import PageNotFound from '../auth/PageNotFound';

interface User {
  id: number;
  name: string;
  profilePic: string;
}

const MasterRouter = (props: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();

  useEffect(() => {
    if (!props.userAccessToken) {
      dispatch(backHandle(false));
      dispatch(userAccessToken({ userAccessToken: '' }));
      dispatch(userRefreshToken({ userRefreshToken: '' }));
      dispatch(isLoggedIn(false));
    }
  }, [dispatch, navigate, props]);

  useEffect(() => {
    if (props.id && props.userStatus === 'Active') {
      userInformation(props?.id);
    }
  }, [props]);

  const [userInfo, setUserInfo] = useState<any>(null);

  const userInformation = (id: number) => {
    const getDetails = privateAxios.get(`user/getuserdetails?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          setUserInfo(data);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const user: User = {
    id: props.id,
    name: userInfo?.firstName,
    profilePic: userInfo?.profilePath,
  };

  return (
    <Routes>
      <Route path="/renew" element={<Renew />} />
      {props.isLoggedIn && (
        <>
          <Route path="/login" element={props.userStatus === 'Active' && <Navigate to="/userFeed" />} />
        </>
      )}
      {props.userStatus === 'Expired' && (
        <>
          <Route path="/*" element={<Navigate to="/premium/choose-payment" />} />
          <Route path="/premium/choose-payment" element={<ChoosePayment />} />
        </>
      )}
      {props.userType === 'Registration' && props.userDC && (
        <Route path="/premium/sign-up" element={<ProtectedRouteLayout />}>
          <Route index element={<RegistrationStepper />} />
        </Route>
      )}
      {props.userStatus === 'Registration' && !props.userType && (
        <>
          <Route path="/*" element={<Navigate to="/basic/sign-up" />} />
          <Route path="/basic/sign-up" element={<Navigate to="/" />} />
        </>
      )}

      {props.userStatus === 'Registration' && props.userType && (
        <>
          {/* <Route path="/*" element={<Navigate to="/premium/choose-payment" />} /> */}
          <Route path="/premium/choose-payment" element={<ChoosePayment />} />
        </>
      )}

      {props.userStatus === undefined ||
        props.userStatus === 'Expired' ||
        (props.userStatus === '' && (
          <>
            <Route
              path="/*"
              element={
                props.userType ? (
                  // props.userPM ? (
                  <Navigate to="/premium/choose-payment" />
                ) : (
                  // ) : (
                  //   <Navigate to="/premium/choose-payment" />
                  // )
                  <Navigate to="/basic/welcome" />
                )
              }
            />
            <Route path="/premium/choose-payment" element={<ChoosePayment />} />
            <Route path="/basic/welcome" element={<ProtectedRouteLayout />}>
              <Route index element={<BasicWelcome />} />
            </Route>
          </>
        ))}

      {props.userStatus === 'Active' && !props.userType ? (
        <Route path="/premium/choose-payment" element={<ChoosePayment />} />
      ) : (
        <Route path="/basic/sign-up" element={<Navigate to="/" />} />
      )}
      {props.userStatus === undefined ||
        (props.userStatus === '' && (
          <>
            <Route
              path="/*"
              element={
                props.userType ? (
                  !props.userPM || !props.userDC ? (
                    <Navigate to="/premium/choose-payment" />
                  ) : (
                    <Navigate to="/premium/sign-up" />
                  )
                ) : (
                  <Navigate to="/basic/welcome" />
                )
              }
            />
            <Route path="/premium/choose-payment" element={<ChoosePayment />} />
            <Route path="/basic/welcome" element={<ProtectedRouteLayout />}>
              <Route index element={<BasicWelcome />} />
            </Route>
          </>
        ))}

      {props.userStatus === 'Active' && props.userType && !props.userPM && (
        <>
          <Route path="/premium/choose-payment" element={<ChoosePayment />} />
        </>
      )}

      {props.userStatus === 'Active' && props.userType && (
        <>
          <Route />
          <Route path="/" element={<ProtectedRouteLayout />}>
            <Route index element={<UserFeed />} />
          </Route>
        </>
      )}

      {props.userStatus === 'Active' && !props.userType && (
        <>
          {/* <Route path="/" element={<Navigate to="/conversations" />} /> */}
          <Route path="/" element={<ProtectedRouteLayout />}>
            {/* <Route index element={<Chat user={user} />} /> */}
            <Route index element={<Dashboard />} />
          </Route>
        </>
      )}

      {props.userStatus === 'Registration' && !props.userType ? (
        <>
          <Route path="/basic/sign-up" element={<ProtectedRouteLayout />}>
            <Route index element={<RegistrationStepper />} />
          </Route>
          <Route path="/basic/welcome" element={<ProtectedRouteLayout />}>
            <Route index element={<BasicWelcome />} />
          </Route>
        </>
      ) : (
        <Route path="/basic/sign-up" element={<Navigate to="/" />} />
      )}

      {(props.userStatus === 'Registration' || props.userStatus === undefined || props.userStatus === '') && (
        <Route path="/premium/sign-up" element={<ProtectedRouteLayout />}>
          <Route index element={<RegistrationStepper />} />
        </Route>
      )}

      <Route path="/user/*" element={<UserRouter />} />
      <Route path="/account-dashboard" element={<Dashboard />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/account-dashboard/edit-profile" element={<ProtectedRouteLayout />}>
        <Route index element={<Editprofile />} />
      </Route>
      <Route path="/userProfile" element={<Dashboard />} />

      <Route path="/bookings" element={<Booking />} />

      {props.userStatus === 'Active' && props.userType ? (
        <>
          <Route path="/userFeed" element={<UserFeed />} />
          <Route path="/myroster" element={<MyRoster />} />
        </>
      ) : (
        props.userStatus === 'Active' &&
        !props.userType && (
          <>
            <Route path="/userFeed" element={<Navigate to="/" />} />
            <Route path="/myroster" element={<Navigate to="/" />} />
          </>
        )
      )}
      <Route path="/conversations" element={<Chat user={user} />} />
      {/* <Route path="/availability" element={<AvailabilityCalender />} /> */}
      <Route path="/user-details" element={<UserProfile />} />
      <Route path="/paypal/payment/success" element={<SuccessDialog />} />
      <Route path="/paypal/payment/cancel" element={<CancelDialog />} />
      {!props.userPM || props.userStatus === 'Expired' ? (
        <Route path="/stripe/add" element={<CreateCard />} />
      ) : (
        <Route path="/stripe/add" element={<Navigate to="/" />} />
      )}
      {!props.userPM || props.userStatus === 'Expired' ? (
        <Route path="/cardDetails" element={<CardDetails />} />
      ) : (
        <Route path="/cardDetails" element={<Navigate to="/" />} />
      )}

      {props.userStatus === 'Active' && <Route path="/premium/sign-up" element={<Navigate to="/" />} />}
      {props.userStatus === 'Active' && <Route path="/premium/choose-payment" element={<Navigate to="/" />} />}

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
    userStatus: state.status,
    userType: state.userType,
    userPM: state.userPM,
    userDC: state.userDC,
    userTM: state.userTM,
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapDispatchToProps)(MasterRouter);

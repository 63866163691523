/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Dialog, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  // userId,
  // isLoggedIn,
  // userType,
  // userAccessToken,
  // userRefreshToken,
  // backHandle,
  userStepStatus,
  userPM,
} from '../../../reducer/action';
import { AppDispatch } from '../../../reducer/store';
import CustomSnackbar from '../../../common/Snackbar';
import { CheckCircleOutline } from '@mui/icons-material';

const SuccessDialog = (props: any) => {
  // console.log('props', props);

  const location = useLocation();
  const navigate = useNavigate();
  const privateAxios = useAxiosPrivate();
  const dispatch = useDispatch<AppDispatch>();

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: '', type: 'success' });

  const searchParams = new URLSearchParams(location.search);

  const paymentId = searchParams.get('paymentId');
  // const token = searchParams.get('token');
  const payerId = searchParams.get('PayerID');

  useEffect(() => {
    if (payerId) {
      setOpenSuccessDialog(true);
      handleOnClick();
    }
  }, []);

  const handleOnClick = () => {
    const getExecute = privateAxios.get(
      `payment/paypal/execute?PayerID=${payerId}&paymentId=${paymentId}&discountEligible=${props.userDisE}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    getExecute
      .then((response) => {
        if (response.status === 200) {
          // const link = document.createElement('a');
          // link.href = response.data;
          // console.log('data', response.data);

          // link.download = 'payment_receipt.pdf';
          // link.click();

          if (props.userType) {
            window.open(response?.data?.data.link, '_blank');
            //   setTimeout(() => {
            //     dispatch(userPM(response.data.data.isPayment));
            //     // navigate('/premium/creat-team');
            //     navigate('/premium/sign-up');
            //   }, 2000);
            // } else {
            setTimeout(() => {
              // dispatch(isLoggedIn(false));
              // dispatch(backHandle(false));
              // dispatch(userId(''));
              dispatch(userStepStatus({ status: response.data.data.status }));
              dispatch(userPM(response.data.data.isPayment));
              // dispatch(userType(false));
              // dispatch(userPM(false));
              // dispatch(userAccessToken({ accessToken: '' }));
              // dispatch(userRefreshToken({ refreshToken: '' }));
              // localStorage.removeItem('center');
              // localStorage.clear();
              navigate(`/`);
            }, 2000);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleClose = () => {
    setTimeout(() => {
      setOpenSuccessDialog(false);
    }, 2000);
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleToastClose();
    }, 2000);
  };
  const handleToastClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={openSuccessDialog}
        onClick={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '500px !important',
            height: 'auto',
            padding: '25px',
          },
        }}>
        <DialogContent sx={{ fontFamily: "'Onest', sans-serif" }}>
          <Avatar sx={{ bgcolor: '#2CB512', marginBottom: '20px', width: '80px', height: '80px', margin: 'auto' }}>
            <CheckCircleOutline sx={{ width: '60px', height: '60px', color: '#fff' }} />
          </Avatar>
          Your Payment has been successfully completed
        </DialogContent>
        {/* <DialogActions sx={{ justifyContent: 'center' }}>
      <Button className="common-button-green" sx={{ color: '#fff' }} onClick={handleOnClick}>
        Next
      </Button>
    </DialogActions> */}
      </Dialog>
      <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleToastClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
    userStatus: state.status,
    userType: state.userType,
    userDisE: state.userDisE,
  };
}

export default connect(mapDispatchToProps)(SuccessDialog);

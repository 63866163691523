/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import maiIcon from '../../Assets/Images/mailIcon.png';
import '../../Assets/css/Header.css';
import { Button, Container, Grid, Typography } from '@mui/material';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import BannerImage from '../../Assets/Images/headerProfile.png';
import profileImage from '../../Assets/Images/banner-04.png';
import { useNavigate } from 'react-router-dom';
import Navbar from '../HomeScreen/Navbar';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { IUserDetails } from '../Interface/UserInterface/IUser';
import CustomSnackbar from '../../common/Snackbar';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reducer/store';
import { profileHeaderPics } from '../../reducer/action';

const Header = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const privateAxios = useAxiosPrivate();

  const [data, setData] = useState<IUserDetails | null>(null);
  const [direction, setDirection] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [landingPage, setLandingPage] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    getUserProfileInfo();

    const check = window.location.href.includes('account-dashboard');
    const edit = window.location.href.includes('edit-profile');
    const search: any = window.location.href.split('/')[3];

    if (!check) {
      setDirection(true);
    }
    if (edit) {
      setEdit(true);
    }
    if (!check && !edit) {
      setDirection(false);
      setEdit(true);
    }
    if (search === '') {
      setLandingPage(true);
    }
  }, []);

  const getUserProfileInfo = () => {
    const getCategoryValues = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getCategoryValues
      .then((response: any) => {
        // console.log('Response', response.data.data);

        if (response.status === 200) {
          setData(response.data.data);
          dispatch(
            profileHeaderPics({
              profilePath: response.data.data?.profilePath,
              headerPath: response.data.data?.headerPath,
              availablity: response.data.data?.availability,
              firstName: response.data.data?.firstName,
              lastName: response.data.data?.lastName,
              jobTitle: response.data.data?.jobTitle,
              userName: response.data.data?.userName,
            })
          );
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpgradePlan = () => {
    navigate('/renew');

    // const upgradePlan = privateAxios.post(`payment/paypal/upgrade`, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });
    // upgradePlan
    //   .then((response: any) => {
    //     if (response.data.status === true) {
    //       console.log('REs', response.data.data);
    //       setToast({ message: response.data.message.value, type: 'success' });
    //       handleClick();
    //       const url = response.data.data;
    //       window.location.href = url;
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.error('RESPONSE', error);
    //     setToast({ message: error.response.data.message.value, type: 'error' });
    //     handleClick();
    //   });
  };

  const getColorLabel = (status: string) => {
    switch (status) {
      case 'Available':
        return '#D5EBD1';
      case 'Not Available':
        return '#f5bac4';
      case 'Available Soon':
        return '#cab6d1';
      case 'Available Part Time':
        return '#f5dbba';
      case 'Part Time':
        return '#f5dbba';
      case 'Off Market':
        return '#b2b9eb';
      default:
        return '';
    }
  };

  const getColor = (status: string) => {
    switch (status) {
      case 'Available':
        return 'var(--green)';
      case 'Not Available':
        return 'red';
      case 'Available Soon':
        return 'darkorchid';
      case 'Available Part Time':
        return 'orange';
      case 'Part Time':
        return 'orange';
      case 'Off Market':
        return 'blue';
      default:
        return '';
    }
  };

  return (
    <>
      <Navbar />
      <Grid sx={{ pt: '88px' }}>
        {data && (
          <section className="acc-banner">
            <img
              src={data.headerPath === null ? BannerImage : props.profilePics.headerPath}
              alt="img"
              height="100% !important"
              width="100% !important"
              style={{ height: '100% !important', objectFit: 'cover' }}
            />
          </section>
        )}
        <div className="acc-header prem-view">
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7} sm={6}>
                <div className="acc-cont">
                  {data && (
                    <div className="acc-img">
                      <img
                        src={data.profilePath === null ? profileImage : props.profilePics.profilePath}
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  )}
                  <div className="det-info">
                    <div className="acc-name dis-align">
                      <Typography
                        component={'span'}
                        variant="h3"
                        sx={{ fontSize: '28px', fontWeight: 600, fontFamily: "'Onest', sans-serif" }}>
                        {/* {data && `${data.firstName} ${data.lastName}`} */}
                        {data && `${props?.profilePics?.firstName} ${props?.profilePics?.lastName}`}
                      </Typography>
                      <Typography
                        component={'span'}
                        variant="body1"
                        className="until-date status-display"
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          fontFamily: "'Onest', sans-serif",
                          ml: 2,
                          // backgroundColor: getColorLabel((data && data.availability) || ''),
                          // color: getColor((data && data.availability) || ''),
                          backgroundColor: getColorLabel((data && props?.profilePics?.availablity) || ''),
                          color: getColor((data && props?.profilePics?.availablity) || ''),
                        }}>
                        <span
                          style={{
                            content: '',
                            padding: '3px',
                            borderRadius: '5px',
                            position: 'absolute',
                            left: '10px',
                            top: '11px',
                            // backgroundColor: getColor((data && data.availability) || ''),
                            backgroundColor: getColor((data && props?.profilePics?.availablity) || ''),
                          }}></span>
                        {/* {data && data.availability !== '-' && data.availability} */}
                        {data && data.availability !== '-' && props?.profilePics?.availablity}
                      </Typography>
                    </div>
                    <div className="skill-info dis-align">
                      <div className="date dis-align">
                        <img src={maiIcon} alt="img" className="img-fluid" />
                        <Typography
                          component={'span'}
                          variant="body1"
                          sx={{ fontSize: '16px', fontWeight: 400, fontFamily: "'Onest', sans-serif", ml: 1 }}>
                          {/* {data && data.jobTitle} */}
                          {data && props?.profilePics?.jobTitle}
                        </Typography>
                      </div>
                    </div>
                    <Typography
                      component={'span'}
                      variant="body1"
                      sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        fontFamily: "'Onest', sans-serif",
                        // ml: 5,
                        color: '#667085',
                      }}>
                      Username
                      {/* <span>{data && data.userName}</span> */}
                    </Typography>
                    <Typography
                      component={'span'}
                      variant="body1"
                      sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        fontFamily: "'Onest', sans-serif",
                        ml: 1,
                        color: '#000',
                      }}>
                      {/* {data && data.userName} */}
                      {data && props?.profilePics?.userName}
                    </Typography>
                  </div>
                </div>
              </Grid>
              {direction ? (
                <Grid item xs={12} lg={5} sm={6} sx={{ mt: 2 }}>
                  <div className="list-btn right-btn">
                    {location.pathname.includes('userProfile') && (
                      <>
                        <Button
                          sx={{ width: '160px' }}
                          className="common-button-green"
                          variant="contained"
                          color="primary"
                          data-toggle="modal"
                          data-target="#exampleModal-hirenow">
                          Hire Now
                        </Button>
                        <Button sx={{ width: '160px' }} className="common-button-white" variant="outlined">
                          Message
                        </Button>
                        <Button sx={{ width: '160px', marginLeft: 2 }} className="common-button-white" variant="outlined">
                          Add to My Roster
                        </Button>
                      </>
                    )}
                  </div>
                </Grid>
              ) : !edit || landingPage ? (
                <Grid item xs={12} lg={5} sm={6} sx={{ mt: 2 }}>
                  <div className="list-btn right-btn">
                    {!props.userType && (
                      <Button
                        className="common-button-green"
                        type="submit"
                        variant="contained"
                        sx={{
                          marginLeft: 1,
                          mb: 2,
                          '&:hover': {
                            background: 'var(--Primary, #2CB512)',
                          },
                        }}
                        onClick={handleUpgradePlan}>
                        Upgrade Premium
                      </Button>
                    )}
                    <Button
                      sx={{ width: '160px' }}
                      className="common-button-white"
                      onClick={() => navigate('/account-dashboard/edit-profile', { state: { tab: 0 } })}>
                      Edit Profile
                    </Button>
                  </div>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Container>
        </div>
      </Grid>
      <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userType: state.userType,
    userAccessToken: state.userAccessToken,
    profilePics: state.userPics,
  };
}

export default connect(mapDispatchToProps)(Header);

import { publicAxios } from '../services/Interceptor';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../reducer/store';
import {
  backHandle,
  isLoggedIn,
  userAccessToken,
  userId,
  userRefreshToken,
  userStepStatus,
  userType,
} from '../reducer/action';
import { useNavigate } from 'react-router-dom';

const useRefreshToken = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const refToken = useSelector((state: any) => {
    return state.userRefreshToken;
  });

  const refresh = async () => {
    const payload = { token: refToken };

    const response = publicAxios.post(`login/refreshtoken`, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    response
      .then((res) => {
        dispatch(userAccessToken({ accessToken: res.data.data.accessToken }));
        return res.data.data.accessToken;
      })
      .catch((err: any) => {
        const errorMsg = err.response.data.message.value;

        if (errorMsg === 'Token already deleted.' || errorMsg === 'Your Subscription Got Expired.') {
          setTimeout(() => {
            dispatch(isLoggedIn(false));
            dispatch(backHandle(false));
            dispatch(userId(''));
            dispatch(userStepStatus({ status: '' }));
            dispatch(userType(false));
            dispatch(userAccessToken({ accessToken: '' }));
            dispatch(userRefreshToken({ refreshToken: '' }));
            localStorage.removeItem('center');
            localStorage.clear();
            navigate(`/login`);
          }, 2000);
        }
      });
  };

  return refresh;
};

export default useRefreshToken;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, CssBaseline, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { validatePassword, isPasswordValid } from '../../common/Validation';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../common/Snackbar';

type ShowPasswordState = {
  oldPassword: boolean;
  newPassword: boolean;
  confirmPassword: boolean;
};

const ChangePassword = () => {
  const navigate = useNavigate();
  const privateAxios = useAxiosPrivate();

  const [formData, setFormData] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });
  const [Errors, setErrors] = useState({ oldPassword: '', newPassword: '', confirmPassword: '', condition: '' });
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [showPassword, setShowPassword] = useState<ShowPasswordState>({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleClickShowPassword = (type: keyof ShowPasswordState) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [type]: !prevShowPassword[type],
    }));
  };

  const handleMouseDownPassword = (type: keyof ShowPasswordState) => {
    setShowPassword((prevShowPassword) => ({
      ...prevShowPassword,
      [type]: !prevShowPassword[type],
    }));
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePassword = () => {
    const { oldPassword, newPassword, confirmPassword } = formData;

    const newPasswordError = validatePassword(newPassword);
    const newPasswordIsValid = isPasswordValid(newPassword);
    const confirmPasswordError = newPassword !== confirmPassword ? 'Passwords do not match' : '';

    setErrors({
      oldPassword: oldPassword === '' ? 'Old Password is required' : '',
      newPassword: newPasswordError,
      confirmPassword: confirmPasswordError,
      condition: newPasswordIsValid,
    });

    if (oldPassword === '' || newPasswordError !== '' || confirmPasswordError !== '' || newPasswordIsValid !== '') {
      return;
    } else {
      const { confirmPassword, ...data } = formData;

      const changePassword = privateAxios.post(`user/changepassword`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      changePassword
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            setFormData({
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            });
            handleClick();
          }
        })
        .catch((error) => {
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    }
  };

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value.replace(/^\s+/, '').replace(/\s+/g, ' '),
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value.trim() === '' ? `${name.charAt(0).toUpperCase() + name.slice(1)} is required` : '',
    }));
  };

  return (
    <React.Fragment>
      <Header />
      <CssBaseline />
      <Container maxWidth="xl">
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                textAlign: 'left',
                fontFamily: "'Onest', sans-serif",
                fontWeight: 600,
                fontSize: '18px',
              }}>
              Change Password
            </Typography>
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                handleChangePassword();
              }}
              sx={{ mt: 3 }}>
              <Typography
                className="lable-name"
                sx={{ textAlign: 'left', fontSize: '14px', fontWeight: 500, color: '#344054' }}>
                Old Password
              </Typography>
              <TextField
                className="formFields"
                sx={{ mt: 0, width: '500px' }}
                name="oldPassword"
                placeholder="Enter the Old Password"
                margin="normal"
                type={showPassword.oldPassword ? 'text' : 'password'}
                value={formData.oldPassword}
                onChange={handleFieldChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword('oldPassword')}
                        onMouseDown={() => handleMouseDownPassword('oldPassword')}>
                        {showPassword.oldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {Errors.oldPassword && <Typography sx={{ color: 'red', mt: 1 }}>{Errors.oldPassword}</Typography>}

              <Typography
                className="lable-name"
                sx={{ textAlign: 'left', mt: '15px', fontSize: '14px', fontWeight: 500, color: '#344054' }}>
                New Password
              </Typography>
              <TextField
                className="formFields"
                sx={{ mt: 0, width: '500px' }}
                name="newPassword"
                placeholder="Enter the New Password"
                margin="normal"
                type={showPassword.newPassword ? 'text' : 'password'}
                value={formData.newPassword}
                onChange={handleFieldChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword('newPassword')}
                        onMouseDown={() => handleMouseDownPassword('newPassword')}>
                        {showPassword.newPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {Errors.newPassword && <Typography sx={{ color: 'red', mt: 1 }}>{Errors.newPassword}</Typography>}
              {Errors.newPassword === '' && Errors.condition ? (
                <Typography sx={{ color: 'red', mt: 1 }}>{Errors.condition}</Typography>
              ) : (
                ''
              )}

              <Typography
                className="lable-name"
                sx={{ textAlign: 'left', mt: '15px', fontSize: '14px', fontWeight: 500, color: '#344054' }}>
                Confirm Password
              </Typography>
              <TextField
                className="formFields"
                sx={{ mt: 0, width: '500px' }}
                name="confirmPassword"
                placeholder="Confirm the New Password"
                margin="normal"
                type={showPassword.confirmPassword ? 'text' : 'password'}
                value={formData.confirmPassword}
                onChange={handleFieldChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleClickShowPassword('confirmPassword')}
                        onMouseDown={() => handleMouseDownPassword('confirmPassword')}>
                        {showPassword.confirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {Errors.confirmPassword && <Typography sx={{ color: 'red', mt: 1 }}>{Errors.confirmPassword}</Typography>}

              <Typography
                className="forgotPass"
                sx={{
                  textAlign: 'left',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '20px',
                  marginTop: '10px',
                  marginLeft: '374px',
                  fontFamily: '"Onest", sans-serif !important',
                }}>
                <a href="/login/forget-password" style={{ textDecoration: 'none', color: '#2CB512' }}>
                  Forgot Password?
                </a>
              </Typography>

              <Box sx={{ display: 'flex', mt: 3, textAlign: 'center' }}>
                <Button
                  className="common-button-white"
                  onClick={() => navigate(-1)}
                  sx={{
                    width: '200px !important',
                    height: '40px !important',
                  }}>
                  Cancel
                </Button>
                <Button
                  className="common-button-green"
                  type="submit"
                  sx={{
                    width: '200px !important',
                    height: '40px !important',
                    backgroundColor: '#2CB512',
                    borderRadius: '8px',
                    color: '#FFFFFF',
                    ml: 3,
                  }}>
                  Submit
                </Button>
              </Box>

              <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleClose} toast={toast} />
            </Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ChangePassword;

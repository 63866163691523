import React, { useEffect, useState } from 'react';
import { Grid, Typography, TextField, InputAdornment, Button } from '@mui/material';
import banks from '../../../Assets/Images/banks.svg';
// import { cardDetail } from '../../Interface/PaymentInterface/IPayment';
//import CustomSnackbar from '../../../common/Snackbar';
import '../../../Assets/css/Cards.css';

interface CardDetailsGridProps {
  card?: any;
  isEditMode: boolean;
  onCancel?: () => void;
  onSave?: (updatedCard: any) => void;
  handleAddCard?: (data: any) => void;
  page?: string;
  clearCardDetails?: boolean;
}

const CardDetailsGrid: React.FC<CardDetailsGridProps> = ({
  card,
  isEditMode,
  onCancel,
  handleAddCard,
  onSave,
  page,
  clearCardDetails,
}) => {
  const [newCardData, setNewCardData] = useState<any>({
    cardNumber: null,
    cardHolderName: '',
    expiry: '',
    cvc: null,
  });

  //const [toast, setToast] = React.useState({ message: '', type: 'success' });
  //const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (clearCardDetails) {
      setNewCardData({
        cardNumber: null,
        cardHolderName: '',
        expiry: '',
        cvc: null,
      });
    }
  }, [clearCardDetails]);

  const [error, setError] = useState({
    cardNumber: '',
    cardHolderName: '',
    expiry: '',
    cvc: '',
  });

  useEffect(() => {
    if (card) {
      setNewCardData((prevCard: any) => ({
        ...prevCard,
        ...(card ?? {}),
        // country: card.country || '',
      }));
    }
  }, [card]);

  const formatCardNumber = (cardNumber: number) => {
    const formattedCardNumber = String(cardNumber)
      .replace(/(\d{4})/g, '$1 ')
      .trim();
    return formattedCardNumber;
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    switch (name) {
      case 'cardNumber': {
        const formattedCardNumber = value
          .replace(/\D/g, '')
          .replace(/(\d{4})/g, '$1 ')
          .trim()
          .slice(0, 19);
        event.target.value = formattedCardNumber;
        setNewCardData((prevCard: any) => ({ ...prevCard, cardNumber: formattedCardNumber }));
        break;
      }
      case 'cardHolderName': {
        const formattedCardHolder = event.target.value
          .replace(/^\s+/, '')
          .replace(/\s+/g, ' ')
          .replace(/^[^a-zA-Z@.]+/, '');

        setNewCardData((prevCard: any) => ({ ...prevCard, cardHolderName: formattedCardHolder }));
        break;
      }
      case 'expiry': {
        const formattedExpiryDate = value
          .replace(/\D/g, '') // Remove non-numeric characters
          .replace(/(\d{2})(\d{0,2})/, (_: any, mm: any, yy: any) => {
            const clampedMM = Math.min(parseInt(mm, 10), 12).toString().padStart(2, '0');
            return yy ? `${clampedMM}/${yy}` : clampedMM;
          })
          .slice(0, 5);

        setNewCardData((prevCard: any) => ({ ...prevCard, expiry: formattedExpiryDate }));

        event.target.value = formattedExpiryDate;
        break;
      }
      case 'cvc': {
        const formattedCVC = value.replace(/\D/g, '').slice(0, 3);
        event.target.value = formattedCVC;
        setNewCardData((prevCard: any) => ({ ...prevCard, cvc: formattedCVC }));
        break;
      }

      default:
        break;
    }

    if (event.target.name === 'cardNumber') {
      if (event.target.value !== '') {
        //error.cardNumber = '';
        setError((pre: any) => ({
          ...pre,
          cardNumber: '',
        }));
        return;
      }
    }

    if (event.target.name === 'cardHolderName') {
      if (event.target.value !== '') {
        setError((pre: any) => ({
          ...pre,
          cardHolderName: '',
        }));
        return;
      }
    }

    if (event.target.name === 'expiry') {
      if (event.target.value !== '') {
        setError((pre: any) => ({
          ...pre,
          expiry: '',
        }));
        return;
      }
    }

    if (event.target.name === 'cvc') {
      if (event.target.value !== '') {
        setError((pre: any) => ({
          ...pre,
          cvc: '',
        }));
        return;
      }
    }
  };

  const handleClick = () => {
    const cardDetails = {
      cardNumber: newCardData?.cardNumber,
      cardHolderName: newCardData?.cardHolderName,
      expiry: newCardData?.expiry,
      cvc: newCardData?.cvc,
    };

    if (cardDetails.cardNumber === null || cardDetails.cardNumber === '') {
      setError((pre: any) => ({
        ...pre,
        cardNumber: 'Enter the Card Number',
      }));
      return;
    }

    if (cardDetails.cardHolderName === '') {
      setError((pre: any) => ({
        ...pre,
        cardHolderName: 'Enter the Card Holder name',
      }));
      return;
    }

    if (cardDetails.expiry === '') {
      setError((pre: any) => ({
        ...pre,
        expiry: 'Enter the card expiry',
      }));
      return;
    }

    if (cardDetails.cvc === null || cardDetails.cvc === '') {
      setError((pre: any) => ({
        ...pre,
        cvc: 'Enter the cvc number',
      }));
      return;
    }
  };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    // <Box sx={{ backgroundColor: 'teal', height: '100vh' }}>
    <Grid>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '14px',
          LineHeight: '20px',
          color: '#344054',
          fontFamily: '"Onest", sans-serif !important',
        }}>
        Card number <span style={{ color: '#FF0000', fontWeight: 'bolder', fontSize: '16px' }}>*</span>
      </Typography>
      <TextField
        className="cardName"
        autoComplete="off"
        placeholder="Enter card number"
        sx={{ width: page === 'create' ? '750px' : '100%', marginTop: '0px', fontFamily: '"Onest", sans-serif !important' }}
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <img src={banks} alt="Your Image" />
            </InputAdornment>
          ),
        }}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          maxLength: 25,
        }}
        name="cardNumber"
        value={
          newCardData?.cardNumber
            ? formatCardNumber(newCardData?.cardNumber)
            : newCardData?.cardNumber === null
            ? ''
            : newCardData?.cardNumber
        }
        onChange={handleInputChange}
        disabled={isEditMode}
      />
      {error.cardNumber && <div style={{ color: 'red' }}>{error.cardNumber}</div>}

      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '14px',
          LineHeight: '20px',
          color: '#344054',
          marginTop: '16px',
          fontFamily: '"Onest", sans-serif !important',
        }}>
        Card Holder&apos;s Name <span style={{ color: '#FF0000', fontWeight: 'bolder', fontSize: '16px' }}>*</span>
      </Typography>
      <TextField
        className="cardHolderName"
        autoComplete="off"
        placeholder="Enter Name"
        sx={{ width: page === 'create' ? '750px' : '100%', marginTop: '0px' }}
        margin="normal"
        inputProps={{
          maxLength: 25,
        }}
        name="cardHolderName"
        value={newCardData?.cardHolderName}
        onChange={handleInputChange}
        disabled={isEditMode}
      />
      {error.cardHolderName && <div style={{ color: 'red' }}>{error.cardHolderName}</div>}

      <Grid container spacing={2} className="expiryAmt">
        <Grid item lg={6} sm={12} xs={12}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              LineHeight: '20px',
              color: '#344054',
              marginTop: '16px',
              fontFamily: '"Onest", sans-serif !important',
            }}>
            Expiry <span style={{ color: '#FF0000', fontWeight: 'bolder', fontSize: '16px' }}>*</span>
          </Typography>
          <TextField
            className="expiryMonth"
            placeholder="MM/YY"
            autoComplete="off"
            sx={{ width: page === 'create' ? '380px' : '100%', marginTop: '0px' }}
            margin="normal"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 5,
            }}
            name="expiry"
            value={newCardData?.expiry}
            onChange={handleInputChange}
          />
          {error.expiry && <div style={{ color: 'red' }}>{error.expiry}</div>}
        </Grid>

        <Grid item lg={6} sm={12} xs={12}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              LineHeight: '20px',
              color: '#344054',
              marginTop: '16px',
              fontFamily: '"Onest", sans-serif !important',
            }}
            className="cvcAmt">
            CVC <span style={{ color: '#FF0000', fontWeight: 'bolder', fontSize: '16px' }}>*</span>
          </Typography>
          <TextField
            className="expiryCVV"
            placeholder="CVC"
            sx={{ width: page === 'create' ? '360px' : '100%', marginTop: '0px' }}
            name="cvc"
            autoComplete="off"
            type="password"
            margin="normal"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 3,
            }}
            value={newCardData?.cvc === null ? '' : newCardData?.cvc}
            onChange={handleInputChange}
            disabled={isEditMode}
          />
          {error.cvc && <div style={{ color: 'red' }}>{error.cvc}</div>}
        </Grid>
      </Grid>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
        {isEditMode && (
          <Button className="common-button-white" sx={{ color: '#fff' }} onClick={onCancel}>
            Cancel
          </Button>
        )}

        <Button
          className="common-button-green"
          sx={{ color: '#fff' }}
          // onClick={() => {
          //   if (isEditMode) {
          //     onSave && onSave(newCardData);
          //   } else {
          //     handleAddCard && handleAddCard(newCardData);
          //   }
          // }}
          onClick={() => {
            // Check if required fields are filled
            if (
              newCardData &&
              newCardData.cardNumber &&
              newCardData.cardHolderName &&
              newCardData.expiry &&
              newCardData.cvc
            ) {
              // Proceed with saving or adding the card
              if (isEditMode) {
                onSave && onSave(newCardData);
              } else {
                handleAddCard && handleAddCard(newCardData);
              }
            } else {
              // Show error message or perform any other action
              //setToast({ message: ' Please fill in all fields.', type: 'error' });
              handleClick();
            }
          }}>
          {isEditMode ? 'Save' : page === 'create' ? 'Next' : 'Add new card'}
        </Button>
      </Grid>
      {/* <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} /> */}
    </Grid>
    // </Box>
  );
};

export default CardDetailsGrid;

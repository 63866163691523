/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, TextField, InputAdornment, Button } from '@mui/material';
import '../../../Assets/css/Common.css';
import uploadCloud from '../../../Assets/Images/uploadCloud.png';
// import Env from '../../../services/Env';
import { connect } from 'react-redux';
import CustomSnackbar from '../../../common/Snackbar';
import trash from '../../../Assets/Images/trash.svg';
// import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import '../../../Assets/css/Portfolio.css';

interface step {
  label: string;
  key: string;
}

interface PortfoliosSocialProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  stepKeys: step;
  // userAccessToken: string;
  // id: number;
}

interface SocialMedialProps {
  link: string;
  mediakey: string;
  key: string;
  label: string;
  logoPath: string;
}
interface imageProps {
  imageUrl: string;
  handleImageDelete: any;
}

function PortfolioImage(props: imageProps) {
  const { imageUrl, handleImageDelete } = props;
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <Grid
        item
        sm={2}
        sx={{ position: 'relative' }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}>
        <img
          style={{ width: '100%', height: '120px', objectFit: 'cover', borderRadius: '8px' }}
          src={imageUrl}
          alt="portfolioimages"
          className="img-fluid upload-button"
        />
        {isHovered && (
          <Box
            onClick={() => handleImageDelete(imageUrl)}
            className="trash-div"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              cursor: 'pointer',
              backgroundColor: 'white',
              width: '40px',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
            }}>
            {/* <DeleteForeverOutlinedIcon /> */}
            <img src={trash} id="trash-icon" />
          </Box>
        )}
      </Grid>
    </>
  );
}

const PortfoliosSocial: React.FC<PortfoliosSocialProps> = ({
  activeStep,
  handleNext,
  handleBack,
  stepKeys,
  // id,
  // userAccessToken,
}) => {
  const privateAxios = useAxiosPrivate();

  const [socialLinks, setSocialLinks] = useState<SocialMedialProps[]>([]);
  const [socialMediaValues, setSocialMediaValues] = useState([]);
  const [portfolioImages, setPortfolioImages] = useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [portfolios, setPortfolios] = useState({
    stepperKey: stepKeys.key,
    // userId: id,
    websiteURL: '',
    portfolio: [],
    socialMedia: [],
  });
  const [Error, setError] = useState({
    // websiteURL: '',
    portfolio: '',
    // socialMedia: '',
  });
  const [existingData, setExistingData] = useState<any>(null);

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    socialMediaData();
    getPortSocailData();
  }, []);

  const getPortSocailData = () => {
    // const getData = Env.get(`user/getuserstepper?stepperKey=${stepKeys.key}&userId=${id}`, userAccessToken);

    const getData = privateAxios.get(`user/getuserstepper?stepperKey=${stepKeys.key}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getData
      .then((response: any) => {
        if (response.status === 200) {
          const getSocialMedia = response.data.data.socialMedia.map((social: any) => ({
            label: social.label || '',
            key: social.key || '',
            link: social.link || '',
          }));

          setExistingData(response.data.data);

          setPortfolios((prevPortfolio) => ({
            ...prevPortfolio,
            websiteURL: response.data.data.websiteURL || '',
            socialMedia: getSocialMedia,
            portfolio: response.data.data.portfolio || [],
          }));
          setPortfolioImages(response.data.data.portfolio);
          setSocialMediaValues(getSocialMedia);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const socialMediaData = () => {
    // const getSocialDatas = Env.get(`user/socialMedia`, userAccessToken);

    const getSocialDatas = privateAxios.get(`user/socialMedia`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getSocialDatas
      .then((response: any) => {
        if (response.status === 200) {
          setSocialLinks(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const validateForm = () => {
    const { portfolio } = portfolios;

    const errors = {
      // websiteURL: '',
      portfolio: '',
      // socialMedia: '',
    };

    // if (!websiteURL) {
    //   errors.websiteURL = 'Website URL is required';
    // }

    if (!portfolioImages || portfolioImages === null || portfolioImages?.length === 0 || portfolioImages?.length < 4) {
      errors.portfolio = 'Atleast Four images is required';
    }

    // const isEmptyLink = socialMedia.some((social: any) => {
    //   if (typeof social === 'object' && social.mediaKey) {
    //     return social.link && social.link.trim() === '';
    //   } else if (social.key && social.label) {
    //     return social.link && social.link.trim() === '';
    //   } else {
    //     return false;
    //   }
    // });

    // if (isEmptyLink) {
    //   errors.socialMedia = 'All social media links must be filled';
    // }

    // const hasAtLeastOneLink = socialMedia.some((social: any) => {
    //   return (
    //     (typeof social === 'object' && social.mediaKey && social.link && social.link.trim() !== '') ||
    //     (social.key && social.label && social.link && social.link.trim() !== '')
    //   );
    // });

    // if (!hasAtLeastOneLink) {
    //   errors.socialMedia = 'At least one social media link is required';
    // }
    setError(errors);

    return Object.values(errors).every((field) => field === '');
  };

  const handlePortfoliosSocialData = () => {
    const isValid = validateForm();

    if (!isValid) {
      return;
    } else {
      const filterEmptyLinks = (socialMedia: any) => socialMedia.filter((item: any) => item.link.trim() !== '');

      const filteredSocialMedia = filterEmptyLinks(portfolios.socialMedia);

      const changesDetected =
        (existingData && existingData.websiteURL !== portfolios.websiteURL) ||
        existingData.portfolio.length !== portfolioImages?.flat().length ||
        (existingData.socialMedia &&
          JSON.stringify(filterEmptyLinks(existingData.socialMedia).map((item: any) => item.link)) !==
            JSON.stringify(filteredSocialMedia.map((item: any) => item.link)));

      if (changesDetected) {
        const modifiedSocialMedia = portfolios.socialMedia
          .filter((item: any) => item.link.trim() !== '')
          .map((item: any) => {
            if (!item.mediaKey && item.label && item.key) {
              return { mediaKey: item.key, link: item.link };
            }
            return item;
          });
        // const postData = Env.post(
        //   `user/register`,
        //   {
        //     ...portfolios,
        //     socialMedia: modifiedSocialMedia,
        //   },
        //   userAccessToken
        // );

        const postData = privateAxios.post(
          `user/register`,
          {
            ...portfolios,
            socialMedia: modifiedSocialMedia,
            portfolio: portfolioImages?.flat(),
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        postData
          .then((response: any) => {
            if (response.status === 200) {
              setToast({ message: response.data.message.value, type: 'success' });
              handleClick();
              setTimeout(() => handleNext(), 2000);
            }
          })
          .catch((error: any) => {
            console.error(error);
            setToast({ message: error.response.data.message, type: 'error' });
            handleClick();
          });
      } else if (portfolios.socialMedia === null || portfolios.portfolio === null) {
        const modifiedSocialMedia = portfolios.socialMedia
          .filter((item: any) => item.link.trim() !== '')
          .map((item: any) => {
            if (!item.mediaKey && item.label && item.key) {
              return { mediaKey: item.key, link: item.link };
            }
            return item;
          });
        // const postData = Env.post(
        //   `user/register`,
        //   {
        //     ...portfolios,
        //     socialMedia: modifiedSocialMedia,
        //   },
        //   userAccessToken
        // );

        const postData = privateAxios.post(
          `user/register`,
          {
            ...portfolios,
            socialMedia: modifiedSocialMedia,
            portfolio: portfolioImages?.flat(),
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        postData
          .then((response: any) => {
            if (response.status === 200) {
              setToast({ message: response.data.message, type: 'success' });
              handleClick();
              setTimeout(() => handleNext(), 2000);
            }
          })
          .catch((error: any) => {
            console.error(error);
            setToast({ message: error.response.data.message.value, type: 'error' });
            handleClick();
          });
      } else {
        handleNext();
        return;
      }
    }
  };

  const handleLinkChange = (key: any, value: any) => {
    setSocialMediaValues((prevValues: any) =>
      prevValues.map((social: any) =>
        social.key === key
          ? {
              ...social,
              link: value
                .replace(/^\s+/, '')
                .replace(/\s+/g, ' ')
                .replace(/^[^a-zA-Z@.]+/, ''),
            }
          : social
      )
    );

    setPortfolios((prevValues: any) => {
      let updatedSocialMedia = prevValues.socialMedia.map((social: any) =>
        social.mediaKey === key
          ? {
              ...social,
              link: value
                .replace(/^\s+/, '')
                .replace(/\s+/g, ' ')
                .replace(/^[^a-zA-Z@.]+/, ''),
            }
          : social
      );

      updatedSocialMedia = updatedSocialMedia.filter((social: any) => social.link.trim() !== '');

      if (!prevValues.socialMedia.some((social: any) => social.mediaKey === key)) {
        updatedSocialMedia.push({
          mediaKey: key,
          link: value
            .replace(/^\s+/, '')
            .replace(/\s+/g, ' ')
            .replace(/^[^a-zA-Z@.]+/, ''),
        });
      }

      const emptyMediaKeys: any = new Set(
        updatedSocialMedia.filter((item: any) => item.mediaKey && item.link === '').map((item: any) => item.mediaKey)
      );

      const filteredData = updatedSocialMedia.filter(
        (item: any) => !emptyMediaKeys.has(item.key) && !(item.mediaKey && item.link === '')
      );

      return {
        ...prevValues,
        socialMedia: filteredData,
      };
    });

    setError((preError: any) => ({
      ...preError,
      socialMedia: '',
    }));
  };

  const handleWebsiteValue = (e: any) => {
    setPortfolios((prevPortfolio) => ({
      ...prevPortfolio,
      websiteURL: e.target.value
        .replace(/^\s+/, '')
        .replace(/\s+/g, ' ')
        .replace(/^[^a-zA-Z@.]+/, ''),
    }));
    // setError((pre: any) => ({
    //   ...pre,
    //   websiteURL: '',
    // }));
  };

  const uploadAllImage = (e: any, type: any) => {
    if (type == 'Portfolio_image') {
      document.getElementById(`icon_profile_icon`)?.click();
    }
  };

  const handleDrop = (e: any, type: any) => {
    e.preventDefault();

    // const files: File[] = e.target.files;
    const files: File[] = e.dataTransfer.files;

    if (files?.length === 0) return;

    if (files?.length > 6) {
      setToast({ message: 'You can only select up to 6 images', type: 'error' });
      handleClick();
      return;
    }

    Array.from(files).forEach((file: File) => {
      const reader = new FileReader();
      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        setToast({ message: 'Unsupported File type ', type: 'error' });
        handleClick();
        return;
      }

      reader.onload = async (event: any) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          // if (image.width > 500 || image.height > 500) {
          //   setToast({ message: 'Image dimensions are large', type: 'error' });
          //   handleClick();
          //   return;
          // }

          if (type === 'portfolioImage') {
            if (portfolioImages?.length < 6) {
              const formData = new FormData();
              formData.append('files', file);

              const responsesssss = privateAxios.post(`user/portfolioupload`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
              responsesssss
                .then((response: any) => {
                  if (response.status === 200) {
                    setPortfolioImages((prevImages) => [...prevImages, response.data.data]);
                    // setPortfolios((prevPortfolios: any) => ({
                    //   ...prevPortfolios,
                    //   portfolio: prevPortfolios.portfolio.concat(response.data.data).flat(),
                    // }));
                    setToast({ message: response.data.message.value, type: 'success' });
                    handleClick();
                    setError((pre: any) => ({
                      ...pre,
                      portfolio: '',
                    }));
                  }
                })
                .catch((error: any) => {
                  console.error(error);
                  setToast({ message: error.data.message.value, type: 'error' });
                  handleClick();
                });
            } else {
              setToast({ message: 'You can upload up to 6 images only', type: 'error' });
              handleClick();
            }
          }
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleFileUpload = async (e: any, type: any) => {
    const files: File[] = e.target.files;

    if (files.length === 0) return;

    if (files.length > 6) {
      setToast({ message: 'You can only select up to 6 images', type: 'error' });
      handleClick();
      return;
    }

    Array.from(files).forEach((file: File) => {
      const reader = new FileReader();
      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        setToast({ message: 'Unsupported File type ', type: 'error' });
        handleClick();
        return;
      }

      reader.onload = async (event: any) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          // if (image.width > 500 || image.height > 500) {
          //   setToast({ message: 'Image dimensions are large', type: 'error' });
          //   handleClick();
          //   return;
          // }

          if (type === 'portfolioImage') {
            // if (portfolios.portfolio.length < 6) {
            if (portfolioImages?.length < 6) {
              const formData = new FormData();
              formData.append('files', file);

              const responsesssss = privateAxios.post(`user/portfolioupload`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
              responsesssss
                .then((response: any) => {
                  if (response.status === 200) {
                    setPortfolioImages((prevImages) => [...prevImages, response.data.data]);
                    // setPortfolios((prevPortfolios: any) => ({
                    //   ...prevPortfolios,
                    //   portfolio: prevPortfolios.portfolio.concat(response.data.data).flat(),
                    // }));
                    setToast({ message: response.data.message.value, type: 'success' });
                    handleClick();
                    setError((pre: any) => ({
                      ...pre,
                      portfolio: '',
                    }));
                  }
                })
                .catch((error: any) => {
                  console.error(error);
                  setToast({ message: error.data.message.value, type: 'error' });
                  handleClick();
                });
            } else {
              setToast({ message: 'You can upload up to 6 images only', type: 'error' });
              handleClick();
            }
          }
        };
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    console.log(portfolioImages);
  }, [portfolioImages]);

  const handleImageDelete = (url: any) => {
    setPortfolios((ps) => {
      const newPortfolio = ps.portfolio.filter((item) => item !== url);
      return { ...ps, portfolio: newPortfolio };
    });
    setPortfolioImages((ps) => {
      const newImageArray = ps.filter((item) => item !== url);
      return newImageArray;
    });

    const deleteImage = privateAxios.delete(`user/deleteportfolio?imagePath=${url}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    deleteImage
      .then((res: any) => {
        setToast({ message: res.data.message.value, type: 'success' });

        handleClick();
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  return (
    <>
      <Typography
        sx={{
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '38px',
          paddingTop: '30px',
        }}>
        Portfolios and Social
      </Typography>
      <Box>
        <Typography
          className="lable-name"
          sx={{
            textAlign: 'left',
            mt: '15px',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          }}>
          Portfolio images<span className="mandatoryFields">*</span> (Up to 6 images are allowed)
        </Typography>
        <Grid container spacing={1} sx={{ paddingRight: '55px' }}>
          {portfolioImages &&
            portfolioImages?.length > 0 &&
            portfolioImages
              .slice(0, 6)
              .map((imageUrl, index) => (
                <PortfolioImage key={index} imageUrl={imageUrl} handleImageDelete={handleImageDelete} />
              ))}
        </Grid>

        <Box sx={{ mt: '12px' }}>
          <Box
            className="p-image imageBox"
            sx={{
              height: '105px',
              textAlign: 'center',
              width: '845px',
              pt: '25px',
            }}
            onClick={(e) => uploadAllImage(e, 'Portfolio_image')}
            onDrop={(e) => handleDrop(e, 'portfolioImage')}
            onDragOver={(e) => handleDragOver(e)}>
            <>
              <img src={uploadCloud} alt="img" className="img-fluid upload-button" />
              <input
                className="file-upload"
                id="icon_profile_icon"
                type="file"
                accept="image/*"
                onChange={(e) => handleFileUpload(e, 'portfolioImage')}
                multiple
              />
            </>
            <Typography
              className="note"
              sx={{
                color: 'var(--Primary, #2CB512)',
                textAlign: 'center',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '18px',
                fontFamily: "'Onest', sans-serif !important",
              }}>
              Drag & Drop, or Click to upload
            </Typography>
            <Typography
              sx={{
                color: 'var(--gray-400, #98A2B3)',
                textAlign: 'center',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '18px',
                fontFamily: "'Onest', sans-serif !important",
              }}>
              JPG, PNG Format
            </Typography>
          </Box>
        </Box>
        {Error.portfolio && <Typography sx={{ color: 'red', mt: 1 }}>{Error.portfolio}</Typography>}
        <Grid>
          <Grid item sm={12}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '15px',
              }}>
              Portfolio Website
            </Typography>

            {portfolios.websiteURL !== undefined && (
              <TextField
                className="formFields"
                sx={{ mt: 0, width: '850px' }}
                type="text"
                name="websiteURL"
                value={portfolios.websiteURL === null ? '' : portfolios.websiteURL}
                onChange={handleWebsiteValue}
                placeholder="Portfolio URL..."
                required
              />
            )}
          </Grid>
          {/* {Error.websiteURL && <Typography sx={{ color: 'red', mt: 1 }}>{Error.websiteURL}</Typography>} */}
        </Grid>
        <Grid>
          <Typography
            className="lable-name"
            sx={{
              textAlign: 'left',
              mt: '15px',
            }}>
            Social Media
          </Typography>

          {socialLinks.map((socialMedia: SocialMedialProps) => {
            const foundSocialMedia = socialMediaValues.find((item: any) => item?.key === socialMedia.key) || { link: '' };

            const linkValue = foundSocialMedia.link ?? '';

            return (
              <TextField
                key={socialMedia.key}
                className="formFields"
                sx={{ mt: 2, width: '850px' }}
                type="text"
                name={socialMedia.key}
                placeholder={`Add ${socialMedia.label} link`}
                value={linkValue !== undefined ? (linkValue === '' ? socialMedia.link : linkValue || '') : ''}
                onChange={(e: any) =>
                  handleLinkChange(
                    socialMedia.key,
                    e.target.value
                      .replace(/^\s+/, '')
                      .replace(/\s+/g, ' ')
                      .replace(/^[^a-zA-Z@.]+/, '')
                  )
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={socialMedia.logoPath === undefined ? '' : socialMedia.logoPath}
                        alt={socialMedia.label}
                        style={{ width: '24px', height: '24px' }}
                      />
                    </InputAdornment>
                  ),
                }}
                required
              />
            );
          })}
        </Grid>
        {/* {Error.socialMedia && <Typography sx={{ color: 'red', mt: 1 }}>{Error.socialMedia}</Typography>} */}
      </Box>
      <Box sx={{ padding: '50px', paddingTop: '40px' }}>
        <Box sx={{ textAlign: 'center' }}>
          {activeStep === 1 ? (
            ''
          ) : (
            <Button
              className="common-button-white"
              type="submit"
              variant="contained"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                marginRight: 1,
                mt: 3,
                mb: 2,
                '&:hover': {
                  color: 'var(--Primary, #2CB512)',
                  backgroundColor: '#fff',
                },
                alignItems: 'center',
              }}>
              Back
            </Button>
          )}

          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handlePortfoliosSocialData}
            disabled={activeStep === 10}
            sx={{
              marginLeft: 1,
              mt: 3,
              mb: 2,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save and Next
          </Button>
        </Box>
        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Box>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(PortfoliosSocial);

/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import '../../../Assets/css/HomeScreen.css';
import '../../../Assets/css/Userfeed.css';
import '../../../Assets/css/responsive.css';
import '../../../Assets/css/Roster.css';
import {
  Button,
  Chip,
  Backdrop,
  CircularProgress,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  IconButton,
  Menu,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import Sidebar from '../SideNavBar';
import CustomSnackbar from '../../../common/Snackbar';
import Navbar from '../Navbar';
import { UserInfo, filterData } from '../../Interface/UserInterface/IUser';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CardDisplay from '../CardDisplay';
import AddListDialog from '../AddListDialog';
import CreateListDialog from '../CreateListDialog';
import InviteMember from './InviteMember';
import { useNavigate } from 'react-router-dom';
// import EditIcon from '@mui/icons-material/Edit';
import EditListDialog from './EditListDialog';
import io from 'socket.io-client';

import {
  ExpandMore as ExpandMoreIcon,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import BookingRequestDialog from '../Feed/BookingRequestDialogProps';
import { BulkHireDialog } from '../Feed/BulkHireDialog';
import { MessageDialog } from '../MessageDialog';
import { connect } from 'react-redux';

const UserRoster: React.FC = (props: any) => {
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();

  const [searchValues, setSearchValues] = useState<string>('');
  const [rosterCardData, setRosterCardData] = useState<any>(null);
  const [openInvite, setOpenInvite] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);
  const [skillsData, setSkillsData] = useState<any[]>([]); // Adjust the type based on your data structure
  const [toolsData, setToolsData] = useState<any[]>([]);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [getListDatas, setListDatas] = useState([]);
  const [isNewListDialogOpen, setNewListDialogOpen] = useState(false);
  const [isEditListDialogOpen, setEditListDialogOpen] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: '', type: 'success' });
  const [filterStructure, setFilterStructure] = useState<filterData>({
    categoryKey: '',
    categoryskillsKey: '',
    categorytoolsKey: '',
    toolsKey: [],
    skillsKey: [],
    country: '',
    state: '',
    language: '',
    skillLabels: [],
    toolLabels: [],
    categoryLabels: [],
  });
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('all');
  const [openMenu, setOpenMenu] = useState(false); // State to manage the dropdown open/close state
  const [keyValue, setKeyValue] = useState('');
  const [openDeleteList, setOpenDeleteList] = useState({ open: false, label: '', listKey: '' });
  const [refresh, setRefresh] = useState(false);
  const [selectCard, setSelectCard] = useState(false);
  const [allCards, setAllCards] = useState<any[]>([]);
  const [selectArtist, setSelectArtist] = useState<any[]>([]);
  const [openList, setOpenList] = useState(false);

  const [bookingRequestData, setBookingRequestData] = useState<any>(null);
  const [isBookingRequestDialogOpen, setBookingRequestDialogOpen] = useState(false);
  const [openBulkDialog, setOpenBulkDialog] = useState(false);
  const [bulkStatus, setBulkStatus] = useState({});
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [resetMessage, setResetMessage] = useState(false);
  const [socket, setSocket] = useState<any | null>(null);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SOCKET_API_URL);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleToggleMenuChange = () => {
    setOpenMenu(!openMenu); // Toggle the open state when the arrow button is clicked
  };

  useEffect(() => {
    userRoster();
    getList();
  }, [searchValues, filterStructure, selectedValue]);

  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);

  const handleSubmenuOpen = (event: any) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const handleSubmenuClose = () => {
    setSubmenuAnchorEl(null);
  };

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const handleSearchValuesChange = (newSearchValues: string) => {
    setSearchValues(newSearchValues);
  };

  const { skillLabels, toolLabels, categoryLabels, ...filterData } = filterStructure || {};

  const userRoster = () => {
    setLoading(true);
    const feedData = privateAxios.post(
      `dashboard/getroster?search=${searchValues}&listKey=${selectedValue === 'all' ? '' : selectedValue}`,
      filterData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    feedData
      .then((response: any) => {
        if (response.status === 200) {
          setRosterCardData(response.data.data);
          setLoading(false);
          // setToast({ message: response.data.message.value, type: 'success' });
          // handleClick();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
        setLoading(false);
      });
  };

  const handleEyeIconClick = (user: any) => {
    setSelectedUser(user);
    navigate('/user-details', { state: { showUser: true, id: user.id } });
  };

  const handleListIconClick = (user: any) => {
    setSelectedUser(user);
    // getList();
    // setRefresh(true);
  };

  const handleCategoryChipDelete = (category: string) => {
    setFilterStructure((prevFilterStructure: any) => {
      const updatedCategories = prevFilterStructure.categoryLabels?.filter(
        (prevCategory: string) => prevCategory !== category
      );

      const updatedCategoryKeys = categoryData
        ?.filter((cat: any) => updatedCategories?.includes(cat.label))
        .map((cat: any) => cat.key);

      const newFilterStructure = {
        ...prevFilterStructure,
        categoryLabels: updatedCategories,
        categoryKey: updatedCategoryKeys,
      };
      updateFilterStructure(newFilterStructure);

      return newFilterStructure;
    });
  };

  const handleSkillChipDelete = (skill: string) => {
    setFilterStructure((prevFilterStructure: any) => {
      const updatedSkills = prevFilterStructure.skillLabels.filter((prevSkill: string) => prevSkill !== skill);

      const updatedSkillsKey = skillsData
        .flatMap((category: any) => category.skills)
        .filter((s: any) => updatedSkills.includes(s.label))
        .map((s: any) => s.key);

      const updatedCategories = skillsData
        .filter((category: any) => category.skills.some((s: any) => updatedSkills.includes(s.label)))
        .map((category: any) => category.category.key);

      const newFilterStructure = {
        ...prevFilterStructure,
        skillLabels: updatedSkills,
        skillsKey: updatedSkillsKey,
        categoryskillsKey: updatedCategories,
      };

      updateFilterStructure(newFilterStructure);

      return newFilterStructure;
    });
  };

  const handleToolChipDelete = (tool: string) => {
    setFilterStructure((prevFilterStructure: any) => {
      const updatedTools = prevFilterStructure.toolLabels?.filter((prevTool: string) => prevTool !== tool);

      const updatedToolsKeys = toolsData?.flatMap((category: any) =>
        category.tools.filter((tool: any) => updatedTools?.includes(tool.label)).map((tool: any) => tool.key)
      );

      const updatedCategories = toolsData
        ?.filter(
          (category: any) =>
            category.tools.some((tool: any) => updatedTools?.includes(tool.label)) && category.tools.length > 0
        )
        .map((category: any) => category.category.key);

      const finalCategories = updatedCategories?.filter((categoryKey: string) =>
        toolsData
          ?.find((category: any) => category.category.key === categoryKey)
          ?.tools.some((tool: any) => updatedTools?.some((updatedTool: any) => updatedTool === tool.label))
      );

      const newFilterStructure = {
        ...prevFilterStructure,
        toolLabels: updatedTools,
        toolsKey: updatedToolsKeys,
        categorytoolsKey: finalCategories,
      };
      updateFilterStructure(newFilterStructure);

      return newFilterStructure;
    });
  };

  const handleLocationChipDelete = (label: string) => {
    const newFilterStructure = { ...filterStructure };

    switch (label) {
      case 'country':
        newFilterStructure.country = '';
        break;
      case 'state':
        newFilterStructure.state = '';
        break;
      case 'language':
        newFilterStructure.language = '';
        break;

      default:
        break;
    }

    updateFilterStructure(newFilterStructure);
  };

  const hasFilters = (filterStructure: {
    categoryLabels: string | any[];
    skillLabels: string | any[];
    toolLabels: string | any[];
    country: any;
    state: any;
    language: any;
  }) => {
    return (
      filterStructure?.categoryLabels?.length > 0 ||
      filterStructure?.skillLabels?.length > 0 ||
      filterStructure?.toolLabels?.length > 0 ||
      filterStructure?.country ||
      filterStructure?.state ||
      filterStructure?.language
    );
  };

  const updateFilterStructure = (newStructure: any) => {
    setFilterStructure(newStructure);
  };

  const handleSkillsDataChange = (newSkillsData: any[]) => {
    setSkillsData(newSkillsData);
  };

  const handleToolsDataChange = (newToolsData: any[]) => {
    setToolsData(newToolsData);
  };

  const handleCategoryChange = (newCategoryData: any[]) => {
    setCategoryData(newCategoryData);
  };

  const getList = () => {
    const getListData = privateAxios.get(`dashboard/getlist`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getListData
      .then((response: any) => {
        if (response.status === 200) {
          setListDatas(response.data.data || []);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddNewListClick = () => {
    setNewListDialogOpen(true);
  };

  const handleCloseNewListDialog = () => {
    setNewListDialogOpen(false);
    getList();
    setRefresh(true);
  };

  const handleCloseEditistDialog = () => {
    setEditListDialogOpen(false);
    setSubmenuAnchorEl(null);
    getList();
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
    setRefresh(false);
  };

  const addArtistList = (newValue: any) => {
    const addArtist = {
      listKey: newValue,
      rosterId: selectedUser === null ? selectArtist.map((artist) => artist.id) : selectedUser?.id,
      // rosterId: selectedUser === null ? selectArtist[0]?.id : selectedUser?.id,
    };

    const addArtistToList = privateAxios.post(`dashboard/addlist`, addArtist, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    addArtistToList
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          handleCloseDialog();
          userRoster();
          if (selectedUser === null) {
            handleCloseEditDialog();
            setSelectCard(false), setSelectArtist([]), userRoster();
            setRefresh(false);
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const updateArtistList = (newValue: any) => {
    const addArtist = {
      listKey: newValue,
      rosterIds: selectArtist.map((artist) => artist.id),
      // rosterId: selectedUser === null ? selectArtist[0]?.id : selectedUser?.id,
    };

    const addArtistToList = privateAxios.post(`dashboard/addmultilist`, addArtist, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    addArtistToList
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          handleCloseDialog();
          userRoster();
          if (selectedUser === null) {
            handleCloseEditDialog();
            setSelectCard(false), setSelectArtist([]), userRoster();
            setRefresh(false);
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };
  const handleInvite = () => {
    setOpenInvite(true);
  };

  const handleCloseInvite = () => {
    setOpenInvite(false);
  };

  const handleEditItemClick = (item: any) => {
    setSelectedValue('all');
    setEditListDialogOpen(true);
    setEditValue(item);
    setSubmenuAnchorEl(null);
  };

  const handleDeleteItemClick = (item: any) => {
    setOpenDeleteList({ open: true, label: item.label, listKey: item.key });
    setSubmenuAnchorEl(null);
  };

  const handleEditIconClick = (event: any, item: any) => {
    event.stopPropagation();
    setSelectedValue('all');
    setKeyValue(item);

    handleSubmenuOpen(event); // Open the submenu
  };

  const deleteList = () => {
    const value = openDeleteList?.listKey;
    const deleteListData = privateAxios.delete(`dashboard/deletelist?listKey=${value}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    deleteListData
      .then((response: any) => {
        if (response.status === 200) {
          setOpenDeleteList({ open: false, label: '', listKey: '' });
          setSubmenuAnchorEl(null);
          getList();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const renderValue = (selected: any) => {
    if (!selected) {
      return '';
    }

    const selectedItem: any = getListDatas.find((item: any) => item.key === selected);
    return selectedItem ? selectedItem?.label : '';
  };

  const handleSelectArtist = () => {
    let allCards: any[];
    setSelectCard(true);

    // const feedData = privateAxios.post(`dashboard/getroster?withoutList=true`, filterData, {
    const feedData = privateAxios.post(
      `dashboard/getroster?listKey=${selectedValue === 'all' ? '' : selectedValue}`,
      filterData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    feedData
      .then((response: any) => {
        if (response.status === 200) {
          setRosterCardData(response.data.data);
          setLoading(false);
          // allCards = response.data.data;
          // setAllCards(allCards);
          setAllCards(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
        setLoading(false);
      });
  };

  const handleCardClick = (emailId: string, id: number) => {
    const updatedSelectedCards = [...selectArtist];
    const index = updatedSelectedCards.findIndex((card) => card.email === emailId);

    if (index === -1) {
      updatedSelectedCards.push({ email: emailId, id: id });
    } else {
      updatedSelectedCards.splice(index, 1);
    }

    setSelectArtist(updatedSelectedCards);
  };

  const handleRemoveRoster = () => {
    const emails = {
      email: selectArtist.map((artist) => artist.email),
    };

    const removeRoster = privateAxios.post(`dashboard/removeroaster`, emails, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    removeRoster
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          setSelectArtist([]);
          setSelectCard(false);
          userRoster();
          handleClick();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const closeDeleteDialog = () => {
    setOpenDeleteList((preData: any) => ({ ...preData, open: false }));
  };

  const editList = () => {
    setOpenList(true);
  };

  const handleCloseEditDialog = () => {
    setRefresh(false);
    setOpenList(false);
  };

  const handleSendBookingRequest = (data: any) => {
    // console.log(data,"{}{}{}{}{}");

    setBookingRequestData(data);
    setBookingRequestDialogOpen(true);
  };

  const handleBookingRequestDialogClose = () => {
    setBookingRequestDialogOpen(false);
    setSelectArtist([]);
    setSelectCard(false);
  };

  const handleSendBookingRequestDialog = (sendData: any) => {
    const { bookingId, ...restData } = sendData;

    if (sendData.bookingIds.length > 0) {
      const sendHiringRequest = privateAxios.post(`dashboard/bulkhire`, restData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      sendHiringRequest
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            // setBookingRequestDialogOpen(false);
            // setSelectCard(false);
            setOpenBulkDialog(true);
            setBulkStatus(response.data.data);
            setSelectArtist([]);
            handleClick();
            return;
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
          return;
        });
    } else {
      const { bookingIds, ...singleHire } = sendData;
      const sendHireRequest = privateAxios.post(`dashboard/hire`, singleHire, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      sendHireRequest
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            setSelectCard(false);
            setBookingRequestDialogOpen(false);
            setSelectArtist([]);
            handleClick();
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    }
  };

  const closeBulkStatus = () => {
    setOpenBulkDialog(false);
    setBookingRequestDialogOpen(false);
    setSelectCard(false);
  };

  const handleMessage = () => {
    setOpenMessageDialog(true);
  };
  const handleMessageClose = () => {
    setOpenMessageDialog(false);
  };

  const handleBulkMessageSubmit = (message: string) => {
    const response = privateAxios.post(
      `chat/bulkchat`,
      JSON.stringify({
        receiverIds: selectArtist.map((artist) => artist.id),
        message: message,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    response
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          setResetMessage(true);
          setOpenMessageDialog(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });

    if (socket) {
      const messageData = {
        content: message,
        sender_id: props.id,
        recipientIds: selectArtist.map((artist) => artist.id),
      };
      socket.emit('sendMessage', messageData);
    } else {
      console.error('Socket is not available.');
    }
  };

  return (
    <>
      <Navbar onSearchValuesChange={handleSearchValuesChange} />
      <Grid className="talentBody" sx={{ backgroundColor: '#f7f8f8' }} container>
        <Grid item sm={1}>
          <Sidebar
            filterStructure={filterStructure}
            updateFilterStructure={updateFilterStructure}
            onChangeSkillData={handleSkillsDataChange}
            onChangeToolData={handleToolsDataChange}
            onChangeCategoryData={handleCategoryChange}
          />
        </Grid>

        {selectCard ? (
          <Grid item sm={11} sx={{ backgroundColor: '#f7f8f8', mt: '80px' }}>
            <div className="right-dash">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginBottom: '10px', backgroundColor: '#fff', padding: '16px', borderRadius: '12px' }}>
                <Grid>
                  <Typography variant="h6" gutterBottom sx={{ fontFamily: "'Onest', sans-serif !important" }}>
                    {`${selectArtist.length} Artist Selected`}
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    className="common-button-green"
                    sx={{
                      width: '200px',
                      // m: '0px 15px',
                      opacity: selectArtist.length === 0 ? 0.5 : '',
                      color: selectArtist.length === 0 ? '#000 !important' : '#fff !important',
                      backgroundColor: selectArtist.length === 0 ? '#667085 !important' : '#2cb512 !important',
                    }}
                    disabled={selectArtist.length === 0}
                    onClick={() => handleSendBookingRequest(selectArtist)}>
                    Send booking request
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="common-button-green"
                    sx={{
                      ml: '15px',
                      opacity: selectArtist.length === 0 ? 0.5 : '',
                      color: selectArtist.length === 0 ? '#000 !important' : '#fff !important',
                      backgroundColor: selectArtist.length === 0 ? '#667085 !important' : '#2cb512 !important',
                    }}
                    disabled={selectArtist.length === 0}
                    onClick={handleMessage}>
                    Message
                  </Button>

                  {selectedValue !== 'all' && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="common-button-green"
                      // sx={{ m: '0px 10px' }}
                      sx={{
                        ml: '15px',
                        opacity: selectArtist.length === 0 ? 0.5 : '',
                        color: selectArtist.length === 0 ? '#000 !important' : '#fff !important',
                        backgroundColor: selectArtist.length === 0 ? '#667085 !important' : '#2cb512 !important',
                      }}
                      disabled={selectArtist.length === 0}
                      onClick={editList}>
                      Edit List
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    className="common-button-green"
                    sx={{
                      width: '200px',
                      m: '0px 15px',
                      opacity: selectArtist.length === 0 ? 0.5 : '',
                      color: selectArtist.length === 0 ? '#000 !important' : '#fff !important',
                      backgroundColor: selectArtist.length === 0 ? '#667085 !important' : '#2cb512 !important',
                    }}
                    disabled={selectArtist.length === 0}
                    onClick={handleRemoveRoster}>
                    Remove from Roster
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="common-button-white"
                    onClick={() => {
                      setSelectCard(false), setSelectArtist([]), userRoster();
                    }}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
              <Grid>
                <CardDisplay
                  cardData={allCards}
                  eyeIcon={handleEyeIconClick}
                  listIcon={handleListIconClick}
                  selectedArtist={selectArtist}
                  onCardClick={handleCardClick}
                  type="Roster"
                />
                <AddListDialog
                  openList={openList}
                  // selectedUser={selectedUser}
                  handleCloseDialog={handleCloseEditDialog}
                  selectedListVal={selectedValue}
                  // receiverId={receiverId}
                  addArtistList={updateArtistList}
                  handleAddNewListClick={handleAddNewListClick}
                  refresh={refresh}
                />
              </Grid>
            </div>
            <BookingRequestDialog
              open={isBookingRequestDialogOpen}
              onCloseDialog={handleBookingRequestDialogClose}
              onSendBookingRequest={handleSendBookingRequestDialog}
              data={bookingRequestData}
              selectedArtist={selectArtist}
            />
            <BulkHireDialog openBulkDialog={openBulkDialog} onCloseDialog={closeBulkStatus} bulkStatus={bulkStatus} />
            <MessageDialog
              openMessageDialog={openMessageDialog}
              handleClose={handleMessageClose}
              user={null}
              handleMessageSubmit={handleBulkMessageSubmit}
              resetMessage={resetMessage}
            />
          </Grid>
        ) : searchValues !== '' ||
          filterStructure.categoryLabels.length > 0 ||
          filterStructure.toolLabels.length > 0 ||
          filterStructure.skillLabels.length > 0 ||
          filterStructure.language !== '' ||
          filterStructure.country !== '' ||
          filterStructure.state !== '' ? (
          <>
            <Grid item sm={11} sx={{ backgroundColor: '#f7f8f8', mt: '100px' }}>
              <div>
                <div className="right-dash">
                  <div className="col-md-12">
                    <Typography component={'span'} sx={{ mr: '15px', fontFamily: "'Onest', sans-serif" }}>
                      <span style={{ fontWeight: 600, fontSize: '34px' }}>Your Results</span>
                    </Typography>
                    {filterStructure && hasFilters(filterStructure) ? (
                      <>
                        <div>
                          {filterStructure?.categoryLabels?.map((category: any, index: number) => (
                            <Chip
                              key={index}
                              label={category}
                              onDelete={() => handleCategoryChipDelete(category)}
                              sx={{ marginRight: '8px', marginBottom: '8px', fontFamily: "'Onest', sans-serif !important" }}
                            />
                          ))}
                          {filterStructure?.skillLabels?.map((skill: any, index: number) => (
                            <Chip
                              key={index}
                              label={skill}
                              onDelete={() => handleSkillChipDelete(skill)}
                              sx={{ marginRight: '8px', marginBottom: '8px', fontFamily: "'Onest', sans-serif !important" }}
                            />
                          ))}
                          {filterStructure.toolLabels?.map((tool: any, index: number) => (
                            <Chip
                              key={index}
                              label={tool}
                              onDelete={() => handleToolChipDelete(tool)}
                              sx={{ marginRight: '8px', marginBottom: '8px', fontFamily: "'Onest', sans-serif !important" }}
                            />
                          ))}
                          {filterStructure?.country && (
                            <Chip
                              label={filterStructure.country}
                              onDelete={() => handleLocationChipDelete('country')}
                              sx={{ marginRight: '8px', marginBottom: '8px', fontFamily: "'Onest', sans-serif !important" }}
                            />
                          )}
                          {filterStructure?.state && (
                            <Chip
                              label={filterStructure.state}
                              onDelete={() => handleLocationChipDelete('state')}
                              sx={{ marginRight: '8px', marginBottom: '8px', fontFamily: "'Onest', sans-serif !important" }}
                            />
                          )}
                          {filterStructure?.language && (
                            <Chip
                              label={filterStructure.language}
                              onDelete={() => handleLocationChipDelete('language')}
                              sx={{ marginRight: '8px', marginBottom: '8px', fontFamily: "'Onest', sans-serif !important" }}
                            />
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <Typography className="dash-title" sx={{ fontFamily: "'Onest', sans-serif !important" }}>
                          <span style={{ fontWeight: 600, fontSize: '24px' }}>My Rosters</span>
                        </Typography>
                      </>
                    )}
                  </div>
                  {loading && (
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={loading}
                      onClick={() => setLoading(false)}>
                      <CircularProgress color="success" />
                    </Backdrop>
                  )}
                  {!loading && (
                    <CardDisplay
                      cardData={rosterCardData}
                      eyeIcon={handleEyeIconClick}
                      listIcon={handleListIconClick}
                      type="Roster"
                    />
                  )}
                </div>
                <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid item sm={11} sx={{ backgroundColor: '#f7f8f8', mt: '100px' }}>
              <div>
                <div className="right-dash">
                  <Grid container>
                    <Grid
                      item
                      sm={9.3}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff',
                        padding: '15px',
                        borderRadius: '12px',
                      }}>
                      <Typography
                        className="dash-title"
                        sx={{ fontFamily: "'Onest', sans-serif !important", mt: '8px', justifyContent: 'center' }}>
                        <span style={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px', color: '#424866' }}>
                          Let’s invite new artists to your list
                        </span>
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        className="common-button-green"
                        sx={{ marginLeft: '8px' }}
                        onClick={handleInvite}>
                        Invite
                      </Button>
                    </Grid>
                    <Grid
                      item
                      sm={2.5}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff',
                        borderRadius: '12px',
                        borderColor: 'transparent  !important',
                      }}
                      onClick={handleToggleMenuChange}>
                      <Select
                        open={openMenu}
                        onClose={() => setOpenMenu(false)}
                        onOpen={() => setOpenMenu(true)}
                        IconComponent={() => (
                          <Grid
                            sx={{
                              border: '1px solid #2CB512',
                              backgroundColor: '#fff',
                              width: '60px',
                              height: '50px',
                              borderRadius: '12px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              position: 'absolute',
                              right: '20px',
                              cursor: 'pointer',
                            }}
                            onClick={handleToggleMenuChange}>
                            {!openMenu ? (
                              <ExpandMoreIcon sx={{ color: '#2CB512' }} />
                            ) : (
                              <ExpandLessIcon sx={{ color: '#2CB512' }} />
                            )}
                          </Grid>
                        )}
                        sx={{
                          width: '100%',
                          fontFamily: "'Onest', sans-serif !important",
                          border: 'transparent !important',
                          '& .MuiSelect-select': {
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            border: 'none !important',
                            cursor: 'pointer',
                            '&:hover': {
                              border: 'none !important',
                            },
                          },
                        }}
                        input={<OutlinedInput label="" />}
                        className="custom-select"
                        value={selectedValue}
                        onChange={handleChange}
                        onClick={handleToggleMenuChange}>
                        <MenuItem value="all">All</MenuItem>
                        {/* <MenuItem value="recently">Recently Imported</MenuItem> */}
                        {Array.isArray(getListDatas) && getListDatas.length > 0 ? (
                          getListDatas.map((item: any) => (
                            <MenuItem key={item.key} value={item.key}>
                              <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item xs={11}>
                                  {item.label}
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    size="small"
                                    onClick={(event: any) => handleEditIconClick(event, item)}
                                    sx={{ marginLeft: 'auto', color: '#2CB512' }}>
                                    <MoreVertIcon />
                                  </IconButton>
                                </Grid>
                                {/* )} */}
                              </Grid>
                              <Menu
                                anchorEl={submenuAnchorEl}
                                open={Boolean(submenuAnchorEl)}
                                onClose={handleSubmenuClose}
                                sx={{ borderRadius: '5px' }}>
                                <MenuItem onClick={() => handleEditItemClick(keyValue)} sx={{ pl: '6px' }}>
                                  <EditIcon sx={{ pr: '4px', color: '#2CB512' }} />
                                  <span style={{ fontFamily: "'Onest', sans-serif" }}>Edit List Name</span>
                                </MenuItem>
                                <MenuItem onClick={() => handleDeleteItemClick(keyValue)} sx={{ pl: '6px' }}>
                                  <DeleteIcon sx={{ pr: '4px', color: '#2CB512' }} />
                                  <span style={{ fontFamily: "'Onest', sans-serif" }}>Delete list</span>
                                </MenuItem>
                              </Menu>
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled>
                            No data
                          </MenuItem>
                        )}
                      </Select>
                    </Grid>
                  </Grid>

                  <EditListDialog
                    isNewListDialogOpen={isEditListDialogOpen}
                    handleCloseNewListDialog={handleCloseEditistDialog}
                    editValue={editValue}
                  />

                  <Dialog
                    open={openDeleteList.open}
                    onClose={() => setOpenDeleteList((preData: any) => ({ ...preData, openDeleteList: false }))}>
                    <DialogTitle>
                      <Typography
                        sx={{
                          textAlign: 'center',
                          fontFamily: "'Onest', sans-serif",
                          fontWeight: 600,
                          fontSize: '18px',
                          lineHeight: '28px',
                          color: 'var(--green)',
                        }}>
                        Are you sure want to delete {openDeleteList && openDeleteList.label} from the list?
                      </Typography>
                    </DialogTitle>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                      <Button
                        className="common-button-white"
                        onClick={closeDeleteDialog}
                        disabled={selectedValue.length === 0}
                        sx={{
                          width: '275px',
                          color: 'var(--white)',
                          '&.Mui-disabled': {
                            color: '#FFF',
                          },
                          cursor: 'pointer',
                        }}>
                        Cancel
                      </Button>
                      <Button
                        className="common-button-green"
                        onClick={deleteList}
                        sx={{ width: '275px', color: 'var(--white)' }}>
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Grid sx={{ display: 'flex', justifyContent: 'space-between', mt: '10px' }}>
                    <Typography className="dash-title" sx={{ fontFamily: "'Onest', sans-serif !important" }}>
                      <span style={{ fontWeight: 600, fontSize: '24px' }}>My Rosters</span>
                    </Typography>
                    {/* {selectedValue === 'all' ? (
                      ''
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        className="common-button-green"
                        onClick={handleSelectArtist}>
                        Select Artist
                      </Button>
                    )} */}
                    <Button
                      variant="contained"
                      color="primary"
                      className="common-button-green"
                      onClick={handleSelectArtist}
                      // disabled={selectedValue === 'all' ? true : false}
                    >
                      Select Artist
                    </Button>
                  </Grid>
                  {loading && (
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={loading}
                      onClick={() => setLoading(false)}>
                      <CircularProgress color="success" />
                    </Backdrop>
                  )}
                  {!loading && (
                    <CardDisplay
                      cardData={rosterCardData}
                      eyeIcon={handleEyeIconClick}
                      listIcon={handleListIconClick}
                      type="Roster"
                    />
                  )}
                </div>
                <AddListDialog
                  selectedUser={selectedUser}
                  handleCloseDialog={handleCloseDialog}
                  getListDatas={getListDatas}
                  addArtistList={addArtistList}
                  handleAddNewListClick={handleAddNewListClick}
                  refresh={refresh}
                />
                <CreateListDialog
                  isNewListDialogOpen={isNewListDialogOpen}
                  handleCloseNewListDialog={handleCloseNewListDialog}
                />
                <InviteMember openDialog={openInvite} close={handleCloseInvite} userRoster={userRoster} />
              </div>
            </Grid>
          </>
        )}
        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Grid>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
  };
}

export default connect(mapDispatchToProps)(UserRoster);

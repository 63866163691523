/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
// import AddTeamMembersDialog from './AddTeamMembersDialog';
import moment from 'moment';
import CustomSnackbar from '../../common/Snackbar';
import '../../Assets/css/TeamSetting.css';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reducer/store';
import { profileHeaderPics, userDisElgble } from '../../reducer/action';

const PlanBillinginfo = (props: any) => {
  const navigate = useNavigate();
  const privateAxios = useAxiosPrivate();

  const [billInfo, setBillInfo] = useState<any>();
  // const [openAddTeam, setOpenAddTeam] = useState(false);
  const [, setUserDetails] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: '', type: 'success' });
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!props.userDC) {
      getPlanBillinInfo();
    }
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    const getDetails = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;

          setUserDetails(data);
          dispatch(
            profileHeaderPics({
              profilePath: data?.profilePath,
              headerPath: data?.headerPath,
              availablity: data?.availability,
              firstName: data?.firstName,
              lastName: data?.lastName,
              jobTitle: data?.jobTitle,
              userName: data?.userName,
            })
          );
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getPlanBillinInfo = () => {
    const getDetails = privateAxios.get(`plan/getplaninfo`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          setBillInfo(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };
  const handleCancelClick = () => {
    navigate('/account-dashboard');
  };

  const handleSubmitClick = () => {
    // Handle save action
  };

  // const handleAddTeamMembers = () => {
  //   setOpenAddTeam(true);
  // };

  const handleSubscribeNow = () => {
    dispatch(userDisElgble(false));
    navigate('/premium/choose-payment');
  };

  const handleUpgradePlan = () => {
    navigate('/renew');
    // const upgradePlan = privateAxios.post(`payment/paypal/upgrade`, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });
    // upgradePlan
    //   .then((response: any) => {
    //     if (response.data.status === true) {
    //       const url = response.data.data;
    //       // navigate(`${response.data.data}`);
    //       // window.open(url, '_blank');
    //       // window.open(url);
    //       window.location.href = url;
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.error('RESPONSE', error);
    //     setToast({ message: error.response.data.message.value, type: 'error' });
    //     handleClick();
    //   });
  };

  // const utilizationPercentage = userDetails ? (userDetails?.teamMember?.totalMembers / 3) * 100 : 0;

  return (
    <>
      <div style={{ margin: '0px', position: 'relative' }} id="plan-Bill">
        <Grid
          container
          className="button-position planBillbtn"
          sx={{
            position: 'absolute',
            top: -140,
            right: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}>
          <Button
            className="common-button-white"
            type="submit"
            variant="contained"
            onClick={handleCancelClick}
            sx={{
              width: '167px',
              height: '44px',
              marginRight: 1,
              '&:hover': {
                color: 'var(--Primary, #2CB512)',
                backgroundColor: '#fff',
              },
            }}>
            Cancel
          </Button>
          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleSubmitClick}
            sx={{
              width: '121px',
              height: '44px',
              marginLeft: 1,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save
          </Button>
        </Grid>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} columns={16}>
          <Grid item md={4} sm={4} xs={12}>
            <Typography
              component={'span'}
              style={{
                color: 'var(--gray-500, #667085)',
                fontSize: '14px!important',
                fontWeight: 400,
                fontFamily: "'Onest', sans-serif !important",
              }}>
              {/* Here you can manage your team settings or your personal information, skills, tools and other account details.
              Click Save once you are done to save your profile changes. */}
              Here you can manage your personal information, skills, tools and other account details. Click Save once you are
              done to save your profile changes.
            </Typography>
          </Grid>
          {props.userType ? (
            <>
              <Grid item md={6} sm={12} xs={12}>
                <Box
                  sx={{
                    height: '252px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                    backgroundColor: '#FFFFFF',
                  }}>
                  <Grid>
                    <Typography
                      variant="h6"
                      component={'div'}
                      style={{
                        display: 'inline-block',
                        color: '#101828',
                        fontSize: '16px',
                        fontWeight: 600,
                      }}
                      sx={{ fontFamily: "'Onest', sans-serif !important" }}>
                      AVALBL{' '}
                      {billInfo && billInfo.isAnnually ? 'Annually Premium' : props.userDC ? 'Permanent' : 'Monthly Premium'}{' '}
                      Membership
                    </Typography>

                    <Typography
                      component={'span'}
                      sx={{
                        marginLeft: '5px',
                        fontSize: '14px',
                        width: 'auto',
                        height: '30px',
                        display: 'inline-block',
                        backgroundColor: '#E9FAF0',
                        color: '#039855',
                        borderRadius: '10px',
                        padding: '4px',
                        textAlign: 'center',
                        fontFamily: "'Onest', sans-serif !important",
                      }}>
                      {billInfo && billInfo.discount} {props.userDC ? 'Permament' : 'days Free Trial'}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      component={'span'}
                      sx={{
                        mb: 5,
                        color: '#667085',
                        fontSize: '16px',
                        fontWeight: 400,
                        fontFamily: "'Onest', sans-serif !important",
                      }}>
                      {props.userDC
                        ? 'Permanent User'
                        : billInfo && billInfo.isAnnually
                        ? `${billInfo && billInfo.monthlyCost}/month (${billInfo && billInfo.subscriptionCost}/yr)`
                        : billInfo && billInfo.subscriptionCost}
                    </Typography>
                  </Grid>
                  <Grid sx={{ pt: '20px' }}>
                    <Typography
                      variant="h5"
                      component={'div'}
                      sx={{
                        mb: 1,
                        color: '#101828',
                        fontSize: '18px',
                        fontWeight: 600,
                        fontFamily: "'Onest', sans-serif !important",
                      }}>
                      Free Trial End Date
                    </Typography>
                    <Typography
                      component={'span'}
                      sx={{
                        color: '#667085',
                        fontSize: '16px',
                        fontWeight: '400',
                        fontFamily: "'Onest', sans-serif !important",
                      }}>
                      {props.userDC
                        ? 'Permanent User'
                        : moment(billInfo && billInfo.freeTrialEndPeriod).format('MMMM DD, YYYY')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ pt: '20px' }}>
                    <Typography
                      variant="h5"
                      component={'div'}
                      sx={{
                        mb: 1,
                        color: '#101828',
                        fontSize: '18px',
                        fontWeight: 600,
                        fontFamily: "'Onest', sans-serif !important",
                      }}>
                      {props.userPM ? (
                        'Subscription will end on'
                      ) : (
                        <Button
                          className="common-button-green"
                          type="submit"
                          variant="contained"
                          sx={{
                            // marginLeft: 1,
                            mt: 2,
                            '&:hover': {
                              background: 'var(--Primary, #2CB512)',
                            },
                          }}
                          onClick={handleSubscribeNow}>
                          Subscribe Now
                        </Button>
                      )}
                    </Typography>
                    {props.userPM && (
                      <Typography
                        component={'span'}
                        sx={{
                          color: '#667085',
                          fontSize: '16px',
                          fontWeight: '400',
                          fontFamily: "'Onest', sans-serif !important",
                        }}>
                        {props.userDC
                          ? 'Permanent User'
                          : moment(billInfo && billInfo.subscriptionEndPeriod).format('MMMM DD, YYYY')}
                      </Typography>
                    )}
                  </Grid>
                </Box>
              </Grid>
              {/* <Grid item md={7} sm={7} xs={12}>
                <Box
                  sx={{
                    height: '232px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                    backgroundColor: '#FFFFFF',
                  }}>
                  <Grid>
                    <Typography
                      variant="h5"
                      component={'div'}
                      style={{ display: 'inline-block', color: '#101828', fontFamily: "'Onest', sans-serif !important" }}>
                      Team size
                    </Typography>
                  </Grid>
                  <Grid sx={{ marginTop: '12px', marginLeft: '5px' }}>
                    <Typography
                      component={'span'}
                      sx={{ mb: 1.5, color: '#667085', fontFamily: "'Onest', sans-serif !important" }}>
                      Utilising{' '}
                      {userDetails && userDetails?.teamMember?.totalMembers ? userDetails?.teamMember?.totalMembers : 0} of 3
                      AVALBL seats
                    </Typography>
                    <LinearProgress variant="determinate" value={utilizationPercentage} />
                  </Grid>
                </Box>
                <AddTeamMembersDialog openAddTeam={openAddTeam} handleDialogClose={() => setOpenAddTeam(false)} />
              </Grid> */}
            </>
          ) : (
            <>
              <Grid item md={5} xs={12}>
                <Box
                  sx={{
                    height: '232px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                    backgroundColor: '#FFFFFF',
                  }}>
                  <Grid>
                    <Typography
                      variant="h6"
                      component={'div'}
                      style={{
                        display: 'inline-block',
                        color: '#101828',
                        fontSize: '16px',
                        fontWeight: 600,
                        fontFamily: "'Onest', sans-serif !important",
                      }}>
                      AVALBL Basic MemberShip
                    </Typography>

                    <Typography
                      component={'span'}
                      sx={{
                        marginLeft: '5px',
                        fontSize: '14px',
                        width: '71px',
                        height: '30px',
                        display: 'inline-block',
                        backgroundColor: '#E9FAF0',
                        color: '#039855',
                        borderRadius: '10px',
                        padding: '4px',
                        textAlign: 'center',
                        fontFamily: "'Onest', sans-serif !important",
                      }}>
                      Free Trial
                    </Typography>
                  </Grid>
                  <Grid sx={{ pt: '45px' }}>
                    <Typography
                      variant="h5"
                      component={'div'}
                      sx={{
                        mb: 1,
                        color: '#101828',
                        fontSize: '18px',
                        fontWeight: 600,
                        fontFamily: "'Onest', sans-serif !important",
                      }}>
                      Get Premium plan only at {billInfo && billInfo.subscriptionCost}
                    </Typography>
                    <Typography
                      component={'span'}
                      sx={{
                        color: '#667085',
                        fontSize: '16px',
                        fontWeight: '400',
                        fontFamily: "'Onest', sans-serif !important",
                      }}>
                      Billed annually
                    </Typography>
                  </Grid>
                  <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                      className="common-button-green"
                      type="submit"
                      variant="contained"
                      sx={{
                        width: '373px!important',
                        height: '44px!important',
                        mt: 6,
                        '&:hover': {
                          background: 'var(--Primary, #2CB512)',
                        },
                      }}
                      onClick={handleUpgradePlan}>
                      Upgrade Premium
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    userType: state.userType,
    userDC: state.userDC,
    userPM: state.userPM,
  };
}

export default connect(mapDispatchToProps)(PlanBillinginfo);

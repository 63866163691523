import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const publicAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
});

export const privateAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
});

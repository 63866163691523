/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import useAxiosPrivate from '../../Hooks/useAxioPrivate';

const ChallengeDialog = ({ openChallenge, handleChallengeClose, requestChallenge, holdData }: any) => {
  // const privateAxios = useAxiosPrivate();

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    // if (selectedArtist !== undefined) {
    //   if (selectedArtist?.id || selectedArtist[0]?.id) {
    //     getUserInfo(selectedArtist[0].id);
    //   }
    // }
    if (holdData) {
      setData(holdData);
    }
  }, [holdData]);

  // const getUserInfo = (id: number) => {
  //   const getData = privateAxios.get(`user/getuserdetails?id=${id}`, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });
  //   getData
  //     .then((response: any) => {
  //       if (response.status === 200) {
  //         setData(response.data.data);
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.error(error);
  //     });
  // };

  return (
    <Dialog
      open={openChallenge}
      onClose={handleChallengeClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '561px',
          height: 'auto',
          padding: '30px',
        },
      }}>
      <Typography style={{ color: '#2CB512', fontSize: '18px', fontWeight: 600 }}>
        This person is already hold by someone
      </Typography>
      <Typography style={{ color: '#73787E', fontSize: '16px', fontWeight: 400, marginTop: '10px' }}>
        You can skip this artist or make change for hiring the artist
      </Typography>
      <Typography style={{ color: '#73787E', fontSize: '16px', fontWeight: 400, marginTop: '10px' }}>
        If you are going to challenge the 1st account holder has 24 hrs to accept the deal or you will going to hire this
        artist
      </Typography>

      <Grid
        sx={{
          display: 'flex',
          width: '480px',
          height: '82px',
          borderRadius: '10px',
          border: '2px solid #F2F4F7',
          padding: '10px',
          marginTop: '40px',
          // justifyContent: 'space-between',
        }}>
        <Grid item xs={2}>
          <img
            src={data && data.profilePath}
            alt="login02"
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />
        </Grid>
        <Grid item xs={10} sx={{ pl: 1 }}>
          <Typography>{data && `${data.firstName} ${data.lastName}`}</Typography>
          <Typography>
            {data && data.jobTitle} | {data && data.country}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
          className="common-button-white"
          type="submit"
          variant="contained"
          onClick={handleChallengeClose}
          sx={{
            width: '244px',
            height: '44px',
            marginRight: 1,
            mt: 3,
            mb: 2,
            '&:hover': {
              color: 'var(--Primary, #2CB512)',
              backgroundColor: '#fff',
            },
          }}>
          Cancel
        </Button>
        <Button
          className="common-button-green"
          type="submit"
          variant="contained"
          sx={{
            width: '244px',
            height: '44px',
            marginLeft: 1,
            mt: 3,
            mb: 2,
            '&:hover': {
              background: 'var(--Primary, #2CB512)',
            },
          }}
          onClick={requestChallenge}>
          Challenge
        </Button>
      </div>
    </Dialog>
  );
};

export default ChallengeDialog;

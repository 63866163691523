import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import PlanBillinginfo from './PlanBilling';
import PersonalInfoadmin from './PersonalInfo';
import TeamSettings from './TeamSetting';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import Header from './Header';
import { connect } from 'react-redux';
import '../../Assets/css/Tabs.css';
import { useLocation } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const location = useLocation();

  useEffect(() => {
    const elementsWithPClass = document.querySelectorAll('#plan-Bill');
    elementsWithPClass.forEach((element) => {
      const rootDiv = element.closest('.css-1y36zv6');
      if (rootDiv) {
        rootDiv.classList.add('planbillclass');
      }
    });
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          <Typography component={'div'}>
            {index === 0 && <PlanBillinginfo />}
            {index === 1 && <TeamSettings />}
            {index === 2 && <PersonalInfoadmin tab={location.state.tab === 2 ? 'PersonalAvailability' : 'PersonalInfo'} />}
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Editprofile = (props: any) => {
  const {
    state: { tab },
  } = useLocation();

  const [value, setValue] = useState(tab);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setLoading(true);
    setValue(newValue);
    setLoading(false);
  };
  useEffect(() => setValue(tab), [tab]);

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress
            color="success"
            // style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          />
        </Backdrop>
      )}

      <>
        <Header />
        <Container maxWidth="xl">
          <Box
            className="planBill"
            sx={{
              fontFamily: "'Onest', sans-serif !important",
              backgroundColor: '#f7f8f8',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px',
            }}>
            <Tabs
              sx={{ fontFamily: "'Onest', sans-serif !important" }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ style: { backgroundColor: '#2CB512', fontFamily: "'Onest', sans-serif !important" } }}>
              <Tab
                label="Plan & Billing info"
                {...a11yProps(0)}
                sx={{
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 500,
                  color: value === 0 ? '#2CB512 !important' : '#81899A',
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  },
                }}
              />
              <Tab
                label="Team Settings"
                {...a11yProps(1)}
                sx={{
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 500,
                  color: value === 1 ? '#2CB512 !important' : '#81899A',
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  },
                  display: props.userType ? 'none' : 'none',
                }}
              />
              <Tab
                label="Personal Info"
                {...a11yProps(2)}
                sx={{
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 500,
                  color: value === 2 ? '#2CB512 !important' : '#81899A',
                  '&:hover': {
                    backgroundColor: 'transparent !important',
                  },
                }}
              />
            </Tabs>
          </Box>
          {!loading && (
            <>
              <CustomTabPanel value={value} index={0}></CustomTabPanel>
              <CustomTabPanel value={value} index={1}></CustomTabPanel>
              <CustomTabPanel value={value} index={2}></CustomTabPanel>
            </>
          )}
        </Container>
      </>
    </React.Fragment>
  );
};

function mapDispatchToProps(state: any) {
  return {
    userType: state.userType,
  };
}

export default connect(mapDispatchToProps)(Editprofile);

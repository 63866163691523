import React from 'react';
import { Typography, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reducer/store';
import { userStepStatus } from '../../reducer/action';

interface WelcomeProps {
  handleNext: () => void;
}

const Welcome: React.FC<WelcomeProps> = ({ handleNext }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = () => {
    handleNext();
    dispatch(userStepStatus({ status: 'Active' }));
  };

  return (
    <>
      <Typography
        sx={{
          fontFamily: "'Onest', sans-serif",
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '38px',
          pt: '25px',
        }}>
        Welcome!
      </Typography>
      <Typography
        sx={{
          color: 'var(--gray-400, #98A2B3)',
          fontFamily: "'Onest', sans-serif",
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          pt: '12px',
        }}>
        Thank you for signing up to Avalbl! Your account is almost ready. Start using Avalbl by completing your profile.
      </Typography>
      <Button
        className="common-button-green"
        type="submit"
        variant="contained"
        onClick={handleSubmit}
        sx={{
          margin: 'auto',
          display: 'block',
          mt: 3,
          mb: 2,
          '&:hover': {
            background: 'var(--Primary, #2CB512)',
          },
        }}>
        Let&apos;s Go!
      </Button>
    </>
  );
};

export default Welcome;

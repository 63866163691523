/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Backdrop,
  IconButton,
} from '@mui/material';
import image1 from '../../Assets/Images/Gag1.png';
import CustomSnackbar from '../../common/Snackbar';
import uploadCloud from '../../Assets/Images/uploadCloud.png';
// import profile from '../../Assets/Images/Image (1).png';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reducer/store';
import { profileHeaderPics } from '../../reducer/action';

const TeamProfile = () => {
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();

  // const [teamData, setTeamData] = useState<any>();
  const [emailState, setEmailState] = useState({ email: '' });
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [open, setOpen] = React.useState(false);
  const [isAddMembersDialogOpen, setIsAddMembersDialogOpen] = useState(false);
  // const [editMode, setEditMode] = useState(false);
  // const [removedMembers, setRemovedMembers] = useState<string[]>([]);
  const [updateTeam, setUpdateTeam] = useState({ teamName: '', logoPath: null });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    teamName: '',
    logoPath: '',
    email: '',
  });
  const dispatch = useDispatch<AppDispatch>();

  // const handleEditClick = () => {
  //   setEditMode(true);
  //   setRemovedMembers([]);
  // };

  // useEffect(() => {
  //   if (removedMembers.length > 0) {
  //     handleSaveEdit();
  //   }
  // }, [removedMembers]);

  // const handleRemoveClick = (email: string) => {
  //   setRemovedMembers((prev) => [...prev, email]);
  // };

  // const handleSave = () => {
  //   setEditMode(false);
  //   userDetails();
  // };

  // const handleSaveEdit = () => {
  //   if (removedMembers.length === 0) {
  //     setEditMode(false);
  //     return;
  //   }

  //   const removeMemberEmail = {
  //     memberEmail: removedMembers,
  //   };

  //   const removeMember = privateAxios.delete(`team/removemember`, {
  //     data: removeMemberEmail,
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   });
  //   removeMember
  //     .then((response: any) => {
  //       if (response.status === 200) {
  //         setToast({ message: response.data.message.value, type: 'success' });
  //         userDetails();
  //         handleClick();
  //       }
  //     })
  //     .catch((error: any) => {
  //       setToast({ message: error.response.data?.message?.value, type: 'error' });
  //       handleClick();
  //     });
  // };

  // const handleAddMembersClick = () => {
  //   setIsAddMembersDialogOpen(true);
  // };

  const handleDialogClose = () => {
    setIsAddMembersDialogOpen(false);
    setError((preErr: any) => ({
      ...preErr,
      email: '',
    }));
    setEmailState({ email: '' });
  };

  const handleCancelClick = () => {
    navigate('/account-dashboard');
  };

  const handleSubmitClick = () => {
    const teamDetails = {
      userName: updateTeam.teamName,
      logoPath: updateTeam.logoPath,
    };

    if (teamDetails.userName.length === 0) {
      setError((pre: any) => ({
        ...pre,
        teamName: 'Team name is required',
      }));
      return;
    }

    if (teamDetails.logoPath === null) {
      setError((pre: any) => ({
        ...pre,
        logoPath: 'Logo is required',
      }));
      return;
    }

    const teamUpdate = privateAxios.put(`team/updateteam`, updateTeam, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    teamUpdate
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          userDetails();
          handleClick();
        }
      })
      .catch((error: any) => {
        setToast({ message: error.response.data?.message, type: 'error' });
        handleClick();
      });
  };

  useEffect(() => {
    userDetails();
  }, []);

  const userDetails = () => {
    setLoading(true);
    const getCategoryValues = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getCategoryValues
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          // console.log(data,"????");
          dispatch(
            profileHeaderPics({
              profilePath: data?.profilePath,
              headerPath: data?.headerPath,
              availablity: data?.availability,
              firstName: data?.firstName,
              lastName: data?.lastName,
              jobTitle: data?.jobTitle,
              userName: data?.userName,
            })
          );

          // setTeamData(data?.teamMember);
          if (data?.teamMember !== null) {
            setUpdateTeam({
              teamName: data?.teamMember?.adminDetail?.teamName,
              logoPath: data?.teamMember?.adminDetail?.logo,
            });
          }

          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const isEmailValid = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'email') {
      if (e.target.value.length > 0) {
        error.email = '';
      }
    }

    setEmailState({
      email: e.target.value
        .replace(/^\s+/, '')
        .replace(/\s+/g, ' ')
        .replace(/^[^a-zA-Z@.]+/, ''),
    });
  };

  const handleTeamMembers = () => {
    const teamDetails = {
      email: emailState.email,
    };

    if (teamDetails.email === '') {
      setError((pre: any) => ({
        ...pre,
        email: 'Enter the Email',
      }));
      return;
    }

    const emailArray = emailState.email.includes(',') ? emailState.email.split(',') : emailState.email.split(' ');

    const formData = {
      memberEmail: emailArray.filter((email) => email.trim() !== ''),
    };

    const invalidEmails = formData.memberEmail.filter((email) => !isEmailValid(email.trim()));

    if (invalidEmails.length > 0) {
      setToast({ message: 'Please Enter valid Email(s)', type: 'error' });
      setOpen(true);
      return;
    }

    if (formData.memberEmail.length === 1 && formData.memberEmail[0] === '') {
      setToast({ message: 'Please Enter a valid Email', type: 'error' });
      setOpen(true);
    } else if (formData.memberEmail.length === 0) {
      setToast({ message: 'Please Enter a valid Email', type: 'error' });
      setOpen(true);
    } else {
      const postData = privateAxios.post(`team/addmember`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      postData
        .then((response) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            setEmailState({ email: '' });
            handleDialogClose();
            userDetails();
            handleClick();
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setToast({ message: error.response.data.message, type: 'error' });
            handleClick();
            setEmailState({ email: '' });
          } else {
            setToast({ message: error.response.data.message.value, type: 'error' });
            handleClick();
            setEmailState({ email: '' });
          }
        });
    }
  };

  const handleTeamName = (e: any) => {
    if (e.target.name === 'teamName') {
      if (e.target.value.length > 0) {
        error.teamName = '';
      }
    }

    setUpdateTeam((preData: any) => ({
      ...preData,
      teamName: e.target.value,
    }));
  };

  const uploadAllImage = (e: any, type: any) => {
    if (type == 'logo_image') {
      document.getElementById(`icon_logo_icon`)?.click();
    }
  };

  const handleFileUpload = async (e: any, type: any) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
        setToast({ message: 'Unsupported File type ', type: 'error' });
        handleClick();
        return;
      }

      reader.onload = async (event: any) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          if (type === 'logoImage') {
            // if (image.width > 200 || image.height > 200) {
            //   setToast({ message: 'Image dimensions are large', type: 'error' });
            //   handleClick();
            //   return;
            // }
            if (image.width > 0 && image.height > 0) {
              // setProfileImage(event.target.result);
              const formData = new FormData();
              formData.append('file', file);
              if (updateTeam.logoPath !== null) {
                formData.append('imagePath', updateTeam.logoPath);
              }
              const request = updateTeam.logoPath === null ? privateAxios.post : privateAxios.put;
              const responsesssss = request(
                `team/${updateTeam.logoPath === null ? 'logoupload' : 'logoreupload'}`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }
              );

              responsesssss
                .then((response: any) => {
                  if (response.status === 200) {
                    error.logoPath = '';

                    setUpdateTeam((prevData: any) => ({
                      ...prevData,
                      logoPath: response.data.data,
                    }));
                    setError((pre: any) => ({
                      ...pre,
                      logoPath: '',
                    }));
                    setToast({ message: response.data.message.value, type: 'success' });
                    handleClick();
                  }
                })
                .catch((error: any) => {
                  setToast({ message: error.response.data.message.value, type: 'error' });
                  handleClick();
                });
            }
            // else {
            //   setToast({ message: 'Image dimensions are large', type: 'error' });
            //   handleClick();
            //   return;
            // }
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleDrop = async (e: any, type: any) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];

    if (file) {
      const reader = new FileReader();
      if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
        setToast({ message: 'Unsupported File type ', type: 'error' });
        handleClick();
        return;
      }

      reader.onload = async (event: any) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          if (type === 'logoImage') {
            // if (image.width > 200 || image.height > 200) {
            //   setToast({ message: 'Image dimensions are large', type: 'error' });
            //   handleClick();
            //   return;
            // }

            const formData = new FormData();
            formData.append('file', file);
            if (updateTeam.logoPath !== null) {
              formData.append('imagePath', updateTeam.logoPath);
            }

            const request = updateTeam.logoPath === null ? privateAxios.post : privateAxios.put;
            const responsesssss = request(`team/${updateTeam.logoPath === null ? 'logoupload' : 'logoreupload'}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            responsesssss
              .then((response: any) => {
                if (response.status === 200) {
                  setUpdateTeam((prevData: any) => ({
                    ...prevData,
                    logoPath: response.data.data,
                  }));
                  setToast({ message: response.data.message.value, type: 'success' });
                  handleClick();
                }
              })
              .catch((error: any) => {
                console.error(error);
                setToast({ message: error.response.data.message.value, type: 'error' });
                handleClick();
              });
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <div style={{ margin: '0px', position: 'relative' }}>
        <Grid
          container
          className="button-position"
          sx={{
            position: 'absolute',
            top: -140,
            right: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}>
          <Button
            className="common-button-white"
            type="submit"
            variant="contained"
            onClick={handleCancelClick}
            sx={{
              width: '167px',
              height: '44px',
              marginRight: 1,
              '&:hover': {
                color: 'var(--Primary, #2CB512)',
                backgroundColor: '#fff',
              },
            }}>
            Cancel
          </Button>
          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleSubmitClick}
            sx={{
              width: '121px',
              height: '44px',
              marginLeft: 1,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save
          </Button>
        </Grid>
      </div>

      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress
            color="success"
            // style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translate(-50%, -50%)' }}
          />
        </Backdrop>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} pr={0}>
              <div style={{ color: '#667085', fontSize: '16px', fontWeight: 400 }}>
                <p style={{ fontFamily: '"Onest", sans-serif !important' }}>
                  Set your team attributes here, as well as add and remove team members
                </p>
                <p style={{ fontFamily: '"Onest", sans-serif !important' }}>*Only team admins can change this information</p>
              </div>
            </Grid>

            <Grid item xs={12} md={9}>
              <Box sx={{ height: 'auto', borderRadius: '10px', backgroundColor: '#FFFFFF' }}>
                <div className="pers-box" style={{ padding: '20px' }}>
                  <form>
                    <Grid container>
                      {!loading && (
                        <>
                          <Grid item xs={12}>
                            <Typography
                              sx={{ fontsize: '14px', fontWeight: 400, color: '#344054', pb: 1 }}
                              className="lable-name">
                              Team Name
                            </Typography>
                            <TextField
                              className="formFields"
                              autoComplete="off"
                              name="teamName"
                              placeholder="Team Name"
                              value={updateTeam.teamName}
                              onChange={(e) => handleTeamName(e)}
                              required
                              fullWidth
                            />
                            {error.teamName && <div style={{ color: 'red' }}>{error.teamName}</div>}
                          </Grid>
                          <Grid item xs={12} sx={{ paddingTop: '20px', paddingBottom: '5px' }}>
                            <Typography style={{ fontWeight: 400, color: '#344054' }} className="lable-name">
                              Team Logo
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={3} lg={1.3}>
                            <div className="profile-info">
                              <div className="pro-image " style={{ width: '80px', paddingTop: '10px' }}>
                                <img
                                  src={updateTeam.logoPath !== null ? updateTeam.logoPath : image1}
                                  alt="img"
                                  id="img-logo"
                                  className="img-fluid profile-pic3"
                                />
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={9} lg={10.7} pl={0}>
                            <Box
                              className="p-image imageBox"
                              sx={{
                                height: '128px',
                                textAlign: 'center',
                                pt: '25px',
                                marginRight: '30px',
                                marginLeft: '1em',
                              }}
                              onClick={(e) => uploadAllImage(e, 'logo_image')}
                              onDrop={(e) => handleDrop(e, 'logoImage')}
                              onDragOver={handleDragOver}>
                              <>
                                <img src={uploadCloud} alt="img" className="img-fluid upload-button" />
                                <input
                                  className="file-upload"
                                  id="icon_logo_icon"
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => handleFileUpload(e, 'logoImage')}
                                />
                              </>

                              <Typography
                                className="note"
                                sx={{
                                  color: 'var(--Primary, #2CB512)',
                                  textAlign: 'center',
                                  fontSize: '12px',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  lineHeight: '18px',
                                  fontFamily: "'Onest', sans-serif !important",
                                  cursor: 'pointer',
                                }}>
                                Drag & Drop, or Click to upload
                              </Typography>
                              <Typography
                                sx={{
                                  color: 'var(--gray-400, #98A2B3)',
                                  textAlign: 'center',
                                  fontSize: '12px',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  lineHeight: '18px',
                                  fontFamily: "'Onest', sans-serif !important",
                                }}>
                                JPG, PNG Format
                              </Typography>
                            </Box>
                            {error.logoPath && <div style={{ color: 'red', paddingLeft: '15px' }}>{error.logoPath}</div>}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </form>
                  {/* <Box sx={{ mt: 3, border: '2px solid #F2F4F7', borderRadius: '10px' }}>
                    <div className="active-member">
                      <div
                        className="active-title"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#667085',
                          backgroundColor: '#F2F4F7',
                        }}>
                        <Typography sx={{ padding: '10px', fontSize: '14px', fontFamily: "'Onest', sans-serif !important" }}>
                          Active Team Members
                        </Typography>
                        <div style={{ display: 'flex', gap: '10px' }}>
                          {editMode ? (
                            ''
                          ) : (
                            <>
                              <Typography
                                sx={{
                                  padding: '10px',
                                  fontSize: '14px',
                                  color: 'var(--Primary, #2CB512)',
                                  cursor: 'pointer',
                                  fontFamily: "'Onest', sans-serif !important",
                                }}
                                onClick={handleAddMembersClick}>
                                Add team members
                              </Typography>
                            </>
                          )}

                          <div style={{ display: 'flex', gap: '10px' }}>
                            {editMode ? (
                              <>
                                <Typography
                                  sx={{
                                    padding: '10px',
                                    fontSize: '14px',
                                    color: 'var(--Primary, #2CB512)',
                                    cursor: 'pointer',
                                    fontFamily: "'Onest', sans-serif !important",
                                  }}
                                  // onClick={handleSaveEdit}
                                  // onClick={() => setIsRefreshPage(!isRefreashPage)}
                                  onClick={handleSave}>
                                  Save
                                </Typography>
                              </>
                            ) : (
                              <Typography
                                sx={{
                                  padding: '10px',
                                  fontSize: '14px',
                                  color: 'var(--Primary, #2CB512)',
                                  cursor: 'pointer',
                                  fontFamily: "'Onest', sans-serif !important",
                                }}
                                onClick={handleEditClick}>
                                Edit
                              </Typography>
                            )}
                          </div>
                        </div>
                      </div>
                      {teamData?.isAdmin && (
                        <div
                          className="pro-cont member-content"
                          style={{
                            display: 'flex',
                            padding: '10px',
                            alignItems: 'center',
                            fontFamily: "'Onest', sans-serif !important",
                          }}>
                          <div className="pro-img">
                            <img
                              src={teamData?.adminDetail?.profileImage}
                              width={'50px'}
                              height={'50px'}
                              alt="img"
                              style={{ borderRadius: '50%' }}
                              className="img-fluid"
                            />
                          </div>
                          <div className="pro-info" style={{ marginLeft: '20px' }}>
                            <Typography
                              sx={{
                                color: '#101828',
                                fontSize: '16px',
                                fontWeight: 500,
                                fontFamily: "'Onest', sans-serif !important",
                              }}>
                              {teamData?.adminDetail?.name}
                              <span
                                style={{
                                  marginLeft: '10px',
                                  color: '#039855',
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  backgroundColor: '#ECFDF3',
                                  borderRadius: '6px',
                                  padding: '4px 5px',
                                  display: 'inline-block',
                                  width: '52px',
                                  textAlign: 'center',
                                  fontFamily: "'Onest', sans-serif !important",
                                }}>
                                Admin
                              </span>
                            </Typography>
                            <Typography
                              sx={{
                                color: '#667085',
                                fontSize: '14px',
                                fontWeight: '400',
                                paddingTop: '2px',
                                fontFamily: "'Onest', sans-serif !important",
                              }}>
                              {teamData?.adminDetail?.email}
                            </Typography>
                          </div>
                        </div>
                      )}

                      {teamData?.memberDetail.length > 0 ? (
                        <>
                          <div className="pro-cont member-content" style={{ fontFamily: "'Onest', sans-serif" }}>
                            {teamData?.memberDetail?.map((member: any, index: any) => (
                              <div
                                key={index}
                                className="pro-cont member-content"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  padding: '10px',
                                }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <div className="pro-img">
                                    <img
                                      src={member.profileImage || profile}
                                      width={'50px'}
                                      height={'50px'}
                                      alt="img"
                                      style={{ borderRadius: '50%' }}
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="pro-info" style={{ marginLeft: '20px' }}>
                                    <Typography sx={{ color: '#101828', fontSize: '16px', fontWeight: 500 }}>
                                      {member.fullName}
                                      <span
                                        style={{
                                          marginLeft: '10px',
                                          color: '#039855',
                                          fontSize: '14px',
                                          fontWeight: 500,
                                          backgroundColor: '#ECFDF3',
                                          borderRadius: '6px',
                                          padding: '4px 5px',
                                          display: 'inline-block',
                                          width: '52px',
                                          textAlign: 'center',
                                        }}>
                                        Member
                                      </span>
                                    </Typography>
                                    <Typography
                                      sx={{ color: '#667085', fontSize: '14px', fontWeight: '400', paddingTop: '2px' }}>
                                      {member.email}
                                    </Typography>
                                  </div>
                                </div>
                                {editMode && (
                                  <Typography
                                    sx={{
                                      color: 'red',
                                      fontSize: '14px',
                                      cursor: 'pointer',
                                      fontFamily: "'Onest', sans-serif !important",
                                    }}
                                    onClick={() => handleRemoveClick(member.email)}>
                                    Remove
                                  </Typography>
                                )}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <div style={{ padding: '1px, 2px' }}>
                          <Typography
                            sx={{
                              color: '#101828',
                              fontSize: '16px',
                              fontWeight: 500,
                              fontFamily: "'Onest', sans-serif",
                              ml: '15px',
                            }}>
                            No Team members
                          </Typography>
                        </div>
                      )}
                    </div>
                  </Box> */}
                  <Dialog
                    open={isAddMembersDialogOpen}
                    onClose={handleDialogClose}
                    sx={{
                      '& .MuiDialog-paper': {
                        width: '100%',
                        height: 'auto',
                        padding: '15px',
                      },
                    }}>
                    <DialogTitle
                      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '14px 11px' }}>
                      <Typography style={{ color: '#2CB512', fontSize: '18px', fontWeight: 600, flex: '1' }}>
                        Add Team members
                      </Typography>
                      <IconButton onClick={handleDialogClose} size="small">
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ pl: '10px' }}>
                      <DialogContentText
                        sx={{ fontSize: '16px', fontFamily: "'Onest', sans-serif !important", fontWeight: 400 }}>
                        You can add up to three members
                      </DialogContentText>
                      <Typography
                        className="label-name"
                        sx={{
                          textAlign: 'left',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          gap: '6px',
                          alignSelf: 'stretch',
                          pt: '20px',
                        }}>
                        <span
                          style={{
                            fontFamily: 'Onest',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                          }}>
                          Use space or comma for multiple entries
                        </span>
                      </Typography>
                      <TextField
                        className="formFields"
                        autoComplete="off"
                        sx={{ width: '465px', outline: 'none' }}
                        name="email"
                        placeholder="Enter Email"
                        value={emailState.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailField(e)}
                      />
                      {error.email && <div style={{ color: 'red' }}>{error.email}</div>}
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                      <Button
                        onClick={handleDialogClose}
                        sx={{
                          textTransform: 'none',
                          fontFamily: '"Onest", sans-serif !important',
                          borderRadius: '10px',
                          border: '1px solid #2CB512',
                          color: '#2CB512',
                          backgroundColor: '#FFF',
                          marginRight: '8px',
                          width: '363.5px',
                          marginBottom: '20px',
                          '&:hover': {
                            backgroundColor: '#FFF',
                          },
                        }}>
                        Cancel
                      </Button>
                      <Button
                        onClick={() => handleTeamMembers()}
                        sx={{
                          textTransform: 'none',
                          fontFamily: '"Onest", sans-serif !important',
                          backgroundColor: '#2CB512',
                          borderRadius: '10px',
                          color: '#FFF',
                          width: '363.5px',
                          marginBottom: '20px',
                          '&:hover': {
                            backgroundColor: '#2CB512',
                          },
                        }}>
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TeamProfile;

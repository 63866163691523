/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Grid, Stack, styled, Switch } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import back from '../../Assets/Images/back.png';
// import Env from '../../services/Env';
import { publicAxios } from '../../services/Interceptor';
import { connect } from 'react-redux';
// import CustomSnackbar from '../../common/Snackbar';
// import { useLocation } from 'react-router-dom';

// interface PlanPageProps {
//   plan: {
//     label: string;
//     key: string;
//   };
//   type: {
//     label: string;
//     key: string;
//   };
//   amount: string;
// }

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2CB512',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-thumb:hover': {
    color: 'red',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const PlanPage = (props: any) => {
  const navigate = useNavigate();
  // const { state } = useLocation();

  const [plan, setPlan] = useState(true);
  const [paymentMode, setPaymentMode] = useState<any>({ isMonthly: false, isAnnually: true });

  function handlePlanChange() {
    setPlan((ps: any) => !ps);
    setPaymentMode((ps: any) => ({ isMonthly: !ps.isMonthly, isAnnually: !ps.isAnnually }));
  }

  const [planData, setPlanData] = useState<any>([]);
  // const [open, setOpen] = useState(false);
  // const [toast, setToast] = useState({ message: '', type: 'success' });

  useEffect(() => {
    getPlanInfo();
  }, []);

  const getPlanInfo = () => {
    // const getPlan = Env.get(`plan/getPlan`, '');
    const getPlan = publicAxios.get(`plan/getPlan`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getPlan
      .then((response: any) => {
        if (response.status === 200) {
          setPlanData(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error('ERROR', error);
        // setToast({ message: error.response.data.message.value, type: 'error' });
        // handleClick();
      });
  };

  // const handleClick = () => {
  //   setOpen(true);
  //   setTimeout(() => {
  //     handleClose();
  //   }, 2000);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const redirection = (type: string) => {
    if (props.isLoggedIn) {
      navigate('/');
    } else {
      if (type === 'Premium') {
        navigate('/plan/premium/email-confirmation', { state: { paymentMode } });
      }
      if (type === 'Basic') {
        navigate('/plan/basic/invite');
      }
    }
  };

  return (
    <>
      <Grid sx={{ backgroundColor: '#f7f8f8', height: '100vh' }}>
        <Grid sx={{ backgroundColor: '#f7f8f8' }}>
          <Grid
            sx={{
              paddingBottom: '16px',
              paddingTop: '25px',
              paddingLeft: '45px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <a href="/login" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <img src={back} alt="back" />
              <span className="lable-name" style={{ marginLeft: '8px', color: '#000' }}>
                Back
              </span>
            </a>
          </Grid>
          <Typography
            sx={{
              color: ' #101828',
              textAlign: 'center',
              fontSize: '30px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '38px',
            }}>
            Get listed or start searching
          </Typography>
          <Typography
            sx={{
              color: '#667085',
              textAlign: 'center',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
            }}>
            Get listed today forever, for free or organize and grow your roster
          </Typography>
        </Grid>
        <Grid item sm={2} sx={{ mt: 3.5 }}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
            <Typography
              sx={{
                fontWeight: 600,
                color: plan && plan ? '#7C8C89' : '',
                fontSize: '18px',
                lineHeight: '28px',
                paragraph: '18px',
                fontFamily: "'Onest', sans-serif !important",
              }}>
              Monthly
            </Typography>
            <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={plan} onChange={handlePlanChange} />
            <Typography
              sx={{
                fontWeight: 600,
                color: !plan ? '#7C8C89' : '',
                fontSize: '18px',
                lineHeight: '28px',
                paragraph: '18px',
                fontFamily: "'Onest', sans-serif !important",
              }}>
              Yearly
            </Typography>
            <Typography
              sx={{
                backgroundColor: '#FFFAEB',
                borderRadius: '16px',
                color: '#B54708',
                fontWeight: 500,
                fontSize: '12px',
                width: '75px',
                lineHeight: '18px',
                paragraph: '12px',
                textDecoration: plan && plan ? 'none' : 'line-through',
                fontFamily: "'Onest', sans-serif !important",
                textAlign: 'center',
              }}
              component={'div'}>
              SAVE {planData && planData[1]?.discount}
            </Typography>
          </Stack>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flex: '1 0 0',
            alignSelf: 'stretch',
            backgroundColor: '#f7f8f8',
            fontFamily: "'Onest', sans-serif",
            paddingTop: '20px',
            paddingBottom: '65px',
          }}>
          <Box
            sx={{
              borderRadius: '24px',
              background: 'var(--White, #FFF)',
              width: '400px',
              height: '600px',
            }}>
            <Box className="planBasic" sx={{ padding: '50px', paddingTop: '40px' }}>
              <Typography
                style={{
                  margin: '0px 0px 0px 0px',
                  fontSize: '22px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: 'var(--green)',
                }}>
                {planData && planData[0]?.plan?.label}
              </Typography>
              <Typography
                style={{
                  margin: '0px',
                  fontSize: '14px',
                  fontWeight: 600,
                  textAlign: 'center',
                  color: '#0000005c',
                  fontFamily: "'Onest', sans-serif !important",
                }}>
                Get listed and get hired
              </Typography>

              <Typography
                variant="h3"
                style={{
                  margin: '20px 0px 5px 0px',
                  fontWeight: 600,
                  textAlign: 'center',
                  color: '##000000d9',
                  fontFamily: "'Onest', sans-serif !important",
                }}>
                {planData && planData[0]?.type.label}
              </Typography>
              <Typography
                style={{
                  margin: '0px 0px 0px 0px',
                  fontSize: '14px',
                  fontWeight: 600,
                  textAlign: 'center',
                  color: '#0000005c',
                }}>
                Invition code required
              </Typography>
              <Button
                className="common-button-white"
                type="submit"
                variant="outlined"
                onClick={() => redirection('Basic')}
                sx={{ mt: 3, mb: 2, ml: 2, textTransform: 'none', width: '300px' }}>
                Get started
              </Button>

              <ul style={{ fontSize: '16px', fontWeight: 600, color: '#00000075', lineHeight: '35px', marginLeft: '-20px' }}>
                <li style={{ listStyleType: 'none' }}>
                  <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Detailed,
                  searchable profile
                </li>
                <li style={{ listStyleType: 'none' }}>
                  <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Availability
                  management
                </li>
                <li style={{ listStyleType: 'none' }}>
                  <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Calendar system
                </li>
                <li style={{ listStyleType: 'none' }}>
                  <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Receive and
                  message premium users
                </li>
                <li style={{ listStyleType: 'none', marginBottom: '110px' }}>
                  <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Receive and
                  accept project requests
                </li>
              </ul>
            </Box>
          </Box>

          <Box
            className="planPremium"
            sx={{
              borderRadius: '24px',
              background: 'var(--White, #FFF)',
              width: '400px',
              marginLeft: '20px',
              height: '600px',
            }}>
            <Box className="planBasic" sx={{ padding: '50px', paddingTop: '40px' }}>
              <Typography
                style={{
                  margin: '0px 0px 0px 0px',
                  fontSize: '22px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: 'var(--green)',
                }}>
                Premium
              </Typography>
              <Typography
                style={{
                  fontSize: '14px',
                  fontWeight: 600,
                  textAlign: 'center',
                  color: '#0000005c',
                }}>
                {plan && planData[1]?.amount?.savedAmount ? (
                  <>
                    You will save <span style={{ color: 'black' }}>{planData[1].amount.savedAmount}</span>
                  </>
                ) : null}
              </Typography>

              <Typography
                variant="h3"
                sx={{
                  // margin: '20px 0px 5px 0px',
                  mt: plan && plan ? '20px' : '43px',
                  mb: '5px',
                  fontWeight: 600,
                  textAlign: 'center',
                  color: '##000000d9',
                  fontFamily: "'Onest', sans-serif !important",
                  //   mt: '2px',
                }}>
                {!plan ? planData && planData[1]?.amount?.monthly : planData[1]?.amount?.yearly}
                <span style={{ fontSize: '20px' }}>/mon</span>
              </Typography>
              <Typography
                style={{
                  margin: '0px 0px 0px 0px',
                  fontSize: '14px',
                  fontWeight: 600,
                  textAlign: 'center',
                  color: '#0000005c',
                }}>
                {plan && planData[1]?.amount?.totalAmount ? (
                  <>
                    <span style={{ color: 'black' }}>{planData[1].amount.totalAmount}</span>, billed annually
                  </>
                ) : null}
              </Typography>
              <Button
                className="common-button-green"
                type="submit"
                variant="contained"
                sx={{
                  mt: plan && plan ? 3 : '43px',
                  mb: 2,
                  ml: 2,
                  textTransform: 'none',
                  width: '300px',
                }}
                onClick={() => redirection('Premium')}>
                Start with 30 days Free Trial
              </Button>

              <ul style={{ fontSize: '16px', fontWeight: 600, color: '#00000075', lineHeight: '35px', marginLeft: '-20px' }}>
                <li style={{ listStyleType: 'none' }}>
                  <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> All features of
                  Basic profile plus:
                </li>
                <li style={{ listStyleType: 'none' }}>
                  <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Advanced talent
                  search tools
                </li>
                <li style={{ listStyleType: 'none' }}>
                  <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Roster management
                  and organization
                </li>
                <li style={{ listStyleType: 'none' }}>
                  <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Add and message
                  Basic members
                </li>
                <li style={{ listStyleType: 'none', marginBottom: '60px' }}>
                  <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Send and manage
                  booking requests
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Grid>
      {/* <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleClose} toast={toast} /> */}
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    isLoggedIn: state.isLoggedIn,
    usertype: state.userType,
  };
}

export default connect(mapDispatchToProps)(PlanPage);

/* eslint-disable no-debugger */
import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import '../../../index.css';
import '../../../Assets/css/Common.css';
// import Env from '../../../services/Env';
import CustomSnackbar from '../../../common/Snackbar';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  userId,
  isLoggedIn,
  userType,
  userAccessToken,
  userRefreshToken,
  backHandle,
  userEmail,
  userStepStatus,
  userPM,
  userTM,
} from '../../../reducer/action';
// import { refreshTokenGenrate } from '../../../reducer/action';
import { AppDispatch } from '../../../reducer/store';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';

const PremiumVerification = (props: any) => {
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [verificationCode, setVerificationCode] = useState({ email: '', verificationCode: '' });
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [Error, setError] = useState('');

  const handleClick = () => {
    setOpen(true);
    setVerificationCode({ email: '', verificationCode: '' });
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVerificationCode = () => {
    // const userEmail = localStorage.getItem('userEmail');

    const dataToSend = {
      // email: userEmail || '',
      code: verificationCode.verificationCode,
    };

    if (dataToSend.code === '') {
      setError('Please Enter the Code.');
      return;
    }

    // const verificationCodeData = Env.post(`invite/verifyCode`, dataToSend, props.userAccessToken);
    const verificationCodeData = privateAxios.post(`invite/verifyCode`, dataToSend, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    verificationCodeData
      .then((response) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          const { id, accessToken, refreshToken, isBasic, isPremium, isPayment } = response.data.data;
          dispatch(isLoggedIn(true));
          dispatch(userId(id));
          if (isPremium) {
            dispatch(userType(true));
          }
          if (isBasic) {
            dispatch(userType(false));
          }
          dispatch(userPM(isPayment));
          dispatch(userStepStatus({ status: 'Registration' }));
          dispatch(userAccessToken({ accessToken: accessToken }));
          dispatch(userRefreshToken({ refreshToken: refreshToken }));
          dispatch(userEmail(''));
          dispatch(backHandle(false));
          dispatch(userTM(false));
          // navigate('/premium/choose-payment');
          navigate('/premium/choose-payment');
          // setTimeout(() => {
          //   // navigate('/premium/creat-team');
          //   navigate('/premium/choose-payment');
          // }, 2000);
        }
      })
      .catch((error) => {
        console.error(error);

        if (error.response.data.data != null) {
          setToast({ message: error.response.data.message.value, type: 'error' });
        } else {
          setToast({ message: error.response.data.message, type: 'error' });
        }
        handleClick();
      });
  };

  const handleVerificationField = (e: any) => {
    const { name, value } = e.target;

    const codeValue = value.replace(/\D/g, '');

    setVerificationCode((prevLogin) => ({
      ...prevLogin,
      [name]: codeValue,
    }));
    setError('');
  };

  const resendCode = () => {
    const resendCode = {
      email: props.userEmail,
    };

    // if (dataToSend.code === '') {
    //   setError('Please Enter the Code.');
    //   return;
    // }
    // debugger
    const resendCodeData = privateAxios.post(`invite/resentinvite`, resendCode, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    resendCodeData
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
        }
      })
      .catch((error: any) => {
        console.error(error);

        if (error.response.data.data != null) {
          setToast({ message: error.response.data.message.value, type: 'error' });
        } else {
          setToast({ message: error.response.data.message, type: 'error' });
        }
        handleClick();
      });
  };

  return (
    <Box>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Typography
          sx={{
            textAlign: 'left',
            fontFamily: "'Onest', sans-serif",
            fontWeight: '900',
            fontSize: '32px',
          }}>
          Check Your Email
        </Typography>
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            color: 'var(--gray-500, #667085)',
          }}>
          We have just sent you a verification code to your email. Enter that <br /> code below to verify your email.
        </Typography>
        <Box
          // component="form"
          // onSubmit={(e) => {
          //   e.preventDefault();
          //   handleVerificationCode();
          // }}
          sx={{ mt: 3 }}>
          <Typography
            className="lable-name"
            sx={{
              textAlign: 'left',
              display: 'flex',
              alignItems: 'flex-start',
              gap: '6px',
              alignSelf: 'stretch',
            }}>
            verification code<span className="mandatoryFields">*</span>
          </Typography>
          <TextField
            className="formFields"
            sx={{ width: '500px !important' }}
            name="verificationCode"
            placeholder="Enter code here."
            value={verificationCode.verificationCode}
            onChange={(e) => handleVerificationField(e)}
          />
          {Error && <Typography sx={{ color: 'red', mt: 1 }}>{Error}</Typography>}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              className="common-button-green"
              onClick={() => handleVerificationCode()}
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: 'none' }}>
              Verify Code
            </Button>

            <Button
              className="common-button-white"
              onClick={() => resendCode()}
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, ml: 2, width: '160px !important' }}>
              Resend
            </Button>
          </Box>
        </Box>
        <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleClose} toast={toast} />
      </Box>
    </Box>
  );
};

function mapDispatchToProps(state: any) {
  return {
    userAccessToken: state.userAccessToken,
    userEmail: state.email,
  };
}

export default connect(mapDispatchToProps)(PremiumVerification);

import { Box } from '@mui/material';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';

const ProtectedRouteLayout = (props: any) => {
  return <Box sx={{ background: '#F7F8F8' }}>{props.userAccessToken ? <Outlet /> : <Navigate to="/login" replace />}</Box>;
};

function mapDispatchToProps(state: any) {
  return {
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(ProtectedRouteLayout);

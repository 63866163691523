import React from 'react';
import { Box, Button } from '@mui/material';
import errImage from '../../Assets/Images/NotFound.png';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  userId,
  isLoggedIn,
  userType,
  userAccessToken,
  userRefreshToken,
  backHandle,
  userStepStatus,
  userDC,
  userDisElgble,
  customerID,
  userPM,
} from '../../reducer/action';

const PageNotFound = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirect = () => {
    if (props.isLoggedIn) {
      if (props.userStatus === 'Active') {
        navigate('/');
      } else if (props.userStatus === 'Registration' && props.userType) {
        navigate('/premium/sign-up');
      } else if (props.userStatus === 'Registration' && !props.userType) {
        navigate('/basic/sign-up');
      }
      // else if (props.userStatus === 'Registration' && !props.userPM) {
      //   navigate('/choose-payment');
      // }
      else if (props.userStatus === 'Registration' && props.userDC) {
        navigate('/premium/sign-up');
      }
    } else {
      dispatch(isLoggedIn(false));
      dispatch(backHandle(false));
      dispatch(userId(''));
      dispatch(customerID(''));
      dispatch(userStepStatus({ status: '' }));
      dispatch(userType(false));
      dispatch(userPM(false));
      dispatch(userAccessToken({ accessToken: '' }));
      dispatch(userRefreshToken({ refreshToken: '' }));
      dispatch(userDC(false));
      dispatch(userDisElgble(false));
      // dispatch(logOut());
      localStorage.removeItem('center');
      localStorage.clear();
      navigate('/login');
    }
  };

  return (
    <>
      <Box sx={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
        <img
          src={errImage}
          alt="pagenotfound"
          style={{ objectFit: 'cover', width: '100%', height: '100%', position: 'relative' }}
        />
        <Button
          variant="outlined"
          sx={{
            position: 'absolute',
            zIndex: '1',
            top: '68%',
            left: '50%',
            fontFamily: "'Onest', sans-serif",
            transform: 'translate(-50%,-50%)',
            backgroundColor: 'white',
            color: 'var(--Primary, #2CB512)',
            fontSize: '18px',
            fontWeight: '550',
            border: '1px solid white',
            textTransform: 'none',
            padding: '10px 85px',
            borderRadius: '16px',
            '&:hover': {
              backgroundColor: 'white',
              border: '1px solid white',
            },
          }}
          onClick={handleRedirect}>
          Back To Home
        </Button>
      </Box>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    isLoggedIn: state.isLoggedIn,
    userAccessToken: state.userAccessToken,
    userStatus: state.status,
    userType: state.userType,
    userPM: state.userPM,
    userDC: state.userDC,
  };
}

export default connect(mapDispatchToProps)(PageNotFound);

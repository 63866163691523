/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, TextField, InputAdornment, Button, Backdrop, CircularProgress } from '@mui/material';
import '../../../Assets/css/Common.css';
import uploadCloud from '../../../Assets/Images/uploadCloud.png';
// import Portfoliimage from '../../../Assets/Images/PortfoliImage.png';
import Social from './Social';
// import Env from 'services/Env';
import { connect } from 'react-redux';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CustomSnackbar from '../../../common/Snackbar';
import { useNavigate } from 'react-router-dom';
// import { IUserDetails } from '../../Interface/UserInterface/IUser';

// interface PersonalWorkSocialProps {
//   data: IUserDetails | null;
// }

interface SocialMedialProps {
  link: string;
  mediakey: string;
  key: string;
  label: string;
  logoPath: string;
}

interface DataProps {
  locationKey: string;
  workKey: string;
  rateKey: string;
  websiteURL: string;
  portfolio: string[];
  socialMedia: string[];
}

interface imageProps {
  imageUrl: string;
  handleImageDelete: any;
}

function PortfolioImage(props: imageProps) {
  const { imageUrl, handleImageDelete } = props;
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <Grid
        item
        sm={2}
        sx={{ position: 'relative' }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}>
        <img
          style={{ width: '100%', height: '120px', objectFit: 'cover', borderRadius: '8px' }}
          src={imageUrl}
          alt="portfolioimages"
          className="img-fluid upload-button"
        />
        {isHovered && (
          <Box
            onClick={() => handleImageDelete(imageUrl)}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              cursor: 'pointer',
              backgroundColor: 'white',
              width: '50px',
              height: '50px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
            }}>
            <DeleteForeverOutlinedIcon />
          </Box>
        )}
      </Grid>
    </>
  );
}

const PersonalPortfolios: React.FC = () => {
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();

  const [socialLinks, setSocialLinks] = useState<SocialMedialProps[]>([]);
  const [portfolioImages, setPortfolioImages] = useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [socialMediaValues, setSocialMediaValues] = useState([]);
  const [workPortfolio, setWorkPortfolio] = useState<DataProps>({
    locationKey: '',
    workKey: '',
    rateKey: '',
    websiteURL: '',
    portfolio: [],
    socialMedia: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    locationKey: '',
    workKey: '',
    rateKey: '',
    portfolio: [],
    socialMedia: [],
  });

  useEffect(() => {
    socialMediaData();
    // if (data !== null) {
    //   PersonalWorkPortfolioData(data);
    // }
    getUserDetails();
  }, []);

  // const PersonalWorkPortfolioData = (data: any) => {
  //   setWorkPortfolio({
  //     locationKey: data.availableLocation.key,
  //     workKey: data.workType.key,
  //     rateKey: data.rateFee.key,
  //     websiteURL: data.portfolioLink,
  //     portfolio: data.porfolio,
  //     socialMedia: data.socialMedia,
  //   });
  //   setPortfolioImages(data.porfolio);
  //   setSocialMediaValues(data.socialMedia);
  // };

  const socialMediaData = () => {
    const getSocialDatas = privateAxios.get(`user/socialMedia`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getSocialDatas
      .then((response: any) => {
        if (response.status === 200) {
          // console.log('RES', response.data.data);
          setSocialLinks(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error('RESPONSE', error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleLinkChange = (key: any, value: any) => {
    setSocialMediaValues((prevValues: any) =>
      prevValues.map((social: any) =>
        social.key === key
          ? {
              ...social,
              link: value
                .replace(/^\s+/, '')
                .replace(/\s+/g, ' ')
                .replace(/^[^a-zA-Z@.]+/, ''),
            }
          : social
      )
    );

    setWorkPortfolio((prevValues: any) => {
      const updatedSocialMedia = prevValues.socialMedia.map((social: any) =>
        social.mediaKey === key
          ? {
              ...social,
              link: value
                .replace(/^\s+/, '')
                .replace(/\s+/g, ' ')
                .replace(/^[^a-zA-Z@.]+/, ''),
            }
          : social
      );

      if (!prevValues.socialMedia.some((social: any) => social.mediaKey === key)) {
        updatedSocialMedia.push({
          mediaKey: key,
          link: value
            .replace(/^\s+/, '')
            .replace(/\s+/g, ' ')
            .replace(/^[^a-zA-Z@.]+/, ''),
        });
      }

      const emptyMediaKeys: any = new Set(
        updatedSocialMedia.filter((item: any) => item.mediaKey && item.link === '').map((item: any) => item.mediaKey)
      );

      const filteredData = updatedSocialMedia.filter(
        (item: any) => !emptyMediaKeys.has(item.key) && !(item.mediaKey && item.link === '')
      );

      return {
        ...prevValues,
        socialMedia: filteredData,
      };
    });
  };

  const handleCancelClick = () => {
    navigate('/account-dashboard');
  };

  const handleSubmitClick = () => {
    // console.log('workPortfolioworkPortfolio', workPortfolio);

    const { locationKey, workKey, rateKey } = workPortfolio;

    const errors: any = {
      locationKey: '',
      workKey: '',
      rateKey: '',
      socialMedia: '',
    };

    if (!locationKey) {
      errors.locationKey = 'Location Availablity is required';
    }

    if (!workKey) {
      errors.workKey = 'Type of Work is required';
    }

    if (!rateKey) {
      errors.rateKey = 'Rate is required';
    }

    if (Object.values(errors).some((field) => field !== '')) {
      setError(errors);
      return;
    }

    if (portfolioImages?.length < 4) {
      setToast({ message: 'Please upload at least 4 portfolio images', type: 'error' });
      handleClick();
      return; // Exit the function to prevent further execution
    }

    const modifiedSocialMedia = workPortfolio.socialMedia
      .filter((item: any) => item.link.trim() !== '')
      .map((item: any) => {
        if (!item.mediaKey && item.label && item.key) {
          return { mediaKey: item.key, link: item.link };
        }
        return item;
      });

    const workPortetails = privateAxios.put(
      `user/worksocialinfo`,
      {
        ...workPortfolio,
        socialMedia: modifiedSocialMedia,
        portfolio: portfolioImages?.flat(),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    workPortetails
      .then((response: any) => {
        if (response.status === 200) {
          getUserDetails();
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleImageDelete = (url: any) => {
    setWorkPortfolio((ps) => {
      const newPortfolio = ps.portfolio.filter((item) => item !== url);
      return { ...ps, portfolio: newPortfolio };
    });
    setPortfolioImages((ps) => {
      const newImageArray = ps.filter((item) => item !== url);
      return newImageArray;
    });

    // const deleteImage = Env.deleteUpload(`user/deleteportfolio`, { imagePath: url }, userAccessToken);
    const deleteImage = privateAxios.delete(`user/deleteportfolio?imagePath=${url}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    deleteImage
      .then((res: any) => {
        setToast({ message: res.data.message.value, type: 'success' });
        handleClick();
      })
      .catch((err: any) => {
        console.error(err);
        setToast({ message: err.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const uploadAllImage = (e: any, type: any) => {
    if (type == 'Portfolio_image') {
      document.getElementById(`icon_profile_icon`)?.click();
    }
  };

  const handleDrop = (e: any, type: any) => {
    e.preventDefault();

    const files: File[] = e.dataTransfer.files;

    if (files?.length === 0) return;

    if (files?.length > 6) {
      setToast({ message: 'You can only select up to 6 images', type: 'error' });
      handleClick();
      return;
    }

    Array.from(files).forEach((file: File) => {
      const reader = new FileReader();
      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        setToast({ message: 'Unsupported File type ', type: 'error' });
        handleClick();
        return;
      }

      reader.onload = async (event: any) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          // if (image.width > 500 || image.height > 500) {
          //   setToast({ message: 'Image dimensions are large', type: 'error' });
          //   handleClick();
          //   return;
          // }

          if (type === 'portfolioImage') {
            // if (workPortfolio.portfolio.length < 6) {
            if (portfolioImages?.length < 6) {
              const formData = new FormData();
              formData.append('files', file);

              // const responsesssss = Env.postUpload('user/portfolioupload', formData, userAccessToken);

              const responsesssss = privateAxios.post(`user/portfolioupload`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
              responsesssss
                .then((response: any) => {
                  if (response.status === 200) {
                    setPortfolioImages((prevImages) => [...prevImages, response.data.data]);

                    // setWorkPortfolio((prevPortfolios: any) => ({
                    //   ...prevPortfolios,
                    //   portfolio: [...prevPortfolios.portfolio, response.data.data],
                    // }));
                    setToast({ message: response.data.message.value, type: 'success' });
                    handleClick();
                  }
                })
                .catch((error: any) => {
                  console.error(error);
                  setToast({ message: error.response.data.message.value, type: 'error' });
                  handleClick();
                });
            } else {
              // Display a message or take action if the limit is reached
              setToast({ message: 'You can upload up to 6 images only', type: 'error' });
              handleClick();
            }
          }
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleFileUpload = async (e: any, type: any) => {
    const files: File[] = e.target.files;

    if (files.length === 0) return;

    if (files.length > 6) {
      setToast({ message: 'You can only select up to 6 images', type: 'error' });
      handleClick();
      return;
    }

    Array.from(files).forEach((file: File) => {
      const reader = new FileReader();
      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        setToast({ message: 'Unsupported File type ', type: 'error' });
        handleClick();
        return;
      }

      reader.onload = async (event: any) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          // if (image.width > 500 || image.height > 500) {
          //   setToast({ message: 'Image dimensions are large', type: 'error' });
          //   handleClick();
          //   return;
          // }

          if (type === 'portfolioImage') {
            // if (workPortfolio.portfolio.length < 6) {
            if (portfolioImages?.length < 6) {
              const formData = new FormData();
              formData.append('files', file);

              // const responsesssss = Env.postUpload('user/portfolioupload', formData, userAccessToken);

              const responsesssss = privateAxios.post(`user/portfolioupload`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
              responsesssss
                .then((response: any) => {
                  if (response.status === 200) {
                    setPortfolioImages((prevImages) => [...prevImages, response.data.data]);

                    // setWorkPortfolio((prevPortfolios: any) => ({
                    //   ...prevPortfolios,
                    //   portfolio: [...prevPortfolios.portfolio, response.data.data],
                    // }));
                    setToast({ message: response.data.message.value, type: 'success' });
                    handleClick();
                  }
                })
                .catch((error: any) => {
                  console.error(error);
                  setToast({ message: error.data.message.value, type: 'error' });
                  handleClick();
                });
            } else {
              // Display a message or take action if the limit is reached
              setToast({ message: 'You can upload up to 6 images only', type: 'error' });
              handleClick();
            }
          }
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const handleWebsiteValue = (e: any) => {
    setWorkPortfolio((prevPortfolio) => ({
      ...prevPortfolio,
      websiteURL: e.target.value
        .replace(/^\s+/, '')
        .replace(/\s+/g, ' ')
        .replace(/^[^a-zA-Z@.]+/, ''),
    }));

    // setError((pre) => ({
    //   ...pre,
    //   websiteURL: '',
    // }));
  };

  const handleSocialDataChange = (newData: any) => {
    setWorkPortfolio((prevData: any) => ({
      ...prevData,
      ...newData,
    }));
  };

  const getUserDetails = () => {
    setLoading(true);
    const getDetails = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          setWorkPortfolio({
            locationKey: data.availableLocation.key,
            workKey: data.workType.key,
            rateKey: data.rateFee.key,
            websiteURL: data.portfolioLink,
            portfolio: data.portfolio,
            socialMedia: data.socialMedia,
          });
          setPortfolioImages(data.portfolio);
          setSocialMediaValues(data.socialMedia);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const updateErrorStatus = (err: any) => {
    setError((pre) => ({
      ...pre,
      locationKey: err.locationKey,
      workKey: err.workKey,
      rateKey: err.rateKey,
    }));
  };

  return (
    <>
      <div style={{ margin: '0px', position: 'relative' }}>
        <Grid
          container
          className="button-position"
          sx={{
            position: 'absolute',
            top: -140,
            right: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}>
          <Button
            className="common-button-white"
            type="submit"
            variant="contained"
            onClick={handleCancelClick}
            sx={{
              width: '167px',
              height: '44px',
              marginRight: 1,
              '&:hover': {
                color: 'var(--Primary, #2CB512)',
                backgroundColor: '#fff',
              },
            }}>
            Cancel
          </Button>
          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleSubmitClick}
            sx={{
              width: '121px',
              height: '44px',
              marginLeft: 1,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save
          </Button>
        </Grid>
      </div>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress color="success" />
        </Backdrop>
      )}

      {!loading && (
        <Box sx={{ p: '20px', backgroundColor: '#FFFFFF', borderRadius: '10px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {workPortfolio !== null ? (
                <Box>
                  <Social
                    data={workPortfolio}
                    onDataChange={handleSocialDataChange}
                    error={error}
                    changeError={updateErrorStatus}
                  />
                </Box>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="lable-name"
                sx={{
                  textAlign: 'left',
                  mt: '15px',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                }}>
                Portfolio images (Up to 6 images are allowed)
              </Typography>
              <Grid container spacing={1} columns={16} sx={{ mt: '15px' }}>
                {portfolioImages &&
                  portfolioImages?.length > 0 &&
                  portfolioImages
                    .slice(0, 6)
                    .map((imageUrl, index) => (
                      <PortfolioImage key={index} imageUrl={imageUrl} handleImageDelete={handleImageDelete} />
                    ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ mt: '12px' }}>
                <Box
                  className="p-image imageBox"
                  sx={{
                    height: '105px',
                    textAlign: 'center',
                    width: '100%',
                    pt: '25px',
                  }}
                  onClick={(e) => uploadAllImage(e, 'Portfolio_image')}
                  onDrop={(e) => handleDrop(e, 'portfolioImage')}
                  onDragOver={handleDragOver}>
                  <>
                    <img src={uploadCloud} alt="img" className="img-fluid upload-button" />
                    <input
                      className="file-upload"
                      id="icon_profile_icon"
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileUpload(e, 'portfolioImage')}
                      multiple
                    />
                  </>
                  <input className="file-upload" type="file" accept="image/*" />
                  <Typography
                    className="note"
                    sx={{
                      color: 'var(--Primary, #2CB512)',
                      textAlign: 'center',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '18px',
                    }}>
                    Drag & Drop, or Click to upload
                  </Typography>
                  <Typography
                    sx={{
                      color: 'var(--gray-400, #98A2B3)',
                      textAlign: 'center',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '18px',
                    }}>
                    JPG, PNG Format
                  </Typography>
                </Box>
              </Box>
              <Grid>
                <Grid item xs={12}>
                  <Typography
                    className="lable-name"
                    sx={{
                      textAlign: 'left',
                      mt: '15px',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                    }}>
                    Portfolio Website
                  </Typography>
                  {workPortfolio.websiteURL !== undefined && (
                    <TextField
                      className="formFields"
                      sx={{ mt: 0, width: '850px' }}
                      type="text"
                      name="websiteURL"
                      value={workPortfolio.websiteURL === null ? '' : workPortfolio.websiteURL}
                      onChange={handleWebsiteValue}
                      placeholder="Portfolio URL..."
                      required
                    />
                  )}
                </Grid>
              </Grid>
              <Grid>
                <Grid>
                  <Typography
                    className="lable-name"
                    sx={{
                      textAlign: 'left',
                      mt: '15px',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                    }}>
                    Social Media
                  </Typography>
                  {socialLinks.map((socialMedia: SocialMedialProps) => {
                    const foundSocialMedia = socialMediaValues.find((item: any) => item?.key === socialMedia.key) || {
                      link: '',
                    };

                    const linkValue = foundSocialMedia.link ?? '';

                    return (
                      <TextField
                        key={socialMedia.key}
                        className="formFields"
                        sx={{ mt: 2, width: '850px' }}
                        type="text"
                        name={socialMedia.key}
                        placeholder={`Add ${socialMedia.label} link`}
                        value={linkValue !== undefined ? (linkValue === '' ? socialMedia?.link : linkValue || '') : ''}
                        onChange={(e: any) =>
                          handleLinkChange(
                            socialMedia.key,
                            e.target.value
                              .replace(/^\s+/, '')
                              .replace(/\s+/g, ' ')
                              .replace(/^[^a-zA-Z@.]+/, '')
                          )
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={socialMedia.logoPath === undefined ? '' : socialMedia.logoPath}
                                alt={socialMedia.label}
                                style={{ width: '24px', height: '24px' }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        required
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
        </Box>
      )}
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(PersonalPortfolios);

/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, ToggleButton, ToggleButtonGroup, Button } from '@mui/material';
import '../../../Assets/css/Common.css';
// import { styled } from '@mui/material/styles';
// import Env from '../../../services/Env';
import { connect } from 'react-redux';
import CustomSnackbar from '../../../common/Snackbar';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';

interface step {
  label: string;
  key: string;
}

interface WorkDetailsProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  stepKeys: step;
  // userAccessToken: string;
  // id: number;
}

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 28,
//   height: 16,
//   padding: 0,
//   display: 'flex',
//   '&:active': {
//     '& .MuiSwitch-thumb': {
//       width: 15,
//     },
//     '& .MuiSwitch-switchBase.Mui-checked': {
//       transform: 'translateX(9px)',
//     },
//   },
//   '& .MuiSwitch-switchBase': {
//     padding: 2,
//     '&.Mui-checked': {
//       transform: 'translateX(12px)',
//       color: '#fff',
//       '& + .MuiSwitch-track': {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2CB512',
//       },
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
//     width: 12,
//     height: 12,
//     borderRadius: 6,
//     transition: theme.transitions.create(['width'], {
//       duration: 200,
//     }),
//   },
//   '& .MuiSwitch-thumb:hover': {
//     color: 'red',
//   },
//   '& .MuiSwitch-track': {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
//     boxSizing: 'border-box',
//   },
// }));

const WorkDetails: React.FC<WorkDetailsProps> = ({ activeStep, handleNext, handleBack, stepKeys }) => {
  const privateAxios = useAxiosPrivate();

  const [alignment, setAlignment] = React.useState({
    stepperKey: stepKeys.key,
    // userId: id,
    locationKey: '',
    workKey: '',
    rateKey: '',
  });

  const [locationAvailability, setLocationAvailability] = useState([]);
  const [rateAndFees, setRateAndFees] = useState([]);
  const [work, setWork] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [, setDataSubmitted] = useState(false);
  const [existingData, setExistingData] = useState<any>(null);
  const [Error, setError] = useState({
    locationKey: '',
    workKey: '',
    rateKey: '',
  });

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    workDetailsData();
    getWorkDetails();
  }, []);

  const getWorkDetails = () => {
    // const getData = Env.get(`user/getuserstepper?stepperKey=${stepKeys.key}&userId=${id}`, userAccessToken);

    const getData = privateAxios.get(`user/getuserstepper?stepperKey=${stepKeys.key}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getData
      .then((response: any) => {
        if (response.status === 200) {
          setExistingData(response.data.data);

          setAlignment({
            stepperKey: stepKeys.key,
            // userId: id,
            locationKey: response.data.data.locationAvailable.key,
            workKey: response.data.data.workType.key,
            rateKey: response.data.data.rateFees.key,
          });
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const workDetailsData = () => {
    // const workdetails = Env.get(`user/workDetails`, userAccessToken);

    const workdetails = privateAxios.get(`user/workDetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    workdetails
      .then((response: any) => {
        if (response.status === 200) {
          setLocationAvailability(response.data.data.locationAvailability);
          setRateAndFees(response.data.data.rateAndFees);
          setWork(response.data.data.workType);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const handleWorkDetailsData = () => {
    const { locationKey, workKey, rateKey } = alignment;

    const errors = {
      locationKey: '',
      workKey: '',
      rateKey: '',
    };

    if (!locationKey) {
      errors.locationKey = 'Location Availablity is required';
    }

    if (!workKey) {
      errors.workKey = 'Type of Work is required';
    }

    if (!rateKey) {
      errors.rateKey = 'Rate is required';
    }

    if (Object.values(errors).some((field) => field !== '')) {
      setError(errors);
      return;
    }

    const changesDetected =
      existingData &&
      (existingData.locationAvailable.key !== alignment.locationKey ||
        existingData.workType.key !== alignment.workKey ||
        existingData.rateFees.key !== alignment.rateKey);

    if (changesDetected || changesDetected === null) {
      // const workdetails = Env.post(`user/register`, alignment, userAccessToken);

      const workdetails = privateAxios.post(`user/register`, alignment, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      workdetails
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setTimeout(() => handleNext(), 2000);
            setDataSubmitted(true);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
      return;
    } else if (alignment.locationKey === '' || alignment.workKey === '' || alignment.rateKey === '') {
      // const workdetails = Env.post(`user/register`, alignment, userAccessToken);

      const workdetails = privateAxios.post(`user/register`, alignment, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      workdetails
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setTimeout(() => handleNext(), 2000);
            setDataSubmitted(true);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    } else {
      handleNext();
      return;
    }
  };

  const handleAlignmentChange = (group: any, newValue: any) => {
    setAlignment((prevAlignment) => ({
      ...prevAlignment,
      [group]: newValue,
    }));

    setError((preError: any) => ({
      ...preError,
      [group]: '',
    }));
  };

  return (
    <>
      <Typography
        sx={{
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '38px',
          paddingTop: '30px',
        }}>
        Work Details
      </Typography>
      <Box>
        <Grid container>
          <Grid item sm={12}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '15px',
                color: '#344054',
              }}>
              Location Availability<span className="mandatoryFields">*</span>
            </Typography>
            <Grid alignItems="center" sx={{ mt: 1 }}>
              <ToggleButtonGroup
                color="primary"
                sx={{ border: '1px solid #D0D5DD', borderRadius: '6px' }}
                value={alignment.locationKey}
                exclusive
                onChange={(event, newValue) => handleAlignmentChange('locationKey', newValue)}
                aria-label="Location Availability">
                {locationAvailability.map((location: { key: string; label: string }) => (
                  <ToggleButton
                    key={location.key}
                    sx={{
                      width: '275px',
                      height: '36px',
                      margin: '3px',
                      textTransform: 'none',
                      border: 'none',
                      color: (theme: any) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                      backgroundColor: (theme: any) => (theme.palette.mode === 'dark' ? '#fff' : '#fff'),
                      '&.Mui-selected': {
                        backgroundColor: '#fff',
                        color: '#2CB512',
                        boxShadow: `0 0px 10px 0px rgba(0,0,0,0.2), 0 2px 10px rgba(0,0,0,0.2)`,
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#fff',
                      },
                    }}
                    value={location.key}>
                    {location.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            {Error.locationKey && <Typography sx={{ color: 'red', mt: 1 }}>{Error.locationKey}</Typography>}
          </Grid>
        </Grid>
        <Grid>
          <Grid item sm={12}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '15px',
                color: '#344054',
              }}>
              Type of Work<span className="mandatoryFields">*</span>
            </Typography>
            <Grid alignItems="center" sx={{ mt: 1 }}>
              <ToggleButtonGroup
                color="primary"
                sx={{ border: '1px solid #D0D5DD', borderRadius: '6px' }}
                value={alignment.workKey}
                exclusive
                onChange={(event, newValue) => handleAlignmentChange('workKey', newValue)}
                aria-label=" Type of Work">
                {work.map((work: { key: string; label: string }) => (
                  <ToggleButton
                    key={work.key}
                    sx={{
                      width: '275px',
                      height: '36px',
                      margin: '3px',
                      textTransform: 'none',
                      border: 'none',
                      color: (theme: any) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                      backgroundColor: (theme: any) => (theme.palette.mode === 'dark' ? '#fff' : '#fff'),
                      '&.Mui-selected': {
                        backgroundColor: '#fff',
                        color: '#2CB512',
                        boxShadow: `0 0px 10px 0px rgba(0,0,0,0.2), 0 2px 10px rgba(0,0,0,0.2)`,
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#fff',
                      },
                    }}
                    value={work.key}>
                    {work.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            {Error.workKey && <Typography sx={{ color: 'red', mt: 1 }}>{Error.workKey}</Typography>}
          </Grid>
          <Grid item sm={12}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '15px',
                color: '#344054',
              }}>
              Rate and Fees<span className="mandatoryFields">*</span>
            </Typography>
            <Grid alignItems="center" sx={{ mt: 1 }}>
              <ToggleButtonGroup
                color="primary"
                sx={{ border: '1px solid #D0D5DD', borderRadius: '6px' }}
                value={alignment.rateKey}
                exclusive
                onChange={(event, newValue) => handleAlignmentChange('rateKey', newValue)}
                aria-label="Rate and Fees">
                {rateAndFees.map((rateAndFee: { key: any; label: string }) => (
                  <ToggleButton
                    key={rateAndFee.key}
                    sx={{
                      width: '275px',
                      height: '36px',
                      margin: '3px',
                      textTransform: 'none',
                      border: 'none',
                      color: (theme: any) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                      backgroundColor: (theme: any) => (theme.palette.mode === 'dark' ? '#fff' : '#fff'),
                      '&.Mui-selected': {
                        backgroundColor: '#fff',
                        color: '#2CB512',
                        boxShadow: `0 0px 10px 0px rgba(0,0,0,0.2), 0 2px 10px rgba(0,0,0,0.2)`,
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: '#fff',
                      },
                    }}
                    value={rateAndFee.key}>
                    {rateAndFee.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            {Error.rateKey && <Typography sx={{ color: 'red', mt: 1 }}>{Error.rateKey}</Typography>}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: '50px', paddingTop: '40px' }}>
        <Box sx={{ textAlign: 'center' }}>
          {activeStep === 1 ? (
            ''
          ) : (
            <Button
              className="common-button-white"
              type="submit"
              variant="contained"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                marginRight: 1,
                mt: 3,
                mb: 2,
                '&:hover': {
                  color: 'var(--Primary, #2CB512)',
                  backgroundColor: '#fff',
                },
                alignItems: 'center',
              }}>
              Back
            </Button>
          )}

          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleWorkDetailsData}
            disabled={activeStep === 10}
            sx={{
              marginLeft: 1,
              mt: 3,
              mb: 2,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save and Next
          </Button>
        </Box>
        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Box>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(WorkDetails);

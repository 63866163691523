import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import BasicStructure from '../auth/BasicStructure';
import LoginForm from '../auth/Login';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
import PlanPage from '../auth/PlanPage';
import BasicRouter from './BasicRouter';
import PremiumRouter from './PremiumRouter';

// import BookingStatus from '../Booking/BookingStatus';
// import PageNotFound from '../auth/PageNotFound';
// import ChoosePayment from '../auth/Payment/ChoosePayment';
// import SuccessDialog from '../auth/Payment/SuccessDialog';

const LoginRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="login" replace />} />
      <Route path="/login" element={<BasicStructure />}>
        <Route index element={<LoginForm />} />
      </Route>
      <Route path="/forget-password" element={<BasicStructure />}>
        <Route index element={<ForgotPassword />} />
      </Route>
      <Route path="/reset-password" element={<BasicStructure />}>
        <Route index element={<ResetPassword />} />
      </Route>
      <Route path="/plan" element={<PlanPage />} />
      <Route path="/plan/basic/*" element={<BasicRouter />} />
      <Route path="/plan/premium/*" element={<PremiumRouter />} />

      {/* <Route path="/dashboard/acceptbooking" element={<BookingStatus />} />
      <Route path="/dashboard/rejectbooking" element={<BookingStatus />} /> */}
      {/* <Route path="/choose-payment" element={<ChoosePayment />} />
      <Route path="/paypal/payment/succes" element={<SuccessDialog />} /> */}
      {/* <Route path="/paypal/payment/cancel" element={} /> */}

      {/* Handle 404 - Not Found */}

      {/* <Route path="/not-found" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/not-found" replace />} /> */}
    </Routes>
  );
};

// function mapStateToProps(state: any) {
//   return {
//     // isLoggedIn: state.isLoggedIn,
//   };
// }

// export default connect(mapStateToProps)(LoginRouter);
export default LoginRouter;

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CustomSnackbar from '../../../common/Snackbar';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import CloseIcon from '@mui/icons-material/Close';

const InviteMember = ({ openDialog, close, userRoster }: any) => {
  const privateAxios = useAxiosPrivate();

  const [enteredEmail, setEnteredEmail] = useState<string>('');
  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);
  const [error, setError] = useState({
    email: '',
    validEmail: '',
  });
  const [toast, setToast] = useState({ message: '', type: 'success' });
  const [open, setOpen] = useState(false);

  const handleInputChange = (event: any) => {
    setEnteredEmail(
      event.target.value
        .replace(/^\s+/, '')
        .replace(/\s+/g, ' ')
        .replace(/^[^a-zA-Z@.]+/, '')
    );
    setError((preError: any) => ({
      ...preError,
      validEmail: '',
      email: '',
    }));
  };

  const isValidEmail = (email: any) => {
    const emails = email.split(/[,\s]+/);

    const isValid = emails.every((part: any) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(part);
    });
    return isValid;
  };

  const handleInviteClick = () => {
    if (!isValidEmail(enteredEmail)) {
      setError((preError) => ({ ...preError, validEmail: 'Enter valid email' }));
      return;
    }

    const enteredEmailsArray = enteredEmail
      .split(/[,\s]+/) // Split by commas or spaces
      .filter((email) => email.trim() !== ''); // Remove empty entries

    const duplicates = enteredEmailsArray.filter(
      (email) => invitedEmails.includes(email) || invitedEmails.includes(email.trim())
    );

    if (duplicates.length > 0) {
      setError((preError: any) => ({ ...preError, validEmail: 'Emails already invited' }));
      return;
    }

    setInvitedEmails((prevEmails) => [...prevEmails, ...enteredEmailsArray]);
    setEnteredEmail('');
    setError((preError: any) => ({
      ...preError,
      validEmail: '',
      email: '',
    }));
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddRosterMembersData = () => {
    const email = {
      email: invitedEmails,
    };

    if (invitedEmails.length === 0) {
      setError((pre: any) => ({
        ...pre,
        email: 'Enter Atleast one member',
      }));
      return;
    }
    const postData = privateAxios.post(`dashboard/addroaster`, email, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    postData
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          close();
          userRoster();
          handleClick();
        }
      })
      .catch((error: any) => {
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const reset = () => {
    setError({
      email: '',
      validEmail: '',
    });
    close();
  };

  return (
    <Dialog
      open={openDialog}
      sx={{
        '& .MuiDialog-paper': {
          width: '650px',
          maxWidth: '950px !important',
          height: 'auto',
          padding: '10px',
        },
      }}>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: "'Onest', sans-serif",
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '28px',
            color: 'var(--green)',
            flex: '1',
          }}>
          Invite members to your roster
        </Typography>
        <IconButton onClick={close} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item lg={12}>
            <TextField
              className="formFields invit-field"
              fullWidth
              sx={{ mt: 0 }}
              type="email"
              name="email"
              placeholder="Enter email address"
              value={enteredEmail}
              onChange={handleInputChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      className="common-button-green"
                      sx={{ width: '100px !important', height: '40px !important' }}
                      onClick={handleInviteClick}>
                      Add
                    </Button>
                  </InputAdornment>
                ),
                sx: { borderColor: 'grey' },
              }}
            />
            {error.email && <div style={{ color: 'red' }}>{error.email}</div>}
            {error.email === '' ? error.validEmail && <div style={{ color: 'red' }}>{error.validEmail}</div> : ''}
          </Grid>
          <Grid item lg={12} sx={{ width: '100%' }}>
            {invitedEmails.map((email) => (
              <Chip
                key={email}
                label={email}
                style={{ margin: '4px' }}
                onDelete={() => {
                  setInvitedEmails((prevEmails) => prevEmails.filter((e) => e !== email));
                }}
              />
            ))}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button className="common-button-white" onClick={reset} sx={{ width: '150px', color: 'var(--white)' }}>
          Cancel
        </Button>
        <Button
          className="common-button-green"
          onClick={handleAddRosterMembersData}
          sx={{
            width: '150px',
            color: '#fff',
            '&:hover': {
              background: 'var(--Primary, #2CB512)',
            },
          }}>
          Send Invite
        </Button>
      </DialogActions>
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </Dialog>
  );
};

export default InviteMember;

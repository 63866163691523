import React from 'react';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface MapProps {
  center: { lat: number; lng: number };
  zoom: number;
}

interface MarkerProps {
  lat: number;
  lng: number;
  text: string;
}

const LocationMarker: React.FC<MarkerProps> = () => (
  <div style={{ color: 'red', fontSize: '18px' }}>
    <LocationOnIcon />
  </div>
);

const Map: React.FC<MapProps> = ({ center, zoom }) => {
  return (
    <div style={{ height: '200px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_API_GOOGLE_MAP_KEY }}
        center={center}
        defaultZoom={zoom}>
        <LocationMarker lat={center.lat} lng={center.lng} text="Your Location" />
      </GoogleMapReact>
    </div>
  );
};

export default Map;

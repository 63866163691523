import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BasicStructure from '../auth/BasicStructure';
import PremiumUser from '../auth/EmailConfirmation/PremiumUser';
import PremiumVerification from '../auth/EmailConfirmation/PremiumVerification';
import { connect } from 'react-redux';

const PremiumRouter = () => {
  return (
    <Routes>
      <Route path="/email-confirmation" element={<BasicStructure />}>
        <Route index element={<PremiumUser />} />
      </Route>
      <Route path="/email-verification" element={<BasicStructure />}>
        <Route index element={<PremiumVerification />} />
      </Route>
    </Routes>
  );
};

function mapDispatchToProps(state: any) {
  return {
    isLoggedIn: state.isLoggedIn,
  };
}

export default connect(mapDispatchToProps)(PremiumRouter);

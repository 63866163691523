/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  // TextField,
  Grid,
  Select,
  MenuItem,
  // Autocomplete,
  IconButton,
  DialogTitle,
} from '@mui/material';
import '../../Assets/css/HomeScreen.css';
import '../../Assets/css/Sidebar.css';
// import profile from '../../Assets/Images/profile.svg';
import skillsIcon from '../../Assets/Images/skillsIcon.svg';
import toolsIcon from '../../Assets/Images/toolsIcon.svg';
import locateIcon from '../../Assets/Images/locateIcon.svg';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import CloseIcon from '@mui/icons-material/Close';

interface Skill {
  label: string;
  key: string;
  information: string;
}
interface Skills {
  category: {
    label: string;
    key: string;
  };
  skills: Skill[];
}
interface Tool {
  label: string;
  key: string;
}
interface Tools {
  category: {
    label: string;
    key: string;
  };
  tools: Tool[];
}
interface LocationType {
  country: string;
  state: string;
  language: string;
}

interface SidebarProps {
  filterStructure: any;
  updateFilterStructure: (newStructure: any) => void;
  onChangeSkillData: (newSkillsData: any[]) => void;
  onChangeToolData: (newToolsData: any[]) => void;
  onChangeCategoryData: (newCategoryData: any[]) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  filterStructure,
  updateFilterStructure,
  onChangeSkillData,
  onChangeToolData,
  onChangeCategoryData,
}) => {
  const privateAxios = useAxiosPrivate();

  const [rolesDialogOpen, setRolesDialogOpen] = useState(false);
  const [rolesData, setRolesData] = useState<string[]>([]);
  const [skillsData, setSkillsData] = useState<Skills[]>([]);
  const [toolsData, setToolsData] = useState<Tools[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [skillsDialogOpen, setSkillsDialogOpen] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [toolsDialogOpen, setToolsDialogOpen] = useState(false);
  const [locateDialogOpen, setLocateDialogOpen] = useState(false);
  const [selectedTools, setSelectedTools] = useState<string[]>([]);
  const [language, setLanguage] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [states, setStates] = useState<string[] | any>([]);
  const [selectedLocations, setSelectedLocations] = useState<LocationType>({
    country: '',
    state: '',
    language: '',
  });
  const [searchLoc, setSearchLoc] = useState({ state: '', country: '' });

  const [isCategoryBtnDis, setCategoryBtnDis] = useState(true);

  // useEffect to check if any checkbox is initially checked
  useEffect(() => {
    setCategoryBtnDis(selectedRoles.length === 0);
  }, [selectedRoles]);

  const [isSkillsBtnDis, setSkillsBtnDis] = useState(true);

  // useEffect to check if any checkbox is initially checked
  useEffect(() => {
    setSkillsBtnDis(selectedSkills.length === 0);
  }, [selectedSkills]);

  const [isToolsBtnDis, setToolsBtnDis] = useState(true);

  // useEffect to check if any checkbox is initially checked
  useEffect(() => {
    setToolsBtnDis(selectedTools.length === 0);
  }, [selectedTools]);

  useEffect(() => {
    getCategory();
    getSkills();
    getTools();
    getLanguage();
    getCountry();
    getStates();
  }, []);

  useEffect(() => {
    if (searchLoc.country.length > 1 || searchLoc.state.length > 1) {
      getCountry();
      getStates();
    }
  }, [searchLoc]);

  useEffect(() => {
    setSelectedRoles(filterStructure.categoryKey || []);
    setSelectedSkills(filterStructure.skillsKey || []);
    setSelectedTools(filterStructure.toolsKey || []);
    setSelectedLocations({
      country: filterStructure.country || '',
      state: filterStructure.state || '',
      language: filterStructure.language || '',
    });
  }, [filterStructure]);

  const updateStructureAndFilter = () => {
    const filterStructure = {
      categoryKey: selectedRoles.length > 0 ? selectedRoles : '',
      categoryskillsKey: selectedSkills.length > 0 ? getSkillsCategoryKey(selectedSkills) : '',
      categorytoolsKey: selectedTools.length > 0 ? getToolsCategoryKey(selectedTools) : '',
      skillsKey: selectedSkills,
      toolsKey: selectedTools,
      country: selectedLocations.country,
      state: selectedLocations.state,
      language: selectedLocations.language,
      skillLabels: selectedSkills.map((skillKey) => getSkillLabel(skillKey)),
      toolLabels: selectedTools.map((toolKey) => getToolLabel(toolKey)),
      categoryLabels: selectedRoles.map((categorykey) => getCategoryLabel(categorykey)),
    };
    updateFilterStructure(filterStructure);
  };

  const getCategory = () => {
    const getCategoryValues = privateAxios.get(`dashboard/getcategory`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getCategoryValues
      .then((response: any) => {
        if (response.status === 200) {
          setRolesData(response.data.data);
          onChangeCategoryData(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  const getSkills = () => {
    const containsSkillsKey = skillsData.some((skill: any) =>
      selectedRoles.some((rolekey) => {
        const isEqual = skill.category.key.trim() === rolekey;
        return isEqual;
      })
    );

    if (containsSkillsKey) {
      const value = {
        categoryKey: selectedRoles,
      };
      const getSkillsValues = privateAxios.post(`dashboard/getskills`, value, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      getSkillsValues
        .then((response: any) => {
          if (response.status === 200) {
            setSkillsData(response.data.data);
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else {
      const value = {
        categoryKey: [],
      };
      const getSkillsValues = privateAxios.post(`dashboard/getskills`, value, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      getSkillsValues
        .then((response: any) => {
          if (response.status === 200) {
            setSkillsData(response.data.data);
            onChangeSkillData(response.data.data);
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  };
  const getTools = () => {
    const containsToolsKey = toolsData.some((tool: any) =>
      selectedRoles.some((rolekey) => {
        const isEqual = tool.category.key.trim() === rolekey;
        return isEqual;
      })
    );
    if (containsToolsKey) {
      const value = {
        categoryKey: selectedRoles,
      };
      const getToolsValues = privateAxios.post(`dashboard/gettools`, value, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      getToolsValues
        .then((response: any) => {
          if (response.status === 200) {
            setToolsData(response.data.data);
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else {
      const value = {
        categoryKey: [],
      };
      const getToolsValues = privateAxios.post(`dashboard/gettools`, value, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      getToolsValues
        .then((response: any) => {
          if (response.status === 200) {
            setToolsData(response.data.data);
            onChangeToolData(response.data.data);
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  };
  const getCountry = () => {
    // const country = {
    //   country: searchLoc.country,
    // };
    const getCountryData = privateAxios.post(`dashboard/getcountry`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getCountryData
      .then((response: any) => {
        setCountries(response.data.data);

        // getStates(type, searchLoc.country);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  const getStates = () => {
    // let searchValue;
    // if (type === 'country') {
    const searchValue = {
      country: searchLoc.country,
      // state: searchLoc.state,
    };

    // } else {
    //   searchValue = {
    //     country: '',
    //     state: searchLoc.state,
    //   };
    // }

    const getStatesData = privateAxios.post(`dashboard/getstate`, searchValue, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getStatesData
      .then((response: any) => {
        setStates(response.data.data);
        setSearchLoc({ state: '', country: '' });
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  const getLanguage = () => {
    const getLanList = privateAxios.post(`dashboard/getlangange`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getLanList
      .then((response: any) => {
        setLanguage(response.data.data);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  // const handleRolesDialogOpen = () => {
  //   setRolesDialogOpen(true);
  // };
  const handleSkillsDialogOpen = () => {
    setSkillsDialogOpen(true);
  };
  // const handleSkillsDialogClose = () => {
  //   setSkillsDialogOpen(false);
  //   onSkillsChange(selectedSkills);
  // };

  const getSkillLabel = (skillKey: string) => {
    const categoryWithSkill = skillsData.find((category) => category.skills.some((skill) => skill.key === skillKey));
    const skill = categoryWithSkill?.skills.find((skill) => skill.key === skillKey);
    return skill?.label || '';
  };

  const getSkillsCategoryKey = (selectedSkills: string[]) => {
    const uniqueSkillsCategoryKeys = Array.from(
      new Set(
        selectedSkills.flatMap((selectedSkill) => {
          const category = skillsData.find((category) =>
            category.skills.some((categorySkill) => categorySkill.key === selectedSkill)
          );
          return category ? [category.category.key] : [];
        })
      )
    );

    return uniqueSkillsCategoryKeys;
  };

  const getToolLabel = (toolKey: string) => {
    const categoryWithTool = toolsData.find((category) => category.tools.some((tool) => tool.key === toolKey));
    const tool = categoryWithTool?.tools.find((tool) => tool.key === toolKey);
    return tool?.label || '';
  };

  const getToolsCategoryKey = (selectedTools: string[]) => {
    const uniqueToolsCategoryKeys = Array.from(
      new Set(
        selectedTools.flatMap((selectedTool) => {
          const category = toolsData.find((category) =>
            category.tools.some((categoryTool) => categoryTool.key === selectedTool)
          );
          return category ? [category.category.key] : [];
        })
      )
    );

    return uniqueToolsCategoryKeys;
  };

  const getCategoryLabel = (categoryKey: string) => {
    const category = rolesData?.find((category: any) => category.key === categoryKey) as { label?: string };

    return category?.label;
  };

  // const handleSkillChange = (newSkills: { key: string; label: string }[]) => {
  //   // Pass the updated skills back to the parent component
  //   onSkillsChange(newSkills);
  // };

  const handleSkillsDialogClose = () => {
    setSkillsDialogOpen(false);
    // onSkillsChange(selectedSkills);
    // const selectedSkillsWithLabels: {
    //   key: string;
    //   label: any;
    // }[] = selectedSkills.map((skillKey) => ({
    //   key: skillKey,
    //   label: getSkillLabel(skillKey),
    // }));

    // if (selectedSkillsWithLabels) {
    //   onSkillsChange(selectedSkillsWithLabels);
    // }

    // onSkillsChange(selectedSkillsWithLabels);
    updateStructureAndFilter();
  };

  const handleResetSkillsDialogClose = () => {
    setSkillsDialogOpen(false);
    setSelectedSkills([]);
    onChangeSkillData([]);
    const updatedFilterStructure = {
      ...filterStructure,
      skillsKey: [],
      categoryskillsKey: [],
      skillLabels: [],
    };

    updateFilterStructure(updatedFilterStructure);
  };

  const handleToolsDialogOpen = () => {
    setToolsDialogOpen(true);
  };
  const handleToolsDialogClose = () => {
    setToolsDialogOpen(false);
    // onToolsChange(selectedTools);
    // const selectedToolsWithLabels: {
    //   key: string;
    //   label: any;
    // }[] = selectedTools.map((toolKey) => ({
    //   key: toolKey,
    //   label: getToolLabel(toolKey),
    // }));
    // onToolsChange(selectedToolsWithLabels);
    updateStructureAndFilter();
  };
  const handleResetToolsDialogClose = () => {
    setToolsDialogOpen(false);
    setSelectedTools([]);

    const updatedFilterStructure = {
      ...filterStructure,
      toolsKey: [],
      categorytoolsKey: [],
      toolLabels: [],
    };

    updateFilterStructure(updatedFilterStructure);
  };
  const handleLocateDialogOpen = () => {
    setLocateDialogOpen(true);
  };
  const handleLocateDialogClose = () => {
    setLocateDialogOpen(false);
    // const updatedLocation: LocationType = {
    //   country: selectedLocations.country,
    //   state: selectedLocations.state,
    //   language: selectedLocations.language,
    // };

    // onLocationChange(updatedLocation);
    updateStructureAndFilter();
  };
  const handleResetLocateDialogClose = () => {
    setSearchLoc({
      state: '',
      country: '',
    });
    getCountry();
    getStates();
    // setCountries([]);
    // setStates([]);

    // setSelectedLocations({
    //   country: '',
    //   state: '',
    //   language: '',
    // });
    // onLocationChange({ country: '', state: '', language: '' });
    setLocateDialogOpen(false);
    const updatedFilterStructure = {
      ...filterStructure,
      country: '',
      state: '',
      language: '',
    };

    updateFilterStructure(updatedFilterStructure);
  };
  const handleRoleCheckboxChange = (key: string) => {
    if (key !== '') {
      setSelectedRoles((prevSelectedRoles) =>
        prevSelectedRoles.includes(key) ? prevSelectedRoles.filter((role) => role !== key) : [...prevSelectedRoles, key]
      );
    } else {
      setSelectedRoles([]);
      setSelectedSkills([]);
      setSelectedTools([]);
      onChangeCategoryData([]);
      // onChangeSkillData([]);
      // onChangeToolData([]);
    }
  };
  useEffect(() => {
    if (selectedRoles.length === 0) {
      getSkills();
      getTools();
    }
  }, [selectedRoles]);

  const handleSkillCheckboxChange = (key: string) => {
    setSelectedSkills((prevSelectedSkills) =>
      prevSelectedSkills.includes(key) ? prevSelectedSkills.filter((skill) => skill !== key) : [...prevSelectedSkills, key]
    );
  };

  const handleToolCheckboxChange = (key: string) => {
    setSelectedTools((prevSelectedTools) =>
      prevSelectedTools.includes(key) ? prevSelectedTools.filter((tool) => tool !== key) : [...prevSelectedTools, key]
    );
  };
  const handleFieldChange = (fieldName: string, value: string | null) => {
    if (fieldName === 'country' && value) {
      setSearchLoc((previe) => ({
        ...previe,
        country: value,
      }));
      // getCountry();
      // getStates();
      // getCountry('country', value);
    }
    if (fieldName === 'state' && value) {
      setSearchLoc((previe) => ({
        ...previe,
        state: value,
      }));
      // getStates('state', value);
      // getCountry();
      // getStates();
    }
    setSelectedLocations((prevLocation) => ({
      ...prevLocation,
      [fieldName]: value,
    }));
  };
  const handleRolesDialogClose = () => {
    updateStructureAndFilter();
    setRolesDialogOpen(false);
    getTools();
    getSkills();
  };

  const handleResetRolesDialogClose = () => {
    setRolesDialogOpen(false);
    handleRoleCheckboxChange('');
    setSelectedRoles([]);
    setSelectedSkills([]);
    setSelectedTools([]);

    const updatedFilterStructure = {
      ...filterStructure,
      categoryKey: [],
      categoryLabels: [],
    };

    updateFilterStructure(updatedFilterStructure);
  };

  const handleCloseSKill = () => {
    setSkillsDialogOpen(false);
  };

  const handleCloseTool = () => {
    setToolsDialogOpen(false);
  };
  const handleCloseLocation = () => {
    setLocateDialogOpen(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        backgroundColor: '#F7F8F8',
        fontFamily: "'Onest', sans-serif",
      }}>
      <div
        style={{
          top: 0,
          left: 0,
        }}>
        <aside className="sidebar-menu">
          <ul style={{ listStyleType: 'none', paddingLeft: '12px', cursor: 'pointer' }}>
            {/* <li onClick={() => handleRolesDialogOpen()} className={rolesDialogOpen ? 'active-side' : ''}>
              <img src={profile} alt="icon" />
              <h4>Category</h4>
            </li> */}
            <li onClick={handleSkillsDialogOpen} className={skillsDialogOpen ? 'active-side' : ''}>
              <img src={skillsIcon} alt="icon" />
              <h4>Skills</h4>
            </li>
            <li onClick={handleToolsDialogOpen} className={toolsDialogOpen ? 'active-side' : ''}>
              <img src={toolsIcon} alt="icon" />
              <h4>Tools</h4>
            </li>
            <li onClick={handleLocateDialogOpen} className={locateDialogOpen ? 'active-side' : ''}>
              <img src={locateIcon} alt="icon" />
              <h4>Location</h4>
            </li>
          </ul>
        </aside>
      </div>
      <Dialog open={rolesDialogOpen} onClose={handleRolesDialogClose}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: "'Onest', sans-serif",
            padding: '5px 16px',
          }}>
          <IconButton
            onClick={handleRolesDialogClose}
            size="small"
            sx={{
              marginLeft: 'auto',
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexDirection: 'column', fontFamily: "'Onest', sans-serif", color: '#848094' }}>
            {rolesData.map((role: any) => (
              <FormControlLabel
                sx={{ color: '#848094 !important' }}
                key={role.key}
                control={
                  <Checkbox checked={selectedRoles.includes(role.key)} onChange={() => handleRoleCheckboxChange(role.key)} />
                }
                label={role.label}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', cursor: 'pointer !important', padding: '18px !important' }}>
          <Button className="common-button-white" type="submit" variant="contained" onClick={handleResetRolesDialogClose}>
            Reset
          </Button>
          <Button
            sx={{
              width: '110px',
              color: isCategoryBtnDis ? '#000 !important' : '#fff !important',
              backgroundColor: isCategoryBtnDis ? '#667085 !important' : '#2cb512 !important',
              opacity: isCategoryBtnDis ? 0.5 : '',
              '&.Mui-disabled': {
                color: '#FFF',
              },
            }}
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleRolesDialogClose}
            disabled={selectedRoles.length === 0}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={skillsDialogOpen}
        // onClose={() => !skillsDialogOpen}
        onClose={handleCloseSKill}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: "'Onest', sans-serif",
            padding: '0px 16px',
          }}>
          <IconButton
            onClick={handleCloseSKill}
            size="small"
            sx={{
              marginLeft: 'auto',
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {skillsData.map((category: any) => (
            <div key={category.category.key}>
              <h3 style={{ fontFamily: "'Onest', sans-serif" }}>{category.category.label}</h3>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {category.skills.map((skill: any) => (
                  <FormControlLabel
                    key={skill.key}
                    control={
                      <Checkbox
                        checked={selectedSkills.includes(skill.key)}
                        onChange={() => handleSkillCheckboxChange(skill.key)}
                      />
                    }
                    label={skill.label}
                  />
                ))}
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', cursor: 'pointer !important', padding: '18px !important' }}>
          <Button className="common-button-white" type="submit" variant="contained" onClick={handleResetSkillsDialogClose}>
            Reset
          </Button>
          <Button
            sx={{
              width: '110px',
              color: isSkillsBtnDis ? '#000 !important' : '#fff !important',
              backgroundColor: isSkillsBtnDis ? '#667085 !important' : '#2cb512 !important',
              opacity: isSkillsBtnDis ? 0.5 : '',
              '&.Mui-disabled': {
                color: '#FFF', // Override text color for disabled state
              },
            }}
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleSkillsDialogClose}
            disabled={isSkillsBtnDis}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={toolsDialogOpen}
        // onClose={() => !toolsDialogOpen}
        onClose={handleCloseTool}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: "'Onest', sans-serif",
            padding: '0px 16px',
          }}>
          <IconButton
            onClick={handleCloseTool}
            size="small"
            sx={{
              marginLeft: 'auto',
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {toolsData.map((category: any) => (
            <div key={category.category.key}>
              <h3 style={{ fontFamily: "'Onest', sans-serif" }}>{category.category.label}</h3>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {category.tools.map((tool: any) => (
                  <FormControlLabel
                    key={tool.key}
                    control={
                      <Checkbox
                        checked={selectedTools.includes(tool.key)}
                        onChange={() => handleToolCheckboxChange(tool.key)}
                      />
                    }
                    label={tool.label}
                  />
                ))}
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', cursor: 'pointer !important', padding: '18px !important' }}>
          <Button className="common-button-white" type="submit" variant="contained" onClick={handleResetToolsDialogClose}>
            Reset
          </Button>
          <Button
            sx={{
              width: '110px',
              color: isToolsBtnDis ? '#000 !important' : '#fff !important',
              backgroundColor: isToolsBtnDis ? '#667085 !important' : '#2cb512 !important',
              opacity: isToolsBtnDis ? 0.5 : '',
              '&.Mui-disabled': {
                color: '#FFF', // Override text color for disabled state
              },
            }}
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleToolsDialogClose}
            disabled={isToolsBtnDis}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={locateDialogOpen}
        // onClose={() => !locateDialogOpen}
        onClose={handleCloseLocation}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: "'Onest', sans-serif",
            padding: '8px 16px',
          }}>
          <IconButton
            onClick={handleCloseLocation}
            size="small"
            sx={{
              marginLeft: 'auto',
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item sm={6} className="countryState">
              <Typography>Country</Typography>
              <Select
                sx={{ mt: 1, borderRadius: '8px', width: '200px' }}
                value={selectedLocations.country}
                onChange={(e) => handleFieldChange('country', e.target.value)}>
                {countries?.map((country, index) => (
                  <MenuItem sx={{ fontFamily: "'Onest', sans-serif" }} key={index} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
              {/* <Autocomplete
                className="formFields"
                sx={{ mt: 1, borderRadius: '8px' }}
                freeSolo
                options={countries}
                value={selectedLocations.country}
                onChange={(event, value) => handleFieldChange('country', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Country"
                    placeholder="Start typing..."
                    onChange={(event) => handleFieldChange('country', event.target.value)}
                  />
                )}
              /> */}
            </Grid>
            <Grid item sm={6} className="countryState">
              <Typography>State</Typography>
              <Select
                sx={{ mt: 1, borderRadius: '8px', width: '200px' }}
                value={selectedLocations.state}
                disabled={states?.length === 0}
                onChange={(e) => handleFieldChange('state', e.target.value)}>
                {states?.map((state: any, index: any) => (
                  <MenuItem sx={{ fontFamily: "'Onest', sans-serif" }} key={index} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
              {/* <Autocomplete
                className="formFields"
                sx={{ mt: 1, borderRadius: '8px' }}
                freeSolo
                options={states}
                value={selectedLocations.state}
                onChange={(event, value) => handleFieldChange('state', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select State"
                    placeholder="Start typing..."
                    onChange={(event) => handleFieldChange('state', event.target.value)}
                  />
                )}
                disabled={states[0] === null}
              /> */}
            </Grid>
            <Grid item sx={{ mt: 2 }} sm={12} className="langForms">
              <Typography>Language</Typography>
              <Select
                className="langName"
                sx={{ width: '430px' }}
                value={selectedLocations.language}
                onChange={(e) => handleFieldChange('language', e.target.value)}>
                {language?.map((language, index) => (
                  <MenuItem sx={{ fontFamily: "'Onest', sans-serif" }} key={index} value={language}>
                    {language}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          className="resetBtn"
          sx={{ justifyContent: 'space-between', cursor: 'pointer !important', padding: '18px !important' }}>
          <Button className="common-button-white" type="submit" variant="contained" onClick={handleResetLocateDialogClose}>
            Reset
          </Button>
          <Button
            sx={{
              width: '110px',
              color:
                selectedLocations.country !== '' || selectedLocations.state !== '' || selectedLocations.language !== ''
                  ? '#fff !important'
                  : '#000 !important',
              backgroundColor:
                selectedLocations.country !== '' || selectedLocations.state !== '' || selectedLocations.language !== ''
                  ? '#2cb512 !important'
                  : '#667085 !important',
              opacity:
                selectedLocations.country !== '' || selectedLocations.state !== '' || selectedLocations.language !== ''
                  ? ''
                  : 0.5,
              '&.Mui-disabled': {
                color: '#FFF', // Override text color for disabled state
              },
            }}
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleLocateDialogClose}
            disabled={
              selectedLocations.country !== '' || selectedLocations.state !== '' || selectedLocations.language !== ''
                ? false
                : true
            }>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Sidebar;

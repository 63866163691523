/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
import { Grid, Typography, Button, Box, Backdrop, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Booking, OnGoing } from '../Interface/BookingInterface/IBooking';
import image1 from '../../Assets/Images/banner-02.png';
import calendar from '../../Assets/Images/calendar.png';
import { connect } from 'react-redux';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../common/Snackbar';
import { Link } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import clientIcon from '../../../src/Assets/Images/client_mark.svg';
import artistIcon from '../../../src/Assets/Images/artist_mark.svg';
import { MessageDialog } from '../../components/HomeScreen/MessageDialog';
import io from 'socket.io-client';

interface BookingGridItemProps {
  datas: Booking[] | OnGoing[];
  type: string;
  onAccept?: () => void;
  onCancel?: () => void;
  onGoingData: () => void;
}

interface OtherProps {
  userType: boolean;
  id: number;
}

const BookingDetails: React.FC<BookingGridItemProps & OtherProps> = ({ datas, type, onGoingData, ...otherprops }) => {
  const privateAxios = useAxiosPrivate();

  const [data, setData] = useState<Booking[] | OnGoing[] | null>(null);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [open, setOpen] = React.useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [resetMessage, setResetMessage] = useState(false);
  const [receiverId, setReceiverId] = useState<any>('');
  const [socket, setSocket] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(datas);
    const newReceiverIds = datas.map((data) =>
      type === 'User Contracts' && 'bookingUser' in data
        ? data.bookingUser.id
        : 'requestingUser' in data
        ? data.requestingUser.id
        : null
    );
    setReceiverId(newReceiverIds);
  }, [datas]);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SOCKET_API_URL);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const getBackgroundColor = (status: string) => {
    switch (status) {
      case 'Accepted':
        return '#2CB512';
      case 'Rejected':
        return '#F04438';
      case 'Pending':
        return '#F08200';
      case 'Completed':
        return '#2CB512';
      case 'Cancelled':
        return '#F08200';
      case 'Cancel Contract':
        return '#F04438';
      case 'Mark as Completed':
        return '#6147FF';
      default:
        return '';
    }
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleContractSubmit = (id: number, status: string) => {
    if (id > 0) {
      setLoading((ps) => !ps);
      const sendContractId = {
        contractId: id,
      };

      const apiPath = status === 'Completed' ? 'completecontract' : 'cancelcontract';

      const completedContract = privateAxios.post(`dashboard/${apiPath}`, sendContractId, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      completedContract
        .then((response: any) => {
          if (response.status === 200) {
            setLoading((ps) => !ps);
            onGoingData();
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
          }
        })
        .catch((error: any) => {
          setLoading((ps) => !ps);
          setToast({ message: error.response.data.message.value, type: 'error' });
        });
    }
  };

  const handleOpenMessage = () => {
    setOpenMessage(true);
  };

  const handleMessageClose = () => {
    setOpenMessage(false);
  };

  const handleMessageSubmit = (message: string) => {
    const response = privateAxios.post(
      `chat/sendmessage`,
      JSON.stringify({
        receiverId: receiverId[0],
        message: message,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    response
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          setResetMessage(true);
          setOpenMessage(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });

    if (socket) {
      const messageData = {
        content: message,
        sender_id: otherprops.id,
        recipientId: receiverId,
      };
      socket.emit('sendMessage', messageData);
    } else {
      console.error('Socket is not available.');
    }
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          // onClick={() => setLoading(false)}
        >
          <CircularProgress
            color="success"
            // style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          />
        </Backdrop>
      )}
      <Grid>
        <Grid sx={{ height: '100%', padding: '10px' }}>
          <div>
            {data && data.length > 0 ? (
              data.map((data, index) => (
                <Box
                  key={index}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '5px',
                    borderRadius: '20px',
                    Width: '100%',
                    margin: 'auto',
                    marginBottom: '20px',
                  }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={3} md={3} lg={1.1}>
                      <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                        <Link
                          to={`/user-details?id=${
                            type === 'User Contracts' && 'bookingUser' in data
                              ? data.bookingUser.id
                              : 'requestingUser' in data
                              ? data.requestingUser.id
                              : ''
                          }`}
                          state={{
                            showUser: true,
                            id:
                              type === 'User Contracts' && 'bookingUser' in data
                                ? data.bookingUser.id
                                : 'requestingUser' in data
                                ? data.requestingUser.id
                                : '',
                          }}
                          target="_blank"
                          style={{ color: '#000', textDecoration: 'none' }}>
                          <img
                            src={
                              type === 'User Contracts' && 'bookingUser' in data
                                ? data.bookingUser.profilePath
                                : 'requestingUser' in data
                                ? data.requestingUser.profilePath
                                : image1
                            }
                            alt={`image${index + 1}`}
                            style={{ width: '100px', height: '100px', marginBottom: '10px', borderRadius: '12px' }}
                          />
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={7}>
                      <Box sx={{ marginBottom: { xs: '0px', md: '10px' } }}>
                        {otherprops.userType ? (
                          <Typography
                            mb={1}
                            sx={{
                              fontSize: '30px',
                              fontWeight: 500,
                              lineHeight: '30px',
                              fontFamily: "'Onest', sans-serif",
                            }}>
                            {data.projectName}
                            {'requestingUser' in data && (
                              <>
                                {data.requestingUser.isArtist && (
                                  <img src={artistIcon} style={{ marginLeft: '20px' }} alt="Artist" />
                                )}
                                {data.requestingUser.isClient && (
                                  <img src={clientIcon} style={{ marginLeft: '20px' }} alt="Client" />
                                )}
                              </>
                            )}
                            {(('bookingUser' in data && otherprops.id !== data.bookingUser.id) ||
                              ('requestingUser' in data && otherprops.id !== data.requestingUser.id)) && (
                              <ChatIcon
                                sx={{
                                  cursor: 'pointer',
                                  color: '#2cb512',
                                  marginLeft: '5px',
                                  width: '50px',
                                  height: '35px',
                                  position: 'absolute',
                                }}
                                onClick={handleOpenMessage}
                              />
                            )}
                          </Typography>
                        ) : (
                          <Typography
                            mb={1}
                            sx={{
                              fontSize: '20px',
                              fontWeight: 500,
                              lineHeight: '30px',
                              fontFamily: "'Onest', sans-serif",
                            }}>
                            {/* {type === 'User Contracts' && 'bookingUser' in data
                              ? data.bookingUser.fullName
                              : 'requestingUser' in data
                              ? data.requestingUser.fullName
                              : ''} */}
                            {data.projectName}
                            {/* {!otherprops.userType && ( */}
                            <ChatIcon
                              sx={{
                                cursor: 'pointer',
                                color: '#2cb512',
                                marginLeft: '5px',
                                width: '50px',
                                height: '35px',
                                position: 'absolute',
                              }}
                              onClick={handleOpenMessage}
                            />
                            {/* )} */}
                            {'requestingUser' in data && (
                              <>
                                {data.requestingUser.isArtist && (
                                  <img src={artistIcon} style={{ marginLeft: '20px' }} alt="Artist" />
                                )}
                                {data.requestingUser.isClient && (
                                  <img src={clientIcon} style={{ marginLeft: '20px' }} alt="Client" />
                                )}
                              </>
                            )}
                          </Typography>
                        )}
                        <Typography mb={1} sx={{ fontSize: '16px', fontWeight: 500, fontFamily: "'Onest', sans-serif" }}>
                          <span style={{ color: '#918EA5', fontSize: '16px' }}>
                            {type === 'User Contracts'
                              ? 'To'
                              : type === 'My Contracts'
                              ? 'From'
                              : 'requestingUser' in data && data.requestingUser.isArtist
                              ? 'To'
                              : 'From'}
                          </span>{' '}
                          {/* <span style={{ color: '#2CB512', fontSize: '16px' }}>{data.projectName}</span> */}
                          <Link
                            to={`/user-details?id=${
                              type === 'User Contracts' && 'bookingUser' in data
                                ? data.bookingUser.id
                                : 'requestingUser' in data
                                ? data.requestingUser.id
                                : ''
                            }`}
                            state={{
                              showUser: true,
                              id:
                                type === 'User Contracts' && 'bookingUser' in data
                                  ? data.bookingUser.id
                                  : 'requestingUser' in data
                                  ? data.requestingUser.id
                                  : '',
                            }}
                            target="_blank"
                            style={{ color: '#000', textDecoration: 'none' }}>
                            {/* {type === 'User Contracts' && 'bookingUser' in data
                                ? data.bookingUser.fullName
                                : 'requestingUser' in data
                                ? data.requestingUser.fullName
                                : ''} */}
                            <span style={{ color: '#2CB512', fontSize: '16px' }}>
                              {type === 'User Contracts' && 'bookingUser' in data
                                ? data.bookingUser.fullName
                                : 'requestingUser' in data
                                ? data.requestingUser.fullName
                                : ''}
                            </span>
                          </Link>
                        </Typography>
                        <Typography
                          mb={1}
                          sx={{
                            color: '#848094',
                            fontSize: '16px',
                            fontWeight: 500,
                            fontFamily: "'Onest', sans-serif",
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <img src={calendar} alt="calendar" style={{ paddingRight: '10px' }} /> {data.startDate} -{' '}
                          {data.endDate}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={2.5}
                      lg={3.8}
                      sx={{ textAlign: 'center', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                      {type === 'User Contracts' && data && 'status' in data && data.status !== 'Accepted' && (
                        <Typography
                          sx={{
                            fontFamily: "'Onest', sans-serif",
                            textTransform: 'none',
                            backgroundColor: data && 'status' in data ? getBackgroundColor(data.status) : '',
                            color: '#fff',
                            border: `1px solid ${data && 'status' in data ? getBackgroundColor(data.status) : ''}`,
                            fontSize: '14px',
                            borderRadius: '8px',
                            width: '100px',
                            height: '32px',
                            lineHeight: '20px',
                            fontWeight: 600,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          {data && 'status' in data ? data.status : ''}
                        </Typography>
                      )}
                      {type === 'My Contracts' && data && 'status' in data && data.status === 'Accepted' && (
                        <Typography
                          sx={{
                            fontFamily: "'Onest', sans-serif",
                            textTransform: 'none',
                            backgroundColor: data && 'status' in data ? getBackgroundColor(data.status) : '',
                            color: '#fff',
                            border: `1px solid ${data && 'status' in data ? getBackgroundColor(data.status) : ''}`,
                            fontSize: '14px',
                            borderRadius: '8px',
                            width: '100px',
                            height: '32px',
                            lineHeight: '20px',
                            fontWeight: 600,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          {data && 'status' in data ? data.status : ''}
                        </Typography>
                      )}
                      {type === 'User Contracts' && data && 'status' in data && data.status === 'Accepted' && (
                        <>
                          <Box sx={{ display: 'flex', justifyContent: 'top', alignItems: 'right' }}>
                            <Button
                              sx={{
                                fontFamily: "'Onest', sans-serif",
                                textTransform: 'none',
                                backgroundColor: getBackgroundColor('Cancel Contract'),
                                color: '#fff',
                                borderRadius: '8px',
                                width: '156px',
                                height: '32px',
                                marginRight: '8px',
                                lineHeight: '20px',
                                fontWeight: 600,
                                fontSize: '14px',
                                border: '1px solid #F04438',
                                '&:hover': {
                                  color: 'var(--Primary, #F04438)',
                                  backgroundColor: '#fff',
                                },
                              }}
                              onClick={() => handleContractSubmit(data && 'id' in data ? data.id : 0, 'Cancel')}>
                              Cancel Contract
                            </Button>
                            <Button
                              sx={{
                                fontFamily: "'Onest', sans-serif",
                                textTransform: 'none',
                                backgroundColor: getBackgroundColor('Mark as Completed'),
                                color: '#fff',
                                border: '1px solid #6147FF',
                                fontSize: '14px',
                                borderRadius: '8px',
                                width: '154px',
                                height: '32px',
                                lineHeight: '20px',
                                fontWeight: 600,
                                '&:hover': {
                                  color: 'var(--Primary, #6147FF)',
                                  backgroundColor: '#fff',
                                },
                              }}
                              onClick={() => handleContractSubmit(data && 'id' in data ? data.id : 0, 'Completed')}>
                              Mark as Completed
                            </Button>
                          </Box>
                        </>
                      )}
                    </Grid>
                    <Typography sx={{ marginLeft: '20px', padding: '5px', fontFamily: "'Onest', sans-serif" }}>
                      <span style={{ color: '#9995AE', fontSize: '16px', fontWeight: '500' }}>Description: </span>
                      <span style={{ color: '#424866', fontSize: '16px', fontWeight: '500' }}>{data.description}</span>
                    </Typography>
                  </Grid>
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  backgroundColor: '#FFFFFF',
                  padding: '5px',
                  borderRadius: '20px',
                  Width: '100%',
                  margin: 'auto',
                  marginBottom: '20px',
                }}>
                {type === 'My Contracts' ? (
                  <Typography
                    mb={1}
                    sx={{
                      fontSize: '20px',
                      fontWeight: 500,
                      lineHeight: '30px',
                      fontFamily: "'Onest', sans-serif",
                      textAlign: 'center',
                    }}>
                    <b>No current contracts:</b> This is where you will find projects you are hired for
                  </Typography>
                ) : type === 'User Contracts' ? (
                  <Typography
                    mb={1}
                    sx={{
                      fontSize: '20px',
                      fontWeight: 500,
                      lineHeight: '30px',
                      fontFamily: "'Onest', sans-serif",
                      textAlign: 'center',
                    }}>
                    <b>No user contracts:</b> This is where you will find projects your hire other users for
                  </Typography>
                ) : (
                  <Typography
                    mb={1}
                    sx={{
                      fontSize: '20px',
                      fontWeight: 500,
                      lineHeight: '30px',
                      fontFamily: "'Onest', sans-serif",
                      textAlign: 'center',
                    }}>
                    No {type}
                  </Typography>
                )}
              </Box>
            )}
          </div>
        </Grid>
        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Grid>
      <MessageDialog
        openMessageDialog={openMessage}
        handleClose={handleMessageClose}
        user={data}
        handleMessageSubmit={handleMessageSubmit}
        resetMessage={resetMessage}
      />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userType: state.userType,
  };
}

export default connect(mapDispatchToProps)(BookingDetails);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Typography, Select, MenuItem, DialogActions, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import useAxiosPrivate from '../../../../Hooks/useAxioPrivate';
import ConfirmationDialog from './ConfirmationDialog';
import CustomSnackbar from '../../../../common/Snackbar';
import { userDisElgble } from '../../../../reducer/action';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../reducer/store';

const CardDetails = (props: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [openChoose, setOpenChoose] = useState(true);
  console.log(setOpenChoose);
  const [selectCard, setSelectCard] = useState<any>('');
  const [cardDetails, setCardDetails] = useState<any>();
  const [toast, setToast] = useState({ message: '', type: 'success' });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const privateAxios = useAxiosPrivate();
  //   const [paymentConfirm,setPaymentConfirm] = useState(false);
  //   const [paymentId , setPaymentId] = useState("");
  const [response, setResponse] = useState<any>({ paymentConfirm: false, paymentId: '', message: '' });

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.CID) {
      const getCardDetails = privateAxios.get(`payment/stripe/getcard?customerId=${props.CID}&isPlan=${true}`);
      getCardDetails
        .then((res: any) => {
          setCardDetails(res.data.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, []);

  function handlePaymentSubmit() {
    dispatch(userDisElgble(false));
    if (!selectCard) {
      setToast({ message: 'Select a Card for Payment', type: 'error' });
      handleClick();
      return;
    }

    const payment = privateAxios.post(
      `payment/stripe/initiatePayment?customerId=${props.CID}&isPlan=${true}`,
      { cardId: selectCard?.cardId, cardNumber: selectCard?.cardNumber },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    payment
      .then((res: any) => {
        console.log(res);
        if (res.status === 200) {
          setResponse({ paymentId: res.data.data.paymentIntentId, paymentConfirm: true, message: res.data.message.value });
          // setPaymentConfirm(true)
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  const handlePaymentClose = () => {
    setResponse({ paymentConfirm: false, paymentId: '', message: '' });
  };

  return (
    <>
      <Dialog
        open={openChoose}
        // onClose={onCloseChoose}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '1500px !important',
            padding: '30px 24px',
            height: 'auto',
            overflowX: 'hidden !important',
            overflowY: 'hidden !important',
          },
        }}>
        <DialogContent sx={{ padding: '20px 10px', overflowX: 'hidden !important', overflowY: 'hidden !important' }}>
          <Typography
            sx={{
              color: '#2CB512',
              fontSize: '18px',
              fontWeight: 600,
              fontFamily: "'Onest', sans-serif !important",
              marginBottom: '16px',
            }}>
            Select a Card For Payment
          </Typography>

          <Select
            sx={{ minWidth: '400px', fontFamily: "'Onest', sans-serif" }}
            value={selectCard || ''}
            onChange={(e) => setSelectCard(e.target.value)}>
            {/* <MenuItem disabled value="">
              <em> **** **** *** 0000</em>
            </MenuItem> */}
            {cardDetails?.map((item: any, index: number) => (
              <MenuItem sx={{ fontFamily: "'Onest', sans-serif" }} key={index} value={item}>
                **** **** **** {item.cardNumber}
              </MenuItem>
            ))}

            {/* {totExperience.map((item: string, index: number) => (
                <MenuItem sx={{ fontFamily: "'Onest', sans-serif" }} key={index} value={item}>
                  {item}
                </MenuItem>
              ))} */}
          </Select>
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', gap: '15px' }}>
            <Button
              className="common-button-white"
              type="submit"
              variant="contained"
              onClick={() => navigate('/premium/choose-payment')}
              sx={{
                width: '100px',
                height: '44px',
                margin: '0px',
                '&:hover': {
                  color: 'var(--Primary, #2CB512)',
                  backgroundColor: '#fff',
                },
              }}>
              Cancel
            </Button>
            <Button
              className="common-button-green"
              type="submit"
              variant="contained"
              onClick={() => navigate('/stripe/add')}
              sx={{
                width: '100px',
                height: '44px',
                margin: '0px',
                '&:hover': {
                  background: 'var(--Primary, #2CB512)',
                },
              }}>
              Add
            </Button>
            <Button
              className="common-button-green"
              type="submit"
              variant="contained"
              onClick={() => handlePaymentSubmit()}
              sx={{
                width: '100px',
                height: '44px',
                margin: '0px',
                '&:hover': {
                  background: 'var(--Primary, #2CB512)',
                },
              }}>
              Next
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        openConfirmation={response.paymentConfirm}
        paymentId={response.paymentId}
        closeConfirmationDialog={handlePaymentClose}
        message={response.message}
        renewal={true}
      />
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    CID: state.CID,
  };
}

export default connect(mapDispatchToProps)(CardDetails);

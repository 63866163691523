/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Toolbar,
  IconButton,
  // Typography,
  Menu,
  Button,
  Tooltip,
  // MenuItem,
  useMediaQuery,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Grid,
  TextField,
  InputAdornment,
} from '@mui/material';
import searchIcon from '../../Assets/Images/search-normal.svg';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../Assets/Images/logo.png';
// import profilrImage from '../../Assets/Images/profileImage.svg';
import accountDashboard from '../../Assets/Images/accountDashboard.png';
import lock from '../../Assets/Images/lock.png';
import logout from '../../Assets/Images/logout.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import '../../Assets/css/Navbar.css';
import '../../Assets/css/HomeScreen.css';
import CustomSnackbar from '../../common/Snackbar';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  userId,
  isLoggedIn,
  userType,
  userAccessToken,
  userRefreshToken,
  backHandle,
  userStepStatus,
  userDC,
  profileHeaderPics,
  userDisElgble,
  customerID,
} from '../../reducer/action';
import { useLocation } from 'react-router-dom';

interface NavbarProps {
  onSearchValuesChange?: (searchValues: string) => void;
}

const settings = [
  { label: 'Account Dashboard', icon: accountDashboard },
  { label: 'Change Password', icon: lock },
  { label: 'Logout', icon: logout },
];

const Navbar: React.FC<NavbarProps> = (props: any) => {
  const location = useLocation();

  const [, setSelectedMenu] = useState('');
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [, setActiveItem] = useState<string>('');
  const [selectedSetting, setSelectedSetting] = useState('');
  const [toast, setToast] = useState({ message: '', type: 'success' });
  const [open, setOpen] = useState(false);
  const [nameSearch, setNameSearch] = useState('');
  const [isMenuOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [showSearch, setShowSearch] = useState(false);

  const isMobileOrTablet = useMediaQuery('(max-width: 960px)');

  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get('search');

  const type = window.location.href.split('/')[3];
  const typeParam = type.split('?')[0];

  useEffect(() => {
    // Store searchValue in localStorage
    if (searchValue) {
      localStorage.setItem('searchValue', searchValue);
      setNameSearch(searchValue);
      props.onSearchValuesChange(searchValue);
    }
    userInformation(props.id);
    if (typeParam === '' || typeParam === 'userFeed' || typeParam === 'myroster') {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  }, [type]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    // setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userInformation = (id: number) => {
    const getDetails = privateAxios.get(`user/getuserdetails?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          dispatch(
            profileHeaderPics({
              profilePath: data?.profilePath,
              headerPath: data?.headerPath,
              availablity: response.data.data?.availability,
              firstName: data?.firstName,
              lastName: data?.lastName,
              jobTitle: data?.jobTitle,
              userName: data?.userName,
            })
          );
          setUserInfo(data);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  // const pages = props.userType
  //   ? [
  //       { page: 'Feed', path: 'userFeed' },
  //       { page: 'My Roster', path: 'myroster' },
  //       { page: 'Conversations', path: 'conversations' },
  //       { page: 'Bookings', path: 'bookings' },
  //       { page: 'Availability', path: 'account-dashboard/edit-profile' },
  //     ]
  //   : [
  //       { page: 'Conversations', path: 'conversations' },
  //       { page: 'Bookings', path: 'bookings' },
  //       { page: 'Availability', path: 'account-dashboard/edit-profile' },
  //     ];

  const handleMenuItemClick = (menuItem: string, path: string) => {
    setSelectedMenu(menuItem);
    setActiveItem(menuItem);
    menuItem === 'Availability'
      ? navigate(`/${path}`, { state: { active: menuItem, tab: 2 } })
      : navigate(`/${path}`, { state: { active: menuItem, conditional: true } });
    // navigate(`/${path}`, { state: { active: menuItem, conditional: true } });

    handleCloseNavMenu();
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    const reToken = {
      token: props.userRefreshToken,
    };

    const logout = privateAxios.post(`login/logout`, reToken, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    logout
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();

          setTimeout(() => {
            dispatch(isLoggedIn(false));
            dispatch(backHandle(false));
            dispatch(userId(''));
            dispatch(customerID(''));
            dispatch(userStepStatus({ status: '' }));
            dispatch(userType(false));
            dispatch(userAccessToken({ accessToken: '' }));
            dispatch(userRefreshToken({ refreshToken: '' }));
            dispatch(userDC(false));
            dispatch(userDisElgble(false));
            localStorage.removeItem('center');
            localStorage.clear();
            navigate(`/login`);
          }, 2000);
        }
      })
      .catch((error: any) => {
        console.error('ERROR', error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleNavOpen = () => {
    setIsNavOpen(true);
  };

  const handleNavClose = () => {
    setIsNavOpen(false);
  };

  // const handleNavigation = (page: string, path: string) => {
  //   setSelectedMenu(page);
  //   setActiveItem(page);
  //   navigate(`/${path}`, { state: { active: page, conditional: true } });
  //   handleNavClose();
  // };

  const handleSettingClick = (label: string) => {
    setSelectedSetting(label);

    switch (label) {
      case 'Account Dashboard':
        navigate('/account-dashboard', { state: { active: '' } });
        setSelectedMenu('');
        setActiveItem('');
        handleCloseUserMenu();

        break;
      case 'Change Password':
        setSelectedMenu('');
        setActiveItem('');
        navigate('/change-password', { state: { active: '' } });
        handleCloseUserMenu();
        break;
      case 'Logout':
        handleLogout();
        break;
      default:
        break;
    }
  };

  const handleSearchName = (e: any) => {
    setNameSearch(e.target.value);
  };

  const handleSearchClick = () => {
    props.onSearchValuesChange(nameSearch);
  };

  return (
    <>
      <Grid>
        <div className="menuBars">
          <Toolbar
            sx={{
              backgroundColor: '#fff',
              pl: '10px',
              pr: '10px',
              width: '100vw',
              position: 'fixed',
              zIndex: 1000,
              fontFamily: "'Onest', sans-serif !important",
            }}
            disableGutters>
            <img src={logo} alt="logo" style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
            <Box sx={{ flexGrow: 1 }}>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                {isMobileOrTablet && (
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleNavOpen}
                    color="inherit"
                    sx={{ marginLeft: 'auto' }}>
                    <MenuIcon />
                  </IconButton>
                )}
                {/* <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}>
                  {pages.map((page) => (
                    <MenuItem key={page.page} onClick={() => handleMenuItemClick(page.page, page.path)}>
                      <Typography textAlign="center" sx={{ fontFamily: "'Onest', sans-serif" }}>
                        {page.page}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu> */}
              </Box>

              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                {props.userType ? (
                  <>
                    <Button
                      onClick={() => handleMenuItemClick('Feed', 'userFeed')}
                      sx={{
                        my: 2,
                        color: '#73787E',
                        display: 'block',
                        textTransform: 'none',
                        padding: '10px 10px 10px 10px',
                        ml: '20px',
                        fontSize: '18px',
                        backgroundColor: location?.state?.active === 'Feed' ? '#D3EDCE' : 'inherit',
                        borderRadius: '12px',
                        '&:hover': {
                          backgroundColor: '#D3EDCE',
                        },
                        fontFamily: "'Onest', sans-serif",
                      }}>
                      Feed
                    </Button>
                    <Button
                      onClick={() => handleMenuItemClick('My Roster', 'myroster')}
                      sx={{
                        my: 2,
                        color: '#73787E',
                        display: 'block',
                        textTransform: 'none',
                        padding: '10px 10px',
                        ml: '10px',
                        fontSize: '18px',
                        backgroundColor: location?.state?.active === 'My Roster' ? '#D3EDCE' : 'inherit',
                        borderRadius: '12px',
                        '&:hover': {
                          backgroundColor: '#D3EDCE',
                        },
                        fontFamily: "'Onest', sans-serif",
                      }}>
                      My Roster
                    </Button>
                  </>
                ) : (
                  ''
                )}

                <Button
                  onClick={() => handleMenuItemClick('Conversations', 'conversations')}
                  sx={{
                    my: 2,
                    color: '#73787E',
                    display: 'block',
                    textTransform: 'none',
                    padding: '10px 10px',
                    ml: '10px',
                    fontSize: '18px',
                    backgroundColor: location?.state?.active === 'Conversations' ? '#D3EDCE' : 'inherit',
                    borderRadius: '12px',
                    '&:hover': {
                      backgroundColor: '#D3EDCE',
                    },
                    fontFamily: "'Onest', sans-serif",
                  }}>
                  Conversations
                </Button>
                <Button
                  onClick={() => handleMenuItemClick('Bookings', 'bookings')}
                  sx={{
                    my: 2,
                    color: '#73787E',
                    display: 'block',
                    textTransform: 'none',
                    padding: '10px 10px',
                    ml: '10px',
                    fontSize: '18px',
                    backgroundColor: location?.state?.active === 'Bookings' ? '#D3EDCE' : 'inherit',
                    borderRadius: '12px',
                    '&:hover': {
                      backgroundColor: '#D3EDCE',
                    },
                    fontFamily: "'Onest', sans-serif",
                  }}>
                  Bookings
                </Button>
                <Button
                  // onClick={() => handleMenuItemClick('Availability', 'availability')}
                  onClick={() => handleMenuItemClick('Availability', 'account-dashboard/edit-profile')}
                  sx={{
                    my: 2,
                    color: '#73787E',
                    display: 'block',
                    textTransform: 'none',
                    padding: '10px 10px',
                    ml: '10px',
                    fontSize: '18px',
                    backgroundColor: location?.state?.active === 'Availability' ? '#D3EDCE' : 'inherit',
                    borderRadius: '12px',
                    '&:hover': {
                      backgroundColor: '#D3EDCE',
                    },
                    fontFamily: "'Onest', sans-serif",
                  }}>
                  Availability
                </Button>
              </Box>
            </Box>
            {props.userType && showSearch ? (
              <Box
                sx={{ flexGrow: 0.4, display: { xs: 'none', md: 'block' }, fontFamily: "'Onest', sans-serif !important" }}
                className="navSearch">
                <TextField
                  sx={{ fontFamily: "'Onest', sans-serif !important" }}
                  type="search"
                  placeholder="Search Talent"
                  value={nameSearch}
                  onChange={handleSearchName}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      props.onSearchValuesChange(nameSearch);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ mr: 2 }}>
                        <img src={searchIcon} alt="searchIcon" onClick={handleSearchClick} style={{ cursor: 'pointer' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            ) : (
              ''
            )}
            <Box sx={{ flexGrow: props.userType ? 0.08 : 0.01, ml: 2, display: { xs: 'none', md: 'block' } }}>
              <Tooltip title="Open settings">
                <IconButton onClick={isMenuOpen ? handleCloseUserMenu : handleOpenUserMenu} sx={{ p: 0 }}>
                  {/* <Avatar alt="user-profile" src={userInfo && userInfo.profilePath} /> */}
                  <Avatar alt="user-profile" src={userInfo && props.profilePics.profilePath} />
                  <ArrowDropDownIcon sx={{ color: isMenuOpen ? '#2cb512' : '#000' }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {settings.map(({ label, icon }) => (
                  <ListItemButton
                    key={label}
                    onClick={() => handleSettingClick(label)}
                    selected={selectedSetting === label}
                    sx={{
                      backgroundColor: selectedSetting === label ? '#fff' : '#fff',
                      color: selectedSetting === label ? '#2cb512' : '#000',
                      '&:hover': {
                        backgroundColor: '#fff',
                        '& .MuiListItemIcon-root, & .MuiTypography-root': {
                          color: '#2cb512',
                        },
                      },
                    }}>
                    <ListItemIcon>
                      <img src={icon} alt={label} style={{ width: '24px', height: '24px', marginRight: '0px' }} />
                    </ListItemIcon>
                    <ListItemText id="setting-listing">{label}</ListItemText>
                  </ListItemButton>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </div>

        <header className="navbar" style={{ position: 'fixed', zIndex: 999, width: '100%' }}>
          <div id="mySidenav" className={`sidenav ${isNavOpen ? 'open' : ''}`}>
            <a href="/userFeed" className="closebtn" onClick={handleNavClose}>
              &times;
            </a>
            {/* <ul className="navbar-nav">
              {pages.map((page, index) => (
                <li className="nav-item" key={index}>
                  <button
                    className={`active ${activeItem === page.page ? 'active-item' : ''}`}
                    style={{ backgroundColor: location?.state?.active === 'Feed' ? '#D3EDCE' : 'inherit' }}
                    onClick={() => handleNavigation(page.page, page.path)}>
                    {page.page}
                  </button>
                </li>
              ))}
            </ul> */}
            <ul className="navbar-nav">
              {props.userType ? (
                <>
                  <li className="nav-item">
                    <Button
                      onClick={() => handleMenuItemClick('Feed', 'userFeed')}
                      sx={{
                        fontFamily: "'Onest', sans-serif !important",
                        background: '#d3edce',
                        border: '1px #d3edce',
                        transition: '0.5s ease-in all',
                        borderRadius: '12px',
                        padding: '10px',
                        width: '240px',
                        textAlign: 'center',
                        textTransform: 'none',
                        color: '#73787E',
                        backgroundColor: location?.state?.active === 'Feed' ? '#D3EDCE' : 'inherit',
                      }}>
                      Feed
                    </Button>
                  </li>
                  <li className="nav-item">
                    <Button
                      onClick={() => handleMenuItemClick('My Roster', 'myroster')}
                      sx={{
                        fontFamily: "'Onest', sans-serif !important",
                        background: '#d3edce',
                        border: '1px #d3edce',
                        transition: '0.5s ease-in all',
                        borderRadius: '12px',
                        padding: '10px',
                        width: '240px',
                        textAlign: 'center',
                        textTransform: 'none',
                        color: '#73787E',
                        backgroundColor: location?.state?.active === 'My Roster' ? '#D3EDCE' : 'inherit',
                      }}>
                      My Roster
                    </Button>
                  </li>
                </>
              ) : (
                ''
              )}
              <li className="nav-item">
                <Button
                  onClick={() => handleMenuItemClick('Conversations', 'conversations')}
                  sx={{
                    fontFamily: "'Onest', sans-serif !important",
                    background: '#d3edce',
                    border: '1px #d3edce',
                    transition: '0.5s ease-in all',
                    borderRadius: '12px',
                    padding: '10px',
                    width: '240px',
                    textAlign: 'center',
                    textTransform: 'none',
                    color: '#73787E',
                    backgroundColor: location?.state?.active === 'Conversations' ? '#D3EDCE' : 'inherit',
                  }}>
                  Conversations
                </Button>
              </li>
              <li className="nav-item">
                <Button
                  onClick={() => handleMenuItemClick('Bookings', 'bookings')}
                  sx={{
                    fontFamily: "'Onest', sans-serif !important",
                    background: '#d3edce',
                    border: '1px #d3edce',
                    transition: '0.5s ease-in all',
                    borderRadius: '12px',
                    padding: '10px',
                    width: '240px',
                    textAlign: 'center',
                    textTransform: 'none',
                    color: '#73787E',
                    backgroundColor: location?.state?.active === 'Bookings' ? '#D3EDCE' : 'inherit',
                  }}>
                  Bookings
                </Button>
              </li>
              <li className="nav-item">
                <Button
                  onClick={() => handleMenuItemClick('Availability', 'account-dashboard/edit-profile')}
                  sx={{
                    fontFamily: "'Onest', sans-serif !important",
                    background: '#d3edce',
                    border: '1px #d3edce',
                    transition: '0.5s ease-in all',
                    borderRadius: '12px',
                    padding: '10px',
                    width: '240px',
                    textAlign: 'center',
                    textTransform: 'none',
                    color: '#73787E',
                    backgroundColor: location?.state?.active === 'Availability' ? '#D3EDCE' : 'inherit',
                  }}>
                  Availability
                </Button>
              </li>
            </ul>
            {props.userType && showSearch ? (
              <form className="search-box">
                <div className="form-group">
                  <input className="form-control" type="search" placeholder="Search Talent" />
                  <span className="fa fa-search" aria-hidden="true"></span>
                </div>
              </form>
            ) : (
              ''
            )}
            <Box sx={{ padding: '20px', width: '25px' }}>
              <Tooltip title="Open settings">
                <IconButton onClick={isMenuOpen ? handleCloseUserMenu : handleOpenUserMenu} sx={{ p: 0 }}>
                  {/* <Avatar alt="user-profile" src={userInfo && userInfo.profilePath} /> */}
                  <Avatar alt="user-profile" src={userInfo && props.profilePics.profilePath} />
                  <ArrowDropDownIcon sx={{ color: isMenuOpen ? '#2cb512' : '#000' }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {settings.map(({ label, icon }) => (
                  <ListItemButton
                    key={label}
                    onClick={() => handleSettingClick(label)}
                    selected={selectedSetting === label}
                    sx={{
                      backgroundColor: selectedSetting === label ? '#fff' : '#fff',
                      color: selectedSetting === label ? '#2cb512' : '#000',
                      '&:hover': {
                        backgroundColor: '#fff',
                        '& .MuiListItemIcon-root, & .MuiTypography-root': {
                          color: '#2cb512',
                        },
                      },
                    }}>
                    <ListItemIcon>
                      <img src={icon} alt={label} style={{ width: '24px', height: '24px', marginRight: '0px' }} />
                    </ListItemIcon>
                    <ListItemText id="setting-listing">{label}</ListItemText>
                  </ListItemButton>
                ))}
              </Menu>
            </Box>
          </div>
        </header>
        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Grid>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
    userRefreshToken: state.userRefreshToken,
    userType: state.userType,
    profilePics: state.userPics,
  };
}

export default connect(mapDispatchToProps)(Navbar);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Slide,
  Typography,
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomSnackbar from '../../common/Snackbar';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import CloseIcon from '@mui/icons-material/Close';
import '../../Assets/css/AddList.css';

const Transition = React.forwardRef(function Transition(props: React.ComponentProps<typeof Slide>, ref: React.Ref<unknown>) {
  return <Slide direction="down" ref={ref} timeout={{ enter: 1, exit: 5 }} {...props} />;
});

type listProps = [
  {
    label: string;
    key: string;
    isChecked: boolean;
  }
];

const AddListDialog = ({
  selectedUser,
  handleCloseDialog,
  addArtistList,
  handleAddNewListClick,
  refresh,
  openList,
  receiverId,
  selectedListVal,
  userName,
  multiAdd,
}: any) => {
  const privateAxios = useAxiosPrivate();
  const [selectedValue, setSelectedValue] = useState<listProps>([
    {
      label: '',
      key: '',
      isChecked: false,
    },
  ]);
  const [listData, setListData] = useState([]);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [open, setOpen] = React.useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [userId, setUserId] = useState<number>(0);

  useEffect(() => {
    if (selectedUser?.id !== undefined) {
      getList(selectedUser?.id);
      setUserId(selectedUser?.id);
    }
  }, [selectedUser?.id]);

  useEffect(() => {
    if (openList !== undefined && openList) {
      getList(receiverId);
      setUserId(receiverId);
    }
  }, [openList, receiverId]);

  useEffect(() => {
    if (refresh) {
      getList(userId);
    }
  }, [refresh]);

  const getList = (id: number) => {
    let getListData;
    if (selectedListVal !== '' && selectedListVal !== undefined) {
      getListData = privateAxios.get(`dashboard/getlistbyid`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else if (multiAdd) {
      getListData = privateAxios.get(`dashboard/getlistbyid`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else {
      getListData = privateAxios.get(`dashboard/getlistbyid?rosterId=${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    getListData
      .then((response: any) => {
        if (response.status === 200) {
          if (selectedListVal !== '' && selectedListVal !== undefined) {
            const data = response.data.data || [];
            const checkedItemKeys = selectedListVal || [];
            const updatedListData: any = data.map((item: any) => ({
              ...item,
              isChecked: checkedItemKeys.includes(item.key),
            }));
            setSelectedValue(checkedItemKeys);
            setListData(updatedListData);
            setSelectedItems([checkedItemKeys]);
          } else {
            const data = response.data.data || [];
            const checkedItems = data.filter((item: any) => item.isChecked);
            const checkedItemKeys = checkedItems.map((item: any) => item.key);

            setSelectedValue(checkedItemKeys);
            setListData(data);
            setSelectedItems(checkedItemKeys);
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
    const selectedItems = event.target.value as string[];
    setSelectedItems(selectedItems);
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const renderSelectedValues = (selected: string[]) => {
    return selected
      .map((value) => {
        const item: any = listData.find((item: any) => item.key === value);
        return item ? item.label : '';
      })
      .join(', ');
  };

  const handleCheckboxChange = (item: any) => {
    const itemKey = item.key;
    const isChecked = !item.isChecked;

    setListData((prevListData: any) => {
      return prevListData.map((listItem: any) => {
        if (listItem.key === itemKey) {
          return { ...listItem, isChecked };
        }
        return listItem;
      });
    });

    setSelectedItems((prevSelectedItems) => {
      if (isChecked) {
        return [...prevSelectedItems, itemKey];
      } else {
        return prevSelectedItems.filter((selectedItem) => selectedItem !== itemKey);
      }
    });
  };

  return (
    <>
      <Dialog
        open={!!selectedUser || openList}
        onClose={handleCloseDialog}
        fullWidth
        TransitionComponent={Transition}
        className="rosterList"
        PaperProps={{
          sx: {
            position: 'absolute',
            borderRadius: '16px',
          },
        }}
        transitionDuration={500}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: "'Onest', sans-serif !important",
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '28px',
              color: 'var(--green)',
              flex: '1',
            }}>
            Select which list you want to add{' '}
            {selectedUser && selectedUser?.fullName ? selectedUser && selectedUser?.fullName : userName && userName} to
          </Typography>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {(selectedUser || openList) && (
            <>
              <Typography
                className="lable-name"
                sx={{
                  textAlign: 'left',
                  mt: '15px',
                }}>
                Select list
              </Typography>
              <Select
                fullWidth
                multiple
                sx={{ fontFamily: "'Onest', sans-serif !important" }}
                value={selectedItems}
                onChange={handleChange}
                renderValue={(selected) => renderSelectedValues(selected as string[])}>
                <Grid className="selectRoster">
                  {listData.map((item: any) => (
                    <MenuItem
                      key={item.key}
                      value={item.key}
                      sx={{ fontFamily: "'Onest', sans-serif !important" }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleCheckboxChange(item);
                      }}>
                      <Checkbox checked={selectedItems.includes(item.key)} />
                      {item.label}
                    </MenuItem>
                  ))}
                </Grid>
              </Select>
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }} className="addButtons">
          <Button
            className="common-button-green"
            onClick={() => {
              if (selectedItems.length > 0) {
                addArtistList(selectedItems);
              } else {
                setToast({ message: 'Select any one list item', type: 'error' });
                handleClick();
              }
            }}
            disabled={selectedValue.length < 0}
            sx={{
              width: '275px',
              color: 'var(--white)',
              '&.Mui-disabled': {
                color: '#FFF',
              },
              cursor: 'pointer',
            }}>
            {selectedListVal !== '' && selectedListVal !== undefined ? 'Update' : 'Add'}
          </Button>
          <Button className="common-button-white" onClick={handleAddNewListClick} sx={{ width: '275px' }}>
            Add new list
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

export default AddListDialog;

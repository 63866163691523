/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../../../Assets/css/Skills.css';
import { Collapse } from 'react-bootstrap';
import { Box, IconButton, Grid, Button, Backdrop, CircularProgress, Tooltip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Info from '../../../Assets/Images/Info.png';
import SelectedInfo from '../../../Assets/Images/SelectedInfo.png';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../../common/Snackbar';
import { useNavigate } from 'react-router-dom';
// import { IUserDetails } from '../../Interface/UserInterface/IUser';

interface CheckedItems {
  [key: string]: boolean;
}

interface Skill {
  label: string;
  key: string;
  information: string;
}

interface Category {
  category: { label: string; key: string };
  skills: Skill[];
}

interface SelectedSkills {
  skills: {
    categoryKey: string;
    skillKey: string[];
  }[];
}

interface CategoryProps {
  category: Category;
  isOpen: Record<string, boolean>;
  handleToggle: (key: string) => void;
  checkedItems: Record<string, boolean>;
  onCheckboxChange: (categoryKey: string, skillKey: string) => void;
  selectedSkills: {
    skills: any[];
  };
  existingData: SelectedSkills;
  size: Record<string, boolean>;
}

interface PayloadItem {
  categoryKey: string | any;
  skillKey: string[] | any;
}

// interface PersonalSkillsProps {
//   data: IUserDetails | null;
// }

const Category: React.FC<CategoryProps> = ({
  category,
  isOpen,
  handleToggle,
  checkedItems,
  onCheckboxChange,
  selectedSkills,
  existingData,
}) => {
  const falseSlideInitial = !isOpen[category.category.key];
  const isSkillSelected = (categoryKey: string, skillKey: string) => {
    if (existingData) {
      const existingDataCheck =
        existingData.skills?.some((selectedCategory: any) => {
          return (
            selectedCategory.category.key === categoryKey &&
            selectedCategory.skills.some((selectedSkill: any) => selectedSkill.key === skillKey)
          );
        }) || false;

      if (existingDataCheck) {
        return true;
      }
    }

    return (
      selectedSkills.skills?.some((selectedCategory: any) => {
        if (selectedCategory && selectedCategory.skills && selectedCategory.category && selectedCategory.category.key) {
          return (
            selectedCategory.category.key === categoryKey &&
            selectedCategory.skills.some((selectedSkill: any) => {
              return selectedSkill.key === skillKey;
            })
          );
        }

        return false;
      }) || false
    );
  };
  return (
    <li key={category.category.key}>
      <div className="check-box">
        <div className="check-head" onClick={() => handleToggle(category.category.key)}>
          <h4>{category.category.label}</h4>
          <IconButton className="drop-icon" aria-label="angle-down">
            {falseSlideInitial ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
        <div id={category.category.key} className="toolsLevel" style={{ height: falseSlideInitial ? '' : '200px' }}>
          <Collapse in={falseSlideInitial}>
            <ul>
              {category.skills.map((skill: any) => (
                <li key={skill.key}>
                  <div
                    className={`form-group check-text ${
                      selectedSkills.skills.length === 0 || selectedSkills.skills.length > 0
                        ? checkedItems[`${category.category.key}-${skill.key}`]
                          ? 'green-box'
                          : ''
                        : isSkillSelected(category.category.key, skill.key)
                        ? 'green-box'
                        : ''
                    }`}>
                    <input
                      type="checkbox"
                      key={skill.key}
                      id={skill.key}
                      name={skill.key}
                      checked={
                        selectedSkills.skills.length === 0 || selectedSkills.skills.length > 0
                          ? checkedItems[`${category.category.key}-${skill.key}`]
                          : isSkillSelected(category.category.key, skill.key)
                      }
                      onChange={() => onCheckboxChange(category.category.key, skill.key)}
                    />
                    <label style={{ cursor: 'pointer' }} htmlFor={skill.key}>
                      {skill.label}
                    </label>
                    <Tooltip title={skill.information} arrow>
                      <img
                        src={
                          selectedSkills.skills.length === 0 || selectedSkills.skills.length > 0
                            ? checkedItems[`${category.category.key}-${skill.key}`]
                              ? SelectedInfo
                              : Info
                            : isSkillSelected(category.category.key, skill.key)
                            ? SelectedInfo
                            : Info
                        }
                        alt="Info"
                      />
                    </Tooltip>
                  </div>
                </li>
              ))}
            </ul>
          </Collapse>
        </div>
      </div>
    </li>
  );
};

const SkillsDetails: React.FC = () => {
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();

  const [payLoad1, setPayLoad1] = useState<PayloadItem[]>([]);
  const [skills, setSkills] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({});
  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});
  const [existingData, setExistingData] = useState<any>(null);
  const [selectedSkills, setSelectedSkills] = useState<SelectedSkills>({
    skills: [],
  });
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    getSkillsData();
    // if (data !== null) {
    //   getSelectedData(data);
    // }
    getUserDetails();
  }, []);

  useEffect(() => {
    const updatedIsOpen = { ...isOpen };
    const selectedSkillsData = skills;
    selectedSkillsData.forEach((selectedCategory: any) => {
      const key = `${selectedCategory.category.key}`;
      updatedIsOpen[key] = false;
    });
    setIsOpen(updatedIsOpen);
    setSize(updatedIsOpen);
  }, [skills]);

  // const getSelectedData = (data: any) => {
  //   setExistingData(data?.skills);
  //   const updatedCheckedItems = { ...checkedItems };
  //   const selectedSkillsData = data.skills;

  //   selectedSkillsData.forEach((selectedCategory: any) => {
  //     selectedCategory.skills.forEach((selectedSkill: any) => {
  //       const key = `${selectedCategory.category.key}-${selectedSkill.key}`;
  //       updatedCheckedItems[key] = true;
  //     });
  //   });

  //   setCheckedItems(updatedCheckedItems);

  //   setSelectedSkills((prevData: any) => ({ ...prevData, skills: selectedSkillsData }));
  // };

  const getSkillsData = () => {
    const skillData = privateAxios.get(`user/getSkills`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    skillData
      .then((response: any) => {
        if (response.status === 200) {
          // const initialCheckedItems = response.data.data.reduce((acc: CheckedItems, category: any) => {
          //   category.skills.forEach((skill: any) => {
          //     acc[`${category.category.key}-${skill.key}`] = false;
          //   });
          //   return acc;
          // }, {});

          // console.log('initialCheckedItems', initialCheckedItems);

          setSkills(response.data.data);
          // setCheckedItems(initialCheckedItems);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleCancelClick = () => {
    navigate('/account-dashboard');
  };

  const handleSubmitClick = () => {
    const { skills } = selectedSkills;

    const updatedSelectedSkills = skills.map((category: any) => ({
      categoryKey: category.category.key,
      skillKey: category.skills
        .filter((skill: any) => checkedItems[`${category.category.key}-${skill.key}`])
        .map((selectedSkill: any) => selectedSkill.key),
    }));

    const skillsMap: Record<string, string[]> = {};

    [...updatedSelectedSkills, ...payLoad1].forEach((entry) => {
      const { categoryKey } = entry;

      if (!skillsMap[categoryKey]) {
        skillsMap[categoryKey] = [];
      }

      skillsMap[categoryKey].push(...entry.skillKey.filter((key: any) => checkedItems[`${categoryKey}-${key}`]));
    });

    const combinedSkills = Object.entries(skillsMap)
      .map(([categoryKey, skillKey]) => ({
        categoryKey,
        skillKey,
      }))
      .filter((entry) => entry.skillKey.length > 0);

    const changesDetected = existingData && existingData.skills !== combinedSkills;

    if (changesDetected) {
      const skilldetails = privateAxios.put(
        `user/skillsinfo`,
        {
          skills: combinedSkills,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      skilldetails
        .then((response: any) => {
          if (response.status === 200) {
            getUserDetails();
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message, type: 'error' });
          handleClick();
        });
      return;
    } else if (selectedSkills.skills.length === 0) {
      const skilldetails = privateAxios.put(
        `user/skillsinfo`,
        {
          skills: payLoad1,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      skilldetails
        .then((response: any) => {
          if (response.status === 200) {
            getUserDetails();
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message, type: 'error' });
          handleClick();
        });
    } else {
      return;
    }
  };

  const handleToggle = (key: any) => {
    setIsOpen((prevIsOpen) => ({
      ...prevIsOpen,

      [key]: !prevIsOpen[key],
    }));

    setSize((prevSize) => ({
      ...prevSize,
      [key]: !prevSize[key],
    }));
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getUserDetails = () => {
    setLoading(true);
    const getDetails = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          // console.log('data', data);
          setExistingData(data?.skills);
          const updatedCheckedItems = { ...checkedItems };
          const selectedSkillsData = data.skills;

          selectedSkillsData.forEach((selectedCategory: any) => {
            selectedCategory.skills.forEach((selectedSkill: any) => {
              const key = `${selectedCategory.category.key}-${selectedSkill.key}`;
              updatedCheckedItems[key] = true;
            });
          });
          setCheckedItems(updatedCheckedItems);
          setSelectedSkills((prevData: any) => ({ ...prevData, skills: selectedSkillsData }));
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleCheckboxChange = (categoryKey: string, skillKey: string) => {
    setPayLoad1((prevPayload) => {
      const existingCategoryIndex = prevPayload.findIndex((item) => item.categoryKey === categoryKey);
      if (existingCategoryIndex !== -1) {
        const updatedPayload = [...prevPayload];
        const skillIndex = updatedPayload[existingCategoryIndex].skillKey.indexOf(skillKey);

        if (skillIndex !== -1) {
          updatedPayload[existingCategoryIndex].skillKey.splice(skillIndex, 1);

          if (updatedPayload[existingCategoryIndex].skillKey.length === 0) {
            updatedPayload.splice(existingCategoryIndex, 1);
          }
        } else {
          updatedPayload[existingCategoryIndex].skillKey.push(skillKey);
        }

        return updatedPayload;
      } else {
        return [...prevPayload, { categoryKey: categoryKey, skillKey: [skillKey] }];
      }
    });

    setCheckedItems((prevCheckedItems) => {
      const key = `${categoryKey}-${skillKey}`;
      const updatedCheckedItems = {
        ...prevCheckedItems,
        [key]: !prevCheckedItems[key],
      };

      return updatedCheckedItems;
    });
  };

  return (
    <>
      <div style={{ margin: '0px', position: 'relative' }}>
        <Grid
          container
          className="button-position"
          sx={{
            position: 'absolute',
            top: -140,
            right: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}>
          <Button
            className="common-button-white"
            type="submit"
            variant="contained"
            onClick={handleCancelClick}
            sx={{
              width: '167px',
              height: '44px',
              marginRight: 1,
              '&:hover': {
                color: 'var(--Primary, #2CB512)',
                backgroundColor: '#fff',
              },
            }}>
            Cancel
          </Button>
          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleSubmitClick}
            sx={{
              width: '121px',
              height: '44px',
              marginLeft: 1,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save
          </Button>
        </Grid>
      </div>

      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress
            color="success"
            // style={{ position: 'absolute', top: '50%', left: '55%', transform: 'translate(-50%, -50%)' }}
          />
        </Backdrop>
      )}

      {!loading && (
        <Box sx={{ p: '20px', backgroundColor: '#FFFFFF', borderRadius: '10px' }}>
          <div id="app">
            <div style={{ width: '900px', paddingTop: 25 }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  color: '#667085',
                  fontFamily: 'Onest, sans-serif',
                }}>
                Click on any roles that you can perform (at any level) professionally. Then, click to activate any skills
                that you utilize. Some skills, once clicked, will reveal sub-skills for even greater detail.
                <br />
                Clients often search for specific skills, so make sure to be thorough. Hover over the &apos;?&apos; icon for
                a description of the skill.
              </p>
            </div>
          </div>
          <div className="box-content" style={{ fontFamily: 'Onest, sans-serif' }}>
            <Grid container sx={{ pl: 0 }}>
              {skills.map((category: any) => (
                <Grid item key={category.category.key} sm={4}>
                  <div className="list-box">
                    <ul style={{ paddingLeft: 0 }} key={category.category.key}>
                      <Category
                        category={category}
                        isOpen={isOpen}
                        handleToggle={handleToggle}
                        checkedItems={checkedItems}
                        onCheckboxChange={handleCheckboxChange}
                        selectedSkills={selectedSkills}
                        existingData={existingData}
                        size={size}
                      />
                    </ul>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
          <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
        </Box>
      )}
    </>
  );
};

export default SkillsDetails;

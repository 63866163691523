import React, { useState } from 'react';
import CardDetailsGrid from '../../../AccountProfile/AccountDetails/CardDetailsGrid';
// import { Backdrop, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { Grid, Paper, Typography } from '@mui/material';
import useAxioPrivate from '../../../../Hooks/useAxioPrivate';
import ConfirmationDialog from './ConfirmationDialog';
import CustomSnackbar from '../../../../common/Snackbar';
import { useNavigate } from 'react-router-dom';
import back from '../../../../Assets/Images/back.png';
import stripe from '../../../../Assets/Images/Stripe.png';
import '../../../../Assets/css/ChoosePayment.css';

const CreateCard = () => {
  const privateAxios = useAxioPrivate();
  const navigate = useNavigate();

  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [message, setMessage] = useState('');
  const [openConfirmation, setConfirmation] = useState(false);
  // const [openAdd, setOpenAdd] = useState(true);
  // const [loading, setLoading] = useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [open, setOpen] = React.useState(false);

  const [paymentDelay, setpaymentDelay] = useState(false);

  const createCardDetails = (data: any) => {
    // setLoading(true);
    setpaymentDelay((ps) => !ps);
    const stripeCreate = privateAxios.post(`payment/stripe/create`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    stripeCreate
      .then((response: any) => {
        if (response.data.status === true) {
          // setToast({ message: response.data.message.value, type: 'success' });
          // handleClick();
          setpaymentDelay((ps) => !ps);
          setPaymentIntentId(response.data.data.paymentIntentId);
          setMessage(response.data.message.value);
          // setLoading(false);
          setConfirmation(true);

          // onCloseAddCard();
        }
      })
      .catch((error: any) => {
        setpaymentDelay((ps) => !ps);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
        // setLoading(false);
        // setTimeout(() => {
        //   navigate(-1);
        // }, 3000);
      });
    // setTimeout(() => {
    //   navigate(-1);
    // }, 3000);
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const closeConfirmationDialog = () => {
    setConfirmation(false);
    navigate('/premium/choose-payment');
  };

  // const onCloseAddCard = () => {
  //   setOpenAdd(false);
  // };
  return (
    <>
      {/* {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress color="success" />
        </Backdrop>
      )} */}
      {/* {!loading && ( */}
      <>
        <Grid>
          <Grid
            sx={{
              paddingBottom: '16px',
              paddingTop: '25px',
              paddingLeft: '45px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}>
            <a href="/premium/choose-payment" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <img src={back} alt="back" />
              <span className="lable-name" style={{ marginLeft: '8px', color: '#000' }}>
                Back
              </span>
            </a>
          </Grid>
        </Grid>
        <Grid sx={{ margin: 'auto', mt: '45px' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={stripe} alt="stripe" />
          </div>
          <Typography
            sx={{
              fontFamily: "'Onest', sans-serif !important",
              fontSize: '28px',
              fontWeight: 600,
              lineHeight: '38px',
              textAlign: 'center',
            }}>
            Add your credit or debit card details
          </Typography>
          <Typography
            sx={{
              fontFamily: "'Onest', sans-serif !important",
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              textAlign: 'center',
              color: '#667085',
              mt: '7px',
            }}>
            Your card, information and transaction details are safe and secure via Stripe
          </Typography>
          <Paper className="addcardDetailss">
            <CardDetailsGrid isEditMode={false} handleAddCard={createCardDetails} page="create" />
          </Paper>
          {/* </Dialog> */}
          <ConfirmationDialog
            paymentId={paymentIntentId}
            message={message}
            openConfirmation={openConfirmation}
            closeConfirmationDialog={closeConfirmationDialog}
            paymentDelay={paymentDelay}
          />
        </Grid>
      </>
      {/* )} */}
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

export default CreateCard;

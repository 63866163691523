/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, Grid, TextField } from '@mui/material';
import uploadCloud from '../../../Assets/Images/uploadCloud.png';
import '../../../Assets/css/ProfileSetup.css';
import { connect } from 'react-redux';
import CustomSnackbar from '../../../common/Snackbar';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../reducer/store';
import { backHandle, profileHeaderPics } from '../../../reducer/action';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import CircularProgress from '@mui/material/CircularProgress';
import Crop from './Crop';

interface step {
  label: string;
  key: string;
}

interface ProfilePicProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  stepKeys: step;
  id: number;
  backStatus: any;
}

const ProfilePic: React.FC<ProfilePicProps> = ({ activeStep, handleNext, handleBack, stepKeys, id, backStatus }) => {
  const privateAxios = useAxiosPrivate();

  const dispatch = useDispatch<AppDispatch>();

  const [profilePic, setProfilePic] = useState({
    stepperKey: stepKeys?.key || '',
    userName: '',
    profilePath: 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultProfileImage.png',
    headerPath: 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultHeaderUpdatedImage.png',
  });

  const [Error, setError] = useState({
    userName: '',
    profilePath: '',
    headerPath: '',
    userConfirm: '',
  });
  const [existingData, setExistingData] = useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [nameValidate, setNameValidate] = useState<any>(false);
  const [nameDisable, setNameDisable] = useState(false);
  const [loading, setLoading] = useState({ profile: false, header: false });
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [cropImage, setCropImage] = useState({ openCrop: false, imageType: '', fileName: '' });

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
    onCloseCropDialog();
  };

  useEffect(() => {
    getProfilePicDetails();
  }, []);

  const getProfilePicDetails = async () => {
    const profilePicDetails = privateAxios.get(`user/getuserstepper?stepperKey=${stepKeys?.key}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    profilePicDetails
      .then((response: any) => {
        if (response.status === 200) {
          setExistingData(response.data.data);

          setProfilePic((prevProfilePic) => ({
            ...prevProfilePic,
            userName: response.data.data.userName,
            profilePath: response.data.data.profilePath
              ? response.data.data.profilePath
              : 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultProfileImage.png',
            headerPath: response.data.data.headerPath
              ? response.data.data.headerPath
              : 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultHeaderUpdatedImage.png',
          }));
          if ((response.data.data.userName === null && backStatus) || response.data.data.userName === null) {
            const userNameData = privateAxios.get(`user/getusername?id=${id}`, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
            userNameData
              .then((res) => {
                if (res.status === 200) {
                  const newUserName = res.data.data;
                  setProfilePic((prevProfilePic) => ({
                    ...prevProfilePic,
                    userName: newUserName,
                  }));
                }
              })
              .catch((error: any) => {
                console.error(error);
              });
          }
          dispatch(backHandle(false));
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const handleProfilePicData = () => {
    const { userName, profilePath, headerPath } = profilePic;

    const errors = {
      userName: '',
      profilePath: '',
      headerPath: '',
      userConfirm: '',
    };

    if (!userName) {
      errors.userName = 'User Name is required';
    }

    if (!profilePath) {
      errors.profilePath = 'Profile Image is required';
    }

    if (!headerPath) {
      errors.headerPath = 'Header Image is required';
    }

    if (!nameValidate && profilePic.userName !== existingData.userName) {
      errors.userConfirm = 'Confirm Username';
    }

    if (Object.values(errors).some((field) => field !== '')) {
      setError(errors);
      return;
    }
    if (!nameValidate && profilePic.userName !== existingData.userName) {
      setToast({ message: 'Confirm Username', type: 'error' });
      handleClick();
      return;
    }

    const changesDetected =
      existingData &&
      (existingData.userName !== profilePic.userName ||
        existingData.profilePath !== profilePic.profilePath ||
        existingData.headerPath !== profilePic.headerPath);

    if (changesDetected) {
      const ProfilePicData = privateAxios.post(`user/register`, profilePic, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      ProfilePicData.then((response: any) => {
        if (response.status === 200) {
          dispatch(profileHeaderPics({ profilePath: profilePic.profilePath, headerPath: profilePic.headerPath }));
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          setTimeout(() => handleNext(), 2000);
        }
      }).catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
      return;
    } else if (profilePic.userName === '' || profilePic.profilePath === '' || profilePic.headerPath === '') {
      const ProfilePicData = privateAxios.post(`user/register`, profilePic, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      ProfilePicData.then((response: any) => {
        if (response.status === 200) {
          dispatch(profileHeaderPics({ profilePath: profilePic.profilePath, headerPath: profilePic.headerPath }));
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          setTimeout(() => handleNext(), 2000);
        }
      }).catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data?.message.value, type: 'error' });
        handleClick();
      });
    } else {
      dispatch(profileHeaderPics({ profilePath: profilePic.profilePath, headerPath: profilePic.headerPath }));
      handleNext();
      return;
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const trimValue = value.replace(/^\s+/, '').replace(/\s+/g, ' ');
    setProfilePic((prevData: any) => ({
      ...prevData,
      [name]: trimValue,
    }));
    setNameDisable(false);
    if (name === 'userName' && trimValue.length > 0) {
      setError((ps) => ({ ...ps, userName: '' }));
    }
  };

  const uploadAllImage = (e: any, type: any) => {
    if (type == 'Profile_image') {
      document.getElementById(`icon_profile_icon`)?.click();
    }
    if (type == 'Header_image') {
      document.getElementById(`icon_header_icon`)?.click();
    }
  };

  const handleFileUpload = (e: any, type: any) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file) {
      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        setToast({ message: 'Unsupported File type ', type: 'error' });
        handleClick();
        return;
      }
      if (file.size > maxSize) {
        setToast({ message: 'Image size is too large', type: 'error' });
        handleClick();
        return;
      }
      if (type === 'profileImage') {
        setCropImage({ openCrop: true, imageType: 'profile', fileName: file.name });
        setSelectedImage(file);
      }

      if (type === 'headerImage') {
        setCropImage({ openCrop: true, imageType: 'banner', fileName: file.name });
        setSelectedImage(file);
      }

      // reader.onload = (event: any) => {
      //   console.log('entered');

      //   const image = new Image();
      //   image.src = event.target.result;

      //   image.onload = () => {
      //     if (type === 'profileImage') {
      //       console.log('1');

      //       setImageType('profile');
      //       setSelectedImage(file);
      //     }

      //     if (type === 'headerImage') {
      //       console.log('2');

      //       setImageType('banner');
      //       setSelectedImage(file);
      //     }
      //   };
      // };
    }
  };

  const UploadImage = async (image: any) => {
    const file = image;

    const formData = new FormData();
    if (image) {
      formData.append('file', file);
    }
    // const request = profilePic.profilePath ? privateAxios.put : privateAxios.post;

    if (cropImage.imageType === 'profile') {
      if (profilePic.profilePath) {
        formData.append('imagePath', profilePic.profilePath);
      }
      const request =
        profilePic.profilePath &&
        profilePic.profilePath !== 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultProfileImage.png'
          ? privateAxios.put
          : privateAxios.post;
      const responsesssss = request(
        `user/${
          profilePic.profilePath &&
          profilePic.profilePath !== 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultProfileImage.png'
            ? 'profilereupload'
            : 'profileupload'
        }`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      responsesssss
        .then((response: any) => {
          if (response.status === 200) {
            setProfilePic((prevData: any) => ({
              ...prevData,
              profilePath: response.data.data,
            }));
            setLoading((previousState: any) => ({ ...previousState, profile: false }));
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();

            setError((pre: any) => ({
              ...pre,
              profilePath: '',
            }));
          }
        })
        .catch((error: any) => {
          console.error(error);
          setLoading((previousState: any) => ({ ...previousState, profile: false }));
          setToast({ message: error.data.message.value, type: 'error' });
          handleClick();
        });
    }

    if (cropImage.imageType === 'banner') {
      if (profilePic.headerPath) {
        formData.append('imagePath', profilePic.headerPath);
      }

      const request =
        profilePic.headerPath &&
        profilePic.headerPath !== 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultHeaderUpdatedImage.png'
          ? privateAxios.put
          : privateAxios.post;
      const responsesssss = request(
        `user/${
          profilePic.headerPath &&
          profilePic.headerPath !== 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultHeaderUpdatedImage.png'
            ? 'headerreupload'
            : 'headerupload'
        }`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      responsesssss
        .then((response: any) => {
          if (response.status === 200) {
            setProfilePic((prevData: any) => ({
              ...prevData,
              headerPath: response.data.data,
            }));
            setLoading((previousState: any) => ({ ...previousState, header: false }));
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setError((pre: any) => ({
              ...pre,
              headerPath: '',
            }));
          }
        })
        .catch((error: any) => {
          console.error(error);
          setLoading((previousState: any) => ({ ...previousState, profile: false }));
          setToast({ message: error.data.message.value, type: 'error' });
          handleClick();
        });
    }
  };

  const onCloseCropDialog = () => {
    setCropImage({
      openCrop: false,
      imageType: '',
      fileName: '',
    });
  };

  // const handleFileUpload = async (e: any, type: any) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
  //       setToast({ message: 'Unsupported File type ', type: 'error' });
  //       handleClick();
  //       return;
  //     }

  //     console.log('files', file);

  //     reader.onload = async (event: any) => {
  //       const image = new Image();
  //       image.src = event.target.result;

  //       image.onload = () => {
  //         if (type === 'profileImage') {
  //           setSelectedImage(file);
  //           // if (image.width > 500 || image.height > 500) {
  //           //   setToast({ message: 'Image dimensions are large', type: 'error' });
  //           //   handleClick();
  //           //   return;
  //           // }
  //           // if (image.width <= 500 && image.height <= 500) {
  //           setError((pre: any) => ({
  //             ...pre,
  //             profilePath: '',
  //           }));
  //           setLoading((previousState: any) => ({ ...previousState, profile: true }));

  //           const formData = new FormData();
  //           formData.append('file', file);
  //           if (profilePic.profilePath) {
  //             formData.append('imagePath', profilePic.profilePath);
  //           }
  //           const request = profilePic.profilePath ? privateAxios.put : privateAxios.post;
  //           const responsesssss = request(`user/${profilePic.profilePath ? 'profilereupload' : 'profileupload'}`, formData, {
  //             headers: {
  //               'Content-Type': 'multipart/form-data',
  //             },
  //           });

  //           responsesssss
  //             .then((response: any) => {
  //               if (response.status === 200) {
  //                 setProfilePic((prevData: any) => ({
  //                   ...prevData,
  //                   profilePath: response.data.data,
  //                 }));
  //                 setLoading((previousState: any) => ({ ...previousState, profile: false }));
  //                 setToast({ message: response.data.message.value, type: 'success' });
  //                 handleClick();

  //                 setError((pre: any) => ({
  //                   ...pre,
  //                   profilePath: '',
  //                 }));
  //               }
  //             })
  //             .catch((error: any) => {
  //               console.error(error);
  //               setLoading((previousState: any) => ({ ...previousState, profile: false }));
  //               setToast({ message: error.data.message.value, type: 'error' });
  //               handleClick();
  //             });
  //           // } else {
  //           //   setToast({ message: 'Image dimensions are large', type: 'error' });
  //           //   handleClick();
  //           //   setLoading((previousState: any) => ({ ...previousState, profile: false }));
  //           //   return;
  //           // }
  //         }

  //         if (type === 'headerImage') {
  //           if ((image.width > 1680 && image.width < 1400) || (image.height > 860 && image.height < 500)) {
  //             setToast({ message: 'Image dimensions are large', type: 'error' });
  //             handleClick();
  //             return;
  //           }
  //           setLoading((previousState: any) => ({ ...previousState, header: true }));
  //           const formData = new FormData();
  //           formData.append('file', file);
  //           if (profilePic.headerPath) {
  //             formData.append('imagePath', profilePic.headerPath);
  //           }
  //           const request = profilePic.headerPath ? privateAxios.put : privateAxios.post;
  //           const responsesssss = request(`user/${profilePic.headerPath ? 'headerreupload' : 'headerupload'}`, formData, {
  //             headers: {
  //               'Content-Type': 'multipart/form-data',
  //             },
  //           });

  //           responsesssss
  //             .then((response: any) => {
  //               if (response.status === 200) {
  //                 setProfilePic((prevData: any) => ({
  //                   ...prevData,
  //                   headerPath: response.data.data,
  //                 }));
  //                 setLoading((previousState: any) => ({ ...previousState, header: false }));
  //                 setToast({ message: response.data.message.value, type: 'success' });
  //                 handleClick();
  //                 setError((pre: any) => ({
  //                   ...pre,
  //                   headerPath: '',
  //                 }));
  //               }
  //             })
  //             .catch((error: any) => {
  //               console.error(error);
  //               setLoading((previousState: any) => ({ ...previousState, profile: false }));
  //               setToast({ message: error.data.message.value, type: 'error' });
  //               handleClick();
  //             });
  //         }
  //       };
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleDrop = (e: any, type: any) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file) {
      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        setToast({ message: 'Unsupported File type ', type: 'error' });
        handleClick();
        return;
      }
      if (file.size > maxSize) {
        setToast({ message: 'Image size is too large', type: 'error' });
        handleClick();
        return;
      }
      if (type === 'profileImage') {
        setCropImage({ openCrop: true, imageType: 'profile', fileName: file.name });
        setSelectedImage(file);
      }

      if (type === 'headerImage') {
        setCropImage({ openCrop: true, imageType: 'banner', fileName: file.name });
        setSelectedImage(file);
      }

      // reader.onload = (event: any) => {
      //   console.log('entered');

      //   const image = new Image();
      //   image.src = event.target.result;

      //   image.onload = () => {
      //     if (type === 'profileImage') {
      //       console.log('1');

      //       setImageType('profile');
      //       setSelectedImage(file);
      //     }

      //     if (type === 'headerImage') {
      //       console.log('2');

      //       setImageType('banner');
      //       setSelectedImage(file);
      //     }
      //   };
      // };
    }
  };

  // const handleDrop = async (e: any, type: any) => {
  //   e.preventDefault();

  //   const file = e.dataTransfer.files[0];

  //   if (file) {
  //     const reader = new FileReader();
  //     if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
  //       setToast({ message: 'Unsupported File type ', type: 'error' });
  //       handleClick();
  //       return;
  //     }

  //     reader.onload = async (event: any) => {
  //       const image = new Image();
  //       image.src = event.target.result;

  //       image.onload = () => {
  //         if (type === 'profileImage') {
  //           if (image.width > 500 || image.height > 500) {
  //             setToast({ message: 'Image dimensions are large', type: 'error' });
  //             handleClick();
  //             return;
  //           }
  //           setError((pre: any) => ({
  //             ...pre,
  //             profilePath: '',
  //           }));
  //           setLoading((previousState: any) => ({ ...previousState, profile: true }));
  //           const formData = new FormData();
  //           formData.append('file', file);
  //           if (profilePic.profilePath) {
  //             formData.append('imagePath', profilePic.profilePath);
  //           }
  //           const request = profilePic.profilePath ? privateAxios.put : privateAxios.post;
  //           const responsesssss = request(`user/${profilePic.profilePath ? 'profilereupload' : 'profileupload'}`, formData, {
  //             headers: {
  //               'Content-Type': 'multipart/form-data',
  //             },
  //           });
  //           responsesssss
  //             .then((response: any) => {
  //               if (response.status === 200) {
  //                 setProfilePic((prevData: any) => ({
  //                   ...prevData,
  //                   profilePath: response.data.data,
  //                 }));
  //                 setLoading((previousState: any) => ({ ...previousState, profile: false }));
  //                 setError((pre: any) => ({
  //                   ...pre,
  //                   profilePath: '',
  //                 }));
  //               }
  //             })
  //             .catch((error: any) => {
  //               setLoading((previousState: any) => ({ ...previousState, profile: false }));
  //               console.error(error);
  //             });
  //         }

  //         if (type === 'headerImage') {
  //           if (image.width > 1680 || image.height > 860) {
  //             setToast({ message: 'Image dimensions are large', type: 'error' });
  //             handleClick();
  //             return;
  //           }
  //           setLoading((previousState: any) => ({ ...previousState, header: true }));
  //           const formData = new FormData();
  //           formData.append('file', file);
  //           if (profilePic.headerPath) {
  //             formData.append('imagePath', profilePic.headerPath);
  //           }
  //           const request = profilePic.headerPath ? privateAxios.put : privateAxios.post;

  //           const responsesssss = request(`user/${profilePic.headerPath ? 'headerreupload' : 'headerupload'}`, formData, {
  //             headers: {
  //               'Content-Type': 'multipart/form-data',
  //             },
  //           });

  //           responsesssss
  //             .then((response: any) => {
  //               if (response.status === 200) {
  //                 setProfilePic((prevData: any) => ({
  //                   ...prevData,
  //                   headerPath: response.data.data,
  //                 }));
  //                 setLoading((previousState: any) => ({ ...previousState, header: false }));

  //                 setError((pre: any) => ({
  //                   ...pre,
  //                   headerPath: '',
  //                 }));
  //               }
  //             })
  //             .catch((error: any) => {
  //               setLoading((previousState: any) => ({ ...previousState, header: false }));
  //               console.error(error);
  //             });
  //         }
  //       };
  //     };

  //     reader.readAsDataURL(file);
  //   }
  // };
  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleUserName = () => {
    const checkUserName = privateAxios.post(
      `user/userNameExists`,
      { userName: profilePic.userName },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    checkUserName
      .then((res) => {
        setNameValidate(() => true);
        const { data } = res;
        setToast({ message: data.message.value, type: 'success' });
        handleClick();
        setError((preData: any) => ({
          ...preData,
          userConfirm: '',
        }));
        setNameDisable(true);
      })
      .catch((error) => {
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  return (
    <>
      <Typography
        sx={{
          fontFamily: "'Onest', sans-serif",
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '38px',
          paddingTop: '30px',
        }}>
        Personal details
      </Typography>
      <Box>
        <Grid container>
          <Typography
            className="lable-name"
            sx={{
              textAlign: 'left',
              mt: '15px',
            }}>
            User Name<span className="mandatoryFields">*</span>&nbsp;
            <span
              style={{
                color: 'var(--gray-400, #98A2B3)',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
              }}>
              You can change this later after your account is created.
            </span>
          </Typography>
          <Grid item sm={9}>
            <TextField
              className="formFields"
              sx={{
                width: '700px',
                '&:hover': {
                  borderColor: 'red',
                },
              }}
              name="userName"
              placeholder="Enter the First Name"
              value={profilePic.userName || ''}
              onChange={handleChange}
              required
            />
          </Grid>

          <Grid item sm={2} sx={{ marginLeft: 'auto', marginRight: '-8px' }}>
            <Button
              className="common-button-white"
              sx={{
                height: '50px !important',
                width: '135px',
                backgroundColor: nameDisable ? 'lightgray !important' : 'white !important',
              }}
              onClick={handleUserName}
              disabled={nameDisable}>
              Confirm
            </Button>
          </Grid>
          {Error.userName && <Typography sx={{ color: 'red', mt: 1 }}>{Error.userName}</Typography>}
          {Error.userName === '' && Error.userConfirm && (
            <Typography sx={{ color: 'red', mt: 1 }}>{Error.userConfirm}</Typography>
          )}
        </Grid>
        <Grid container>
          <Grid item sm={1.8}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '15px',
              }}>
              Profile Image {/* <span className="mandatoryFields">*</span> */}
            </Typography>
            <Box
              className="pro-image"
              sx={{
                width: '170px',
                height: '180px',
                mt: 3,
              }}>
              {!loading.profile ? (
                <img src={profilePic.profilePath} alt="profileimg" className="img-fluid profile-pic" />
              ) : (
                <CircularProgress color="success" sx={{ marginLeft: '26px' }} />
              )}
            </Box>
          </Grid>
          <Grid item sm={10.2}>
            <Box sx={{ mt: '32px', ml: '12px' }}>
              <Box
                className="p-image imageBox"
                sx={{
                  height: '128px',
                  textAlign: 'center',
                  pt: '25px',
                  marginRight: '30px',
                }}
                onClick={(e) => uploadAllImage(e, 'Profile_image')}
                onDrop={(e) => handleDrop(e, 'profileImage')}
                onDragOver={handleDragOver}>
                <>
                  <img src={uploadCloud} alt="img" className="img-fluid upload-button" />
                  <input
                    className="file-upload"
                    id="icon_profile_icon"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileUpload(e, 'profileImage')}
                  />
                </>

                <Typography
                  className="note"
                  sx={{
                    color: 'var(--Primary, #2CB512)',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '18px',
                    fontFamily: "'Onest', sans-serif !important",
                  }}>
                  Drag & Drop, or Click to upload
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--gray-400, #98A2B3)',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '18px',
                    fontFamily: "'Onest', sans-serif !important",
                  }}>
                  JPG, PNG Format
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {Error.profilePath && <Typography sx={{ color: 'red', mt: 1 }}>{Error.profilePath}</Typography>}
        <Grid container>
          <Grid item sm={12}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '19px',
              }}>
              Header Image
            </Typography>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // width: '100%',
              // height: '120px',
              // height:'calc(100vw / 9)',
              overflow: 'hidden',
              borderRadius: '10px',
              marginTop: '10px',
            }}>
            {!loading.header ? (
              <img
                src={profilePic.headerPath}
                alt="headerImage"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            ) : (
              <CircularProgress color="success" />
            )}
          </Box>
          <Grid item sm={12}>
            <Box sx={{ mt: '32px' }}>
              <Box
                className="p-image imageBox"
                sx={{
                  height: '128px',
                  textAlign: 'center',
                  pt: '25px',
                  marginRight: '30px',
                }}
                onClick={(e) => uploadAllImage(e, 'Header_image')}
                onDrop={(e) => handleDrop(e, 'headerImage')}
                onDragOver={handleDragOver}>
                <img src={uploadCloud} alt="img" className="img-fluid upload-button" />
                <input
                  className="file-upload"
                  id="icon_header_icon"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, 'headerImage')}
                />
                <Typography
                  className="note"
                  sx={{
                    color: 'var(--Primary, #2CB512)',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '18px',
                    fontFamily: "'Onest', sans-serif !important",
                  }}>
                  Drag & Drop, or Click to upload
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--gray-400, #98A2B3)',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '18px',
                    fontFamily: "'Onest', sans-serif !important",
                  }}>
                  JPG,PNG Format
                </Typography>
              </Box>
            </Box>
          </Grid>

          {selectedImage && (
            <Grid item xs={12}>
              <Crop
                cropOpen={cropImage.openCrop}
                images={selectedImage}
                imageSize={cropImage.imageType}
                onCropComplete={(croppedImage: any) => {
                  UploadImage(croppedImage);
                }}
                onCloseDialog={onCloseCropDialog}
              />
            </Grid>
          )}
        </Grid>
        {Error.headerPath && <Typography sx={{ color: 'red', mt: 1 }}>{Error.headerPath}</Typography>}
      </Box>
      <Box sx={{ padding: '50px', paddingTop: '40px' }}>
        <Box sx={{ textAlign: 'center' }}>
          {activeStep === 1 ? (
            ''
          ) : (
            <Button
              className="common-button-white"
              type="submit"
              variant="contained"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                marginRight: 1,
                mt: 3,
                mb: 2,
                '&:hover': {
                  color: 'var(--Primary, #2CB512)',
                  backgroundColor: '#fff',
                },
                alignItems: 'center',
              }}>
              Back
            </Button>
          )}
          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleProfilePicData}
            disabled={activeStep === 10}
            sx={{
              marginLeft: 1,
              mt: 3,
              mb: 2,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save and Next
          </Button>
        </Box>
        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Box>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
    backStatus: state.isBack,
  };
}

export default connect(mapDispatchToProps)(ProfilePic);

import React from 'react';
import logo from '../../Assets/Images/logo.png';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function BasicWelcome() {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: '200px 0px',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '540px',
          flex: '1 0 0',
          alignSelf: 'stretch',
          backgroundColor: '#f7f8f8',
        }}>
        <Box
          sx={{
            borderRadius: '24px',
            background: 'var(--White, #FFF)',
            padding: '50px',
          }}>
          <img src={logo} alt="logo" />
          <Typography
            sx={{
              fontFamily: "'Onest', sans-serif",
              fontSize: '30px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '38px',
              pt: '25px',
            }}>
            Welcome!
          </Typography>
          <Typography
            sx={{
              color: 'var(--gray-400, #98A2B3)',
              fontFamily: "'Onest', sans-serif",
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              pt: '12px',
            }}>
            Thank you for signing up to Avalbl! Your account is almost ready. Start using Avalbl by completing your profile.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              className="common-button-green"
              type="submit"
              onClick={() => navigate('/basic/sign-up')}
              variant="contained"
              sx={{
                marginLeft: 1,
                mt: 3,
                mb: 2,
                '&:hover': {
                  background: 'var(--Primary, #2CB512)',
                },
              }}>
              Setup your profile
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BasicWelcome;

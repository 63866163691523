import { Box, Button, Dialog, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import '../../Assets/css/MessageDialog.css';

export const MessageDialog = ({ openMessageDialog, handleClose, data, handleMessageSubmit, resetMessage }: any) => {
  const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    setUserData(data);

    if (resetMessage) {
      setMessage('');
    }
  }, [data, resetMessage]);

  const handleChange = (e: any) => {
    setMessage(e.target.value);
  };

  return (
    <Dialog
      open={openMessageDialog}
      // onClose={handleClose}
      className="msgArt"
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '906px !important',
          padding: '30px',
          height: 'auto',
        },
      }}>
      <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography style={{ color: '#2CB512', fontSize: '18px', fontWeight: 600, flex: '1' }}>
          Send a message to the {userData === undefined ? 'selected' : userData} user
        </Typography>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </Grid>
      <Typography style={{ color: '#73787E', fontSize: '16px', fontWeight: 400, marginTop: '10px' }}>
        {/* The message you wire it will directly share to induvial of their chats. */}
        Your message will be directly sent to the user&apos;s conversations panel and their reply will appear in yours.
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className="lable-name" sx={{ textAlign: 'left', mt: '30px' }}>
            Write message
          </Typography>
          <TextField
            className="formFields message-field"
            multiline
            rows={5}
            sx={{ mt: 0.5, width: '780px' }}
            type="text"
            placeholder="Type Something Here..."
            value={message}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
          className="common-button-white"
          type="submit"
          variant="contained"
          onClick={handleClose}
          sx={{
            width: '300px',
            height: '44px',
            marginRight: 2,
            mt: 3,
            mb: 2,
            '&:hover': {
              color: 'var(--Primary, #2CB512)',
              backgroundColor: '#fff',
            },
          }}>
          Cancel
        </Button>
        <Button
          className="common-button-green"
          type="submit"
          variant="contained"
          onClick={() => handleMessageSubmit(message)}
          disabled={message === ''}
          sx={{
            color: message === '' ? '#000 !important' : '#fff !important',
            backgroundColor: message === '' ? '#667085 !important' : '#2cb512 !important',
            opacity: message == '' ? 0.5 : '',
            width: '300px',
            height: '44px',
            marginRight: 2,
            mt: 3,
            mb: 2,
            '&:hover': {
              background: 'var(--Primary, #2CB512)',
            },
            '&.Mui-disabled': {
              color: '#FFF', // Override text color for disabled state
            },
          }}>
          Send Message
        </Button>
      </Box>
    </Dialog>
  );
};

import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Card, CardContent, Typography, Button, Box, Grid } from '@mui/material';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import cardSideIcon from '../../../Assets/Images/cardSideIcon.png';
import Paymentmethodicon from '../../../Assets/Images/Paymentmethodicon.png';

interface CardSliderProps {
  cards: any;
  onEdit: (index: number, card: any) => void;
  onDelete: (index: number) => void;
}
const CardSlider: React.FC<CardSliderProps> = ({ cards, onEdit, onDelete }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const isPrevDisabled = activeIndex === 0;
  const isNextDisabled = activeIndex === cards.length - 1;

  const formatCardNumber = (cardNumber: number) => {
    const formattedCardNumber = String(cardNumber)
      .replace(/(\d{4})/g, '$1 ')
      .trim();
    return formattedCardNumber;
  };

  // const formatCardNumber = (cardNumber: number) => {
  //   const maskedNumber = String(cardNumber).replace(/.(?=.{4})/g, '*'); // Mask all but the last four digits
  //   const formattedCardNumber = maskedNumber.replace(/(.{4})/g, '$1 '); // Insert a space after every four characters
  //   return formattedCardNumber;
  // };

  return (
    <Grid sx={{ height: 'auto', alignItems: 'center' }}>
      <Carousel
        sx={{ height: '300px' }}
        autoPlay={false}
        indicators={false}
        index={activeIndex}
        // animation="slide"
        // timeout={500}
        navButtonsAlwaysInvisible>
        {cards.map((card: any, index: any) => (
          <React.Fragment key={index}>
            <Box sx={{ backgroungColor: 'red' }}>
              <Box
                sx={{
                  borderRadius: '16px',
                  position: 'relative',
                  backgroundColor: '#b9ddb1',
                  boxShadow: '0px 10px 15px -5px rgba(0, 0, 0, 0.1),0px 0px 30px 0px rgba(0, 0, 0, 0.1)',
                  '::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: '-10px',
                    borderRadius: '16px',
                    width: '10px',
                    height: '100%',
                    backgroundColor: '#b9ddb1',
                    boxShadow: '15px 0px 15px -5px rgba(0, 0, 0, 0.1)',
                  },
                  '::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    right: '-10px',
                    width: '10px',
                    height: '100%',
                    backgroundColor: '#b9ddb1',
                    boxShadow: '-15px 0px 15px -5px rgba(0, 0, 0, 0.1)',
                  },
                }}>
                <Card
                  key={index}
                  className="cardWidth"
                  sx={{
                    alignItems: 'center',
                    maxWidth: '80%',
                    width: '100%',
                    marginBottom: '20px',
                    background: 'linear-gradient(to top, #2CB512 0%, #1E740E 100%)',
                    color: '#fff',
                    justifyContent: 'space-between',
                    padding: '10px',
                    borderRadius: '16px',
                    position: 'relative', // Make the container relative for pseudo-elements
                    overflow: 'hidden',
                    margin: '0 auto',
                  }}>
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: '10px',
                        flexEnd: 'right',
                      }}>
                      <Typography
                        variant="h1"
                        component={'span'}
                        sx={{
                          fontSize: '19px',
                          fontWeight: 600,
                          fontFamily: 'Inter, sans-serif',
                          lineHeight: '19px',
                          letterSpacing: '0em',
                          textAlign: 'left',
                          textTransform: 'uppercase',
                        }}>
                        {card.cardHolderName}
                      </Typography>
                      <img src={cardSideIcon} alt="Card Image" style={{ marginRight: '10px' }} />
                    </Box>
                    <Grid container spacing={2} sx={{ mt: '90px' }}>
                      <Grid item sm={10}>
                        <Grid>
                          <Grid container>
                            {/* <Grid item sm={8} sx={{ textAlign: 'left' }}>
                              <Typography
                                component={'span'}
                                sx={{
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  fontFamily: 'Manrope !important',
                                  lineHeight: '16px',
                                  letterSpacing: '0.05em',
                                  textAlign: 'left',
                                  marginTop: '5px',
                                  textTransform: 'uppercase',
                                }}>
                                {card.name}
                              </Typography>
                            </Grid> */}
                            <Grid item sx={{ textAlign: 'right' }}>
                              <Typography
                                variant="h5"
                                component={'span'}
                                sx={{
                                  fontSize: '15px',
                                  fontWeight: 600,
                                  fontFamily: 'Manrope !important',
                                  lineHeight: '16px',
                                  letterSpacing: '0.05em',
                                  textAlign: 'left',
                                  marginTop: '5px',
                                }}>
                                {card.expiry}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid sx={{ textAlign: 'left' }}>
                          <Typography
                            className="cardNumbers"
                            variant="h1"
                            sx={{
                              fontSize: '25px',
                              fontWeight: 600,
                              fontFamily: 'Manrope !important',
                              lineHeight: '22px',
                              letterSpacing: '0.15em',
                              textAlign: 'left',
                              marginTop: '5px',
                            }}>
                            **** **** **** {formatCardNumber(card.cardNumber)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item sm={2}>
                        <img
                          src={Paymentmethodicon}
                          alt="Card Image"
                          style={{ width: '50px', height: '30px', alignSelf: 'flex-end' }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
              <Typography sx={{ color: '#2CB512', marginRight: 2 }} onClick={() => onEdit(card.cardId, card)}>
                Edit card
              </Typography>
              <Typography sx={{ color: '#2CB512' }} onClick={() => onDelete(card.cardId)}>
                Delete card
              </Typography>
            </Box>
          </React.Fragment>
        ))}
      </Carousel>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <Button sx={{ color: '#fff' }} onClick={handlePrevClick} disabled={activeIndex === 0}>
          <ArrowCircleLeftOutlinedIcon sx={{ color: isPrevDisabled ? 'gray' : '#2CB512' }} />
        </Button>
        <span style={{ margin: '0 10px' }}>
          {activeIndex + 1} / {cards.length}
        </span>
        <Button sx={{ color: '#fff' }} onClick={handleNextClick} disabled={activeIndex === cards.length - 1}>
          <ArrowCircleRightOutlinedIcon sx={{ color: isNextDisabled ? 'gray' : '#2CB512' }} />
        </Button>
      </div>
    </Grid>
  );
};

export default CardSlider;

import { useEffect, useState } from 'react';
import axios from 'axios';

interface ErrorResponse {
  error: string;
}

interface UserData {
  id: number;
  name: string;
  email: string;
  password: string;
  profilePic: string;
}

interface HookResponse {
  recipientUser: UserData | null;
  error: ErrorResponse | null;
}

export const useFetchRecipientUser = (chat: any, user: any): HookResponse => {
  console.log('useFetchRecipientchat', chat);
  console.log('useFetchRecipientUser', user);

  const [recipientUser, setRecipientUser] = useState<UserData | null>(null);
  const [error, setError] = useState<ErrorResponse | null>(null);

  const recipientId = chat?.members?.find((id: any) => id !== user?.id);

  useEffect(() => {
    const getUser = async () => {
      if (!recipientId) return;

      try {
        const response = await axios.get(`http://localhost:8000/api/v1/user/find/${recipientId}`);
        setRecipientUser(response.data);
      } catch (error: any) {
        // Explicitly specify the type of 'error'
        setError(error.response ? error.response.data : ({ error: 'An unknown error occurred' } as ErrorResponse));
      }
    };

    getUser();
  }, [recipientId, chat, user]);

  return { recipientUser, error };
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Autocomplete,
  InputAdornment,
  Button,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import mapPin from '../../../Assets/Images/mapPin.png';
import '../../../Assets/css/Common.css';
import CustomSnackbar from '../../../common/Snackbar';
import { connect } from 'react-redux';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import Map from '../../auth/Stepper/Map';
import { useNavigate } from 'react-router-dom';
// import { IUserDetails } from '../../Interface/UserInterface/IUser';

interface AutocompleteOption {
  id: string;
  label: string;
}

// interface LanguageLocationProps {
//   data: IUserDetails | null;
// }

interface langLoacProps {
  language: string[];
  city: string;
  state: string;
  country: string;
  locationId: string;
  lat: string;
  long: string;
}

interface MapProps {
  lat: number;
  lng: number;
}

const Personallocation: React.FC = () => {
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOption[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [langLocData, setLangLocData] = useState<langLoacProps>({
    language: [],
    city: '',
    state: '',
    country: '',
    locationId: '',
    lat: '',
    long: '',
  });
  const [error, setError] = useState({
    language: [],
    city: '',
    state: '',
    country: '',
  });
  const [center, setCenter] = useState<MapProps>({ lat: 9.318327499999999, lng: 76.61108449999999 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if (data !== null) {
    //   languageLocationData(data);
    // }
    getUserDetails();
  }, []);

  const splitLocationLabel = (label: any) => {
    const parts = label.split(', ');

    let city, state, country;

    if (parts.length === 6) {
      city = parts[0];
      state = parts.slice(1, 5).join(', ');
      country = parts[5];
    } else if (parts.length === 5) {
      city = parts[0];
      state = parts.slice(1, 4).join(', ');
      country = parts[4];
    } else if (parts.length === 4) {
      city = parts[0];
      state = parts.slice(1, 3).join(', ');
      country = parts[3];
    } else if (parts.length === 3) {
      city = parts[0];
      state = parts[1];
      country = parts[2];
    } else if (parts.length === 2) {
      city = null;
      state = parts[0];
      country = parts[1];
    } else if (parts.length === 1) {
      city = null;
      state = null;
      country = parts[0];
    }

    return { city, state, country };
  };

  // const languageLocationData = (data: IUserDetails) => {
  //   const label = data?.location.label;

  //   const { city, state, country } = splitLocationLabel(label);
  //   setLangLocData({
  //     language: data?.language,
  //     city: city,
  //     state: state,
  //     country: country,
  //     locationId: data?.location.label,
  //     lat: '',
  //     long: '',
  //   });
  //   setSelectedLocation({
  //     label: `${city || ''}, ${state || ''}, ${country || ''}`,
  //   });
  //   setCenter({
  //     lat: data?.location.lat,
  //     lng: data?.location.long,
  //   });
  // };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLocation = (e: any, newValue: AutocompleteOption | null) => {
    if (newValue === null) {
      clearSelection();
    } else {
      setSelectedLocation(newValue);
      const { city, state, country } = splitLocationLabel(newValue.label);
      setLangLocData((preData: any) => ({
        ...preData,
        city: city,
        state: state,
        country: country,
        locationId: newValue.id,
      }));
    }

    if (newValue) {
      const getExactLocation = privateAxios.get(`user/getlatlong?id=${newValue.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      getExactLocation
        .then((response: any) => {
          if (response.status === 200) {
            const coordinatesData = response.data.data.coordinates;

            localStorage.setItem(
              'center',
              JSON.stringify({ lat: coordinatesData.latitude, lng: coordinatesData.longitude })
            );

            setCenter({
              lat: coordinatesData.latitude,
              lng: coordinatesData.longitude,
            });

            setLangLocData((preData: any) => ({
              ...preData,
              lat: String(coordinatesData.latitude),
              long: String(coordinatesData.longitude),
            }));
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    }
  };

  const clearSelection = () => {
    setLangLocData((preData) => ({
      ...preData,
      city: '',
      state: '',
      country: '',
      locationId: '',
    }));
    setAutocompleteOptions([]);
    localStorage.setItem('center', JSON.stringify({ lat: '', lng: '' }));
    setCenter({
      lat: 9.318327499999999,
      lng: 76.61108449999999,
    });
  };

  const handleCityStateCountry = (e: any, newValue: any) => {
    const location = {
      location: newValue,
    };

    if (newValue.length > 4) {
      const searchLocation = privateAxios.post(`user/location`, location, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      searchLocation
        .then((response: any) => {
          setAutocompleteOptions(response.data.data);
        })
        .catch((error: any) => {
          if (error.response.data.data != null) {
            setToast({ message: error.response.data.message.value, type: 'error' });
          } else {
            setToast({ message: error.response.data.message, type: 'error' });
          }
          handleClick();
        });
    }
  };
  const handleCancelClick = () => {
    navigate('/account-dashboard');
  };

  const handleSubmitClick = () => {
    const { language, country } = langLocData;

    const errors: any = {
      language: '',
      city: '',
      state: '',
      country: '',
    };

    if (language.length === 0) {
      errors.language = 'Language is required';
    }

    // if (!city) {
    //   errors.city = 'Location is required';
    // }

    // if (!state) {
    //   errors.state = 'Location is required';
    // }

    if (!country) {
      errors.country = 'Location is required';
    }
    if (Object.values(errors).some((field) => field !== '')) {
      setError(errors);
      return;
    }

    const updateLocationData = privateAxios.put(`user/langlocationinfo`, langLocData, {
      // const updateLocationData = privateAxios.put(`user/langlocationinfo`, backupdata, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    updateLocationData
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
        }
      })
      .catch((error: any) => {
        setToast({ message: error.response.data.message, type: 'error' });
        handleClick();
      });
  };

  const languageData = (event: any, newValue: any) => {
    setLangLocData((prevLocation) => ({
      ...prevLocation,
      language: newValue,
    }));
  };

  const getUserDetails = () => {
    setLoading(true);
    const getDetails = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          const label = data?.location.label;

          const { city, state, country } = splitLocationLabel(label);
          setLangLocData({
            language: data?.language,
            city: city,
            state: state,
            country: country,
            locationId: data?.location.label,
            lat: String(data?.location.lat),
            long: String(data?.location.long),
          });
          setSelectedLocation({
            label: `${city || ''}, ${state || ''}, ${country || ''}`,
          });
          setCenter({
            lat: data?.location.lat,
            lng: data?.location.long,
          });
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  return (
    <>
      <div style={{ margin: '0px', position: 'relative' }}>
        <Grid
          container
          className="button-position"
          sx={{
            position: 'absolute',
            top: -140,
            right: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}>
          <Button
            className="common-button-white"
            type="submit"
            variant="contained"
            onClick={handleCancelClick}
            sx={{
              width: '167px',
              height: '44px',
              marginRight: 1,
              '&:hover': {
                color: 'var(--Primary, #2CB512)',
                backgroundColor: '#fff',
              },
            }}>
            Cancel
          </Button>
          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleSubmitClick}
            sx={{
              width: '121px',
              height: '44px',
              marginLeft: 1,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save
          </Button>
        </Grid>
      </div>

      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress color="success" />
        </Backdrop>
      )}

      {!loading && (
        <Box sx={{ p: '20px', backgroundColor: '#FFFFFF', borderRadius: '10px' }}>
          <Typography
            className="lable-name"
            sx={{
              textAlign: 'left',
              mt: '15px',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              mb: 1,
            }}>
            Languages Spoken (Specify up to 3 Languages you can work in.)
          </Typography>

          <Grid>
            <Autocomplete
              fullWidth
              multiple
              freeSolo
              options={[]}
              value={langLocData?.language}
              onChange={(event, newValue) => languageData(event, newValue)}
              renderInput={(params) => <TextField className="formFields" {...params} placeholder="Type & Press Enter" />}
            />
          </Grid>
          {error.language && <Typography sx={{ color: 'red', mt: 1 }}>{error.language}</Typography>}

          <Grid>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '15px',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px',
                mb: 1,
              }}>
              Location
            </Typography>

            <Autocomplete
              value={selectedLocation || null}
              onChange={(e, newValue) => handleLocation(e, newValue)}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                handleCityStateCountry(event, newInputValue);
                if (newInputValue.trim() === '') {
                  clearSelection();
                  setSelectedLocation(null);
                }
              }}
              options={autocompleteOptions}
              getOptionLabel={(option) => (option && option.label ? option.label : '')}
              isOptionEqualToValue={(option, value) => option && option.id === value.id && option.label === value.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="formFields"
                  sx={{
                    // width: '850px',
                    '&:hover': {
                      borderColor: 'red',
                    },
                  }}
                  name="location"
                  placeholder="City, State, Country"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={mapPin} alt="mapPin" style={{ width: '24px', height: '24px' }} />
                      </InputAdornment>
                    ),
                  }}
                  value={selectedLocation ? selectedLocation.label : ''}
                />
              )}
            />
          </Grid>
          {error.country && <Typography sx={{ color: 'red', mt: 1 }}>{error.country}</Typography>}

          <div className="location-select">
            <Map center={center} zoom={10} />
          </div>

          <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleClose} toast={toast} />
        </Box>
      )}
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(Personallocation);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-debugger */
import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, InputAdornment, IconButton } from '@mui/material';
// import Env from '../../services/Env';
import '../../Assets/css/Common.css';
import '../../Assets/css/ProfileSetup.css';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from '../../common/Snackbar';
import {
  validateEmail,
  validatePassword,
  // isPasswordValid
} from '../../common/Validation';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useDispatch } from 'react-redux';
import {
  userId,
  isLoggedIn,
  userType,
  userAccessToken,
  userRefreshToken,
  userStepStatus,
  userPM,
  customerID,
  userDC,
} from '../../reducer/action';
// import { refreshTokenGenrate } from '../../reducer/action';
import { AppDispatch } from '../../reducer/store';
// import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { publicAxios } from '../../services/Interceptor';

const LoginForm = () => {
  // const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [formData, setFormData] = useState({ email: '', password: '' });
  const [Errors, setErrors] = useState({ email: '', password: '' });
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [showPassword, setShowPassword] = useState(false);

  // New
  const [planData, setPlanData] = useState([]);
  useEffect(() => {
    getPlanInfo();
  }, []);

  const getPlanInfo = () => {
    // const getPlan = Env.get(`plan/getPlan`, '');
    const getPlan = publicAxios.get(`plan/getPlan`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getPlan
      .then((response: any) => {
        if (response.status === 200) {
          setPlanData(response.data.data);
        }
      })
      .catch((error: any) => {
        if (error.message) {
          setToast({ message: error.message, type: 'error' });
        } else {
          setToast({ message: error.response.data.message.value, type: 'error' });
        }

        handleClick();
      });
  };

  // end

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = () => {
    const { email, password } = formData;

    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);
    // const passwordIsValid = isPasswordValid(password);

    setErrors({
      email: emailError,
      password: passwordError,
      // condition: passwordIsValid,
    });

    if (emailError !== '' || passwordError !== '') {
      return;
    }

    // const postData = Env.post(`login`, formData, '');
    const postData = publicAxios.post(`login`, formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    postData
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          setTimeout(() => {
            const { id, accessToken, refreshToken, isBasic, isPremium, status, isPayment, isPermanent } = response.data.data;
            status === 'Active'
              ? navigate('/')
              : status === 'Registration'
              ? isPremium
                ? isPayment
                  ? navigate('/premium/sign-up')
                  : navigate('/premium/choose-payment')
                : navigate('/basic/welcome')
              : navigate('/login');
            dispatch(isLoggedIn(true));
            dispatch(userId(id));
            if (isPremium) {
              dispatch(userType(true));
            }
            if (isBasic) {
              dispatch(userType(false));
            }
            dispatch(userStepStatus({ status: response.data.data.status }));
            dispatch(userDC(isPermanent));
            dispatch(userPM(isPayment));
            dispatch(userAccessToken({ accessToken: accessToken }));
            dispatch(userRefreshToken({ refreshToken: refreshToken }));
          }, 2000);
        }
        if (response.status === 202) {
          handleClick();
          setToast({ message: response.data.message.value, type: 'error' });

          const { id, accessToken, refreshToken, isBasic, isPremium, isPayment, stripeCusId, isPermanent } =
            response.data.data;

          dispatch(userId(id));
          if (isPremium) {
            dispatch(userType(true));
          }
          if (isBasic) {
            dispatch(userType(false));
          }
          dispatch(userPM(isPayment));
          dispatch(userStepStatus({ status: response.data.data.status }));
          dispatch(userAccessToken({ accessToken: accessToken }));
          dispatch(userRefreshToken({ refreshToken: refreshToken }));
          dispatch(customerID(stripeCusId));
          dispatch(userDC(isPermanent));
          setTimeout(() => {
            navigate('/renew');
            dispatch(isLoggedIn(true));
          }, 3 * 1000);
        }
        if (response.status === 203) {
          handleClick();
          setToast({ message: response.data.message.value, type: 'error' });

          const { id, accessToken, refreshToken, isBasic, isPremium, isPayment, stripeCusId, isPermanent } =
            response.data.data;

          dispatch(userId(id));
          if (isPremium) {
            dispatch(userType(true));
          }
          if (isBasic) {
            dispatch(userType(false));
          }
          dispatch(userPM(isPayment));
          dispatch(userStepStatus({ status: response.data.data.status }));
          dispatch(userAccessToken({ accessToken: accessToken }));
          dispatch(userRefreshToken({ refreshToken: refreshToken }));
          dispatch(customerID(stripeCusId));
          dispatch(userDC(isPermanent));
          setTimeout(() => {
            navigate('/premium/choose-payment');
            dispatch(isLoggedIn(true));
          }, 3 * 1000);
        }
      })
      .catch((error: any) => {
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleFieldChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value.replace(/^\s+/, '').replace(/\s+/g, ' '),
    }));

    if (name === 'email') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: value.trim() === '' ? 'Email is required' : '',
      }));
    }

    if (name === 'password') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: value === '' ? 'Password required' : '',
        // condition: value < 8 ? 'Password must be at least 8 characters long' : '',
      }));
    }
  };

  return (
    <>
      <Box>
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              textAlign: 'left',
              fontFamily: "'Onest', sans-serif !important",
              fontWeight: '900',
              fontSize: '32px',
            }}>
            Sign In
          </Typography>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              color: 'var(--gray-500, #667085)',
              fontFamily: "'Onest', sans-serif !important",
            }}>
            Sign in your account using your email and password.
          </Typography>
          <Box
            component="form"
            onSubmit={(e: any) => {
              e.preventDefault();
              handleLogin();
            }}
            sx={{ mt: 3 }}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                display: 'flex',
                alignItems: 'flex-start',
                // gap: '6px',
                marginBottom: '6px',
                alignSelf: 'stretch',
              }}>
              Email<span className="mandatoryFields">*</span>
            </Typography>
            <TextField
              className="formFields"
              autoComplete="off"
              sx={{ mt: 1, borderRadius: '8px', width: '500px !important' }}
              name="email"
              placeholder="Enter the Email ID"
              value={formData.email}
              onChange={handleFieldChange}
            />
            {Errors.email && <Typography sx={{ color: 'red', mt: 1 }}>{Errors.email}</Typography>}
            <Typography className="lable-name" sx={{ textAlign: 'left', mt: '15px' }}>
              Password<span className="mandatoryFields">*</span>
            </Typography>
            <TextField
              className="formFields"
              sx={{ mt: 1, width: '500px' }}
              name="password"
              placeholder="Enter the Password"
              margin="normal"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleFieldChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClickShowPassword()} onMouseDown={() => handleMouseDownPassword()}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {Errors.password && <Typography sx={{ color: 'red', mt: 1 }}>{Errors.password}</Typography>}
            {/* {Errors.password === '' && Errors.condition ? (
              <Typography sx={{ color: 'red', mt: 1 }}>{Errors.condition}</Typography>
            ) : (
              ''
            )} */}

            <Typography
              sx={{
                textAlign: 'left',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '20px',
              }}>
              <a href="/forget-password" style={{ textDecoration: 'none', color: '#2CB512' }}>
                Forgot Password?
              </a>
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button
                className="common-button-green"
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  textTransform: 'none',
                  width: '120px !important',
                  height: '50px !important',
                }}>
                Sign In
              </Button>
            </Box>
            <Typography
              sx={{
                textAlign: 'left',
                color: 'var(--_Gray-500, #667085)',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                fontFamily: "'Onest', sans-serif !important",
              }}
              component="h6"
              variant="h6">
              Not yet a member?{' '}
              <span
                // href="/plan"
                onClick={() => navigate('/plan', { state: { plan: planData } })}
                style={{
                  textDecoration: 'none',
                  color: 'var(--_Gray-800, #1D2939)',
                  fontWeight: 600,
                  cursor: 'pointer',
                  fontFamily: "'Onest', sans-serif !important",
                }}>
                Sign up here.
              </span>
            </Typography>
            <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginForm;

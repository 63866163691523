import { Box, Grid, Typography, Switch, styled, Stack, Button, Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import back from '../../Assets/Images/back.png';
import DoneIcon from '@mui/icons-material/Done';
import { publicAxios } from '../../services/Interceptor';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../common/Snackbar';
import { connect } from 'react-redux';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2CB512',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-thumb:hover': {
    color: 'red',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const Renew = (props: any) => {
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();
  const [toast, setToast] = useState({ message: '', type: 'success' });
  const [planData, setPlanData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState(true);
  const [open, setOpen] = useState(false);
  const [paymentMode, setPaymentMode] = useState<any>({ isMonthly: false, isAnnually: true });
  // console.log(planData, paymentMode);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function handlePlanChange() {
    setPlan((ps: any) => !ps);
    setPaymentMode((ps: any) => ({ isMonthly: !ps.isMonthly, isAnnually: !ps.isAnnually }));
  }
  useEffect(() => {
    getPlanInfo();
  }, []);

  const getPlanInfo = () => {
    const getPlan = publicAxios.get(`plan/getPlan?isPlan=${true}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getPlan
      .then((response: any) => {
        if (response.status === 200) {
          setPlanData(response.data.data);
          setLoading(() => false);
        }
      })
      .catch((error: any) => {
        console.error('ERROR', error);
        // setToast({ message: error.response.data.message.value, type: 'error' });
        // handleClick();
      });
  };
  function handleRenew() {
    const planPost = privateAxios.put(`plan/updateUserPlan?isPlan=${true}`, paymentMode, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    planPost
      .then((response: any) => {
        setToast({ message: response.data.message.value, type: 'Success' });
        handleClick();
        navigate(`/premium/choose-payment`);
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  return (
    <>
      <Grid sx={{ backgroundColor: '#f7f8f8', height: '100vh' }}>
        {loading && (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="success" />
          </Backdrop>
        )}
        {!loading && (
          <>
            <Box
              sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center', padding: '30px', cursor: 'pointer' }}
              onClick={() => navigate(-1)}>
              <img src={back} alt="back" />
              <span className="lable-name" style={{ marginLeft: '8px', color: '#000' }}>
                Back
              </span>
            </Box>
            <Grid sx={{ mt: 'auto' }}>
              <Box sx={{ margin: '30px 0px' }}>
                <Typography
                  sx={{
                    color: ' #101828',
                    textAlign: 'center',
                    fontSize: '30px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '38px',
                    paragraph: '30px',
                    fontFamily: "'Onest', sans-serif !important",
                  }}>
                  Get ready to go premium!
                </Typography>
                <Typography
                  sx={{
                    color: '#667085',
                    textAlign: 'center',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                    paragraph: '16px',
                    fontFamily: "'Onest', sans-serif !important",
                  }}>
                  Start adding talent to organize and grow your roster
                </Typography>
              </Box>
              <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: plan ? '#7C8C89' : '',
                    fontSize: '18px',
                    lineHeight: '28px',
                    paragraph: '18px',
                    fontFamily: "'Onest', sans-serif !important",
                  }}>
                  Monthly
                </Typography>
                <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={plan} onChange={handlePlanChange} />
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: !plan ? '#7C8C89' : '',
                    fontSize: '18px',
                    lineHeight: '28px',
                    paragraph: '18px',
                    fontFamily: "'Onest', sans-serif !important",
                  }}>
                  Yearly
                </Typography>
                <Typography
                  sx={{
                    backgroundColor: '#FFFAEB',
                    borderRadius: '16px',
                    color: '#B54708',
                    fontWeight: 500,
                    fontSize: '12px',
                    width: '75px',
                    lineHeight: '18px',
                    paragraph: '12px',
                    textDecoration: plan ? 'none' : 'line-through',
                    fontFamily: "'Onest', sans-serif !important",
                    textAlign: 'center',
                  }}
                  component={'div'}>
                  SAVE {planData && planData[1]?.discount}
                </Typography>
              </Stack>

              <Box
                sx={{
                  width: '741px',
                  backgroundColor: '#fff',
                  padding: '35px',
                  margin: '16px auto',
                  borderRadius: '8px',
                  display: 'flex',
                }}>
                <Stack spacing={2}>
                  <Typography
                    style={{
                      margin: '0px 0px 0px 0px',
                      fontSize: '24px',
                      fontWeight: 'bold',
                      // textAlign: 'center',
                      color: 'var(--green)',
                      fontFamily: "'Onest', sans-serif !important",
                    }}>
                    Premium
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#0000005c',
                      marginTop: '0px',
                    }}>
                    {plan && planData[1]?.amount?.savedAmount ? (
                      <>
                        You will save <span style={{ color: 'black' }}>{planData[1].amount.savedAmount}</span>
                      </>
                    ) : null}
                  </Typography>
                  <Box sx={{ marginBottom: '20px !important' }}>
                    <Typography
                      variant="h4"
                      sx={{
                        mt: plan && plan ? '0px ' : '20px',
                        mr: '0px',
                        mb: plan && plan ? '0px ' : '25px',
                        ml: '0px',
                        fontWeight: 600,
                        color: '##000000d9',
                        fontSize: '48px',
                      }}>
                      {!plan ? planData[1]?.amount?.monthly : planData[1]?.amount?.yearly}
                      <span style={{ fontSize: '20px' }}>/mon</span>
                    </Typography>
                    <Typography
                      style={{
                        margin: '0px 0px 0px 0px',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        color: '#0000005c',
                      }}>
                      {plan && planData[1]?.amount?.totalAmount ? (
                        <>
                          <span style={{ color: 'black' }}>{planData[1].amount.totalAmount}</span>, billed annually
                        </>
                      ) : null}
                    </Typography>
                  </Box>
                  <Button
                    className="common-button-green"
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      ml: 2,
                      textTransform: 'none',
                      width: '320px',
                      height: '56px',
                    }}
                    onClick={handleRenew}>
                    {props.userType ? 'Renew Now' : 'Upgrade Now'}
                  </Button>
                </Stack>
                <Box sx={{ fontFamily: "'Onest', sans-serif !important" }}>
                  <ul
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      color: '#00000075',
                      lineHeight: '35px',
                      marginTop: '0px',
                    }}>
                    <li style={{ listStyleType: 'none' }}>
                      <DoneIcon
                        style={{
                          color: 'var(--green)',
                          verticalAlign: 'middle',
                          fontSize: '18px',
                        }}
                      />
                      All features of Basic profile plus:
                    </li>
                    <li style={{ listStyleType: 'none' }}>
                      <DoneIcon
                        style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px', lineHeight: '26px' }}
                      />{' '}
                      Advanced talent search tools
                    </li>
                    <li style={{ listStyleType: 'none' }}>
                      <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Roster
                      management and organization
                    </li>
                    <li style={{ listStyleType: 'none' }}>
                      <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Add and
                      message Basic members
                    </li>
                    <li style={{ listStyleType: 'none' }}>
                      <DoneIcon style={{ color: 'var(--green)', verticalAlign: 'middle', fontSize: '18px' }} /> Send and
                      manage booking requests
                    </li>
                  </ul>
                </Box>
              </Box>
            </Grid>
            <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
          </>
        )}
      </Grid>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    userType: state.userType,
  };
}

export default connect(mapDispatchToProps)(Renew);

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Backdrop, CircularProgress, Container, Grid, Typography, Box } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import BannerImage from '../../Assets/Images/headerProfile.png';
import profileImage from '../../Assets/Images/banner-04.png';
import sliderImage1 from '../../Assets/Images/sliderImage1.png';
import sliderImage2 from '../../Assets/Images/sliderImage2.png';
import sliderImage3 from '../../Assets/Images/sliderImage3.png';
import maiIcon from '../../Assets/Images/mailIcon.png';
import mapPin from '../../Assets/Images/mapPin.png';
import calendarIcon from '../../Assets/Images/calendarIcon.svg';
import Map from '../auth/Stepper/Map';
import Carousel from '../AccountProfile/Carousel';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import '../../Assets/css/Dashboard.css';
import '../../Assets/css/Header.css';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import { MessageDialog } from './MessageDialog';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import CustomSnackbar from '../../common/Snackbar';
import { IUserDetails, UserInfo } from '../Interface/UserInterface/IUser';
import BookingRequestDialog from './Feed/BookingRequestDialogProps';
import Dialog from '@mui/material/Dialog';
import ReportArtist from './Roster/ReportArtist';
import Calendar from '../AccountProfile/AccountDetails/Calendar';
import AddListDialog from './AddListDialog';
import CreateListDialog from './CreateListDialog';

const defaultImages = [sliderImage1, sliderImage2, sliderImage3];

interface ExperienceData {
  label: string;
  key: string;
}

const UserProfileDetail = (props: any) => {
  const privateAxios = useAxiosPrivate();
  const location = useLocation();

  const [showCalender, setShowCalender] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const getId = urlParams.get('id');

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const [data, setData] = useState<IUserDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const [socket, setSocket] = useState<any | null>(null);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [open, setOpen] = React.useState(false);
  const [resetMessage, setResetMessage] = useState(false);
  const [receiverId, setReceiverId] = useState<any>('');
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [sendBookingRequestDialog, setSendBookingRequestDialog] = useState(false);
  const [reportDialog, setReportDialog] = useState(false);
  const [reportData, setReportData] = useState<IUserDetails | null>(null);
  const [reportValues, setReportValues] = useState<any | null>(null);
  const [onlineUsers, setOnlineUsers] = useState<any | null>(null);
  const [fullUserName, setFullUserName] = useState('');
  const [calenderData, setCalenderData] = useState<any>();

  useEffect(() => {
    getUserProfileInfo();
    if (location?.state?.id) {
      setReceiverId(location?.state?.id);
    } else {
      setReceiverId(getId);
    }
    getReportValues();
  }, [location, receiverId]);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SOCKET_API_URL);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket === null) return;
    socket.emit('addNewUser', props.id);
    socket.on('getOnlineUsers', (res: any) => {
      setOnlineUsers(res);
    });

    return () => {
      socket.off('getOnlineUsers');
    };
  }, [socket]);
  const getUserProfileInfo = () => {
    setLoading(true);
    if (receiverId) {
      const getCategoryValues = privateAxios.get(`user/getuserdetails?id=${receiverId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      getCategoryValues
        .then((response: any) => {
          if (response.status === 200) {
            setData(response.data.data);
            const fullName = response.data.data.firstName + ' ' + response.data.data.lastName;
            setFullUserName(fullName);
            setLoading(false);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    }
  };

  const getAbsoluteURL = (path: string) => {
    const externalLink = path;
    const hrefValue = externalLink.startsWith('https') ? externalLink : `http://${externalLink}`;
    return hrefValue;
  };

  const handleMessage = () => {
    setOpenMessageDialog(true);
  };

  const handleSendBookingRequest = () => {
    setSendBookingRequestDialog(true);
  };

  const handleSendBookingRequestDialogClose = () => {
    setSendBookingRequestDialog(false);
  };

  const handleMessageClose = () => {
    setOpenMessageDialog(false);
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMessageSubmit = (message: string) => {
    const response = privateAxios.post(
      `chat/sendmessage`,
      JSON.stringify({
        receiverId: receiverId,
        message: message,
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    response
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          setResetMessage(true);
          setOpenMessageDialog(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });

    if (socket) {
      const messageData = {
        content: message,
        sender_id: props.id,
        recipientId: receiverId,
      };
      socket.emit('sendMessage', messageData);
    } else {
      console.error('Socket is not available.');
    }
  };

  const handleSendBookingRequestDialogSubmit = (sendData: any) => {
    const { bookingId, bookingIds, ...restData } = sendData;

    const hiringData = {
      ...restData,
      bookingId: receiverId,
    };

    const sendHiringRequest = privateAxios.post(`dashboard/hire`, hiringData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    sendHiringRequest
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          setSendBookingRequestDialog(false);
          handleClick();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const [openList, setOpenList] = useState(false);

  const handleOpenList = (status: boolean | null) => {
    if (status) {
      if (data?.email) {
        handleRemoveRoster(data.email);
      }
    } else {
      setOpenList(true);
    }
  };

  const handleRemoveRoster = (email: string) => {
    const emails = {
      email: [email],
    };

    const removeRoster = privateAxios.post(`dashboard/removeroaster`, emails, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    removeRoster
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          getUserProfileInfo();
          handleClick();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const editList = () => {
    setOpenList(true);
  };

  const handleReportArtist = (data: any) => {
    setReportDialog(true);
    setReportData(data);
  };

  const handleReport = (value: string, userName: string) => {
    const submitValue = {
      userName: userName,
      reportKey: value,
    };

    const responseReport = privateAxios.post(`dashboard/report`, submitValue, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    responseReport
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          setResetMessage(true);
          setReportDialog(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const getReportValues = () => {
    const getReports = privateAxios.get(`dashboard/getreport`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getReports
      .then((response: any) => {
        if (response.status === 200) {
          setReportValues(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const labelToValues: any = {
    '1-3 yrs': [100, 0, 0, 0],
    '3-5 yrs': [100, 100, 0, 0],
    '5-10 yrs': [100, 100, 100, 0],
    '10+ yrs': [100, 100, 100, 100],
  };

  const { label = '' } = (data?.experience || {}) as ExperienceData;

  const values = labelToValues[label.toLowerCase()] || [0, 0, 0, 0];

  // const [getListDatas, setListDatas] = useState([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isNewListDialogOpen, setNewListDialogOpen] = useState(false);

  const addArtistList = (newValue: any) => {
    const addArtist = {
      listKey: newValue,
      rosterId: receiverId,
    };

    const addArtistToList = privateAxios.post(`dashboard/addlist`, addArtist, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    addArtistToList
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          getUserProfileInfo();
          handleCloseDialog();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleAddNewListClick = () => {
    setNewListDialogOpen(true);
    setRefresh(false);
  };

  const handleCloseNewListDialog = () => {
    setNewListDialogOpen(false);
    setRefresh(true);
  };

  const handleCloseDialog = () => {
    setRefresh(false);
    setOpenList(false);
  };

  const getColorLabel = (status: string) => {
    switch (status) {
      case 'Available':
        return '#D5EBD1';
      case 'Not Available':
        return '#f5bac4';
      case 'Available Soon':
        return '#cab6d1';
      case 'Available Part Time':
        return '#f5dbba';
      case 'Part Time':
        return '#f5dbba';
      case 'Off Market':
        return '#b2b9eb';
      default:
        return '';
    }
  };

  const getColor = (status: string) => {
    switch (status) {
      case 'Available':
        return 'var(--green)';
      case 'Not Available':
        return 'red';
      case 'Available Soon':
        return 'darkorchid';
      case 'Available Part Time':
        return 'orange';
      case 'Part Time':
        return 'orange';
      case 'Off Market':
        return 'blue';
      default:
        return '';
    }
  };

  function handleCalender() {
    const getCategoryValues = privateAxios.get(`user/getuserdetails?id=${receiverId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getCategoryValues
      .then((response: any) => {
        if (response.status === 200) {
          setCalenderData(() => response.data?.data?.allAvailability);
          setShowCalender(true);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress color="success" />
        </Backdrop>
      )}

      <Grid sx={{ backgroundColor: '#eaecf0' }}>
        <Navbar />
        <Grid sx={{ pt: '88px' }}>
          {data && (
            <section className="acc-banner">
              <img
                src={data.headerPath === null ? BannerImage : data.headerPath}
                alt="img"
                height="100% !important"
                width="100% !important"
                style={{ height: '100% !important', objectFit: 'cover' }}
              />
            </section>
          )}
          <div className="acc-header prem-view">
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={7} sm={6}>
                  <div className="acc-cont">
                    {data && (
                      <div className="acc-img">
                        <img
                          src={data.profilePath === null ? profileImage : data.profilePath}
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                    )}
                    <div className="det-info">
                      <div className="acc-name dis-align">
                        <Typography
                          component={'span'}
                          variant="h3"
                          sx={{ fontSize: '28px', fontWeight: 600, fontFamily: "'Onest', sans-serif" }}>
                          {data && `${data.firstName} ${data.lastName}`}
                        </Typography>
                        <Typography
                          component={'span'}
                          variant="body1"
                          className={`until-date ${data && data.availability === 'available' ? '.available' : ''}`}
                          sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            fontFamily: "'Onest', sans-serif",
                            ml: 2,
                            backgroundColor: getColorLabel((data && data.availability) || ''),
                            color: getColor((data && data.availability) || ''),
                          }}>
                          <span
                            style={{
                              content: '',
                              padding: '3px',
                              borderRadius: '5px',
                              position: 'absolute',
                              left: '10px',
                              top: '11px',
                              backgroundColor: getColor((data && data.availability) || ''),
                            }}></span>
                          {data && data.availability !== '-' && data.availability}
                        </Typography>
                        {data?.allAvailability?.isCalender && (
                          <img
                            src={calendarIcon}
                            alt="img"
                            style={{ marginLeft: '15px', cursor: 'pointer' }}
                            onClick={() => handleCalender()}
                          />
                        )}
                      </div>
                      <div className="skill-info dis-align">
                        <div className="date dis-align">
                          <img src={maiIcon} alt="img" className="img-fluid" />
                          <Typography
                            component={'span'}
                            variant="body1"
                            sx={{ fontSize: '16px', fontWeight: 400, fontFamily: "'Onest', sans-serif", ml: 1 }}>
                            {data && data.jobTitle}
                          </Typography>
                        </div>
                      </div>
                      <Typography
                        component={'span'}
                        variant="body1"
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          fontFamily: "'Onest', sans-serif",
                          // ml: 5,
                          color: '#667085',
                        }}>
                        Username
                      </Typography>
                      <Typography
                        component={'span'}
                        variant="body1"
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          fontFamily: "'Onest', sans-serif",
                          ml: 1,
                          color: '#000',
                        }}>
                        {data && data.userName}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={5} sm={6} sx={{ mt: 2 }}>
                  <div className="list-btn right-btn">
                    <Button
                      sx={{ width: '200px' }}
                      className="common-button-green"
                      variant="contained"
                      color="primary"
                      data-toggle="modal"
                      onClick={handleSendBookingRequest}
                      data-target="#exampleModal-hirenow">
                      Send booking request
                    </Button>
                    <Button
                      sx={{ width: '160px' }}
                      className="common-button-white"
                      variant="outlined"
                      onClick={handleMessage}>
                      Message
                    </Button>
                    {data && data?.isRosterAdded && (
                      <Button
                        sx={{ width: '160px', ml: '15px' }}
                        className="common-button-white"
                        variant="outlined"
                        onClick={editList}>
                        Edit List
                      </Button>
                    )}
                    <Button
                      sx={{ width: data && data?.isRosterAdded ? '200px' : '160px', marginLeft: 2 }}
                      className="common-button-white"
                      variant="outlined"
                      onClick={() => handleOpenList((data && data?.isRosterAdded) || null)}>
                      {data && data?.isRosterAdded ? 'Remove from My Roster' : 'Add to My Roster'}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Container>
            <AddListDialog
              openList={openList}
              handleCloseDialog={handleCloseDialog}
              receiverId={receiverId}
              userName={fullUserName}
              // getListDatas={getListDatas}
              addArtistList={addArtistList}
              handleAddNewListClick={handleAddNewListClick}
              refresh={refresh}
            />
            <CreateListDialog
              isNewListDialogOpen={isNewListDialogOpen}
              handleCloseNewListDialog={handleCloseNewListDialog}
            />
          </div>
        </Grid>
        <MessageDialog
          openMessageDialog={openMessageDialog}
          handleClose={handleMessageClose}
          user={data}
          handleMessageSubmit={handleMessageSubmit}
          resetMessage={resetMessage}
        />
        <BookingRequestDialog
          open={sendBookingRequestDialog}
          onCloseDialog={handleSendBookingRequestDialogClose}
          onSendBookingRequest={handleSendBookingRequestDialogSubmit}
          data={data}
          userPage={true}
          id={receiverId}
        />
        {data && data.portfolio && (
          <Grid>
            <Carousel images={data?.portfolio?.length === 0 ? defaultImages : data.portfolio} />
          </Grid>
        )}
        {!loading && (
          <section className="acc-info">
            <Container sx={{ fontFamily: '"Onest", sans-serif !important;' }}>
              <Grid container spacing={3}>
                <Grid item md={8}>
                  <div className="acc-box">
                    <div className="acc-skill">
                      <h4 className="title-name"> About {data && `${data.firstName} ${data.lastName}`}</h4>
                      <Typography component={'span'} variant="body1" className="acc-text">
                        {data && data.about}
                      </Typography>
                    </div>
                    <div className="rate-box acc-skill">
                      <div className="aval-title">
                        <h4 className="title-name">Availability</h4>
                        <p className="acc-text">{data && data.availability}</p>
                      </div>
                      <div className="aval-title rate-rabge-box">
                        <h4 className="title-name">Rate Range</h4>
                        <p className="acc-text"> {data && data.range.label}</p>
                      </div>
                    </div>
                    <div className="rate-box acc-skill">
                      <div className="aval-title">
                        <h4 className="title-name">Job Title</h4>
                        <p className="acc-text">{data && data.jobTitle} </p>
                      </div>
                      <Grid className="aval-title rate-rabge-box">
                        <h4 className="title-name">Total-Experience</h4>
                        {data && data.experience && (
                          // <>
                          //   <Typography
                          //     component={'span'}
                          //     style={{
                          //       fontSize: '14px',
                          //       fontWeight: 600,
                          //       color: '#0000005c',
                          //       display: 'flex',
                          //     }}>
                          //     <BorderLinearProgress
                          //       variant="determinate"
                          //       value={Math.min(100, data.experience * 20)}
                          //       style={{ width: '100px', height: '12px' }}
                          //     />
                          //     <BorderLinearProgress
                          //       variant="determinate"
                          //       value={Math.min(100, (data.experience - 5) * 25)}
                          //       style={{ width: '100px', height: '12px' }}
                          //     />
                          //     <BorderLinearProgress
                          //       variant="determinate"
                          //       value={data.experience > 10 ? 100 : Math.min(100, (data.experience - 10) * 25)}
                          //       style={{ width: '100px', height: '12px' }}
                          //     />
                          //   </Typography>
                          //   <Typography
                          //     component={'span'}
                          //     style={{
                          //       fontSize: '14px',
                          //       fontWeight: 600,
                          //       color: '#0000005c',
                          //       display: 'flex',
                          //     }}>
                          //     <Grid style={{ width: '100px', textAlign: 'center' }}>1-5</Grid>
                          //     <Grid style={{ width: '100px', textAlign: 'center' }}>5-10</Grid>
                          //     <Grid style={{ width: '100px', textAlign: 'center' }}>10+</Grid>
                          //   </Typography>
                          // </>
                          <>
                            <Typography
                              component={'span'}
                              style={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#0000005c',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              {/* Render progress bars based on the values */}
                              {values.map((value: any, index: any) => (
                                <BorderLinearProgress
                                  key={index}
                                  variant="determinate"
                                  value={Math.min(100, value)}
                                  style={{ width: '100px', height: '12px' }}
                                />
                              ))}
                            </Typography>
                            <Typography
                              component={'span'}
                              style={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#0000005c',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}>
                              {/* Render labels based on the experience range */}
                              {['1-3', '3-5', '5-10', '10+'].map((rangeLabel) => (
                                <Grid key={rangeLabel} style={{ width: '100px', textAlign: 'center' }}>
                                  {rangeLabel}
                                </Grid>
                              ))}
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </div>
                    <div className="client-list list-box acc-skill">
                      <h4 className="title-name">Client List</h4>
                      <ul style={{ paddingLeft: 0 }}>
                        {(data && data.company.length === 0) || data?.company === null ? (
                          <li>Not Available</li>
                        ) : (
                          data?.company.map((company, index) => <li key={index}>{company}</li>)
                        )}
                      </ul>
                    </div>
                    <div className="skill-sec list-box acc-skill">
                      <h4 className="title-name">Skills</h4>
                      <ul style={{ paddingLeft: 0 }}>
                        {(data && data.skills.length === 0) || data?.skills === null ? (
                          <li style={{ color: '#73787e' }}>Not Available</li>
                        ) : (
                          data?.skills.map((category, index) => (
                            <li key={index}>
                              <div className="check-box">
                                <div className="check-head">
                                  <h4>{category.category.label}</h4>
                                </div>
                                <ul>
                                  {category.skills.map((skill, skillIndex) => (
                                    <li key={skillIndex}>{skill.label}</li>
                                  ))}
                                </ul>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                    <div className="Tools-sec list-box ">
                      <h4 className="title-name">Tools</h4>
                      <ul style={{ paddingLeft: 0 }}>
                        {(data && data.tools.length === 0) || data?.tools === null ? (
                          <li style={{ color: '#73787e' }}>Not Available</li>
                        ) : (
                          data?.tools.map((category, index) => (
                            <li key={index}>
                              <div className="check-box">
                                <div className="check-head">
                                  <h4>{category.category.label}</h4>
                                </div>
                                <ul>
                                  {category.tools.map((tool, toolIndex) => (
                                    <li key={toolIndex}>{tool.label}</li>
                                  ))}
                                </ul>
                              </div>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="acc-box right-box">
                    <div className="lang-list acc-skill">
                      <h4 className="title-name">Language</h4>
                      <ul style={{ paddingLeft: 0 }}>
                        {(data && data.language.length === 0) || data?.language === null ? (
                          <li style={{ color: '#73787e' }}>Not Available</li>
                        ) : (
                          data?.language.map((language, index) => <li key={index}>{language}</li>)
                        )}
                      </ul>
                    </div>
                    <div className="loca-cont acc-skill">
                      <h4 className="title-name">Location</h4>
                      <Grid sx={{ pt: '20px', height: '240px', width: '430px' }}>
                        {data && data.location && (
                          <Map
                            center={{
                              lat: data?.location.lat,
                              lng: data?.location.long,
                            }}
                            zoom={10}
                          />
                        )}
                      </Grid>
                      <div className="map-cont">
                        <div className="date">
                          <img src={mapPin} className="img-fluid" alt="img" />
                          <p>{data && data.location.label}</p>
                        </div>
                      </div>
                    </div>
                    <div className="port-cont acc-skill">
                      <h4 className="title-name">Portfolio Link</h4>
                      {data && data.portfolioLink ? (
                        <p className="acc-text">
                          <a href={getAbsoluteURL(data.portfolioLink)} target="_blank" rel="noopener noreferrer">
                            {data.portfolioLink}
                          </a>
                        </p>
                      ) : (
                        <span style={{ color: '#73787e' }}>Not Available</span>
                      )}
                    </div>
                    <div className="media-cont acc-skill">
                      <h4 className="title-name">Social Media</h4>
                      <ul style={{ paddingLeft: 0 }}>
                        {(data && data.socialMedia.length === 0) || data?.socialMedia === null ? (
                          <li style={{ color: '#73787e' }}>Not Available</li>
                        ) : (
                          data?.socialMedia.map((social, index) => (
                            <li key={index}>
                              <a href={getAbsoluteURL(social.link)} target="_blank" rel="noopener noreferrer">
                                <img src={social.logoPath} alt={social.label} className="img-fluid" />
                              </a>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                    <Box sx={{ textAlign: 'center' }}>
                      <Button
                        className="common-button-orange"
                        type="submit"
                        variant="contained"
                        sx={{
                          width: '341px',
                          height: '44px',
                          marginRight: 1,
                          mt: 3,
                          mb: 2,
                          '&:hover': {
                            color: 'var(--Primary, #FDB022)',
                            backgroundColor: '#fff',
                          },
                        }}
                        onClick={() => handleReportArtist(data)}>
                        Report this artist?
                      </Button>
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </section>
        )}
      </Grid>
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      <Dialog
        open={showCalender}
        onClose={() => setShowCalender(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            maxWidth: '1200px',
            overflow: 'hidden',
          },
        }}>
        {/* <Calendar events={data && data?.allAvailability} /> */}
        <Calendar events={calenderData && calenderData} />
      </Dialog>
      <ReportArtist
        open={reportDialog}
        reportData={reportData}
        handleCloseDialog={() => setReportDialog(false)}
        handleReport={handleReport}
        reportValues={reportValues}
        resetMessage={resetMessage}
      />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
    backStatus: state.isBack,
  };
}

export default connect(mapDispatchToProps)(UserProfileDetail);

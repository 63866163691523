/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, TextField, InputAdornment, Button } from '@mui/material';
import mapPin from '../../../Assets/Images/mapPin.png';
import '../../../Assets/css/Common.css';
import CustomSnackbar from '../../../common/Snackbar';
// import Env from '../../../services/Env';
import { connect } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import Map from './Map';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';

interface step {
  label: string;
  key: string;
}

interface LocationProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  stepKeys: step;
  // userAccessToken: string;
  id: number;
}

interface AutocompleteOption {
  id: string;
  label: string;
}

interface LocationState {
  stepperKey: string;
  // userId: number;
  language: string[];
  city: string;
  state: string;
  country: string;
  locationId: string;
  lat: number;
  long: number;
}

interface MapProps {
  lat: number;
  lng: number;
}

const Location: React.FC<LocationProps> = ({ activeStep, handleNext, handleBack, stepKeys }) => {
  const privateAxios = useAxiosPrivate();

  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [autocompleteOptions, setAutocompleteOptions] = useState<AutocompleteOption[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [center, setCenter] = useState<MapProps>({ lat: 9.318327499999999, lng: 76.61108449999999 });
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [location, setLocation] = useState<LocationState>({
    stepperKey: stepKeys.key,
    // userId: id,
    language: [],
    locationId: '',
    city: '',
    state: '',
    country: '',
    lat: 9.318327499999999,
    long: 76.61108449999999,
  });
  const [, setDataSubmitted] = useState(false);
  const [existingData, setExistingData] = useState<any>(null);
  const [zoom] = useState(10);
  const [Error, setError] = useState({
    language: '',
    city: '',
    state: '',
    country: '',
  });

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const locationDegree: any = localStorage.getItem('center');
    if (locationDegree) {
      const local = JSON.parse(locationDegree);

      setCenter({
        lat: local.lat,
        lng: local.lng,
      });
    }

    getLocationData();
    setDataSubmitted(true);
  }, []);

  const getLocationData = () => {
    // const getData = Env.get(`user/getuserstepper?stepperKey=${stepKeys.key}&userId=${id}`, userAccessToken);
    const getData = privateAxios.get(`user/getuserstepper?stepperKey=${stepKeys.key}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    getData
      .then((response: any) => {
        setExistingData(response.data.data);
        if (response.status === 200 && response.data.data.country !== null) {
          setSelectedLanguages(response.data.data.language);

          setLocation((preData: any) => ({
            ...preData,
            language: response.data.data.language,
            city: response.data.data.city,
            state: response.data.data.state,
            country: response.data.data.country,
            locationId: response.data.data.locationId,
            lat: response.data.data.lat,
            long: response.data.data.long,
          }));

          setSelectedLocation({
            label: `${response.data.data.city || ''}, ${response.data.data.state || ''}, ${
              response.data.data.country || ''
            }`,
          });

          setLocation((preData: any) => ({
            ...preData,
            language: response.data.data.language,
            city: response.data.data.city || '',
            state: response.data.data.state || '',
            country: response.data.data.country || '',
            locationId: response.data.data.locationId || '',
          }));

          setInputValue(
            `${response.data.data.city || ''}, ${response.data.data.state || ''}, ${response.data.data.country || ''}`
          );
        }
      })
      .catch((error: any) => {
        setToast({ message: error.response?.data?.message.value, type: 'error' });
      });
  };

  const parseLocationLabel = (label: any) => {
    const parts = label?.split(',').map((part: string) => part.trim());
    let city: null, state: string | null, country: null;

    if (parts.length === 6) {
      city = parts[0];
      state = parts[1] + ', ' + parts[2] + ',' + parts[3] + ',' + parts[4];
      country = parts[5];
    } else if (parts.length === 5) {
      city = parts[0];
      state = parts[1] + ', ' + parts[2] + ',' + parts[3];
      country = parts[4];
    } else if (parts.length === 4) {
      city = parts[0];
      state = parts[1] + ', ' + parts[2];
      country = parts[3];
    } else if (parts.length === 3) {
      city = parts[0];
      state = parts[1];
      country = parts[2];
    } else if (parts.length === 2) {
      city = null;
      state = parts[0];
      country = parts[1];
    } else if (parts.length === 1) {
      city = null;
      state = null;
      country = parts[0];
    } else {
      city = null;
      state = null;
      country = null;
    }

    setLocation((preData: any) => ({
      ...preData,
      city: city,
      state: state,
      country: country,
    }));

    return {
      City: city,
      State: state,
      Country: country,
    };
  };

  const handleLocation = (e: any, newValue: AutocompleteOption | null) => {
    // console.log('newValue', newValue);
    if (newValue === null) {
      clearSelection();
    } else {
      setSelectedLocation(newValue);
      parseLocationLabel(newValue.label);
      setLocation((preData: any) => ({
        ...preData,
        locationId: newValue.id,
      }));
      setError((pre: any) => ({
        ...pre,
        city: '',
        state: '',
        country: '',
      }));
    }

    if (newValue) {
      const getExactLocation = privateAxios.get(`user/getlatlong?id=${newValue.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      getExactLocation
        .then((response: any) => {
          if (response.status === 200) {
            const coordinatesData = response.data.data.coordinates;

            localStorage.setItem(
              'center',
              JSON.stringify({ lat: coordinatesData.latitude, lng: coordinatesData.longitude })
            );

            setCenter({
              lat: coordinatesData.latitude,
              lng: coordinatesData.longitude,
            });
            setLocation((preData: any) => ({
              ...preData,
              lat: String(coordinatesData.latitude),
              long: String(coordinatesData.longitude),
            }));
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  };

  const handleCityStateCountry = (e: any, newValue: any) => {
    const location = {
      location: newValue,
    };

    if (newValue.length > 4) {
      // const searchLocation = Env.post(`user/location`, location, userAccessToken);
      const searchLocation = privateAxios.post(`user/location`, location, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      searchLocation
        .then((response: any) => {
          setAutocompleteOptions(response.data.data);
        })
        .catch((error: any) => {
          if (error.response.data.data != null) {
            setToast({ message: error.response.data.message.value, type: 'error' });
          } else {
            setToast({ message: error.response.data.message, type: 'error' });
          }
          handleClick();
        });
    }
  };

  const languageData = (event: any, newValue: any) => {
    setSelectedLanguages(newValue);
    setLocation((prevLocation) => ({
      ...prevLocation,
      language: newValue,
    }));

    setError((prevErrors: any) => ({
      ...prevErrors,
      language: '',
    }));
  };

  const handleLocationData = () => {
    const { language, country } = location;

    const errors = {
      language: '',
      city: '',
      state: '',
      country: '',
    };

    if (language.length === 0) {
      errors.language = 'Language is required';
    }

    // if (!city) {
    //   errors.city = 'Location is required';
    // }

    // if (!state) {
    //   errors.state = 'Location is required';
    // }

    if (!country) {
      errors.country = 'Location is required';
    }

    if (Object.values(errors).some((field) => field !== '')) {
      setError(errors);
      return;
    }

    const changesDetected =
      existingData &&
      (existingData.language !== location.language ||
        existingData.city !== location.city ||
        existingData.state !== location.state ||
        existingData.country !== location.country);

    if (changesDetected) {
      // const locationData = Env.post(`user/register`, location, userAccessToken);
      const locationData = privateAxios.post(`user/register`, location, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // const backupdata = {
      //   stepperKey: location.stepperKey,
      //   language: location.language,
      //   locationId: 'ChIJl--CGusiBjsROaABnbLMc_U',
      //   city: 'Manhattan',
      //   state: 'NY',
      //   country: 'USA',
      //   lat: '9.318327499999999',
      //   long: '76.61108449999999',
      // };
      // const locationData = privateAxios.post(`user/register`, backupdata, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // });
      locationData
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setTimeout(() => handleNext(), 2000);
            setDataSubmitted(true);
          }
        })
        .catch((error: any) => {
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    } else if (location.language.length === 0 || location.city === '' || location.country === '' || location.state === '') {
      // const locationData = Env.post(`user/register`, location, userAccessToken);
      const locationData = privateAxios.post(`user/register`, location, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      locationData
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setTimeout(() => handleNext(), 2000);
            setDataSubmitted(true);
          }
        })
        .catch((error: any) => {
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    } else {
      handleNext();
      return;
    }
  };

  const clearSelection = () => {
    setLocation((preData) => ({
      ...preData,
      city: '',
      state: '',
      country: '',
      locationId: '',
      lat: 9.318327499999999,
      long: 76.61108449999999,
    }));
    setAutocompleteOptions([]);
    localStorage.setItem('center', JSON.stringify({ lat: 9.318327499999999, lng: 76.61108449999999 }));
    setCenter({
      lat: 9.318327499999999,
      lng: 76.61108449999999,
    });
  };

  return (
    <>
      <Typography
        sx={{
          fontFamily: "'Onest', sans-serif",
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '38px',
          paddingTop: '30px',
        }}>
        Share a bit more about yourself
      </Typography>
      <Box>
        <Typography
          className="lable-name"
          sx={{
            textAlign: 'left',
            mt: '15px',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
          }}>
          Languages Spoken<span className="mandatoryFields">*</span>
        </Typography>
        <Grid>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={selectedLanguages}
            onChange={(event, newValue) => languageData(event, newValue)}
            renderInput={(params) => (
              <TextField className="formFields" sx={{ width: '100%', mt: 1 }} {...params} placeholder="Type & Press Enter" />
            )}
          />
        </Grid>
        {Error.language && <Typography sx={{ color: 'red', mt: 1 }}>{Error.language}</Typography>}

        <Grid>
          <Typography
            className="lable-name"
            sx={{
              textAlign: 'left',
              mt: '15px',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
            }}>
            Location<span className="mandatoryFields">*</span>
          </Typography>

          <Autocomplete
            value={selectedLocation || null}
            onChange={(e, newValue) => handleLocation(e, newValue)}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              handleCityStateCountry(event, newInputValue);
              if (newInputValue.trim() === '') {
                clearSelection();
                setSelectedLocation(null);
              }
            }}
            options={autocompleteOptions}
            getOptionLabel={(option) => (option && option.label ? option.label : '')}
            isOptionEqualToValue={(option, value) => option && option.id === value.id && option.label === value.label}
            renderInput={(params) => (
              <TextField
                {...params}
                className="formFields"
                sx={{
                  width: '100%',
                  '&:hover': {
                    borderColor: 'red',
                  },
                  mt: 1,
                }}
                name="location"
                placeholder="City, State, Country"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={mapPin} alt="mapPin" style={{ width: '24px', height: '24px' }} />
                    </InputAdornment>
                  ),
                }}
                value={selectedLocation ? selectedLocation.label : ''}
              />
            )}
          />
        </Grid>
        {Error.country && <Typography sx={{ color: 'red', mt: 1 }}>{Error.country}</Typography>}

        <Grid sx={{ pt: '20px', width: '850px' }}>
          <Map center={center} zoom={zoom} />
        </Grid>
      </Box>
      <Box sx={{ padding: '50px', paddingTop: '40px' }}>
        <Box sx={{ textAlign: 'center' }}>
          {activeStep === 1 ? (
            ''
          ) : (
            <Button
              className="common-button-white"
              type="submit"
              variant="contained"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                marginRight: 1,
                mt: 3,
                mb: 2,
                '&:hover': {
                  color: 'var(--Primary, #2CB512)',
                  backgroundColor: '#fff',
                },
                alignItems: 'center',
              }}>
              Back
            </Button>
          )}

          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleLocationData}
            disabled={activeStep === 10}
            sx={{
              marginLeft: 1,
              mt: 3,
              mb: 2,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save and Next
          </Button>
        </Box>
      </Box>
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
    isBack: state.isBack,
  };
}

export default connect(mapDispatchToProps)(Location);

/* eslint-disable no-debugger */
import React, { useState } from 'react';
import { Button, Box, Typography, TextField, InputAdornment, Chip } from '@mui/material';
import '../../../Assets/css/ProfileSetup.css';
// import Env from '../../../services/Env';
import { connect } from 'react-redux';
import CustomSnackbar from '../../../common/Snackbar';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';

interface AddTalentRosterMembersProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  // userAccessToken: string;
}

const AddTalentRosterMembers: React.FC<AddTalentRosterMembersProps> = ({
  activeStep,
  handleNext,
  handleBack,
  // userAccessToken,
}) => {
  const privateAxios = useAxiosPrivate();

  const [enteredEmail, setEnteredEmail] = useState<string>('');
  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);
  const [error, setError] = useState({
    email: '',
    validEmail: '',
  });
  const [toast, setToast] = useState({ message: '', type: 'success' });
  const [open, setOpen] = useState(false);

  const handleInputChange = (event: any) => {
    setEnteredEmail(
      event.target.value
        .replace(/^\s+/, '')
        .replace(/\s+/g, ' ')
        .replace(/^[^a-zA-Z@.]+/, '')
    );
    setError((preError: any) => ({
      ...preError,
      validEmail: '',
      email: '',
    }));
  };

  const isValidEmail = (email: any) => {
    const emails = email.split(/[,\s]+/);

    const isValid = emails.every((part: any) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(part);
    });
    return isValid;
  };

  const handleInviteClick = () => {
    if (!isValidEmail(enteredEmail)) {
      setError((preError) => ({ ...preError, validEmail: 'Enter valid email' }));
      return;
    }

    const enteredEmailsArray = enteredEmail
      .split(/[,\s]+/) // Split by commas or spaces
      .filter((email) => email.trim() !== ''); // Remove empty entries

    const duplicates = enteredEmailsArray.filter(
      (email) => invitedEmails.includes(email) || invitedEmails.includes(email.trim())
    );

    if (duplicates.length > 0) {
      setError((preError: any) => ({ ...preError, validEmail: 'Emails already invited' }));
      return;
    }

    // if (invitedEmails.includes(enteredEmail)) {
    //   setError((preError: any) => ({ ...preError, validEmail: 'Email already invited' }));
    //   return;
    // }

    setInvitedEmails((prevEmails) => [...prevEmails, ...enteredEmailsArray]);
    setEnteredEmail('');
    setError((preError: any) => ({
      ...preError,
      validEmail: '',
      email: '',
    }));
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAddRosterMembersData = () => {
    const email = {
      email: invitedEmails,
    };

    if (invitedEmails.length === 0) {
      setError((pre: any) => ({
        ...pre,
        email: 'Enter Atleast one member',
      }));
      return;
    }

    // const postData = Env.post(`dashboard/addroaster`, email, userAccessToken);
    const postData = privateAxios.post(`dashboard/addroaster`, email, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    postData
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
          setTimeout(() => handleNext(), 2000);
        }
      })
      .catch((error: any) => {
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };
  return (
    <>
      <Typography
        sx={{
          fontFamily: "'Onest', sans-serif",
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '38px',
          paddingTop: '30px',
        }}>
        Add talent members to your roster
      </Typography>
      <Box style={{ width: '900px' }}>
        <Typography
          className="lable-name"
          sx={{
            textAlign: 'left',
            mt: '15px',
          }}>
          Use space or comma for multiple entries<span className="mandatoryFields">*</span>
        </Typography>
        <div>
          <TextField
            className="formFields"
            sx={{ mt: 0, width: '860px' }}
            type="email"
            name="email"
            placeholder="Enter the Email"
            value={enteredEmail}
            onChange={handleInputChange}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    className="common-button-green"
                    sx={{ width: '130px !important', height: '40px !important' }}
                    onClick={handleInviteClick}>
                    Invite Now
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {error.email && <div style={{ color: 'red' }}>{error.email}</div>}
          {error.email === '' ? error.validEmail && <div style={{ color: 'red' }}>{error.validEmail}</div> : ''}
          {invitedEmails.map((email) => (
            <Chip
              key={email}
              label={email}
              style={{ margin: '4px' }}
              onDelete={() => {
                setInvitedEmails((prevEmails) => prevEmails.filter((e) => e !== email));
              }}
            />
          ))}
        </div>
      </Box>
      <Box sx={{ padding: '50px', paddingTop: '40px' }}>
        <Box sx={{ textAlign: 'center' }}>
          {activeStep === 1 ? (
            ''
          ) : (
            <Button
              className="common-button-white"
              type="submit"
              variant="contained"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                marginRight: 1,
                mt: 3,
                mb: 2,
                '&:hover': {
                  color: 'var(--Primary, #2CB512)',
                  backgroundColor: '#fff',
                },
                alignItems: 'center',
              }}>
              Back
            </Button>
          )}

          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleAddRosterMembersData}
            sx={{
              marginLeft: 1,
              mt: 3,
              mb: 2,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save and Next
          </Button>
          <Button
            sx={{
              mt: 3,
              mb: 2,
              textTransform: 'none',
              background: 'transparent',
              alignItems: 'center',
              borderRadius: '12px',
              width: '160px',
              height: '45px',
              fontFamily: "'Onest', sans-serif !important",
            }}>
            <p
              onClick={() => handleNext()}
              style={{
                fontFamily: 'Onest',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0em',
                textAlign: 'left',
                color: '#667085',
              }}>
              Skip for now
            </p>
          </Button>
        </Box>
      </Box>
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(AddTalentRosterMembers);

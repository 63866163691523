/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Select,
  MenuItem,
  IconButton,
  Button,
} from '@mui/material';
import '../../../Assets/css/Common.css';
import '../../../Assets/css/Calendar.css';
import { styled } from '@mui/material/styles';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
// import Env from '../../../services/Env';
import { connect } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import CustomSnackbar from '../../../common/Snackbar';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
// import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the styles
// import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

interface step {
  label: string;
  key: string;
}

interface AvailabilityDetailsProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  stepKeys: step;
  // userAccessToken: string;
  // id: number;
}

interface AvailabilityDetail {
  label: string;
  key: string;
  description: string;
}

interface AvailabilityState {
  stepperKey: string;
  // userId: string; // assuming userId is of type string
  isCalender: boolean;
  availability: Array<{ availabilityKey: string | null }>; // explicitly defining the type
  isOffMarket: boolean;
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#2CB512',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-thumb:hover': {
    color: 'red',
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const AvailabilityDetails: React.FC<AvailabilityDetailsProps> = ({
  activeStep,
  handleNext,
  handleBack,
  stepKeys,
  // id,
  // userAccessToken,
}) => {
  const privateAxios = useAxiosPrivate();

  const localizer = dayjsLocalizer(dayjs);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [events, setEvents] = React.useState<{ title: string; start: Date; end: Date }[]>([]);
  const [alignment, setAlignment] = React.useState('');
  const [useCalendar, setUseCalendar] = React.useState(false);
  const [availableStatus, setAvailableStatus] = React.useState('notAvailable');
  const [availability, setAvailability] = useState<AvailabilityDetail[]>([]);
  const [isOffMarket, setIsOffMarket] = React.useState(false);
  const [toast, setToast] = useState({ message: '', type: 'success' });
  const [error, setError] = React.useState('');
  const [open, setOpen] = useState(false);
  const [availableData, setAvailable] = useState<AvailabilityState>({
    stepperKey: 'availabilityDetails',
    // userId: id,
    isCalender: false,
    availability: [],
    isOffMarket: false,
  });
  const [existingData, setExistingData] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [info, setInfo] = useState(false);

  useEffect(() => {
    getAvablbData();
    availabilityData();
  }, []);

  useEffect(() => {
    if (useCalendar) {
      availabilityData();
    }

    if (!useCalendar) {
      availabilityData();
    }
  }, [useCalendar]);

  function dateFormatHandler(date: any, label: string) {
    let formatedDate: any;
    if (label === 'end') formatedDate = dayjs(date).hour(17).minute(30).second(0).millisecond(0);
    else formatedDate = dayjs(date);
    return formatedDate;
  }

  const getAvablbData = () => {
    // const getData = Env.get(`user/getuserstepper?stepperKey=${stepKeys.key}&userId=${id}`, userAccessToken);

    const getData = privateAxios.get(`user/getuserstepper?stepperKey=${stepKeys.key}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getData
      .then((response: any) => {
        if (response.status === 200) {
          setExistingData(response.data.data);
          if (response.data.data.isCalender) {
            setUseCalendar(response.data.data.isCalender);

            const getAvable = response.data.data?.availability;

            setAvailable((prevData: any) => ({
              ...prevData,
              isCalender: response.data.data.isCalender,
              isOffMarket: response.data.data.isOffMarket,
              availability: Array.isArray(getAvable)
                ? getAvable.map((item: any) => ({
                    availabilityKey: item.key,
                    startDate: dateFormatHandler(item.startDate, 'start'),
                    endDate: dateFormatHandler(item.endDate, 'end'),
                  }))
                : getAvable
                ? [
                    {
                      availabilityKey: getAvable.key,
                      startDate: dateFormatHandler(getAvable.startDate, 'start'),
                      endDate: dateFormatHandler(getAvable.endDate, 'end'),
                    },
                  ]
                : [],
            }));
            setIsOffMarket(response.data.data.isOffMarket);

            const transformedEvents = response.data.data.availability;

            const TranData = Array.isArray(transformedEvents)
              ? transformedEvents.map((item: any) => ({
                  // title: item.key,
                  title: transformTitle(item.key),
                  // title: item.label,
                  start: new Date(item.startDate),
                  end: new Date(item.endDate),
                }))
              : [
                  {
                    // title: transformedEvents.key,
                    title: transformTitle(transformedEvents.key),
                    start: new Date(transformedEvents.startDate),
                    end: new Date(transformedEvents.endDate),
                  },
                ];

            setEvents(TranData);
          } else {
            setAlignment(response.data.data.availability.key);
            setUseCalendar(response.data.data.isCalender);
            const getAvableData = {
              availabilityKey: response.data.data.availability.key,
            };
            setAvailable((prevData: any) => ({
              ...prevData,
              isCalender: response.data.data.isCalender,
              availability: [getAvableData],
              isOffMarket: response.data.data.isOffMarket,
            }));
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const availabilityData = () => {
    // const getAvailabiltyDatas = Env.get(`user/getAvailability?isCalender=${useCalendar}`, userAccessToken);
    const getAvailabiltyDatas = privateAxios.get(
      `user/getAvailability?isCalender=${useCalendar === undefined ? false : useCalendar}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    getAvailabiltyDatas
      .then((response: any) => {
        if (response.status === 200) {
          setAvailability(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAvailabilityDetailsData = () => {
    let existingKeys = [];
    let existingStartDates = [];
    let existingEndDates = [];

    if (existingData && Array.isArray(existingData.availability)) {
      existingKeys = existingData.availability.map((item: any) => item.key);
      existingStartDates = existingData.availability.map((item: any) => item.startDate);
      existingEndDates = existingData.availability.map((item: any) => item.endDate);
    }

    let currentKeys = [];
    let currentStartDates = [];
    let currentEndDates = [];

    if (availableData && Array.isArray(availableData.availability)) {
      currentKeys = availableData.availability.map((item: any) => item.availabilityKey);
      currentStartDates = availableData.availability.map((item: any) => item.startDate);
      currentEndDates = availableData.availability.map((item: any) => item.endDate);
    }

    const keysEqual = JSON.stringify(existingKeys) === JSON.stringify(currentKeys);
    const startDatesEqual = JSON.stringify(existingStartDates) === JSON.stringify(currentStartDates);
    const endDatesEqual = JSON.stringify(existingEndDates) === JSON.stringify(currentEndDates);
    const arrayLengthEqual =
      Array.isArray(existingData?.availability) && existingData.availability.length === availableData.availability.length;

    const changesDetected =
      existingData === null ||
      !keysEqual ||
      !startDatesEqual ||
      !endDatesEqual ||
      !arrayLengthEqual ||
      existingData.isCalender !== availableData.isCalender ||
      existingData.isOffMarket !== availableData.isOffMarket;

    if (useCalendar) {
      if (availableData?.isOffMarket) {
        availableData.availability = [];
      } else {
        availableData.availability = availableData.availability.filter(
          (item: any) => 'startDate' in item && 'endDate' in item
        );
      }

      if (availableData.availability[0]?.availabilityKey === undefined) {
        setToast({ message: 'Select any Status', type: 'error' });
        handleClick();
        return;
      }

      if (changesDetected) {
        // const withCalendarData = Env.post(`user/register`, availableData, userAccessToken);
        const withCalendarData = privateAxios.post(`user/register`, availableData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        withCalendarData
          .then((response: any) => {
            if (response.status === 200) {
              setToast({ message: response.data.message.value, type: 'success' });
              handleClick();
              setTimeout(() => handleNext(), 2000);
            }
          })
          .catch((error: any) => {
            setToast({ message: error.response.data.message.value, type: 'error' });
            handleClick();
          });
      } else {
        handleNext();
        return;
      }
    } else {
      const { isOffMarket, ...dataWithoutOffMarket } = availableData;

      if (dataWithoutOffMarket.availability[0]?.availabilityKey === undefined) {
        setToast({ message: 'Select Any Status', type: 'error' });
        handleClick();
        return;
      }

      if (changesDetected) {
        // const withoutCalendarData = Env.post(`user/register`, dataWithoutOffMarket, userAccessToken);
        const withoutCalendarData = privateAxios.post(`user/register`, dataWithoutOffMarket, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        withoutCalendarData
          .then((response: any) => {
            if (response.status === 200) {
              setToast({ message: response.data.message.value, type: 'success' });
              handleClick();
              setTimeout(() => handleNext(), 2000);
            }
          })
          .catch((error: any) => {
            setToast({ message: error.response.data.message.value, type: 'error' });
            handleClick();
          });
      } else {
        handleNext();
        return;
      }
    }
  };

  const handleChange = (event: any, newAlignment: any) => {
    setAlignment(newAlignment);

    setAvailable((preData: any) => ({
      ...preData,
      availability: [
        {
          availabilityKey: newAlignment,
        },
      ],
    }));
  };

  const handleSwitchChange = () => {
    setUseCalendar((prevAlignment) => !prevAlignment);

    setAvailable((pre: any) => ({
      ...pre,
      isCalender: !pre.isCalender,
    }));
  };

  const handleSwitchOffMarketChange = () => {
    setAvailable((prevData: any) => ({
      ...prevData,
      isOffMarket: !prevData.isOffMarket,
    }));

    setIsOffMarket((prevOffMarket) => !prevOffMarket);

    if (!isOffMarket) {
      setEvents([]);
    } else {
      setEvents([]);
    }
  };

  const transformTitle = (originalTitle: string) => {
    switch (originalTitle) {
      case 'available':
        return 'Available';
      case 'partTime':
        return 'Part Time';
      case 'notAvailable':
        return 'Not Available';
      case 'availableSoon':
        return 'Available Soon';
      // Add more cases if needed
      default:
        return originalTitle;
    }
  };

  const transformTitleKey = (originalTitle: string) => {
    switch (originalTitle) {
      case 'Available':
        return 'available';
      case 'Part Time':
        return 'partTime';
      case 'Not Available':
        return 'notAvailable';
      case 'Available Soon':
        return 'availableSoon';
      // Add more cases if needed
      default:
        return originalTitle;
    }
  };

  const renderContent = () => {
    const selectedAvailability = availability.find((item: any) => item.key === alignment);

    if (!alignment) {
      return null;
    }

    switch (alignment) {
      case 'available':
      case 'notAvailable':
      case 'partTime':
      case 'offMarket':
        return selectedAvailability?.description || null;
      default:
        return null;
    }
  };

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  const handleAvailableStatusChange = (event: any) => {
    setAvailableStatus(event.target.value);
  };

  const handleAddToCalendar = () => {
    if (startDate && endDate) {
      if (endDate <= startDate) {
        setError('End date must be after the start date');
        return;
      }

      const filteredEvents = events.filter((event: any) => endDate < event.start || startDate > event.end);

      const newEvent = {
        title: transformTitle(availableStatus),
        start: startDate,
        end: endDate,
      };

      const overlappingEvents = events.filter((event: any) => {
        return startDate < event.end && endDate > event.start;
      });

      if (overlappingEvents.length > 0) {
        setError('The selected date range overlaps with existing events. Please Delete the Previous one');
        return;
      }

      setEvents([...filteredEvents, newEvent]);

      setAvailable((predata: any) => ({
        ...predata,
        availability: Array.isArray(predata.availability)
          ? [
              ...predata.availability,
              {
                availabilityKey: availableStatus,
                startDate: formatDate(startDate),
                endDate: formatDate(endDate),
              },
            ]
          : [
              {
                availabilityKey: availableStatus,
                startDate: formatDate(startDate),
                endDate: formatDate(endDate),
              },
            ],
      }));
      setStartDate(null);
      setEndDate(null);
      setError('');
    } else {
      setError('Please select both Start Date and End Date');
    }
  };

  function formatDate(date: any) {
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formattedDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleEventDelete = (event: any) => {
    const updatedEvents: any = events.filter((e) => e !== event);
    setEvents(updatedEvents);

    const updatedAvailability = updatedEvents.map((updatedEvent: any) => ({
      availabilityKey: transformTitleKey(updatedEvent.title),
      startDate: formatDate(updatedEvent.start),
      endDate: formatDate(updatedEvent.end),
    }));

    // Update the availability state with the updated events
    setAvailable((prevAvailableData: any) => ({
      ...prevAvailableData,
      availability: [...availability, ...updatedAvailability],
    }));
    setIsDialogOpen(false);
    setInfo(true);
    setTimeout(() => {
      handleInfoDialog();
    }, 2000);
  };

  const handleDeleteConfirmation = (event: any) => {
    setSelectedEvent(event);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedEvent(null);
  };

  const handleInfoDialog = () => {
    setInfo(false);
  };

  const CustomDay = ({ day, events }: any) => {
    const dayDate = day.toDate();

    const matchingEvents = events.filter((event: any) => dayDate >= event.start && dayDate <= event.end);

    return (
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#000',
            padding: '4px',
            borderRadius: '4px',
            marginTop: '40px',
          }}>
          {/* {matchingEvents.map(
            (event: any) => (
              <div
                key={event.title}
                style={{
                  backgroundColor: matchingEvents[0]?.title ? getBackgroundColor(matchingEvents[0].title) : '',
                  width: 'auto',
                }}>
                {event.title}
              </div>
            )
            // event?.title
          )} */}
          {day.format('D')}
        </div>
        {/* )} */}
      </div>
    );
  };

  CustomDay.propTypes = {
    day: PropTypes.object.isRequired,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        start: PropTypes.object.isRequired,
        end: PropTypes.object.isRequired,
      })
    ).isRequired,
  };

  const getCurrentMonthAndDate = () => {
    const currentDate = moment().format('MMMM D');
    return currentDate;
  };

  const CustomDayCell = ({ date, view }: any) => <div>{moment(date).format('D')}</div>;

  return (
    <>
      <Grid container>
        <Grid item sm={10}>
          <Typography
            sx={{
              fontFamily: "'Onest', sans-serif",
              fontSize: '30px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '38px',
              paddingTop: '30px',
            }}>
            {useCalendar === true ? 'Set Your availability' : 'Availability Details'}
          </Typography>
        </Grid>
        <Grid item sm={2} sx={{ mt: 3.5 }}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
            <Typography>Use Calendar</Typography>
            <AntSwitch
              inputProps={{ 'aria-label': 'ant design' }}
              checked={useCalendar === undefined ? false : true}
              onChange={handleSwitchChange}
            />
          </Stack>
        </Grid>
      </Grid>
      {useCalendar === true ? (
        <>
          <Box>
            <Grid container sx={{ mt: 5 }}>
              <Grid container>
                <Grid item sm={5.5}>
                  <Typography
                    className="lable-name"
                    sx={{
                      textAlign: 'left',
                      mt: '15px',
                      color: '#344054',
                    }}>
                    Start Date<span className="mandatoryFields">*</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                      <DateTimePicker
                        label="Start Date"
                        value={startDate}
                        sx={{ fontFamily: "'Onest', sans-serif !important" }}
                        onChange={handleStartDateChange}
                        minDate={dayjs()}
                        views={['year', 'month', 'day']}
                        disabled={isOffMarket}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item sm={5.5} sx={{ ml: 4 }}>
                  <Typography
                    className="lable-name"
                    sx={{
                      textAlign: 'left',
                      mt: '15px',
                      color: '#344054',
                    }}>
                    End Date<span className="mandatoryFields">*</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                      <DateTimePicker
                        label="End Date"
                        value={endDate !== null ? endDate || (dayjs(endDate).hour(12).minute(0) as Dayjs) : null}
                        onChange={(newEndDate) => {
                          const adjustedEndDate =
                            newEndDate !== null ? dayjs(newEndDate).hour(17).minute(30).second(0).millisecond(0) : null;
                          handleEndDateChange(adjustedEndDate);
                        }}
                        minDate={startDate || dayjs()}
                        views={['year', 'month', 'day']}
                        disabled={isOffMarket}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              {error && <Typography sx={{ color: 'red', mt: 1 }}>{error}</Typography>}
              <Grid container>
                <Grid item sm={6}>
                  <Typography
                    className="lable-name"
                    sx={{
                      textAlign: 'left',
                      mt: '15px',
                      color: '#344054',
                    }}>
                    Availability Status<span className="mandatoryFields">*</span>
                  </Typography>

                  <Select
                    sx={{ width: '350px' }}
                    value={availableStatus}
                    onChange={handleAvailableStatusChange}
                    disabled={isOffMarket}>
                    {availability.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={3}>
                  <Typography
                    className="lable-name"
                    sx={{
                      textAlign: 'left',
                      mt: '15px',
                      color: '#344054',
                    }}>
                    Off Market?
                  </Typography>
                  <Grid
                    alignItems="center"
                    className="onMarket"
                    sx={{
                      width: '150px',
                      height: '40px',
                      backgroundColor: '#fff',
                      border: '1px solid var(--_Gray-300, #D0D5DD)',
                      borderRadius: '8px',
                      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                      mt: 1,
                    }}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" sx={{ mt: 1 }}>
                      <Typography>Off</Typography>
                      <AntSwitch
                        name="switch"
                        inputProps={{ 'aria-label': 'ant design' }}
                        checked={availableData.isOffMarket}
                        onChange={handleSwitchOffMarketChange}
                      />
                      <Typography>On</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item sm={3}>
                  <Button
                    className="common-button-green calButton"
                    type="submit"
                    variant="contained"
                    onClick={handleAddToCalendar}
                    disabled={isOffMarket}
                    sx={{
                      marginLeft: 1,
                      mt: 5,
                      mb: 2,
                      '&:hover': {
                        background: 'var(--Primary, #2CB512)',
                      },
                    }}>
                    Add to calendar
                  </Button>
                </Grid>
              </Grid>
              <Grid item sm={12} sx={{ mt: 3, fontFamily: "'Onest', sans-serif !important" }}>
                <Typography variant="h6" sx={{ marginBottom: '15px' }}>
                  {getCurrentMonthAndDate()}
                </Typography>
                <Calendar
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 500 }}
                  views={['month', 'agenda']}
                  components={{
                    month: {
                      dateHeader: ({ date }: any) => {
                        const formattedDate = moment(date);
                        return <CustomDay day={formattedDate} events={events} />;
                      },
                    },
                    day: {
                      cell: CustomDayCell,
                    },
                  }}
                  step={30}
                  defaultDate={new Date()}
                  eventPropGetter={(event: any) => {
                    const backgroundColor =
                      event.title === 'Available'
                        ? 'green'
                        : event.title === 'Not Available'
                        ? 'red'
                        : event.title === 'Part Time'
                        ? '#FFA500'
                        : event.title === 'Available Soon'
                        ? '#2596be'
                        : '';
                    return { style: { backgroundColor } };
                  }}
                  onSelectEvent={(event: any, e: any) => {
                    handleDeleteConfirmation(event);
                  }}
                />
                <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                  <DialogTitle
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography style={{ color: '#2CB512', fontSize: '18px', fontWeight: 600, flex: '1' }}>
                      Confirm Delete
                    </Typography>
                    <IconButton onClick={handleDialogClose} size="small">
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>Are you sure you want to delete this event?</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleDialogClose}>No</Button>
                    <Button onClick={() => handleEventDelete(selectedEvent)} autoFocus>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={info}>
                  <DialogTitle
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography style={{ color: '#2CB512', fontSize: '18px', fontWeight: 600, flex: '1' }}>Note</Typography>
                    <IconButton onClick={handleInfoDialog} size="small">
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <Typography sx={{ fontFamily: "'Onest', sans-serif !important" }}>
                        Click &quot;Save&quot; to update the change!
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ padding: '50px', paddingTop: '40px' }}>
            <Box sx={{ textAlign: 'center' }}>
              {activeStep === 1 ? (
                ''
              ) : (
                <Button
                  className="common-button-white"
                  type="submit"
                  variant="contained"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{
                    marginRight: 1,
                    mt: 3,
                    mb: 2,
                    '&:hover': {
                      color: 'var(--Primary, #2CB512)',
                      backgroundColor: '#fff',
                    },
                    alignItems: 'center',
                  }}>
                  Back
                </Button>
              )}

              <Button
                className="common-button-green"
                type="submit"
                variant="contained"
                onClick={handleAvailabilityDetailsData}
                disabled={activeStep === 10}
                sx={{
                  marginLeft: 1,
                  mt: 3,
                  mb: 2,
                  '&:hover': {
                    background: 'var(--Primary, #2CB512)',
                  },
                }}>
                Save and Next
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box>
            <Grid>
              <Grid item sm={12} sx={{ mt: 5 }}>
                <Typography
                  className="lable-name"
                  sx={{
                    textAlign: 'left',
                    mt: '15px',
                    color: '#344054',
                  }}>
                  My Availability <span className="mandatoryFields">*</span>
                </Typography>
                <Grid alignItems="center" sx={{ mt: 1 }}>
                  <ToggleButtonGroup
                    color="primary"
                    sx={{ border: '1px solid #D0D5DD', borderRadius: '6px' }}
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label=" Type of Work">
                    {availability.map((availabilitydetail: { key: string; label: string }) => (
                      <ToggleButton
                        key={availabilitydetail.key}
                        sx={{
                          fontFamily: "'Onest', sans-serif !important",
                          width: '212px',
                          height: '36px',
                          margin: '3px',
                          textTransform: 'none',
                          border: 'none',
                          color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                          backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#fff'),
                          '&.Mui-selected': {
                            backgroundColor: '#fff',
                            color: '#2CB512',
                            boxShadow: `0 0px 10px 0px rgba(0,0,0,0.2), 0 2px 10px rgba(0,0,0,0.2)`,
                          },
                          '&.Mui-selected:hover': {
                            backgroundColor: '#fff',
                          },
                        }}
                        value={availabilitydetail.key}>
                        {availabilitydetail.label}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>

                <Grid>
                  <Typography
                    className="lable-name"
                    sx={{
                      mt: '15px',
                      color: '#98A2B3',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px',
                      width: '850px',
                    }}>
                    {renderContent()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ padding: '50px', paddingTop: '40px' }}>
            <Box sx={{ textAlign: 'center' }}>
              {activeStep === 1 ? (
                ''
              ) : (
                <Button
                  className="common-button-white"
                  type="submit"
                  variant="contained"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{
                    marginRight: 1,
                    mt: 3,
                    mb: 2,
                    '&:hover': {
                      color: 'var(--Primary, #2CB512)',
                      backgroundColor: '#fff',
                    },
                    alignItems: 'center',
                  }}>
                  Back
                </Button>
              )}

              <Button
                className="common-button-green"
                type="submit"
                variant="contained"
                onClick={handleAvailabilityDetailsData}
                disabled={activeStep === 10}
                sx={{
                  marginLeft: 1,
                  mt: 3,
                  mb: 2,
                  '&:hover': {
                    background: 'var(--Primary, #2CB512)',
                  },
                }}>
                Save and Next
              </Button>
            </Box>
          </Box>
        </>
      )}
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(AvailabilityDetails);

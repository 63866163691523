/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Dialog, Typography, Grid } from '@mui/material';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';

const ReportArtist = ({ open, handleCloseDialog, reportData, handleReport, reportValues, resetMessage }: any) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (resetMessage) {
      setValue('');
    }
  }, [resetMessage]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      sx={{
        '& .MuiDialog-paper': {
          width: '561px',
          height: 'auto',
          padding: '30px',
        },
      }}>
      <Typography style={{ color: '#2CB512', fontSize: '18px', fontWeight: 600 }}>
        {' '}
        Are you sure you want to report this artist?{' '}
      </Typography>

      <Grid
        sx={{
          display: 'flex',
          width: '70%',
          height: '100%',
          borderRadius: '10px',
          border: '2px solid #F2F4F7',
          padding: '10px',
          marginTop: '40px',
          justifyContent: 'space-between',
        }}>
        <Grid item xs={2} className="userprofiles">
          <img src={reportData && reportData?.profilePath} alt="login02" />
        </Grid>
        <Grid item xs={8} style={{ marginLeft: '15px' }}>
          <Typography>{reportData && `${reportData.firstName} ${reportData.lastName}`}</Typography>
          <Typography>
            {reportData && reportData.jobTitle} | {reportData && reportData.country}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <NorthEastIcon />
        </Grid>
      </Grid>
      <Grid sx={{ marginTop: '40px' }}>
        <FormControl>
          <FormLabel sx={{ color: '#667085', fontSize: '18px', fontWeight: 600 }}>Select reason</FormLabel>
          <RadioGroup
            sx={{ color: '#8C87A2' }}
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}>
            {reportValues &&
              reportValues.map((reportReason: any, index: number) => (
                <FormControlLabel
                  key={index}
                  sx={{ color: '#848094', fontSize: '16px', fontWeight: 500 }}
                  value={reportReason.key}
                  control={<Radio />}
                  label={reportReason.label}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
          className="common-button-white"
          type="submit"
          variant="contained"
          onClick={handleCloseDialog}
          sx={{
            width: '244px',
            height: '44px',
            marginRight: 1,
            mt: 3,
            mb: 2,
            '&:hover': {
              color: 'var(--Primary, #2CB512)',
              backgroundColor: '#fff',
            },
          }}>
          Cancel
        </Button>
        <Button
          className="common-button-green"
          type="submit"
          variant="contained"
          sx={{
            width: '244px',
            height: '44px',
            marginLeft: 1,
            mt: 3,
            mb: 2,
            '&:hover': {
              background: 'var(--Primary, #2CB512)',
            },
          }}
          onClick={() => handleReport(value, reportData.userName)}>
          Report
        </Button>
      </div>
    </Dialog>
  );
};

export default ReportArtist;

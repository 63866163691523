/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import '../../../Assets/css/Common.css';
import '../../../Assets/css/Calendar.css';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import dayjs from 'dayjs';

import PropTypes from 'prop-types';

const CalendarData = (events: any) => {
  const localizer = dayjsLocalizer(dayjs);

  const [status, setStatus] = useState([]);
  function dateFormatHandler(date: any, label: string) {
    let formatedDate: any;
    if (label === 'end') formatedDate = dayjs(date).hour(17).minute(30).second(0).millisecond(0);
    else formatedDate = dayjs(date);
    return formatedDate;
  }

  useEffect(() => {
    if (events.events.availableStatus) {
      const transformedEvents = events.events.availableStatus;
      const TranData: any = Array.isArray(transformedEvents)
        ? transformedEvents.map((item: any) => ({
            title: transformTitle(item.key),
            start: dateFormatHandler(item.startDate, 'start'),
            end: dateFormatHandler(item.endDate, 'end'),

            // start: new Date(item.startDate),
            // end: new Date(item.endDate),
          }))
        : [
            {
              title: transformTitle(transformedEvents?.key),
              start: dateFormatHandler(transformedEvents.startDate, 'start'),
              end: dateFormatHandler(transformedEvents.endDate, 'end'),
              // start: new Date(transformedEvents?.startDate),
              // end: new Date(transformedEvents?.endDate),
            },
          ];
      setStatus(TranData);
    }
  }, []);

  const transformTitle = (originalTitle: string) => {
    switch (originalTitle) {
      case 'available':
        return 'Available';
      case 'partTime':
        return 'Part Time';
      case 'notAvailable':
        return 'Not Available';
      case 'availableSoon':
        return 'Available Soon';
      // Add more cases if needed
      default:
        return originalTitle;
    }
  };

  const CustomDay = ({ day, events }: any) => {
    const dayDate = day.toDate();

    const matchingEvents = events.filter((event: any) => dayDate >= event.start && dayDate <= event.end);

    return (
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#000',
            padding: '4px',
            borderRadius: '4px',
            marginTop: '40px',
          }}>
          {day.format('D')}
        </div>
        {/* )} */}
      </div>
    );
  };

  CustomDay.propTypes = {
    day: PropTypes.object.isRequired,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        start: PropTypes.object.isRequired,
        end: PropTypes.object.isRequired,
      })
    ).isRequired,
  };

  const getCurrentMonthAndDate = () => {
    const currentDate = moment().format('MMMM D');
    return currentDate;
  };

  const CustomDayCell = ({ date, view }: any) => <div>{moment(date).format('D')}</div>;

  return (
    <Box sx={{ padding: '25px', width: '800px' }}>
      <Grid item sm={12} sx={{ mt: 3, fontFamily: "'Onest', sans-serif !important" }}>
        <Typography variant="h6" sx={{ marginBottom: '15px' }}>
          {getCurrentMonthAndDate()}
        </Typography>
        <Calendar
          localizer={localizer}
          events={status}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          views={['month', 'agenda']}
          components={{
            month: {
              dateHeader: ({ date }: any) => {
                const formattedDate = moment(date);
                return <CustomDay day={formattedDate} events={status} />;
              },
            },
            day: {
              cell: CustomDayCell,
            },
          }}
          step={30}
          defaultDate={new Date()}
          eventPropGetter={(event: any) => {
            const backgroundColor =
              event.title === 'Available'
                ? 'green'
                : event.title === 'Not Available'
                ? 'red'
                : event.title === 'Part Time'
                ? '#FFA500'
                : event.title === 'Available Soon'
                ? '#2596be'
                : '';
            return { style: { backgroundColor } };
          }}
        />
      </Grid>
    </Box>
  );
};

export default CalendarData;

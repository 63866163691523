/* eslint-disable react-hooks/exhaustive-deps */
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../common/Snackbar';

const BookingStatus = () => {
  const privateAxios = useAxiosPrivate();

  const [opendialog, setOpenDialog] = useState(true);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const type = window.location.href.split('/')[4];
  const status = type.split('?')[0];

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleStatus = () => {
    setLoading(true);
    const getStatus = privateAxios.get(`dashboard/${status}?bookingId=${id}`);
    getStatus
      .then((response: any) => {
        if (response.status === 200) {
          setMessage(response.data.message.value);
          setLoading(false);
          setTimeout(() => {
            closeDialog();
          }, 3000);
        }
      })
      .catch((error: any) => {
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
        setTimeout(() => {
          closeDialog();
        }, 3000);
      });
  };

  const closeDialog = () => {
    setOpenDialog(false);
    window.close();
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress color="success" />
        </Backdrop>
      )}
      <Dialog
        open={opendialog}
        onClose={closeDialog}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '500px !important',
            height: 'auto',
            padding: '25px',
          },
        }}>
        <DialogContent sx={{ fontFamily: "'Onest', sans-serif", textAlign: 'center' }}>
          {message && message ? message : 'Please confirm your Booking Request choice'}
        </DialogContent>
        {message && message ? (
          ''
        ) : (
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button className="common-button-white" sx={{ color: '#fff' }} onClick={closeDialog}>
              Cancel
            </Button>
            <Button className="common-button-green" sx={{ color: '#fff' }} onClick={handleStatus}>
              Confirm
            </Button>
          </DialogActions>
        )}

        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Dialog>
    </>
  );
};

export default BookingStatus;

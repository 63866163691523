/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Typography, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import '../../../Assets/css/Common.css';
import { connect } from 'react-redux';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../../common/Snackbar';

interface DataProps {
  locationKey: string;
  workKey: string;
  rateKey: string;
  websiteURL: string;
  portfolio: string[];
  socialMedia: string[];
}

interface SocialProps {
  data: DataProps | null;
  onDataChange: (newData: Partial<DataProps>) => void;
  error: any;
  changeError: (err: any) => void;
}

const Social: React.FC<SocialProps> = ({ data, onDataChange, error, changeError }) => {
  const privateAxios = useAxiosPrivate();

  const [locationAvailability, setLocationAvailability] = useState([]);
  const [rateAndFees, setRateAndFees] = useState([]);
  const [work, setWork] = useState([]);
  const [alignment, setAlignment] = useState({
    locationKey: '',
    workKey: '',
    rateKey: '',
  });
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: '', type: 'success' });

  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    workDetailsData();
    if (data !== null) {
      workValues(data);
    }
  }, [data]);

  const workDetailsData = () => {
    // setLoading(true);
    const workdetails = privateAxios.get(`user/workDetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    workdetails
      .then((response: any) => {
        if (response.status === 200) {
          setLocationAvailability(response.data.data.locationAvailability);
          setRateAndFees(response.data.data.rateAndFees);
          setWork(response.data.data.workType);
          // setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const workValues = (data: DataProps) => {
    setAlignment({
      locationKey: data.locationKey,
      workKey: data.workKey,
      rateKey: data.rateKey,
    });
  };

  const handleAlignmentChange = (group: string, newValue: string) => {
    setAlignment((prevAlignment) => ({
      ...prevAlignment,
      [group]: newValue,
    }));
    changeError({
      [group]: '',
    });
    onDataChange({ [group]: newValue });
  };

  return (
    <>
      {/* {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress
            color="success"
            // style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          />
        </Backdrop>
      )} */}
      <Grid container>
        <Grid item sm={12} lg={6} md={12}>
          <Typography
            className="lable-name"
            sx={{
              textAlign: 'left',
              mt: '15px',
              color: '#344054',
            }}>
            Location Availability
          </Typography>
          <Grid alignItems="center" sx={{ mt: 1 }}>
            <ToggleButtonGroup
              color="primary"
              sx={{ border: '1px solid #D0D5DD', borderRadius: '6px', width: '100%' }}
              value={alignment.locationKey}
              exclusive
              onChange={(event, newValue) => handleAlignmentChange('locationKey', newValue)}
              aria-label="Location Availability">
              {locationAvailability.map((location: { key: string; label: string }) => (
                <ToggleButton
                  key={location.key}
                  sx={{
                    width: '100%',
                    height: '36px',
                    margin: '3px',
                    textTransform: 'none',
                    border: 'none',
                    color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#fff'),
                    '&.Mui-selected': {
                      backgroundColor: '#fff',
                      color: '#2CB512',
                      boxShadow: `0 0px 2px 0px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.2)`,
                      borderRadius: '5px',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: '#fff',
                    },
                  }}
                  value={location.key}>
                  {location.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
          {error.locationKey && <Typography sx={{ color: 'red', mt: 1 }}>{error.locationKey}</Typography>}
        </Grid>

        <Grid item sm={12} lg={6} md={12}>
          <Typography
            className="lable-name"
            sx={{
              textAlign: 'left',
              mt: '15px',
              color: '#344054',
            }}>
            Type of Work
          </Typography>
          <Grid alignItems="center" sx={{ mt: 1 }}>
            <ToggleButtonGroup
              color="primary"
              sx={{ border: '1px solid #D0D5DD', borderRadius: '6px', width: '100%' }}
              value={alignment.workKey}
              exclusive
              onChange={(event, newValue) => handleAlignmentChange('workKey', newValue)}
              aria-label="Type of Work">
              {work.map((work: { key: string; label: string }) => (
                <ToggleButton
                  key={work.key}
                  sx={{
                    width: '100%',
                    height: '36px',
                    margin: '3px',
                    textTransform: 'none',
                    border: 'none',
                    color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#fff'),
                    '&.Mui-selected': {
                      backgroundColor: '#fff',
                      color: '#2CB512',
                      boxShadow: `0 0px 2px 0px rgba(0,0,0,0.2), 0 2px 5px rgba(0,0,0,0.2)`,
                      borderRadius: '5px',
                    },
                    '&.Mui-selected:hover': {
                      backgroundColor: '#fff',
                    },
                  }}
                  value={work.key}>
                  {work.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
          {error.workKey && <Typography sx={{ color: 'red', mt: 1 }}>{error.workKey}</Typography>}
        </Grid>
      </Grid>

      <Typography
        className="lable-name"
        sx={{
          textAlign: 'left',
          mt: '15px',
          color: '#344054',
        }}>
        Rate and Fees
      </Typography>
      <Grid item sm={12}>
        <Grid alignItems="center" sx={{ mt: 1 }}>
          <ToggleButtonGroup
            color="primary"
            sx={{ border: '1px solid #D0D5DD', borderRadius: '6px', width: '100%' }}
            value={alignment.rateKey}
            exclusive
            onChange={(event, newValue) => handleAlignmentChange('rateKey', newValue)}
            aria-label="Rate and Fees">
            {rateAndFees.map((rateAndFee: { key: string; label: string }) => (
              <ToggleButton
                key={rateAndFee.key}
                sx={{
                  width: '100%',
                  height: '36px',
                  margin: '3px',
                  textTransform: 'none',
                  border: 'none',
                  color: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
                  backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#fff' : '#fff'),
                  '&.Mui-selected': {
                    backgroundColor: '#fff',
                    color: '#2CB512',
                    boxShadow: `0 0px 2px 0px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.2)`,
                    borderRadius: '5px',
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: '#fff',
                  },
                }}
                value={rateAndFee.key}>
                {rateAndFee.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        {error.rateKey && <Typography sx={{ color: 'red', mt: 1 }}>{error.rateKey}</Typography>}
      </Grid>
      <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleClose} toast={toast} />
    </>
  );
};

export default connect()(Social);

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';

export const BulkHireDialog = ({ openBulkDialog, bulkStatus, onCloseDialog }: any) => {
  return (
    <Dialog
      open={openBulkDialog}
      onClose={onCloseDialog}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          height: 'auto',
          padding: '20px',
        },
      }}>
      <DialogTitle
        sx={{
          color: '#2CB512',
          fontSize: '18px',
          fontWeight: 600,
          fontFamily: "'Onest', sans-serif !important",
          pl: '10px',
          textAlign: 'center',
        }}>
        Booking Request Status
      </DialogTitle>
      {bulkStatus && bulkStatus?.bookedUsers?.length > 0 && (
        <DialogContent>
          <Typography>
            <b>Requests sent to users:</b> {bulkStatus.bookedUsers.join(', ')}
          </Typography>
        </DialogContent>
      )}
      {bulkStatus && bulkStatus?.notAvailableUsers?.length > 0 && (
        <DialogContent>
          <Typography>
            <b>Not Available Users:</b> {bulkStatus.notAvailableUsers.join(', ')}
          </Typography>
        </DialogContent>
      )}
      {bulkStatus && bulkStatus?.offMarketUsers?.length > 0 && (
        <DialogContent>
          <Typography>
            <b>Off Market Users:</b> {bulkStatus.offMarketUsers.join(', ')}{' '}
          </Typography>
        </DialogContent>
      )}
      {bulkStatus && bulkStatus?.registrationUsers?.length > 0 && (
        <DialogContent>
          <Typography>
            <b>Registration Users:</b> {bulkStatus.registrationUsers.join(', ')}{' '}
          </Typography>
        </DialogContent>
      )}
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button className="common-button-white" sx={{ color: '#fff' }} onClick={onCloseDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../../Assets/css/Profile.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Personaldetails from './AccountDetails/UserDetails';
import Personallocation from './AccountDetails/Location';
import PersonalPortfolios from './AccountDetails/Portfolio';
import PersonalSkills from './AccountDetails/Skills';
import PersonalTool from './AccountDetails/Tools';
import PersonalExpression from './AccountDetails/Experience';
import PersonalAvailability from './AccountDetails/Availability';
import Menu1 from '../../Assets/Images/personalCard.png';
import mapPin from '../../Assets/Images/mapPin.svg';
import Menu3 from '../../Assets/Images/Work.png';
import Menu4 from '../../Assets/Images/Skills.png';
import Menu5 from '../../Assets/Images/Tools.png';
import Menu6 from '../../Assets/Images/profileTick.png';
import Menu7 from '../../Assets/Images/clock.png';
import Menu8 from '../../Assets/Images/card.png';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import { IUserDetails } from '../Interface/UserInterface/IUser';
import PaymentDetails from './AccountDetails/PaymentDetails';
import { connect } from 'react-redux';
import CustomSnackbar from '../../common/Snackbar';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reducer/store';
import { profileHeaderPics } from '../../reducer/action';

const Personalinfobar = (props: any) => {
  const privateAxios = useAxiosPrivate();

  // const [activeComponent, setActiveComponent] = useState<string | null>('PersonalInfo');
  const [activeComponent, setActiveComponent] = useState<string | null>(props.tab);
  const [userDetails, setUserDetails] = useState<IUserDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    setLoading(true);
    const getDetails = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          dispatch(
            profileHeaderPics({
              profilePath: data?.profilePath,
              headerPath: data?.headerPath,
              availablity: data?.availability,
              firstName: data?.firstName,
              lastName: data?.lastName,
              jobTitle: data?.jobTitle,
              userName: data?.userName,
            })
          );

          setUserDetails(data);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSidebarItemClick = (component: string) => {
    setLoading(true);
    setActiveComponent(component);
    setLoading(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <Box sx={{ p: '15px', borderRadius: '10px', backgroundColor: '#FFFFFF' }}>
            <div className="sidebar">
              <div
                className={`sidebar-item ${activeComponent === 'PersonalInfo' ? 'active-menu' : ''}`}
                onClick={() => handleSidebarItemClick('PersonalInfo')}>
                <div className="menu-item-content" style={{ padding: '5px' }}>
                  <img src={Menu1} alt="Personal Info" className="menu-icon" />
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }} className="menu-text">
                    Personal Info
                  </Typography>
                </div>
              </div>
              <div
                className={`sidebar-item ${activeComponent === 'LanguageLocation' ? 'active-menu' : ''}`}
                onClick={() => handleSidebarItemClick('LanguageLocation')}>
                <div className="menu-item-content" style={{ padding: '5px' }}>
                  <img src={mapPin} alt="Language & Location" className="menu-icon" />
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }} className="menu-text">
                    Language & Location
                  </Typography>
                </div>
              </div>
              <div
                className={`sidebar-item ${activeComponent === 'PersonalPortfolios' ? 'active-menu' : ''}`}
                onClick={() => handleSidebarItemClick('PersonalPortfolios')}>
                <div className="menu-item-content" style={{ padding: '5px' }}>
                  <img src={Menu3} alt="Work & Social" className="menu-icon" />
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }} className="menu-text">
                    Work & Social
                  </Typography>
                </div>
              </div>
              <div
                className={`sidebar-item ${activeComponent === 'PersonalSkills' ? 'active-menu' : ''}`}
                onClick={() => handleSidebarItemClick('PersonalSkills')}>
                <div className="menu-item-content" style={{ padding: '5px' }}>
                  <img src={Menu4} alt="Skills" className="menu-icon" />
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }} className="menu-text">
                    Skills
                  </Typography>
                </div>
              </div>
              <div
                className={`sidebar-item ${activeComponent === 'PersonalTool' ? 'active-menu' : ''}`}
                onClick={() => handleSidebarItemClick('PersonalTool')}>
                <div className="menu-item-content" style={{ padding: '5px' }}>
                  <img src={Menu5} alt="Tools" className="menu-icon" />
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }} className="menu-text">
                    Tools
                  </Typography>
                </div>
              </div>
              <div
                className={`sidebar-item ${activeComponent === 'PersonalExpression' ? 'active-menu' : ''}`}
                onClick={() => handleSidebarItemClick('PersonalExpression')}>
                <div className="menu-item-content" style={{ padding: '5px' }}>
                  <img src={Menu6} alt="Rate & Experience" className="menu-icon" />
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }} className="menu-text">
                    Rate & Experience
                  </Typography>
                </div>
              </div>
              <div
                className={`sidebar-item ${activeComponent === 'PersonalAvailability' ? 'active-menu' : ''}`}
                onClick={() => handleSidebarItemClick('PersonalAvailability')}>
                <div className="menu-item-content" style={{ padding: '5px' }}>
                  <img src={Menu7} alt="Rate & Experience" className="menu-icon" />
                  <Typography style={{ fontWeight: 500, fontSize: '16px' }} className="menu-text">
                    Availability
                  </Typography>
                </div>
              </div>
              {props.userType && !props.userDC ? (
                <div
                  className={`sidebar-item ${activeComponent === 'PersonalPaymentDetails' ? 'active-menu' : ''}`}
                  onClick={() => handleSidebarItemClick('PersonalPaymentDetails')}>
                  <div className="menu-item-content" style={{ padding: '5px' }}>
                    <img src={Menu8} alt="Rate & Experience" className="menu-icon" />
                    <Typography style={{ fontWeight: 500, fontSize: '16px' }} className="menu-text">
                      My Subscription
                    </Typography>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          {loading && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
              onClick={() => setLoading(false)}>
              <CircularProgress
                color="success"
                // style={{ position: 'absolute', top: '50%', left: '55%', transform: 'translate(-50%, -50%)' }}
              />
            </Backdrop>
          )}
          {userDetails !== null && !loading ? (
            <Box>
              {activeComponent === 'PersonalInfo' && <Personaldetails />}
              {activeComponent === 'LanguageLocation' && <Personallocation />}
              {activeComponent === 'PersonalPortfolios' && <PersonalPortfolios />}
              {activeComponent === 'PersonalSkills' && <PersonalSkills />}
              {activeComponent === 'PersonalTool' && <PersonalTool />}
              {activeComponent === 'PersonalExpression' && <PersonalExpression />}
              {activeComponent === 'PersonalAvailability' && <PersonalAvailability condition={false} />}
              {activeComponent === 'PersonalPaymentDetails' && <PaymentDetails />}
            </Box>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleClose} toast={toast} />
    </Box>
  );
};

function mapDispatchToProps(state: any) {
  return {
    userStatus: state.status,
    userType: state.userType,
    userDC: state.userDC,
  };
}

export default connect(mapDispatchToProps)(Personalinfobar);

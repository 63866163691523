import { Grid, Typography } from '@mui/material';
import React from 'react';
import { UserInfo } from '../Interface/UserInterface/IUser';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Link } from 'react-router-dom';
import '../../Assets/css/CardDisplay.css';

interface CardDisplayProps {
  cardData: UserInfo[];
  eyeIcon?: (data: any) => void;
  listIcon?: (data: any) => void;
  selectedArtist?: UserInfo[] | null | undefined;
  onCardClick?: (email: any, id: any, isRosterAdded: boolean) => void;
  type?: string;
}

const CardDisplay: React.FC<CardDisplayProps> = ({ cardData, eyeIcon, listIcon, selectedArtist, onCardClick, type }) => {
  const getColorClass = (status: string) => {
    switch (status) {
      case 'Available':
        return 'green-label';
      case 'Not Available':
        return 'red-label';
      case 'Available Soon':
        return 'dark-orchid-label';
      case 'Available Part Time':
        return 'orange-label';
      case 'Part Time':
        return 'orange-label';
      case 'Off Market':
        return 'blue-label';
      default:
        return '';
    }
  };

  const getColor = (status: string) => {
    switch (status) {
      case 'Available':
        return 'var(--green)';
      case 'Not Available':
        return 'red';
      case 'Available Soon':
        return '#b049d1';
      case 'Available Part Time':
        return 'orange';
      case 'Part Time':
        return 'orange';
      case 'Off Market':
        return 'blue';
      default:
        return '';
    }
  };

  return (
    <div className="row selectArt">
      {cardData && cardData.length === 0 ? (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <Typography
            component={'span'}
            sx={{
              ml: '15px',
              fontFamily: "'Onest', sans-serif",
              color: 'var(--green)',
              fontSize: '24px',
              fontWeight: 600,
            }}>
            No Users
          </Typography>
        </div>
      ) : type !== 'Roster' ? (
        <Grid container spacing={2} className="check-div">
          {cardData?.map((data: UserInfo) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={data.id} className="categoryGrid">
              {/* <div key={data.id} className="col-md-6 col-lg-4 col-xl-3 col-sm-6 col-12"> */}
              <div key={data.id}>
                <div
                  className={`talent-box ${
                    selectedArtist && selectedArtist.some((card) => card.email === data.email) ? 'selected-card' : ''
                  }
                   `}
                  onClick={() => onCardClick && onCardClick(data.email, data.id, data.isRosterAdded)}>
                  <div className="slider-for one">
                    <img className="item-slick" src={data?.headerPath} alt="Alt" />
                  </div>
                  <div className="slider-nav one">
                    {data.portFolio?.slice(0, 4).map((portfolioImage: string, index: number) => (
                      <img key={index} className="item-slick" src={portfolioImage} alt={`Portfolio ${index + 1}`} />
                    ))}
                  </div>
                  <div
                    className={`label-info ${getColorClass(
                      data.availableStatus === null ? 'Available' : data.availableStatus
                    )}`}>
                    <span>{data.availableStatus === null ? 'Available' : data.availableStatus}</span>
                  </div>
                  <div className="telent-box-detail">
                    <div className="image-talent green-bg">
                      <img
                        src={data.profilePath}
                        alt="demo"
                        style={{
                          borderColor: getColor(data.availableStatus === null ? 'Available' : data.availableStatus),
                        }}
                      />
                    </div>
                    <div className="telent-title">
                      <h4 className="text-wrap">{data.fullName}</h4>
                      <div style={{ display: 'flex', alignItems: 'center', width: 'inherit' }}>
                        <p className="text-wrap" style={{ marginRight: '8px' }}>
                          {data.jobTitle}
                        </p>
                        <span style={{ marginTop: '8px' }}>|</span>
                        <p className="text-wrap" style={{ marginLeft: '8px' }}>
                          {data.country}
                        </p>
                      </div>

                      <div className="btn-arrow">
                        <Link to={`/user-details?id=${data.id}`} state={{ showUser: true, id: data.id }} target="_self">
                          <ArrowOutwardIcon sx={{ color: '#000' }} />
                          {/* <span className="fa fa-eye" aria-hidden="true"></span> */}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="hover-wrapper">
                    <ul>
                      <li>
                        {eyeIcon && (
                          <span
                            className="fa fa-eye"
                            style={{ cursor: 'pointer' }}
                            aria-hidden="true"
                            onClick={() => eyeIcon(data)}></span>
                        )}
                      </li>
                      <li>
                        {listIcon && (
                          <span
                            className="fa fa-list"
                            style={{ cursor: 'pointer' }}
                            aria-hidden="true"
                            onClick={() => listIcon(data)}></span>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2} sx={{ ml: '0px' }}>
          {cardData?.map((data: any) => (
            <React.Fragment key={data.label}>
              <Typography className="dash-title" sx={{ fontFamily: "'Onest', sans-serif !important", marginBottom: '10px' }}>
                <span style={{ fontWeight: 600, fontSize: '24px', display: 'block' }}>{data.label}</span>
              </Typography>

              <Grid container spacing={2} key={data.label}>
                {data.data?.map((card: any) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4} key={card.id} className="categoryGrid">
                    <div key={data.id}>
                      <div
                        className={`talent-box ${
                          selectedArtist && selectedArtist.some((artist) => artist.email === card.email)
                            ? 'selected-card'
                            : ''
                        }`}
                        onClick={() => onCardClick && onCardClick(card.email, card.id, card.isRosterAdded)}>
                        <div className="slider-for one">
                          <img className="item-slick" src={card?.headerPath} alt="Alt" />
                        </div>
                        <div className="slider-nav one">
                          {card.portFolio?.slice(0, 4).map((portfolioImage: any, index: any) => (
                            <img key={index} className="item-slick" src={portfolioImage} alt={`Portfolio ${index + 1}`} />
                          ))}
                        </div>
                        <div
                          className={`label-info ${getColorClass(
                            card.availableStatus === null ? 'Available' : card.availableStatus
                          )}`}>
                          <span>{card.availableStatus === null ? 'Available' : card.availableStatus}</span>
                        </div>
                        <div className="telent-box-detail">
                          <div className="image-talent green-bg">
                            <img
                              src={card.profilePath}
                              alt="demo"
                              style={{
                                borderColor: getColor(card.availableStatus === null ? 'Available' : card.availableStatus),
                              }}
                            />
                          </div>
                          <div className="telent-title">
                            <h4>{card.fullName}</h4>
                            <p>
                              {card.jobTitle} | {card.country}
                            </p>
                            <div className="btn-arrow">
                              <Link
                                to={`/user-details?id=${card.id}`}
                                state={{ showUser: true, id: card.id }}
                                target="_self">
                                <ArrowOutwardIcon sx={{ color: '#000' }} />
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="hover-wrapper">
                          <ul>
                            <li>
                              {eyeIcon && (
                                <span
                                  className="fa fa-eye"
                                  style={{ cursor: 'pointer' }}
                                  aria-hidden="true"
                                  onClick={() => eyeIcon(card)}></span>
                              )}
                            </li>
                            <li>
                              {listIcon && (
                                <span
                                  className="fa fa-list"
                                  style={{ cursor: 'pointer' }}
                                  aria-hidden="true"
                                  onClick={() => listIcon(card)}></span>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default CardDisplay;

/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, TextField, Button, Backdrop, CircularProgress } from '@mui/material';
import uploadCloud from '../../../Assets/Images/uploadCloud.png';
import '../../../Assets/css/ProfileSetup.css';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../../common/Snackbar';
import defaultProfileImage from '../../../Assets/Images/defaultProfileImage.png';
import defaultHeaderImage from '../../../Assets/Images/defaultHeaderImage.png';
import { useDispatch } from 'react-redux';
import {
  userId,
  isLoggedIn,
  userType,
  userAccessToken,
  userRefreshToken,
  backHandle,
  userStepStatus,
  profileHeaderPics,
} from '../../../reducer/action';
import { AppDispatch } from '../../../reducer/store';
import Crop from '../../auth/Stepper/Crop';

const Personaldetails: React.FC = () => {
  const privateAxios = useAxiosPrivate();
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [nameDisable, setNameDisable] = useState(false);
  const [imageLoading, setImageLoading] = useState({ profile: false, header: false });
  const [registrationData, setRegistrationData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    userName: '',
    profilePath: '',
    headerPath: '',
  });
  const [error, setError] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    userName: '',
  });
  const [mailCheck, setMailCheck] = useState({ email: '' });
  const [loading, setLoading] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [prevUserName, setPrevUserName] = useState<any>('');
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [cropImage, setCropImage] = useState({ openCrop: false, imageType: '', fileName: '' });

  useEffect(() => {
    setLoading(true);
    getUserDetails();
    setLoading(false);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (e.target.name === 'firstName') {
      if (e.target.value.length > 0) {
        error.firstName = '';
      }
    }

    if (e.target.name === 'lastName') {
      if (e.target.value.length > 0) {
        error.lastName = '';
      }
    }

    if (e.target.name === 'jobTitle') {
      if (e.target.value.length > 0) {
        error.jobTitle = '';
      }
    }

    if (e.target.name === 'email') {
      if (e.target.value.length > 0) {
        error.email = '';
      }
    }

    if (e.target.name === 'userName') {
      if (e.target.value.length > 0) {
        error.userName = '';
      }
    }

    setRegistrationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancelClick = () => {
    navigate('/account-dashboard');
  };

  const handleSubmitClick = () => {
    const personalDetails = {
      firstName: registrationData.firstName,
      lastName: registrationData.lastName,
      jobTitle: registrationData.jobTitle,
      email: registrationData.email,
      userName: registrationData.userName,
    };

    if (personalDetails.firstName.length === 0) {
      setError((pre: any) => ({
        ...pre,
        firstName: 'Enter the first name',
      }));
      return;
    }

    if (personalDetails.lastName.length === 0) {
      setError((pre: any) => ({
        ...pre,
        lastName: 'Enter the last name',
      }));
      return;
    }

    if (personalDetails.jobTitle.length === 0) {
      setError((pre: any) => ({
        ...pre,
        jobTitle: 'Enter Your Job Title',
      }));
      return;
    }

    if (personalDetails.email.length === 0) {
      setError((pre: any) => ({
        ...pre,
        email: 'Enter the email address',
      }));
      return;
    }

    if (personalDetails.userName.length === 0) {
      setError((pre: any) => ({
        ...pre,
        userName: 'Enter the UserName',
      }));
      return;
    }

    const updateUserDetailsData = privateAxios.put(`user/personalinfo`, registrationData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    updateUserDetailsData
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          setMailCheck({ email: '' });
          getUserDetails();
          handleClick();
          setPrevUserName(registrationData?.userName);
          if (nameDisable) {
            setNameDisable(false);
          }
          if (emailChanged) {
            setToast({ message: 'Email Changed Please SignIn Again', type: 'success' });
            setTimeout(() => {
              dispatch(isLoggedIn(false));
              dispatch(backHandle(false));
              dispatch(userId(''));
              dispatch(userStepStatus({ status: '' }));
              dispatch(userType(false));
              dispatch(userAccessToken({ accessToken: '' }));
              dispatch(userRefreshToken({ refreshToken: '' }));
              localStorage.removeItem('center');
              localStorage.clear();
              navigate(`/login`);
            }, 4000);
          }
        }
      })
      .catch((error: any) => {
        setToast({ message: error.response.data.message, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
    onCloseCropDialog();
  };

  const changeUserName = () => {
    const userDetails = {
      userName: registrationData.userName,
    };

    if (userDetails.userName.length === 0) {
      setError((pre: any) => ({
        ...pre,
        userName: 'Enter the User name',
      }));
      return;
    }

    const checkUserName = privateAxios.post(
      `user/userNameExists`,
      { userName: registrationData.userName },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    checkUserName
      .then((res) => {
        const { data } = res;
        setToast({ message: data.message.value, type: 'success' });
        handleClick();
        setNameDisable(true);
      })
      .catch((error) => {
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleNameCancelClick = () => {
    // Revert the userName to previous value
    setRegistrationData((prevData) => ({
      ...prevData,
      userName: prevUserName,
    }));
    setNameDisable(false);
  };

  const uploadAllImage = (e: any, type: any) => {
    if (type == 'Profile_image') {
      document.getElementById(`icon_profile_icon`)?.click();
    }
    if (type == 'Header_image') {
      document.getElementById(`icon_header_icon`)?.click();
    }
  };

  const handleFileUpload = (e: any, type: any) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file) {
      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        setToast({ message: 'Unsupported File type ', type: 'error' });
        handleClick();
        return;
      }
      if (file.size > maxSize) {
        setToast({ message: 'Image size is too large', type: 'error' });
        handleClick();
        return;
      }
      if (type === 'profileImage') {
        setCropImage({ openCrop: true, imageType: 'profile', fileName: file.name });
        setSelectedImage(file);
      }

      if (type === 'headerImage') {
        setCropImage({ openCrop: true, imageType: 'banner', fileName: file.name });
        setSelectedImage(file);
      }
    }
  };

  const onCloseCropDialog = () => {
    setCropImage({
      openCrop: false,
      imageType: '',
      fileName: '',
    });
  };

  const UploadImage = async (image: any) => {
    const file = image;

    const formData = new FormData();
    if (image) {
      formData.append('file', file);
    }
    if (cropImage.imageType === 'profile') {
      setImageLoading((previousState: any) => ({ ...previousState, profile: true }));
      if (registrationData.profilePath) {
        formData.append('imagePath', registrationData.profilePath);
      }
      const request =
        registrationData.profilePath &&
        registrationData.profilePath !== 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultProfileImage.png'
          ? privateAxios.put
          : privateAxios.post;
      const responsesssss = request(
        `user/${
          registrationData.profilePath &&
          registrationData.profilePath !== 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultProfileImage.png'
            ? 'profilereupload'
            : 'profileupload'
        }`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      responsesssss
        .then((response: any) => {
          if (response.status === 200) {
            setRegistrationData((prevData: any) => ({
              ...prevData,
              profilePath: response.data.data,
            }));
            setImageLoading((previousState: any) => ({ ...previousState, profile: false }));
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
          }
        })
        .catch((error: any) => {
          setImageLoading((previousState: any) => ({ ...previousState, profile: false }));
          console.error(error);
          setToast({ message: error.data.message.value, type: 'error' });
          handleClick();
        });
    }

    if (cropImage.imageType === 'banner') {
      setImageLoading((previousState: any) => ({ ...previousState, header: true }));
      if (registrationData.headerPath) {
        formData.append('imagePath', registrationData.headerPath);
      }

      const request =
        registrationData.headerPath &&
        registrationData.headerPath !== 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultHeaderUpdatedImage.png'
          ? privateAxios.put
          : privateAxios.post;
      const responsesssss = request(
        `user/${
          registrationData.headerPath &&
          registrationData.headerPath !== 'https://d1b0o98gwlv9wy.cloudfront.net/appImage/defaultHeaderUpdatedImage.png'
            ? 'headerreupload'
            : 'headerupload'
        }`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      responsesssss
        .then((response: any) => {
          if (response.status === 200) {
            setRegistrationData((prevData: any) => ({
              ...prevData,
              headerPath: response.data.data,
            }));
            setImageLoading((previousState: any) => ({ ...previousState, header: false }));
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
          }
        })
        .catch((error: any) => {
          setImageLoading((previousState: any) => ({ ...previousState, header: false }));
          setToast({ message: error.data.message.value, type: 'error' });
          handleClick();
        });
    }
  };

  const handleDrop = (e: any, type: any) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file) {
      if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        setToast({ message: 'Unsupported File type ', type: 'error' });
        handleClick();
        return;
      }
      if (file.size > maxSize) {
        setToast({ message: 'Image size is too large', type: 'error' });
        handleClick();
        return;
      }
      if (type === 'profileImage') {
        setCropImage({ openCrop: true, imageType: 'profile', fileName: file.name });
        setSelectedImage(file);
      }

      if (type === 'headerImage') {
        setCropImage({ openCrop: true, imageType: 'banner', fileName: file.name });
        setSelectedImage(file);
      }
    }
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const checkEmail = () => {
    const emailDetails = {
      email: registrationData.email,
    };

    if (emailDetails.email.length === 0) {
      setError((pre: any) => ({
        ...pre,
        email: 'Enter the email',
      }));
      return;
    }

    const changeEmailCheck = privateAxios.post(
      `user/userEmailExists`,
      { email: registrationData.email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    changeEmailCheck
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          setMailCheck({
            email: 'Please Save to update the email',
          });
          handleClick();
          setEmailChanged(true);
        }
      })
      .catch((error: any) => {
        console.error(error);
        if (error.response.status === 400) {
          setToast({ message: error.response.data.message.value, type: 'error' });
        } else {
          setToast({ message: error.response.data.message.value, type: 'error' });
        }

        handleClick();
      });
  };

  const getUserDetails = () => {
    setLoading(true);
    const getDetails = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          dispatch(
            profileHeaderPics({
              firstName: data?.firstName,
              lastName: data?.lastName,
              jobTitle: data?.jobTitle,
              userName: data?.userName,
              profilePath: data?.profilePath,
              headerPath: data?.headerPath,
              availablity: data?.availability,
            })
          );

          setRegistrationData({
            firstName: data?.firstName,
            lastName: data?.lastName,
            jobTitle: data?.jobTitle,
            email: data?.email,
            userName: data?.userName,
            profilePath: data?.profilePath,
            headerPath: data?.headerPath,
          });
          setPrevUserName(data.userName);
          setLoading(false);
          // dispatch(profileHeaderPics({ profilePath: data?.profilePath,
          //   headerPath: data?.headerPath ,
          //   availablity : response.data.data?.availability,
          //   firstName: data?.firstName,
          //   lastName: data?.lastName,
          //   jobTitle : data?.jobTitle,
          //   userName : data?.userName,
          // }));
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  return (
    <>
      <div style={{ margin: '0px', position: 'relative' }}>
        <Grid
          container
          className="button-position userDetailbtn"
          sx={{
            position: 'absolute',
            top: -140,
            right: 0,
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
          }}>
          <Button
            className="common-button-white"
            type="submit"
            variant="contained"
            onClick={handleCancelClick}
            sx={{
              width: '167px',
              height: '44px',
              marginRight: 1,
              '&:hover': {
                color: 'var(--Primary, #2CB512)',
                backgroundColor: '#fff',
              },
            }}>
            Cancel
          </Button>
          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleSubmitClick}
            sx={{
              width: '121px',
              height: '44px',
              marginLeft: 1,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save
          </Button>
        </Grid>
      </div>

      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress color="success" />
        </Backdrop>
      )}
      {!loading && (
        <Box sx={{ p: '20px', backgroundColor: '#FFFFFF', borderRadius: '10px', position: 'relative' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography className="lable-name" sx={{ color: '#344054', fontWeight: '500', mb: 0.5 }}>
                First Name
              </Typography>
              <TextField
                className="formFields"
                name="firstName"
                placeholder="Enter the First Name"
                value={registrationData.firstName}
                onChange={handleInputChange}
                required
                fullWidth
              />
              {error.firstName && <div style={{ color: 'red' }}>{error.firstName}</div>}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className="lable-name" sx={{ color: '#344054', fontWeight: '500', mb: 0.5 }}>
                Last Name
              </Typography>
              <TextField
                className="formFields"
                name="lastName"
                placeholder="Enter the Last Name"
                value={registrationData.lastName}
                onChange={handleInputChange}
                required
                fullWidth
              />
              {error.lastName && <div style={{ color: 'red' }}>{error.lastName}</div>}
            </Grid>
            <Grid item xs={12}>
              <Typography className="lable-name" sx={{ mb: 1 }}>
                Job Title
              </Typography>
              <TextField
                className="formFields"
                name="jobTitle"
                placeholder="Enter Your Job Title"
                value={registrationData.jobTitle}
                onChange={handleInputChange}
                required
                fullWidth
              />
              {error.jobTitle && <div style={{ color: 'red' }}>{error.jobTitle}</div>}
            </Grid>
            <Grid item xs={12} md={8} lg={10}>
              <Typography className="lable-name" sx={{ mb: 1 }}>
                User Email{' '}
                <span style={{ color: 'var(--gray-400, #98A2B3)', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}>
                  Also used as your login
                </span>
              </Typography>
              <TextField
                className="formFields"
                name="email"
                type="email"
                placeholder="Enter the Email"
                value={registrationData.email}
                onChange={handleInputChange}
                required
                fullWidth
              />
              {error.email && <div style={{ color: 'red' }}>{error.email}</div>}
              {mailCheck.email && <div style={{ color: 'red' }}>{mailCheck.email}</div>}
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
              <Box sx={{ textAlign: 'center' }}>
                <Button
                  className="common-button-green changeEmail"
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    '&:hover': {
                      background: 'var(--Primary, #2CB512)',
                    },
                  }}
                  onClick={() => checkEmail()}>
                  Change Email
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Box>
                <Typography className="lable-name" sx={{ textAlign: 'left', mb: 1 }}>
                  User Name
                  <span style={{ color: 'var(--gray-400, #98A2B3)', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}>
                    {' '}
                    You can change this later after your account is created.
                  </span>
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8}>
                    <TextField
                      className="formFields"
                      sx={{ width: '100%' }}
                      name="userName"
                      placeholder="Enter the User Name"
                      value={registrationData.userName}
                      onChange={handleInputChange}
                      required
                    />
                    {error.userName && <div style={{ color: 'red' }}>{error.userName}</div>}
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Button
                      className="common-button-white"
                      sx={{ height: '50px !important', width: '100%' }}
                      onClick={handleNameCancelClick}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Button
                      className="common-button-white"
                      sx={{
                        height: '50px !important',
                        width: '100%',
                        backgroundColor: nameDisable ? 'lightgray !important' : 'white !important',
                      }}
                      onClick={() => changeUserName()}>
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} md={3} lg={1.5}>
              <Typography className="lable-name" sx={{ textAlign: 'left' }}>
                Profile Image
              </Typography>
              {registrationData.profilePath !== null && registrationData.profilePath !== '' ? (
                <Box
                  className="pro-image"
                  sx={{
                    width: '170px',
                    height: '180px',
                    mt: 3,
                  }}>
                  {!imageLoading.profile ? (
                    <img src={registrationData.profilePath} alt="profileimg" className="img-fluid profile-pic" />
                  ) : (
                    <CircularProgress color="success" sx={{ marginLeft: '26px' }} />
                  )}
                </Box>
              ) : (
                <Box
                  className="pro-image"
                  sx={{
                    width: '170px',
                    height: '150px',
                    mt: 3,
                  }}>
                  <img src={defaultProfileImage} alt="profileimg" className="img-fluid profile-pic" />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={9} lg={10.5}>
              <Box sx={{ mt: '15px' }}>
                <Box
                  className="p-image imageBox"
                  sx={{
                    height: '128px',
                    textAlign: 'center',
                    pt: '25px',
                    marginRight: '30px',
                  }}
                  onClick={(e) => uploadAllImage(e, 'Profile_image')}
                  onDrop={(e) => handleDrop(e, 'profileImage')}
                  onDragOver={handleDragOver}>
                  <>
                    <img src={uploadCloud} alt="img" className="img-fluid upload-button" />
                    <input
                      className="file-upload"
                      id="icon_profile_icon"
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileUpload(e, 'profileImage')}
                    />
                  </>

                  <Typography
                    className="note"
                    sx={{
                      color: 'var(--Primary, #2CB512)',
                      textAlign: 'center',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '18px',
                      fontFamily: "'Onest', sans-serif !important",
                    }}>
                    Drag & Drop, or Click to upload
                  </Typography>
                  <Typography
                    sx={{
                      color: 'var(--gray-400, #98A2B3)',
                      textAlign: 'center',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '18px',
                      fontFamily: "'Onest', sans-serif !important",
                    }}>
                    JPG, PNG Format
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <Typography className="lable-name" sx={{ textAlign: 'left' }}>
                  Header Image
                </Typography>
                {registrationData.headerPath !== null && registrationData.headerPath !== '' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      // width: '100%',
                      // height: '120px',
                      // height:'calc(100vw / 9)',
                      overflow: 'hidden',
                      borderRadius: '10px',
                      marginRight: '30px',
                      marginTop: '10px',
                    }}>
                    {!imageLoading.header ? (
                      <img
                        src={registrationData.headerPath}
                        alt="headerImage"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    ) : (
                      <CircularProgress color="success" />
                    )}
                  </Box>
                ) : (
                  <img
                    src={defaultHeaderImage}
                    alt="headerImage"
                    style={{
                      width: '100%',
                      height: '120px',
                      objectFit: 'cover',
                      borderRadius: '10px',
                      marginRight: '30px',
                      marginTop: '10px',
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ padding: '5px' }}>
                <Box
                  className="p-image imageBox"
                  sx={{
                    height: '128px',
                    textAlign: 'center',
                    pt: '25px',
                    marginRight: '30px',
                  }}
                  onClick={(e) => uploadAllImage(e, 'Header_image')}
                  onDrop={(e) => handleDrop(e, 'headerImage')}
                  onDragOver={handleDragOver}>
                  <img src={uploadCloud} alt="img" className="img-fluid upload-button" />
                  <input
                    className="file-upload"
                    id="icon_header_icon"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileUpload(e, 'headerImage')}
                  />

                  <Typography
                    className="note"
                    sx={{
                      color: 'var(--Primary, #2CB512)',
                      textAlign: 'center',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      lineHeight: '18px',
                      fontFamily: "'Onest', sans-serif !important",
                    }}>
                    Drag & Drop, or Click to upload
                  </Typography>
                  <Typography
                    sx={{
                      color: 'var(--gray-400, #98A2B3)',
                      textAlign: 'center',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '18px',
                      fontFamily: "'Onest', sans-serif !important",
                    }}>
                    JPG,PNG Format
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {selectedImage && (
            <Grid item xs={12}>
              <Crop
                cropOpen={cropImage.openCrop}
                images={selectedImage}
                imageSize={cropImage.imageType}
                onCropComplete={(croppedImage: any) => {
                  UploadImage(croppedImage);
                }}
                onCloseDialog={onCloseCropDialog}
              />
            </Grid>
          )}
          <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
        </Box>
      )}
    </>
  );
};

export default Personaldetails;

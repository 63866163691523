/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { MobileStepper, Box } from '@mui/material';
import logo from '../../../Assets/Images/logo.png';
import PersonalInfo from './PersonalInfo';
import ProfilePic from './ProfilePic';
import Location from './Location';
import PortfoliosSocial from './PortfoliosSocial';
import WorkDetails from './WorkDetails';
import AvailabilityDetails from './AvailabilityDetails';
import RateExpression from './RateExpression';
import SkillsDetails from './SkillsDetails';
import ToolsDetails from './ToolsDetails';
import AddTalentRosterMembers from './AddTalentRosterMembers';
import { useNavigate } from 'react-router-dom';
import Welcome from '../Welcome';
// import Env from '../../../services/Env';
import { connect } from 'react-redux';
import { AppDispatch } from '../../../reducer/store';
import { useDispatch } from 'react-redux';
import { backHandle } from '../../../reducer/action';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';

const RegistrationStepper = (props: any) => {
  const privateAxios = useAxiosPrivate();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [stepKeys, setStepKeys] = useState([]);

  useEffect(() => {
    stepperKey();
  }, []);

  useEffect(() => {
    if (props.id && stepKeys !== null) {
      getLastStepper();
    }
  }, [stepKeys]);

  const stepperKey = () => {
    // const steps = Env.get(`user/getStepper`, props.userAccessToken);

    const steps = privateAxios.get(`user/getStepper`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    steps
      .then((response: any) => {
        if (response.status === 200) {
          const steps = response.data.data;
          setStepKeys(() => {
            return steps;
          });
        }
      })
      .catch((error: any) => {
        console.error('ERROR', error);
      });
  };

  const getLastStepper = () => {
    const user = {
      userId: props.id,
    };
    // const getLastStep = Env.post(`user/getlaststepper`, user, props.userAccessToken);

    const getLastStep = privateAxios.post(`user/getlaststepper`, user, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    getLastStep
      .then((response: any) => {
        if (response.status === 200) {
          getInitalStep(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error('ERROR', error);
      });
  };

  const getInitalStep = (lastStepKey: any) => {
    // if (!props.userType && activeStep === 9) {
    //   setActiveStep(11);
    //   navigate('/');
    //   return;
    // } else {
    // console.log('userLastStep', userLastStep[0]);

    if (stepKeys.length !== 0) {
      const nextStepIndex = stepKeys?.findIndex((step: any) => step.key === lastStepKey.key);

      if (nextStepIndex !== -1) {
        setActiveStep(nextStepIndex + 2);
      } else {
        setActiveStep((prevStep: number) => prevStep + 1);
      }
    }

    // }
  };

  const handleBack = () => {
    dispatch(backHandle(true));
    setActiveStep((prevStep: number) => prevStep - 1);
  };

  const handleNext = () => {
    dispatch(backHandle(true));
    if (!props.userType && activeStep === 9) {
      setActiveStep(11);
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }

    if (activeStep === 11) {
      navigate('/');
    }
  };

  const renderStepContent = (step: any) => {
    if (!props.userType && step === 10) {
      step = 11;
    }
    switch (step) {
      case 1:
        return (
          <PersonalInfo activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} stepKeys={stepKeys[0]} />
        );
      case 2:
        return <ProfilePic activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} stepKeys={stepKeys[1]} />;
      case 3:
        return <Location activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} stepKeys={stepKeys[2]} />;
      case 4:
        return (
          <PortfoliosSocial activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} stepKeys={stepKeys[3]} />
        );
      case 5:
        return (
          <WorkDetails activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} stepKeys={stepKeys[4]} />
        );
      case 6:
        return (
          <AvailabilityDetails
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            stepKeys={stepKeys[5]}
          />
        );
      case 7:
        return (
          <RateExpression activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} stepKeys={stepKeys[6]} />
        );
      case 8:
        return (
          <SkillsDetails activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} stepKeys={stepKeys[7]} />
        );
      case 9:
        return (
          <ToolsDetails activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} stepKeys={stepKeys[8]} />
        );
      case 10:
        return <AddTalentRosterMembers activeStep={activeStep} handleNext={handleNext} handleBack={handleBack} />;

      case 11:
        return <Welcome handleNext={handleNext} />;

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        // padding: '200px 0px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1 0 0',
        alignSelf: 'stretch',
        backgroundColor: '#f7f8f8',
        // height: '100vh',
      }}>
      <Box
        sx={{
          borderRadius: '24px',
          background: 'var(--White, #FFF)',
          width: '956px',
        }}>
        <MobileStepper
          className="stepper progress-bar progress-bar-striped progress-bar-animated"
          variant="progress"
          steps={12}
          position="static"
          activeStep={activeStep}
          sx={{
            width: '100%',
            borderRadius: '24px 24px 0 0',
            paddingTop: 0,
            marginTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
          }}
          backButton={undefined}
          nextButton={undefined}
        />
        <Box sx={{ padding: '40px 50px 24px 50px' }}>
          <img src={logo} alt="logo" />
          {renderStepContent(activeStep)}
        </Box>
      </Box>
    </Box>
  );
};

function mapDispatchToProps(state: any) {
  return {
    userAccessToken: state.userAccessToken,
    id: state.id,
    userType: state.userType,
  };
}

export default connect(mapDispatchToProps)(RegistrationStepper);

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { privateAxios } from '../services/Interceptor';
import useRefreshToken from './useRefreshToken';
import { useSelector } from 'react-redux';
import userExpired from './usePlanExpired';

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const planExpired = userExpired();
  const bearerToken = useSelector((state: any) => {
    return state.userAccessToken;
  });

  useEffect(() => {
    const requestIntercept = privateAxios.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${bearerToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
    const responseIntercept = privateAxios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;

        if (error?.response?.status === 401 && !prevRequest?.retry) {
          prevRequest.retry = true;
          const newAccessToken = await refresh();

          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

          return privateAxios(prevRequest);
        }
        if (error?.response?.status === 402) {
          planExpired();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      privateAxios.interceptors.request.eject(requestIntercept);
      privateAxios.interceptors.response.eject(responseIntercept);
    };
  }, [bearerToken]);

  return privateAxios;
};

export default useAxiosPrivate;

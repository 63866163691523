/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avalibility from './ChatSpace';
import RecentChat from './ChatHistory';
import io from 'socket.io-client';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import Navbar from '../Navbar';
import '../../../Assets/css/Chat.css';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface UserType {
  id: number;
  name: string;
  profilePic: string;
}

interface Message {
  id: number;
  chat_id: number;
  sender_id: number;
  text: string;
}

interface ResponsiveChatProps {
  user?: UserType;
}

const ResponsiveChat: React.FC<ResponsiveChatProps> = ({ user }) => {
  const privateAxios = useAxiosPrivate();

  const [onlineUsers, setOnlineUsers] = useState<any>([]);
  const [userChats, setUserChats] = useState<any>([]);
  const [, setIsUserChatsLoading] = useState<boolean>(false);
  const [, setUserChatsError] = useState<any>(null);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [currentChat, setCurrentChat] = useState<any>(null);
  const [socket, setSocket] = useState<any | null>(null);
  const [notifications, setNotifications] = useState<any>([]);
  const [, setMessages] = useState<Message[]>([]);
  const recentChatRef = useRef<HTMLDivElement>(null);
  const [chatRecentClass, setChatRecentClass] = useState<string>('');

  //Socket connection
  //initial socket
  useEffect(() => {
    console.log('Socket URL', process.env.REACT_APP_SOCKET_API_URL);
    const newSocket = io(process.env.REACT_APP_SOCKET_API_URL);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [user]);

  //add online users
  useEffect(() => {
    if (socket === null) return;
    socket.emit('addNewUser', user?.id);
    socket.on('getOnlineUsers', (res: any) => {
      setOnlineUsers(res);
    });

    return () => {
      socket.off('getOnlineUsers');
    };
  }, [socket]);

  console.log('onlineUsers', onlineUsers);

  //receive message and notification
  useEffect(() => {
    if (socket === null) return;

    socket.on('getMessage', (res: any) => {
      console.log('res', res);
      if (currentChat?.id !== res.chatId) return;

      setMessages((prev) => [...prev, res]);
    });

    socket.on('getNotification', (res: any) => {
      console.log('SOCKET_RES', res);

      const isChatOpen = currentChat?.chatId === res.senderId;
      console.log('currentChat?.chatId', currentChat?.chatId);
      console.log('res.chatId', res?.chatId);

      console.log('dsgdfb', isChatOpen);

      if (isChatOpen) {
        setNotifications((prev: any) => [{ ...res, isRead: true }, ...prev]);
      } else {
        setNotifications((prev: any) => [res, ...prev]);
      }
    });

    return () => {
      socket.off('getMessage');
      socket.off('getNotification');
    };
  }, [socket, currentChat, notifications]);

  console.log('trtytkytr', notifications);

  useEffect(() => {
    if (recentChatRef.current) {
      recentChatRef.current.scrollTop = recentChatRef.current.scrollHeight;
    }
  }, [userChats]);

  const updateCurrentChat = useCallback((selectedChat: any) => {
    setCurrentChat(selectedChat);
  }, []);

  useEffect(() => {
    const getUserChats = async () => {
      if (user?.id) {
        const response = privateAxios.get(`chat/chatlist`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        response
          .then((response: any) => {
            if (response.status === 200) {
              setIsUserChatsLoading(false);

              setUserChats(response.data.data);
            }
          })
          .catch((error: any) => {
            console.error(error);
            setUserChatsError(error);
          });
      }
    };

    getUserChats();
  }, [user]);

  console.log('fdsvdfb', userChats);

  const handleItemClick = (index: number) => {
    setSelectedItem(index);
  };

  //show individual notification
  const markThisUserNotificationsAsRead = useCallback((thisUserNotifications: any, notifications: any) => {
    //mark notifications as read

    const mNotifications = notifications.map((el: any) => {
      let notification;

      thisUserNotifications.forEach((n: any) => {
        if (n.senderId === el.senderId) {
          notification = { ...n, isRead: true };
        } else {
          notification = el;
        }
      });
      return notification;
    });

    setNotifications(mNotifications);
  }, []);

  console.log('dfbhdfngn', notifications);

  const toggleChatRecent = () => {
    // const sidebar = document.querySelector('.side-bar');
    // sidebar?.classList.toggle('show-sliderbar');
    setChatRecentClass(chatRecentClass === 'recentChats' ? '' : 'recentChats'); // Toggle class
    setChatSpaceClass('');
  };

  const [chatSpaceClass, setChatSpaceClass] = useState<string>('');

  const toggleChatSpace = () => {
    setChatSpaceClass(chatSpaceClass === 'backButtons' ? '' : 'backButtons'); // Toggle class
    setChatRecentClass('');
    // setCurrentChat(null);
  };
  useEffect(() => {
    const elementsWithPClass = document.querySelectorAll('#chat-top');
    elementsWithPClass.forEach((element) => {
      const rootDiv = element.closest('.css-1y36zv6');
      if (rootDiv) {
        rootDiv.classList.add('centerConclass');
      }
    });
  }, []);

  return (
    <>
      <Grid container>
        <Grid item lg={12}>
          <Navbar />
        </Grid>
        <Grid item sx={{ mt: '89px' }} lg={12} id="chat-top">
          {userChats && userChats.length > 0 ? (
            <Grid>
              <Grid container spacing={2} sx={{ backgroundColor: '#F7F8F8', height: '100%' }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  sx={{ borderRight: '2px solid #BEBAD2', padding: '10px' }}
                  className={chatRecentClass}
                  onClick={toggleChatRecent}>
                  <Typography
                    variant="h5"
                    textAlign="left"
                    mr={2}
                    sx={{ padding: '10px 0px 0px 10px', fontFamily: '"Onest", sans-serif !important' }}>
                    Recent Chats
                  </Typography>

                  <div
                    ref={recentChatRef}
                    style={{
                      overflowY: 'auto',
                      maxHeight: 'calc(100vh - 150px)',
                      fontFamily: '"Onest", sans-serif !important',
                    }}>
                    {userChats.map((item: any, index: any) => (
                      <Grid
                        container
                        key={index}
                        spacing={1}
                        onClick={() => updateCurrentChat(item)}
                        sx={{ marginTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                        <RecentChat
                          chat={item}
                          selectedItem={selectedItem}
                          onItemClick={handleItemClick}
                          index={index}
                          notifications={notifications}
                          markThisUserNotificationsAsRead={markThisUserNotificationsAsRead}
                        />
                      </Grid>
                    ))}
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={9}
                  sx={{
                    width: '100%',
                    overflowY: 'auto',
                    padding: '20px',
                    marginTop: '40px',
                  }}
                  className={chatSpaceClass}>
                  <Button
                    className="backWhite"
                    sx={{
                      color: 'var(--Primary, #2CB512)',
                      display: selectedItem === null ? 'none' : '',
                      width: '10px',
                      mb: '18px',
                    }}
                    onClick={toggleChatSpace}>
                    <ArrowBackIcon />
                  </Button>
                  {selectedItem !== null ? (
                    <Avalibility user={user} currentChat={currentChat} setRecentSelectedChat={() => setCurrentChat(null)} />
                  ) : (
                    <Grid
                      style={{
                        position: 'relative',
                        height: '80vh',
                        overflow: 'hidden',
                        width: '100%',
                        margin: 'auto',
                        textAlign: 'center',
                      }}>
                      <Typography
                        component={'span'}
                        sx={{
                          ml: '15px',
                          fontFamily: "'Onest', sans-serif",
                          color: 'var(--green)',
                          fontSize: '24px',
                          fontWeight: 600,
                          textAlign: 'center',
                        }}>
                        Start the Conversation
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Typography
                component={'span'}
                sx={{
                  ml: '15px',
                  fontFamily: "'Onest', sans-serif",
                  color: 'var(--green)',
                  fontSize: '24px',
                  fontWeight: 600,
                  textAlign: 'center',
                }}>
                Yet to Start Conversation
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ResponsiveChat;

import React from 'react';
import CardDetailsGrid from './CardDetailsGrid';
import { Dialog } from '@mui/material';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../../common/Snackbar';

interface EditCardDialogProps {
  open: boolean;
  card: any;
  onCancel?: () => void;
  onSave?: (updatedCard: any) => void;
}

const EditCardDialog: React.FC<EditCardDialogProps> = ({ open, card, onCancel }) => {
  const privateAxios = useAxiosPrivate();

  const [openCustomer, setOpenCustomer] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });

  const onSave = (updatedCard: any) => {
    const updatevalue = {
      cardId: updatedCard.cardId,
      expiry: updatedCard.expiry,
    };
    const updateCards = privateAxios.put(`payment/stripe/editcard`, updatevalue, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    updateCards
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpenCustomer(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpenCustomer(false);
  };

  return (
    <>
      <Dialog open={open}>
        <CardDetailsGrid card={card} isEditMode={true} onCancel={onCancel} onSave={onSave} />
      </Dialog>
      <CustomSnackbar open={openCustomer} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

export default EditCardDialog;

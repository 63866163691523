/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import axios from 'axios';
import { useFetchRecipientUser } from './UserChat';
// import io, { Socket } from 'socket.io-client';
import io from 'socket.io-client';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import '../../../Assets/css/Chat.css';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface UserType {
  id: number;
  name: string;
  // email: string;
  // password: string;
  profilePic: string;
}

interface AvalibilityProps {
  user?: UserType;
  currentChat?: any;
  setRecentSelectedChat?: () => void;
}

interface Message {
  id: number;
  chatId: string;
  sender: boolean;
  receiver: boolean;
  message: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

const ChatSpace: React.FC<AvalibilityProps> = ({ user, currentChat }) => {
  const privateAxios = useAxiosPrivate();

  const [messages, setMessages] = useState<Message[]>([]);
  const [textMessage, setTextMessage] = useState<string>('');
  const [newMessage, setNewMessage] = useState(null);
  const [socket, setSocket] = useState<any | null>(null);
  // const [chatSpaceClass, setChatSpaceClass] = useState<string>('');
  // const [resetcurrentChat, setResetCurrentChat] = useState(null);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  // //Socket connection
  //initial socket
  useEffect(() => {
    console.log('Socket URL', process.env.REACT_APP_SOCKET_API_URL);

    const newSocket = io(process.env.REACT_APP_SOCKET_API_URL);
    setSocket(newSocket);

    newSocket.on('message', (data: any) => {
      console.log('Received message:', data);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [user]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (textMessage.trim() !== '') {
      // try {

      const response = privateAxios.post(
        `chat/sendmessage`,
        JSON.stringify({
          // id: user?.id,
          // receiverId: currentChat?.chatId,
          receiverId: currentChat?.receiverId,
          message: textMessage,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      response
        .then((response: any) => {
          if (response.status === 200) {
            setNewMessage(response.data);
            setMessages((prev) => [...prev, response.data]);
            setTextMessage('');
            getChats();
          }
        })
        .catch((error: any) => {
          console.error(error);
          // setUserChatsError(error);
        });
      // const response = await axios.post(
      //   `http://localhost:8000/api/v1/chat/sendmessage`,
      //   JSON.stringify({
      //     id: user?.id,
      //     // receiverId: currentChat?.chatId,
      //     receiverId: currentChat?.receiverId,
      //     message: textMessage,
      //   }),
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   }
      // );

      // Check if socket is available
      if (socket) {
        console.log('Enter Socket cond');
        console.log('Current Chat value', currentChat);

        // Emit the message with the 'sendMessage' event
        // const recipientId = currentChat.members.find((id: any) => id !== user?.id);
        // const recipientId = currentChat.members.find((id: any) => id !== user?.id);
        const messageData = {
          content: textMessage,
          sender_id: user?.id,
          recipientId: currentChat?.receiverId,
        };
        socket.emit('sendMessage', messageData);
      } else {
        console.error('Socket is not available.');
      }

      // Update state with the response data

      // } catch (error) {
      //   console.error('Error sending message:', error);
      // }
    }
  };
  console.log('newMessage', newMessage);

  // Add a new function to handle key press event
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior of Enter key (e.g., new line)
      handleSendMessage(); // Call handleSendMessage function when Enter key is pressed
    }
  };

  useEffect(() => {
    if (currentChat?.chatId) {
      const response = privateAxios.get(`chat/getChat?chatId=${currentChat?.chatId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      response
        .then((response: any) => {
          if (response.status === 200) {
            setMessages(response.data.data);
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }, [currentChat]);

  const getChats = () => {
    const response = privateAxios.get(`chat/getChat?chatId=${currentChat?.chatId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    response
      .then((response: any) => {
        if (response.status === 200) {
          setMessages(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  // console.log('fdgfe', currentChat);

  // console.log('userftyf', user);

  // console.log('MESSAGESDETAILS', messages);

  const { recipientUser } = useFetchRecipientUser(currentChat, user);

  console.log('recipientUser', recipientUser);

  // const toggleChatSpace = () => {
  //   // const sidebar = document.querySelector('.side-bar');
  //   // sidebar?.classList.toggle('show-sliderbar');
  //   setChatSpaceClass(chatSpaceClass === 'backButtons' ? '' : 'backButtons'); // Toggle class
  //   setRecentSelectedChat && setRecentSelectedChat();
  // };

  return (
    // <Grid container spacing={2} style={{ height: '80vh', width: '100%' }} className={chatSpaceClass}>
    <Grid container spacing={2} style={{ height: '80vh', width: '100%' }}>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          style={{ position: 'relative', height: '80vh', overflow: 'hidden', width: '100%', margin: 'auto' }}>
          {/* <Button className="common-button-white backButtonss" sx={{ color: 'white' }} onClick={toggleChatSpace}> */}
          {/* <Button className="common-button-white backButtonss" sx={{ color: 'white' }}>
            <ArrowBackIcon />
          </Button> */}
          <div
            style={{
              width: '100%',
              position: 'absolute',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              padding: '12px 20px',
              overflow: 'hidden',
              borderBottom: '2px solid #C3BFD7',
            }}>
            <img
              src={currentChat?.profileImage}
              alt="profile"
              style={{ width: '50px', maxWidth: '100%', height: '50px', marginTop: '15px', borderRadius: '50px' }}
            />
            <a
              href={`/user-details?id=${currentChat?.receiverId}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none', color: '#000' }}>
              <Typography
                variant="h6"
                style={{ marginLeft: '8px', marginTop: '20px', fontFamily: '"Onest", sans-serif !important' }}>
                {currentChat?.name || ''}{' '}
              </Typography>
            </a>
          </div>

          <div
            style={{
              overflowY: 'auto',
              height: 'calc(55vh - 60px)',
              marginTop: '90px',
              paddingTop: '10px',
            }}
            ref={messagesEndRef}>
            {messages.map((message, index) => (
              <Grid
                key={index}
                item
                xs={12}
                style={{
                  padding: '10px',
                  overflow: 'hidden',
                  textAlign: message?.sender ? 'right' : 'left',
                }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: message?.sender ? 'row-reverse' : 'row',
                  }}>
                  <img
                    src={message?.sender ? user?.profilePic : currentChat?.profileImage}
                    alt={`profile-${index}`}
                    style={{ width: '50px', maxWidth: '100%', height: '50px', borderRadius: '50%' }}
                  />
                  {/* <Typography
                  variant="h6"
                  style={{
                    marginLeft: '8px',
                    marginRight: '8px',
                    backgroundColor: message?.sender_id === user?.id ? '#424866' : '#69C1AC',
                    color: message?.sender_id === user?.id ? 'white' : 'black',
                    borderRadius: message?.sender_id === user?.id ? '12px 0px 12px 12px' : '0px 12px 12px 12px',
                    padding: '5px',
                  }}>
                  {message?.message || ''}
                </Typography> */}
                  <Typography
                    variant="h6"
                    style={{
                      marginLeft: '8px',
                      marginRight: '8px',
                      backgroundColor: message?.sender ? '#E1E2E7' : '#424866',
                      color: message?.sender ? 'black' : 'white',
                      borderRadius: message?.sender ? '12px 0px 12px 12px' : '0px 12px 12px 12px',
                      padding: '5px',
                      textAlign: 'left',
                    }}>
                    {typeof message?.message === 'string' ? message.message : ''}
                  </Typography>
                </div>
              </Grid>
            ))}
          </div>

          <div
            style={{
              position: 'absolute',
              bottom: '2vh',
              left: '1%',
              right: '1%',
              display: 'flex',
              alignItems: 'center',
            }}>
            <TextField
              className="textMsg"
              label="Write Your Message Here"
              variant="outlined"
              fullWidth
              multiline
              rows={1}
              value={textMessage || ''}
              onChange={(e) => setTextMessage(e.target.value)}
              InputProps={{ style: { overflow: 'hidden' } }}
              style={{ position: 'absolute', bottom: 0, width: '85%' }}
              onKeyDown={handleKeyPress} // Add onKeyDown event handler
            />
            <Button
              className="textButton"
              variant="contained"
              onClick={handleSendMessage}
              style={{ position: 'absolute', bottom: 12, right: '5px', backgroundColor: '#2CB512' }}>
              Send
            </Button>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChatSpace;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BasicStructure from '../auth/BasicStructure';
// import BasicWelcome from '../auth/BasicWelcome';
// import RegistrationStepper from '../auth/Stepper/Stepper';
import ProvideInvite from '../auth/ProvideInvite';

function BasicRouter() {
  return (
    <Routes>
      <Route path="invite" element={<BasicStructure />}>
        <Route index element={<ProvideInvite />} />
      </Route>
    </Routes>
  );
}

export default BasicRouter;

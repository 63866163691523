/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, TextField, Select, MenuItem, Chip, Button } from '@mui/material';
import '../../../Assets/css/Common.css';
import '../../../Assets/css/RateExperience.css';
// import Env from '../../../services/Env';
import { connect } from 'react-redux';
import CustomSnackbar from '../../../common/Snackbar';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';

interface step {
  label: string;
  key: string;
}

interface RateExpressionProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  stepKeys: step;
  // userAccessToken: string;
  // id: number;
}

interface RateRangeItem {
  key: string;
  label: string;
}

const RateExpression: React.FC<RateExpressionProps> = ({
  activeStep,
  handleNext,
  handleBack,
  stepKeys,
  // id,
  // userAccessToken,
}) => {
  const privateAxios = useAxiosPrivate();

  const [totExperience, setTotExperience] = useState<any[]>([]);
  const [selectedTotExperience, setSelectedTotExperience] = useState<string>('');
  const [rateRange, setRateRange] = useState<RateRangeItem[]>([]);
  const [rate, setRate] = useState(rateRange?.length > 0 ? rateRange[0].label : '');
  const [companyInput, setCompanyInput] = useState('');
  const [rateLabel, setRateLabel] = useState('');
  const [companies, setCompanies] = useState<string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [selectedRateExperience, setSelectedRateExperience] = useState({
    stepperKey: stepKeys.key,
    // userId: id,
    experienceKey: '',
    rangeKey: '',
    bio: '',
    companyName: [],
  });
  const [existingData, setExistingData] = useState<any>(null);
  const [Error, setError] = useState({
    experienceKey: '',
    rangeKey: '',
    bio: '',
    companyName: '',
  });

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    rateAndExperience();
    getRateData();
  }, []);

  const getRateData = () => {
    // const getData = Env.get(`user/getuserstepper?stepperKey=${stepKeys.key}&userId=${id}`, userAccessToken);

    const getData = privateAxios.get(`user/getuserstepper?stepperKey=${stepKeys.key}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    getData
      .then((response: any) => {
        if (response.status === 200) {
          setExistingData(response.data.data);
          setRateLabel(response.data.data.range.label);

          const receivedRateKey = response.data.data.range.key;

          const matchingRate = rateRange.find((rate: any) => {
            return rate.key == receivedRateKey;
          });

          setRate(matchingRate?.label || rateRange[0]?.label || '');
          setSelectedRateExperience((prevData: any) => ({
            ...prevData,
            experienceKey: response.data.data.year.key,
            rangeKey: response.data.data.range.key,
            bio: response.data.data.bio,
            companyName: response.data.data.company,
          }));
          setSelectedTotExperience(response.data.data.year.key);
          setCompanies(response.data.data.company);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const rateAndExperience = () => {
    // const workdetails = Env.get(`user/rateRange`, userAccessToken);

    const workdetails = privateAxios.get(`user/rateRange`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    workdetails
      .then((response: any) => {
        if (response.status === 200) {
          // const experienceArray = Array.isArray(response.data.data.year)
          //   ? response.data.data.year
          //   : [response.data.data.year];
          const experienceArray = Array.isArray(response.data.data.experience)
            ? response.data.data.experience
            : [response.data.data.experience];
          setRateRange(response.data.data.rateRange);
          setTotExperience(experienceArray);
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const handleRateExpressionData = () => {
    const { experienceKey, rangeKey, bio, companyName } = selectedRateExperience;

    const errors = {
      experienceKey: '',
      rangeKey: '',
      bio: '',
      companyName: '',
    };

    if (!experienceKey || experienceKey === 'null Year') {
      errors.experienceKey = 'Experience is required';
    }

    if (!rangeKey || rangeKey === undefined) {
      errors.rangeKey = 'Range is required';
    }

    if (!bio || bio === null) {
      errors.bio = 'Bio is required';
    }

    if (companyName === null || companyName.length === 0) {
      errors.companyName = 'CompanyName is required';
    }

    if (Object.values(errors).some((field) => field !== '')) {
      setError(errors);
      return;
    }

    const changesDetected =
      existingData &&
      (existingData.experienceKey !== selectedRateExperience.experienceKey ||
        existingData.range.key !== selectedRateExperience.rangeKey ||
        existingData.bio !== selectedRateExperience.bio ||
        existingData.company !== selectedRateExperience.companyName);

    if (changesDetected || changesDetected === null) {
      // const rateExperienceData = Env.post(`user/register`, selectedRateExperience, userAccessToken);

      const rateExperienceData = privateAxios.post(`user/register`, selectedRateExperience, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      rateExperienceData
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setTimeout(() => handleNext(), 2000);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
      return;
    } else if (
      selectedRateExperience.experienceKey === '' ||
      selectedRateExperience.rangeKey === '' ||
      selectedRateExperience.bio === '' ||
      selectedRateExperience.companyName === null
    ) {
      // const rateExperienceData = Env.post(`user/register`, selectedRateExperience, userAccessToken);

      const rateExperienceData = privateAxios.post(`user/register`, selectedRateExperience, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      rateExperienceData
        .then((response: any) => {
          if (response.status === 200) {
            setToast({ message: response.data.message.value, type: 'success' });
            handleClick();
            setTimeout(() => handleNext(), 2000);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setToast({ message: error.response.data.message.value, type: 'error' });
          handleClick();
        });
    } else {
      handleNext();
      return;
    }
  };

  const handleTotalExperienceChange = (event: any) => {
    setSelectedTotExperience(event.target.value);
    setSelectedRateExperience((prevData: any) => ({
      ...prevData,
      experienceKey: event.target.value,
    }));
    setError((preError: any) => ({
      ...preError,
      experienceKey: '',
    }));
  };

  const handleRateChange = (event: any) => {
    setRate(event.target.value);
  };

  const handleRateChangeKey = (selectedRate: any) => {
    setRate(selectedRate.label);
    setSelectedRateExperience((prevData: any) => ({
      ...prevData,
      rangeKey: selectedRate.key,
    }));
    setError((pre: any) => ({
      ...pre,
      rangeKey: '',
    }));
  };

  const handleInputChange = (event: any) => {
    setCompanyInput(event.target.value);
    setError((pre: any) => ({
      ...pre,
      companyName: '',
    }));
  };

  const handleBioChange = (e: any) => {
    setSelectedRateExperience((prevData: any) => ({
      ...prevData,
      bio: e.target.value,
    }));

    setError((pre: any) => ({
      ...pre,
      bio: '',
    }));
  };

  const handleChipDelete = (companyToDelete: any) => {
    setCompanies((prevCompanies) => prevCompanies.filter((company) => company !== companyToDelete));
    setSelectedRateExperience((prevData: any) => ({
      ...prevData,
      companyName: prevData.companyName.filter((company: any) => company !== companyToDelete),
    }));
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const trimmedCompany = companyInput.trim();
      if (trimmedCompany !== '') {
        setCompanies((prevCompanies: string[]) => [...prevCompanies, trimmedCompany]);
        setCompanyInput('');
        setSelectedRateExperience((prevData: any) => ({
          ...prevData,
          companyName: [...prevData.companyName, trimmedCompany],
        }));
        setError((pre: any) => ({
          ...pre,
          companyName: '',
        }));
      }
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontSize: '30px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '38px',
          paddingTop: '30px',
        }}>
        Rate & Experience
      </Typography>
      <Box>
        <Grid container>
          <Grid item sm={6}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '15px',
              }}>
              Total Experience<span className="mandatoryFields">*</span>
            </Typography>

            <Select
              sx={{ width: '393px', fontFamily: "'Onest', sans-serif" }}
              value={selectedTotExperience || ''}
              onChange={handleTotalExperienceChange}>
              {totExperience &&
                totExperience.map((item: any, index: number) => (
                  <MenuItem sx={{ fontFamily: "'Onest', sans-serif" }} key={index} value={item.key}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
            {Error.experienceKey && <Typography sx={{ color: 'red', mt: 1 }}>{Error.experienceKey}</Typography>}
          </Grid>
          <Grid item sm={6}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '15px',
              }}>
              Rate Range<span className="mandatoryFields">*</span>
            </Typography>

            <Select
              sx={{ width: '393px', fontFamily: "'Onest', sans-serif" }}
              value={rate ? rate || '' : rateLabel || ''}
              onChange={handleRateChange}>
              {rateRange?.map((rate: any) => (
                <MenuItem
                  sx={{ fontFamily: "'Onest', sans-serif" }}
                  key={rate.key}
                  value={rate.label}
                  onClick={() => handleRateChangeKey(rate)}>
                  {rate.label}
                </MenuItem>
              ))}
            </Select>
            {Error.rangeKey && <Typography sx={{ color: 'red', mt: 1 }}>{Error.rangeKey}</Typography>}
          </Grid>
          <Grid item sm={12}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '30px',
              }}>
              Companies or clients you&apos;ve done work for<span className="mandatoryFields">*</span>
              <span style={{ color: '#98A2B3' }}> [Type and Press Enter for multiple entries]</span>
            </Typography>
            <TextField
              className="formFields"
              sx={{ mt: 0, width: '850px' }}
              type="text"
              name="companies"
              placeholder="XYZ Company"
              value={companyInput}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              required
            />
            <div style={{ marginTop: '10px' }}>
              {companies.map((company, index) => (
                <Chip
                  key={index}
                  label={company}
                  onDelete={() => handleChipDelete(company)}
                  style={{ marginRight: '8px', marginBottom: '8px' }}
                />
              ))}
            </div>
          </Grid>
          {Error.companyName && <Typography sx={{ color: 'red', mt: 1 }}>{Error.companyName}</Typography>}

          <Grid item sm={12}>
            <Typography
              className="lable-name"
              sx={{
                textAlign: 'left',
                mt: '30px',
              }}>
              Bio<span className="mandatoryFields">*</span>
            </Typography>
            <TextField
              className="formFields"
              multiline
              rows={4}
              sx={{ mt: 0, width: '850px', maxHeight: '200px', fontFamily: "'Onest', sans-serif !important" }}
              type="text"
              name="bio"
              value={selectedRateExperience.bio || ''}
              onChange={(e: any) => handleBioChange(e)}
              placeholder="Type Here..."
              required
            />
          </Grid>
          {Error.bio && <Typography sx={{ color: 'red', mt: 1 }}>{Error.bio}</Typography>}
        </Grid>
      </Box>
      <Box sx={{ padding: '50px', paddingTop: '40px' }}>
        <Box sx={{ textAlign: 'center' }}>
          {activeStep === 1 ? (
            ''
          ) : (
            <Button
              className="common-button-white"
              type="submit"
              variant="contained"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                marginRight: 1,
                mt: 3,
                mb: 2,
                '&:hover': {
                  color: 'var(--Primary, #2CB512)',
                  backgroundColor: '#fff',
                },
                alignItems: 'center',
              }}>
              Back
            </Button>
          )}

          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            onClick={handleRateExpressionData}
            disabled={activeStep === 10}
            sx={{
              marginLeft: 1,
              mt: 3,
              mb: 2,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}>
            Save and Next
          </Button>
        </Box>
        <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
      </Box>
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    id: state.id,
    userAccessToken: state.userAccessToken,
  };
}

export default connect(mapDispatchToProps)(RateExpression);

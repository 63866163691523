import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../common/Snackbar';
import '../../Assets/css/CreateList.css';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props: React.ComponentProps<typeof Slide>, ref: React.Ref<unknown>) {
  return <Slide direction="down" ref={ref} timeout={{ enter: 1, exit: 5 }} {...props} />;
});

const CreateListDialog = ({ isNewListDialogOpen, handleCloseNewListDialog }: any) => {
  const privateAxios = useAxiosPrivate();

  const [newListValue, setNewListValue] = useState('');
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: '', type: 'success' });
  const [error, setError] = useState('');

  const handleNewListData = (e: any) => {
    setNewListValue(e.target.value);
    setError('');
  };

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const addList = () => {
    if (newListValue.length === 0) {
      setError('Create name is required');
      return;
    }
    const data = {
      data: newListValue,
    };

    const addListData = privateAxios.post(`dashboard/createlist`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    addListData
      .then((response: any) => {
        if (response.status === 200) {
          setToast({ message: response.data.message.value, type: 'success' });
          setNewListValue('');
          handleClick();
          handleCloseNewListDialog();
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const clearData = () => {
    setNewListValue('');
    setError('');
    handleCloseNewListDialog();
  };

  return (
    <>
      <Dialog
        open={isNewListDialogOpen}
        onClose={clearData}
        fullWidth
        TransitionComponent={Transition}
        className="rosterList"
        PaperProps={{
          sx: {
            position: 'absolute',
            // top: '50px',
            // left: '50%',
            // transform: 'translateX(-50%)',
            transitionDuration: '500ms',
          },
        }}
        transitionDuration={500}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: "'Onest', sans-serif",
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '28px',
              color: 'var(--green)',
              flex: '1',
            }}>
            Create a new list
          </Typography>
          <IconButton onClick={clearData} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>New list</Typography>
          <TextField
            fullWidth
            value={newListValue}
            sx={{ fontFamily: "'Onest', sans-serif !important" }}
            onChange={(e) => handleNewListData(e)}
            helperText={
              <Typography id="helperText" style={{ color: 'red', marginLeft: '0px !important' }}>
                {error}
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }} className="addButtons">
          <Button className="common-button-white" onClick={clearData} sx={{ width: '275px' }}>
            Cancel
          </Button>
          <Button
            className="common-button-green"
            onClick={() => {
              addList();
            }}
            sx={{
              width: '275px',
              color: 'var(--white)',
              '&.Mui-disabled': {
                color: '#FFF', // Override text color for disabled state
              },
              cursor: 'pointer',
            }}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

export default CreateListDialog;

/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const CancelDialog = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const searchParams = new URLSearchParams(location.search);

  // const paymentId = searchParams.get('paymentId');
  // const token = searchParams.get('token');
  // const payerId = searchParams.get('PayerID');
  const payerId = searchParams.get('token');

  useEffect(() => {
    if (payerId) {
      setOpenCancelDialog(true);
    }
  }, []);

  const handleOnClick = () => {
    navigate('/premium/choose-payment');
  };

  const handleClose = () => {
    setTimeout(() => {
      setOpenCancelDialog(false);
    }, 2000);
  };
  return (
    <Dialog
      open={openCancelDialog}
      onClick={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: '500px !important',
          height: 'auto',
          padding: '25px',
        },
      }}>
      <DialogContent sx={{ fontFamily: "'Onest', sans-serif" }}>Your Payment has been Cancelled.</DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button className="common-button-green" sx={{ color: '#fff' }} onClick={handleOnClick}>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Navbar from '../HomeScreen/Navbar';
import { Booking, OnGoing } from '../Interface/BookingInterface/IBooking';
import BookingDetails from './BookingDetails';
import { CircularProgress, Backdrop } from '@mui/material';
import { connect } from 'react-redux';
import useAxiosPrivate from '../../Hooks/useAxioPrivate';
import CustomSnackbar from '../../common/Snackbar';

const titles = ['My Contracts', 'User Contracts', 'Previous Contracts', 'Cancelled Contracts'];

const Bookings = (props: any) => {
  const privateAxios = useAxiosPrivate();

  const [selectedTitle, setSelectedTitle] = useState<string>('My Contracts');
  const [loading, setLoading] = useState<boolean>(false);
  const [bookingContracts, setBookingContracts] = useState<Booking[] | OnGoing[] | null>(null);
  const [previousContracts, setPreviousContracts] = useState<Booking[] | OnGoing[] | null>(null);
  const [onGoingContracts, setOnGoingContracts] = useState<Booking[] | OnGoing[] | null>(null);
  const [cancelContracts, setCancelContracts] = useState<Booking[] | OnGoing[] | null>(null);
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({ message: '', type: 'success' });

  const handleTitleClick = (title: string) => {
    setSelectedTitle(title);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    bookingData();
    previousData();
    onGoingData();
    cancelledData();
    // if (!props.userType) {
    //   setSelectedTitle('My Contracts');
    // }
  }, [selectedTitle]);

  const bookingData = () => {
    const bookingContractsData = privateAxios.get(`dashboard/bookingreq`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    bookingContractsData
      .then((response: any) => {
        if (response.status === 200) {
          setBookingContracts(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error('RESPONSE', error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const previousData = () => {
    const previousContractsData = privateAxios.get(`dashboard/previouscontract`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    previousContractsData
      .then((response: any) => {
        if (response.status === 200) {
          setPreviousContracts(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error('RESPONSE', error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const onGoingData = () => {
    const onGoingContractsData = privateAxios.get(`dashboard/ongoingcontract`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    onGoingContractsData
      .then((response: any) => {
        if (response.status === 200) {
          setOnGoingContracts(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error('RESPONSE', error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const cancelledData = () => {
    const cancelledContractsData = privateAxios.get(`dashboard/cancelledcontract`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    cancelledContractsData
      .then((response: any) => {
        if (response.status === 200) {
          setCancelContracts(response.data.data);
        }
      })
      .catch((error: any) => {
        console.error('RESPONSE', error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleClick = () => {
    setOpen(true);
    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12}>
          <Navbar />
        </Grid>
        <Grid item xs={12}>
          <Grid className="bookingReqq" container spacing={2} sx={{ backgroundColor: '#F7F8F8', marginTop: '90px' }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={2}
              sx={{ padding: '10px', borderRight: '1px solid #C3BFD7', height: '100vh' }}>
              {titles.map((title, index) => {
                if (title === 'User Contracts' && !props.userType) {
                  return null;
                }
                return (
                  <div
                    key={index}
                    style={{
                      marginLeft: '8px',
                      padding: '10px',
                      margin: '10px',
                      cursor: 'pointer',
                      borderRadius: '10px',
                      backgroundColor: selectedTitle === title ? '#fff' : 'transparent',
                      color: selectedTitle === title ? '#000000' : '#424866',
                    }}
                    onClick={() => handleTitleClick(title)}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 500, fontFamily: "'Onest', sans-serif" }}>
                      {title}
                    </Typography>
                  </div>
                );
              })}
            </Grid>
            {props.userType ? (
              <Grid item xs={12} sm={12} md={9} lg={10} sx={{ height: '100%', padding: '10px', overflowY: 'auto' }}>
                {loading && (
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={() => setLoading(false)}>
                    <CircularProgress color="success" />
                  </Backdrop>
                )}
                {!loading && (
                  <>
                    {selectedTitle === 'My Contracts' && onGoingContracts && (
                      <BookingDetails datas={onGoingContracts} type={selectedTitle} onGoingData={onGoingData} />
                    )}
                    {selectedTitle === 'User Contracts' && bookingContracts && (
                      <BookingDetails datas={bookingContracts} type={selectedTitle} onGoingData={bookingData} />
                    )}
                    {selectedTitle === 'Previous Contracts' && previousContracts && (
                      <BookingDetails datas={previousContracts} type={selectedTitle} onGoingData={onGoingData} />
                    )}
                    {selectedTitle === 'Cancelled Contracts' && cancelContracts && (
                      <BookingDetails datas={cancelContracts} type={selectedTitle} onGoingData={onGoingData} />
                    )}
                  </>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={9} lg={10} sx={{ height: '100%', padding: '10px', overflowY: 'auto' }}>
                {loading && (
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                    onClick={() => setLoading(false)}>
                    <CircularProgress color="success" />
                  </Backdrop>
                )}
                {!loading && (
                  <>
                    {selectedTitle === 'My Contracts' && onGoingContracts && (
                      <BookingDetails datas={onGoingContracts} type={selectedTitle} onGoingData={onGoingData} />
                    )}
                    {selectedTitle === 'Previous Contracts' && previousContracts && (
                      <BookingDetails datas={previousContracts} type={selectedTitle} onGoingData={onGoingData} />
                    )}
                    {selectedTitle === 'Cancelled Contracts' && cancelContracts && (
                      <BookingDetails datas={cancelContracts} type={selectedTitle} onGoingData={onGoingData} />
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <CustomSnackbar open={open} autoHideDuration={3000} onClose={handleClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    userType: state.userType,
  };
}

export default connect(mapDispatchToProps)(Bookings);

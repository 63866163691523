/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CardSlider from './CardSlider';
import { Backdrop, Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { cardSliderDetails } from '../../Interface/PaymentInterface/IPayment';
import CardDetailsGrid from './CardDetailsGrid';
import EditCardDialog from './EditCardDialog';
import useAxiosPrivate from '../../../Hooks/useAxioPrivate';
import { Transaction } from '../../Interface/PaymentInterface/IPayment';
import CustomSnackbar from '../../../common/Snackbar';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userDisElgble } from '../../../reducer/action';
import { AppDispatch } from '../../../reducer/store';

const PaymentDetails = (props: any) => {
  const privateAxios = useAxiosPrivate();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [cards, setCards] = useState<cardSliderDetails[]>([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editData, setEditdata] = useState<any>([]);
  const [transactionData, setTransactionData] = useState<Transaction[]>();
  const [open, setOpen] = React.useState(false);
  const [toast, setToast] = React.useState({ message: '', type: 'success' });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTransactionDetails();
    getUserDetails();
    // if (data?.stripeCustomerId) {
    //   getAllCards(data?.stripeCustomerId);
    // }
  }, []);

  const handleClick = () => {
    setOpen(true);

    setTimeout(() => {
      handleClose();
    }, 2000);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getAllCards = (id: string) => {
    const getCardDetails = privateAxios.get(`payment/stripe/getcard?customerId=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getCardDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          setCards(data);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const getTransactionDetails = () => {
    const getTransactionInfo = privateAxios.get(`transaction/gettransaction`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    getTransactionInfo
      .then((response: any) => {
        setTransactionData(response.data.data);
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleEditCard = (id: any, card: any) => {
    setEditdata(card);
    setOpenEditDialog(true);
  };

  const handleDeleteCard = (id: any) => {
    // setCards((prevCards) => prevCards.filter((_, i) => i !== id));

    const deleteCard = privateAxios.delete(`payment/stripe/deletecard?cardId=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    deleteCard
      .then((response: any) => {
        getUserDetails();
        setToast({ message: response.data.message.value, type: 'success' });
        handleClick();
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const handleDownloadLink = (downloadLink: any) => {
    const anchor = document.createElement('a');
    anchor.href = downloadLink;

    anchor.download = downloadLink;
    anchor.click();
  };

  const addCardDetails = (data: any) => {
    const stripeCreate = privateAxios.post(`payment/stripe/addcard`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    stripeCreate
      .then((response: any) => {
        if (response.data.status === true) {
          clearCardDetails();
          getUserDetails();
          setToast({ message: response.data.message.value, type: 'success' });
          handleClick();
        }
      })
      .catch((error: any) => {
        console.error('RESPONSE', error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const getUserDetails = () => {
    setLoading(true);
    const getDetails = privateAxios.get(`user/getuserdetails`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    getDetails
      .then((response: any) => {
        if (response.status === 200) {
          const data = response.data.data;
          if (data?.stripeCustomerId !== null) {
            getAllCards(data?.stripeCustomerId);
          }
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setToast({ message: error.response.data.message.value, type: 'error' });
        handleClick();
      });
  };

  const [clear, setClear] = useState(false);
  const clearCardDetails = () => {
    setClear(true);
  };

  const handleSubscribeNow = () => {
    dispatch(userDisElgble(false));
    navigate('/premium/choose-payment');
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          onClick={() => setLoading(false)}>
          <CircularProgress color="success" />
        </Backdrop>
      )}
      {!props.userPM ? (
        <Box
          sx={{
            p: '20px',
            backgroundColor: '#fff',
            borderRadius: '10px',
            mt: cards && cards.length > 0 ? '45px' : '0px',
          }}>
          <Typography
            sx={{ color: '#2CB512', fontSize: '18px', fontWeight: 600, fontFamily: "'Onest', sans-serif !important" }}>
            Welcome to Your Trial Period!
          </Typography>
          <Typography sx={{ fontFamily: "'Onest', sans-serif !important", mt: '10px' }}>
            Thank you for starting your trial with us! During this period, you&apos;ll get a taste of our premium content and
            features designed to elevate your experience.
          </Typography>
          <Typography sx={{ fontFamily: "'Onest', sans-serif !important", mt: '10px' }}>
            Ready to unlock full access and continue enjoying all the benefits?
          </Typography>
          <Button
            className="common-button-green"
            type="submit"
            variant="contained"
            sx={{
              // marginLeft: 1,
              mt: 2,
              '&:hover': {
                background: 'var(--Primary, #2CB512)',
              },
            }}
            onClick={handleSubscribeNow}>
            Subscribe Now
          </Button>
        </Box>
      ) : (
        <>
          {cards && cards.length > 0 && (
            <Box sx={{ p: '20px', backgroundColor: '#fff', borderRadius: '10px' }}>
              <Grid container spacing={2} sx={{ backgroundColor: '#fff', mt: '5px' }}>
                <Grid item lg={6.8} md={12} xs={12}>
                  <Grid
                    sx={{
                      padding: '20px',
                      height: '400px',
                      textAlign: 'center',
                      backgroundColor: '#fff',
                      borderRadius: '16px',
                    }}>
                    <CardSlider cards={cards} onEdit={handleEditCard} onDelete={handleDeleteCard} />
                  </Grid>
                </Grid>

                <Grid
                  item
                  lg={4.8}
                  md={12}
                  xs={12}
                  sx={{
                    backgroundColor: '#fff',
                    pb: '15px',
                    borderRadius: '16px',
                    border: '1px solid #E6E9E8',
                  }}>
                  <CardDetailsGrid isEditMode={false} handleAddCard={addCardDetails} clearCardDetails={clear} />
                </Grid>
              </Grid>
            </Box>
          )}

          <EditCardDialog open={openEditDialog} card={editData} onCancel={() => setOpenEditDialog(false)} />
          <Box
            sx={{
              p: '20px',
              backgroundColor: '#fff',
              borderRadius: '10px',
              mt: cards && cards.length > 0 ? '45px' : '0px',
            }}>
            <Grid container>
              {/* <Grid
            item
            xs={12}
            sx={{
              padding: '20px 24px',
              border: '1px solid #E6E9E8',
              borderRadius: '24px',
              minHeight: '138px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
            <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  sx={{
                    gap: '6px',
                    fontFamily: "'Onest', sans-serif",
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#73787E',
                    mt: '5px',
                  }}>
                  Next Billing Date
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                <Button className="common-button-green" sx={{ color: '#fff', width: '165px', mt: '15px' }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "'Onest', sans-serif", fontWeight: 600, fontSize: '20px', lineHeight: '30px' }}>
                  Aug 1, 2023
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    gap: '6px',
                    fontFamily: "'Onest', sans-serif",
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#73787E',
                    mt: '5px',
                  }}>
                  Subscription fees are billed at beginning of each period and may take dew days after billing date to appear
                  on your account
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
            </Grid>
            {transactionData &&
              transactionData.map((item, index) => (
                <Grid key={index} container sx={{ mt: '20px' }}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: '20px 24px',
                      border: '1px solid #E6E9E8',
                      borderRadius: '24px',
                      minHeight: '138px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}>
                    <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "'Onest', sans-serif", fontWeight: 600, fontSize: '20px', lineHeight: '30px' }}>
                          {item.billDate}
                        </Typography>

                        <Typography
                          variant="body1"
                          sx={{
                            gap: '6px',
                            fontFamily: "'Onest', sans-serif",
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#73787E',
                            mt: '5px',
                          }}>
                          {item.description}
                        </Typography>

                        <Typography
                          variant="body1"
                          sx={{
                            gap: '6px',
                            fontFamily: "'Onest', sans-serif",
                            fontWeight: 400,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#73787E',
                            mt: '5px',
                          }}>
                          {item.startDate} - {item.endDate}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: "'Onest', sans-serif",
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '24px',
                            color: '#000',
                          }}>
                          {item.amount}
                        </Typography>
                        <Button
                          className="common-button-white"
                          sx={{ color: '#2CB512', width: '165px', mt: '15px' }}
                          onClick={() => handleDownloadLink(item.downloadLink)}>
                          Download receipt
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Box>
        </>
      )}

      <CustomSnackbar open={open} autoHideDuration={2000} onClose={handleClose} toast={toast} />
    </>
  );
};

function mapDispatchToProps(state: any) {
  return {
    status: state.status,
    userPM: state.userPM,
  };
}

export default connect(mapDispatchToProps)(PaymentDetails);
